import type { MarketViewMicroAssetClasses } from "$root/api/api-gen";

export const marketViewAssetClassMap = {
	USA_EQUITY_LARGE_CAP: { group: "equity" },
	EU_EQUITY_LARGE_CAP: { group: "equity" },
	JAPAN_EQUITY_LARGE_CAP: { group: "equity" },
	EMERGING_MARKETS_EQUITY: { group: "equity" },
	PACIFIC_EX_JAPAN_EQUITY_LARGE_CAP: { group: "equity" },
	UK_EQUITY: { group: "equity" },
	CHINA_EQUITY: { group: "equity" },
	AUSTRALIA_EQUITY: { group: "equity" },
	EMEA_EQUITY: { group: "equity" },
	CANADA_EQUITY: { group: "equity" },
	FRANCE_EQUITY: { group: "equity" },
	GERMANY_EQUITY: { group: "equity" },
	HONG_KONG_EQUITY: { group: "equity" },
	ITALY_EQUITY: { group: "equity" },
	LATIN_AMERICA_EQUITY: { group: "equity" },
	SINGAPORE_EQUITY: { group: "equity" },
	SPAIN_EQUITY: { group: "equity" },
	SWITZERLAND_EQUITY: { group: "equity" },
	EU_EQUITY_COMMUNICATION_SERVICES: { group: "equity" },
	EU_EQUITY_CONSUMER_DISCRETIONARY: { group: "equity" },
	EU_EQUITY_CONSUMER_STAPLES: { group: "equity" },
	EU_EQUITY_ENERGY: { group: "equity" },
	EU_EQUITY_FINANCIALS: { group: "equity" },
	EU_EQUITY_HEALTHCARE: { group: "equity" },
	EU_EQUITY_INDUSTRIALS: { group: "equity" },
	EU_EQUITY_INFORMATION_TECHNOLOGY: { group: "equity" },
	EU_EQUITY_MATERIALS: { group: "equity" },
	EU_EQUITY_REAL_ESTATE: { group: "equity" },
	EU_EQUITY_SMALL_CAP: { group: "equity" },
	EU_EQUITY_UTILITIES: { group: "equity" },
	USA_EQUITY_COMMUNICATION_SERVICES: { group: "equity" },
	USA_EQUITY_CONSUMER_DISCRETIONARY: { group: "equity" },
	USA_EQUITY_CONSUMER_STAPLES: { group: "equity" },
	USA_EQUITY_ENERGY: { group: "equity" },
	USA_EQUITY_FINANCIALS: { group: "equity" },
	USA_EQUITY_HEALTHCARE: { group: "equity" },
	USA_EQUITY_INDUSTRIALS: { group: "equity" },
	USA_EQUITY_INFORMATION_TECHNOLOGY: { group: "equity" },
	USA_EQUITY_MATERIALS: { group: "equity" },
	USA_EQUITY_REAL_ESTATE: { group: "equity" },
	USA_EQUITY_UTILITIES: { group: "equity" },
	EMERGING_MARKETS_EX_CHINA_EQUITY: { group: "equity" },
	CHINA_EQUITY_COMMUNICATION_SERVICES: { group: "equity" },
	CHINA_EQUITY_CONSUMER_DISCRETIONARY: { group: "equity" },
	CHINA_EQUITY_CONSUMER_STAPLES: { group: "equity" },
	CHINA_EQUITY_ENERGY: { group: "equity" },
	CHINA_EQUITY_FINANCIALS: { group: "equity" },
	CHINA_EQUITY_HEALTHCARE: { group: "equity" },
	CHINA_EQUITY_INDUSTRIALS: { group: "equity" },
	CHINA_EQUITY_INFORMATION_TECHNOLOGY: { group: "equity" },
	CHINA_EQUITY_MATERIALS: { group: "equity" },
	CHINA_EQUITY_REAL_ESTATE: { group: "equity" },
	CHINA_EQUITY_UTILITIES: { group: "equity" },
	EMERGING_MARKETS_EQUITY_COMMUNICATION_SERVICES: { group: "equity" },
	EMERGING_MARKETS_EQUITY_CONSUMER_DISCRETIONARY: { group: "equity" },
	EMERGING_MARKETS_EQUITY_CONSUMER_STAPLES: { group: "equity" },
	EMERGING_MARKETS_EQUITY_ENERGY: { group: "equity" },
	EMERGING_MARKETS_EQUITY_FINANCIALS: { group: "equity" },
	EMERGING_MARKETS_EQUITY_HEALTHCARE: { group: "equity" },
	EMERGING_MARKETS_EQUITY_INDUSTRIALS: { group: "equity" },
	EMERGING_MARKETS_EQUITY_INFORMATION_TECHNOLOGY: { group: "equity" },
	EMERGING_MARKETS_EQUITY_MATERIALS: { group: "equity" },
	EMERGING_MARKETS_EQUITY_REAL_ESTATE: { group: "equity" },
	EMERGING_MARKETS_EQUITY_UTILITIES: { group: "equity" },
	EQUITY_GLOBAL: { group: "equity" },
	FRONTIER_MARKET_EQUITY: { group: "equity" },
	JAPAN_EQUITY_COMMUNICATION_SERVICES: { group: "equity" },
	JAPAN_EQUITY_CONSUMER_DISCRETIONARY: { group: "equity" },
	JAPAN_EQUITY_CONSUMER_STAPLES: { group: "equity" },
	JAPAN_EQUITY_ENERGY: { group: "equity" },
	JAPAN_EQUITY_FINANCIALS: { group: "equity" },
	JAPAN_EQUITY_HEALTHCARE: { group: "equity" },
	JAPAN_EQUITY_INDUSTRIALS: { group: "equity" },
	JAPAN_EQUITY_INFORMATION_TECHNOLOGY: { group: "equity" },
	JAPAN_EQUITY_MATERIALS: { group: "equity" },
	JAPAN_EQUITY_REAL_ESTATE: { group: "equity" },
	JAPAN_EQUITY_UTILITIES: { group: "equity" },
	KOREA_EQUITY: { group: "equity" },
	EMERGING_MARKETS_FIXED_INCOME_CORPORATE: { group: "fixedIncome" },
	EMERGING_MARKETS_FIXED_INCOME_GOVERNMENT_LOCAL_CURRENCY: { group: "fixedIncome" },
	EMERGING_MARKETS_FIXED_INCOME_GOVERNMENT_STRONG_CURRENCY: { group: "fixedIncome" },
	USA_FIXED_INCOME: { group: "fixedIncome" },
	USA_FIXED_INCOME_LONG_GOVERNMENT: { group: "fixedIncome" },
	USA_FIXED_INCOME_MEDIUM_GOVERNMENT: { group: "fixedIncome" },
	USA_FIXED_INCOME_SHORT_GOVERNMENT: { group: "fixedIncome" },
	USA_FIXED_INCOME_CORPORATE_HIGH_YIELD: { group: "fixedIncome" },
	USA_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE: { group: "fixedIncome" },
	USA_FIXED_INCOME_GOVERNMENT: { group: "fixedIncome" },
	EU_FIXED_INCOME: { group: "fixedIncome" },
	EU_FIXED_INCOME_LONG_GOVERNMENT: { group: "fixedIncome" },
	EU_FIXED_INCOME_MEDIUM_GOVERNMENT: { group: "fixedIncome" },
	EU_FIXED_INCOME_SHORT_GOVERNMENT: { group: "fixedIncome" },
	EU_FIXED_INCOME_CORPORATE_HIGH_YIELD: { group: "fixedIncome" },
	EU_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE: { group: "fixedIncome" },
	EU_FIXED_INCOME_GOVERNMENT: { group: "fixedIncome" },
	EMERGING_MARKETS_FIXED_INCOME_GOVERNMENT: { group: "fixedIncome" },
	EMERGING_MARKETS_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE: { group: "fixedIncome" },
	EMERGING_MARKETS_FIXED_INCOME_STRONG_CURRENCY: { group: "fixedIncome" },
	EMERGING_MARKETS_FIXED_INCOME_LOCAL_CURRENCY: { group: "fixedIncome" },
	CHINA_FIXED_INCOME_GOVERNMENT: { group: "fixedIncome" },
	COMMODITIES_PRECIOUS_METALS: { group: "commodities" },
	COMMODITIES_INDUSTRIAL_METALS: { group: "commodities" },
	COMMODITIES_ENERGY: { group: "commodities" },
	COMMODITIES_AGRICULTURE: { group: "commodities" },
	COMMODITIES_GLOBAL: { group: "commodities" },
	COMMODITIES_GOLD: { group: "commodities" },
	ITALY_FIXED_INCOME_SHORT_GOVERNMENT: { group: "fixedIncome" },
	ITALY_FIXED_INCOME_MEDIUM_GOVERNMENT: { group: "fixedIncome" },
	ITALY_FIXED_INCOME_LONG_GOVERNMENT: { group: "fixedIncome" },
	SPAIN_FIXED_INCOME_SHORT_GOVERNMENT: { group: "fixedIncome" },
	SPAIN_FIXED_INCOME_MEDIUM_GOVERNMENT: { group: "fixedIncome" },
	SPAIN_FIXED_INCOME_LONG_GOVERNMENT: { group: "fixedIncome" },
	FRANCE_FIXED_INCOME_SHORT_GOVERNMENT: { group: "fixedIncome" },
	FRANCE_FIXED_INCOME_MEDIUM_GOVERNMENT: { group: "fixedIncome" },
	FRANCE_FIXED_INCOME_LONG_GOVERNMENT: { group: "fixedIncome" },
	GERMANY_FIXED_INCOME_SHORT_GOVERNMENT: { group: "fixedIncome" },
	GERMANY_FIXED_INCOME_MEDIUM_GOVERNMENT: { group: "fixedIncome" },
	GERMANY_FIXED_INCOME_LONG_GOVERNMENT: { group: "fixedIncome" },
	EU_FIXED_INCOME_CORPORATE_HIGH_YIELD_EX_FIN: { group: "fixedIncome" },
	EU_FIXED_INCOME_CORPORATE_HIGH_YIELD_FIN: { group: "fixedIncome" },
	EU_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE_EX_FIN: { group: "fixedIncome" },
	EU_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE_FIN: { group: "fixedIncome" },
	USA_FIXED_INCOME_CORPORATE_HIGH_YIELD_EX_FIN: { group: "fixedIncome" },
	USA_FIXED_INCOME_CORPORATE_HIGH_YIELD_FIN: { group: "fixedIncome" },
	USA_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE_EX_FIN: { group: "fixedIncome" },
	USA_FIXED_INCOME_CORPORATE_INVESTMENT_GRADE_FIN: { group: "fixedIncome" },
	GLOBAL_FIXED_INCOME_CORPORATE_SHORT_INVESTMENT_GRADE: { group: "fixedIncome" },
	GLOBAL_FIXED_INCOME_GOVERNMENT_INFLATION_LINKED: { group: "fixedIncome" },
	ASIA_FIXED_INCOME_CORPORATE: { group: "fixedIncome" },
	ASIA_FIXED_INCOME_GOVERNMENT: { group: "fixedIncome" },
	ASIA_FIXED_INCOME_GOVERNMENT_LOCAL_CURRENCY: { group: "fixedIncome" },
	ASIA_FIXED_INCOME_HIGH_YIELD: { group: "fixedIncome" },
	EU_FIXED_INCOME_CORPORATE_LONG: { group: "fixedIncome" },
	EU_FIXED_INCOME_CORPORATE_SHORT: { group: "fixedIncome" },
	EU_FIXED_INCOME_GOVERNMENT_INFLATION_LINKED: { group: "fixedIncome" },
	GLOBAL_FIXED_INCOME_CORPORATE: { group: "fixedIncome" },
	GLOBAL_FIXED_INCOME_GOVERNMENT: { group: "fixedIncome" },
	US_FIXED_INCOME_CORPORATE_LONG: { group: "fixedIncome" },
	US_FIXED_INCOME_CORPORATE_SHORT: { group: "fixedIncome" },
	MONEY_MARKET: { group: "moneyMarket" },
	AUSTRALIA_MONEY_MARKET: { group: "moneyMarket" },
	CANADA_MONEY_MARKET: { group: "moneyMarket" },
	EU_MONEY_MARKET: { group: "moneyMarket" },
	SINGAPORE_MONEY_MARKET: { group: "moneyMarket" },
	SWITZERLAND_MONEY_MARKET: { group: "moneyMarket" },
	UK_MONEY_MARKET: { group: "moneyMarket" },
	US_FIXED_INCOME_GOVERNMENT_INFLATION_LINKED: { group: "moneyMarket" },
	USA_MONEY_MARKET: { group: "moneyMarket" },
	USD_CURRENCY: { group: "currencies" },
	ALTERNATIVE_GLOBAL: { group: "alternative" },
	JP_MONEY_MARKET: { group: "moneyMarket" },
	CURRENCY_USDAUD: { group: "currencies" },
	CURRENCY_USDCAD: { group: "currencies" },
	CURRENCY_USDCHF: { group: "currencies" },
	CURRENCY_USDEUR: { group: "currencies" },
	CURRENCY_USDGBP: { group: "currencies" },
	CURRENCY_USDJPY: { group: "currencies" },
	CURRENCY_USDZAR: { group: "currencies" },

	COMMODITIES_PLATINUM: { group: "commodities" },
	SOUTH_AFRICA_ALTERNATIVE_REAL_ESTATE: { group: "equity" },
	SOUTH_AFRICA_EQUITY: { group: "equity" },
	SOUTH_AFRICA_FIXED_INCOME_AGGREGATE: { group: "fixedIncome" },
	SOUTH_AFRICA_FIXED_INCOME_GOVERNMENT_INFLATION_LINKED: { group: "fixedIncome" },
	SOUTH_AFRICA_MONEY_MARKET: { group: "moneyMarket" }
} satisfies {
	[key in MarketViewMicroAssetClasses]: {
		group: "commodities" | "fixedIncome" | "equity" | "currencies" | "moneyMarket" | "alternative";
	};
};
