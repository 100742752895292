import type {
	InvestmentCompositionResponse,
	InvestmentSummary,
	ReviewTicker,
	UserCompositionColumnOrdering,
	UserCompositionColumnPreference,
	UserCompositionColumnPreferencePreferenceTypeEnum,
	UserEnhancementCompositionColumnOrdering,
	UserEnhancementCompositionColumnPreference,
	UserEnhancementCompositionColumnPreferencePreferenceTypeEnum,
} from "$root/api/api-gen";
import {
	IntegrationsControllerApiFactory,
	InvestmentEnhancementExportControllerApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentExportControllerApiFactory,
	InvestmentExportConverterType,
	InvestmentReportsControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import type { ColumnMetadata } from "$root/components/tables-extra/CustomizeColumns";
import { spawnCustomizeColumnsDialog } from "$root/components/tables-extra/CustomizeColumns";
import type { PortfolioAlert } from "$root/functional-areas/portfolio/alerts";
import { useUserValue } from "$root/functional-areas/user";
import useCompositionDownload from "$root/hooks/useCompositionDownload";
import { platformToast } from "$root/notification-system/toast";
import { usePortfolioStudioTableSettings } from "$root/pages/PortfoliosStudio/portfolio-studio-table-settings";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { actionsColumn } from "$root/ui-lib/interactive-collections/common-table-actions";
import { preventSubmitOnPressEnter } from "$root/utils/experimental";
import { downloadContentDisposition } from "$root/utils/files";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { Card } from "$root/widgets-architecture/layout/Card";
import { Checkbox, DropdownMenu, Icon, NestedTransition, TableV2, sortRows } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useTranslation } from "react-i18next";
import { PortfolioQueryWidgetBase, WidgetStatus, portfolioWidgetMissingDataReason } from "../PortfolioWidgetStatus";
import { useCompositionColumn } from "./columns";
import { useSearchableInstrumentTable } from "$root/functional-areas/instruments/hooks";
import { DebouncedSearchInput } from "$root/components/DebouncedSearchInput";
import { useState } from "react";
import { AxiosResponse } from "axios";

type PortfolioCompositionProps = {
	portfolio: InvestmentSummary;
	enhanced: boolean;
	reportExcutionCounter: number;
	alerts: PortfolioAlert[];
};

export const PortfolioComposition = (props: PortfolioCompositionProps): JSX.Element => {
	const { enhanced, portfolio, reportExcutionCounter } = props;
	const uuid = props.portfolio?.uuid;

	const [portfolioNested, setPortfolioNested] = useState(portfolio.currentlyContainsNestedPortfolios ?? false);
	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const investmentEnhancementReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const exportApi = useApiGen(InvestmentExportControllerApiFactory);
	const exportEnhancedApi = useApiGen(InvestmentEnhancementExportControllerApiFactory);

	const { t } = useTranslation();

	const query = useQueryNoRefetch(
		[
			"compositionProvider",
			uuid,
			enhanced,
			portfolio?.status,
			reportExcutionCounter,
			portfolio.currentlyContainsNestedPortfolios,
			portfolioNested,
		],
		{
			queryFn: async () => {
				let data: InvestmentCompositionResponse | undefined = undefined;
				if (!uuid) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "Composition"),
					};
				}

				if (portfolio.currentlyContainsNestedPortfolios && !portfolioNested) {
					data = await axiosExtract(
						props.enhanced
							? investmentEnhancementReportApi.getInvestmentEnhancementAggregateComposition(uuid)
							: investmentReportApi.getInvestmentAggregateComposition(uuid),
					);
				} else {
					data = await axiosExtract(
						props.enhanced
							? investmentEnhancementReportApi.getInvestmentComposition1(uuid)
							: investmentReportApi.getInvestmentComposition(uuid),
					);
				}

				// PROPOSAL: we can either remap the response here or ask the BE to give us the
				// appropriate data status (letting us delete portfolioWidgetMissingDataReason)
				if (data.composition) {
					return {
						data: {
							composition: data.composition,
							userCompositionColumnOrderingPreferences: !enhanced
								? (await axiosExtract(portfolioStudioPreferencesApi.getUserCompositionColumnOrderingPreferences()))
										.userCompositionColumnPreferences
								: [],
							userEnhancementCompositionColumnOrderingPreferences: enhanced
								? (
										await axiosExtract(
											portfolioStudioPreferencesApi.getUserEnhancementCompositionColumnOrderingPreferences(),
										)
								  ).userEnhancementCompositionColumnPreferences
								: [],
						},
						widgetStatus: WidgetStatus.READY,
					};
				}

				return {
					data: undefined,
					widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "Composition"),
				};
			},
		},
	);

	const {
		filtered,
		setQuery: setSearchInstrument,
		query: searchInstrument,
	} = useSearchableInstrumentTable(query?.data?.data?.composition ?? [], {
		mode: "keyword",
	});

	const exportPortfolioComposition = async (downloadTarget: "composition" | "trades") => {
		let response: AxiosResponse<any, any> | undefined = undefined;
		if (portfolioNested) {
			response = enhanced
				? downloadTarget === "composition"
					? await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "FULL_COMPOSITION", {
							responseType: "blob",
					  })
					: await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "TRADES_ONLY", { responseType: "blob" })
				: await exportApi.exportComposition(portfolio!.uuid!, { responseType: "blob" });
		} else {
			response = enhanced
				? downloadTarget === "composition"
					? await exportEnhancedApi.exportEnhancedAggregateComposition(portfolio!.uuid!, "FULL_COMPOSITION", {
							responseType: "blob",
					  })
					: await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "TRADES_ONLY", { responseType: "blob" })
				: await exportApi.exportAggregateComposition(portfolio!.uuid!, { responseType: "blob" });
		}

		trackMixPanelEvent("Portfolio", {
			Type: "Export",
			Area: downloadTarget,
			ID: portfolio!.uuid!,
		});
		downloadContentDisposition(response);
	};

	return (
		<Card>
			<div className="flex justify-between mb-6">
				<DebouncedSearchInput
					query={searchInstrument}
					onChange={setSearchInstrument}
					name="search"
					maxLength={60}
					placeholder={t("PORTFOLIOS.CONSTRAINTS_TARGETS.PORTFOLIO_LIST")}
					onKeyDown={preventSubmitOnPressEnter}
					style={{ width: 692 }}
				/>
				<div className="flex space-x-2">
					{portfolio.currentlyContainsNestedPortfolios && (
						<Checkbox switchType="switch" checked={!portfolioNested} onChange={setPortfolioNested}>
							Aggregate view
						</Checkbox>
					)}
					<DropdownMenu
						trigger={({ innerRef, open, ...forward }) => (
							<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
								<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
							</button>
						)}
						actions={[
							{
								icon: "xls",
								onClickAsync: () => exportPortfolioComposition("composition"),
								label: t("COMPOSITION.DOWNLOAD_TITLE"),
							},
							portfolio?.status === "PROPOSAL_READY" &&
								enhanced && {
									icon: "xls",
									onClickAsync: async () => {
										await exportPortfolioComposition("trades");
									},
									label: t("COMPOSITION.DOWNLOAD_TRADES_TITLE"),
								},
						]}
					/>
				</div>
			</div>
			<PortfolioQueryWidgetBase query={query}>
				{(
					{
						composition,
						userCompositionColumnOrderingPreferences,
						userEnhancementCompositionColumnOrderingPreferences,
					},
					{ refetch },
				) => (
					<PortfolioCompositionInner
						ctx={props}
						filtered={filtered}
						rows={composition ?? []}
						columnPreferences={{
							current: userCompositionColumnOrderingPreferences ?? [],
							enhance: userEnhancementCompositionColumnOrderingPreferences ?? [],
						}}
						onAsyncChangeColumnPreferences={async (columns) => {
							if (enhanced) {
								const payload = {
									userEnhancementCompositionColumnPreferences: columns.map((preference) => ({
										enabled: preference.visible,
										preferenceType: preference.id as UserEnhancementCompositionColumnPreferencePreferenceTypeEnum,
									})),
								} satisfies UserEnhancementCompositionColumnOrdering;
								await portfolioStudioPreferencesApi.setUserEnhancementCompositionColumnOrderingPreferences(payload);
							} else {
								const payload = {
									userCompositionColumnPreferences: columns.map((preference) => ({
										enabled: preference.visible,
										preferenceType: preference.id as UserCompositionColumnPreferencePreferenceTypeEnum,
									})),
								} satisfies UserCompositionColumnOrdering;
								await portfolioStudioPreferencesApi.setUserCompositionColumnOrderingPreferences(payload);
							}

							await refetch();
						}}
					/>
				)}
			</PortfolioQueryWidgetBase>
		</Card>
	);
};

function PortfolioCompositionInner(props: {
	ctx: PortfolioCompositionProps;
	rows: ReviewTicker[];
	filtered: ReviewTicker[];
	columnPreferences: {
		current: UserCompositionColumnPreference[];
		enhance: UserEnhancementCompositionColumnPreference[];
	};
	onAsyncChangeColumnPreferences: (
		columns: ColumnMetadata<
			UserEnhancementCompositionColumnPreferencePreferenceTypeEnum | UserCompositionColumnPreferencePreferenceTypeEnum
		>[],
	) => Promise<void>;
}) {
	const { columnPreferences, onAsyncChangeColumnPreferences } = props;
	const { enhanced } = props.ctx;
	const user = useUserValue();
	const { t } = useTranslation();
	const {
		portfolioCompositionOrderByName,
		setPortfolioCompositionOrderByName,
		portfolioCompositionEnhanceOrderByName,
		setPortfolioCompositionEnhanceOrderByName,
	} = usePortfolioStudioTableSettings();

	const customizableColumns = (enhanced ? columnPreferences.enhance : columnPreferences.current ?? []).map((c) => ({
		label: c.preferenceType ? t(`TABLE.HEADERS.${c.preferenceType}`) : "-",
		id: c.preferenceType!,
		visible: c.enabled ?? false,
		disabled:
			c.preferenceType === "INSTRUMENT_NAME" ||
			c.preferenceType === "WEIGHT" ||
			c.preferenceType === "CURRENT_WEIGHT" ||
			c.preferenceType === "ENHANCED_WEIGHT" ||
			c.preferenceType === "DIFFERENCE",
		hidden: c.preferenceType === "INSTRUMENT_NAME",
	}));

	const columns = [
		...useCompositionColumn(
			enhanced ? columnPreferences.enhance : columnPreferences.current,
			props.ctx.portfolio,
			props.rows,
			props.ctx.alerts,
		),
		actionsColumn({
			onSettingsClick: () =>
				spawnCustomizeColumnsDialog({
					columns: customizableColumns,
					onSubmitAsync: onAsyncChangeColumnPreferences,
				}),
			dropdownActions: [],
		}),
	];

	return (
		<>
			<TableV2.BaseHScrollTable
				columns={columns}
				rows={sortRows({
					rows: props.filtered,
					columns,
					orderByArr: enhanced ? portfolioCompositionEnhanceOrderByName : portfolioCompositionOrderByName,
				})}
				pinnedColumns={[
					{ name: "instrument", side: "left" },
					{ name: "settings-action", side: "right" },
				]}
				orderBy={enhanced ? portfolioCompositionEnhanceOrderByName : portfolioCompositionOrderByName}
				onOrderByChange={enhanced ? setPortfolioCompositionEnhanceOrderByName : setPortfolioCompositionOrderByName}
			/>
		</>
	);
}
