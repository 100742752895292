import type { ElementDTO } from "$root/api/api-gen";
import GraphLegend from "$root/components/GraphLegend";
import { PaletteColors } from "$root/styles/themePalette";
import { handlePreviousMarketData } from "$root/utils/marketUtils";
import { Icon } from "@mdotm/mdotui/components";
import type { FC } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import type { TableParams } from "../OutlookExpected/OutlookExpectedTable";
import OutlookExpectedTable from "../OutlookExpected/OutlookExpectedTable";

type HistorycalVariants =
	| "expected_return_short_term_trend"
	| "expected_return_long_term_trend"
	| "expected_return_short_time_series"
	| "expected_return_long_time_series";

type ERAHistoricalValuePercentileProps = {
	data: ElementDTO[];
};

const ERAHistoricalValuePercentile: FC<ERAHistoricalValuePercentileProps> = ({ data: ERAData }) => {
	const { t } = useTranslation();

	const composeTableRowData = useCallback(
		(data: Array<ElementDTO>) => {
			const composeLabel = (historycalLabel?: HistorycalVariants) =>
				historycalLabel
					? t(`EXPECTED_RETURNS_ANALYSYS.HISTORICAL_VALUE_PERCENTILE.SERIES.${historycalLabel}`, {
							returnObjects: true,
					  })
					: {
							LABEL: "-",
							TOOLTIP: "-",
					  };

			return data.reduce((acc, { currentValue, prevValue, label }, i) => {
				if (currentValue?.value === undefined) {
					return acc;
				}
				const { LABEL, TOOLTIP } = composeLabel(label as HistorycalVariants | undefined);

				const { previous, tooltip: graphTooltip } = handlePreviousMarketData(
					"outlook",
					(position) => t("EXPECTED_RETURNS_ANALYSYS.EXPECTED_CONTRIBUTION.AXIS", { returnObjects: true })[position],
					{ value: currentValue.value, data: currentValue.data },
					{ value: prevValue?.value, data: prevValue?.data },
				);

				return [
					...acc,
					{
						dynamics: LABEL,
						dynamicsDescription: TOOLTIP,
						current: currentValue.value,
						prev: previous,
						tooltip: graphTooltip,
						index: i,
					},
				];
			}, [] as Array<TableParams>);
		},
		[t],
	);

	const mappedData = composeTableRowData(ERAData);

	return (
		<>
			<div className="mt-5 max-h-[15rem]">
				<OutlookExpectedTable data={mappedData} title="RETURNS" sortable />
			</div>
			<GraphLegend>
				<div className="legend-container light more-space book">
					<Icon icon="driver-arrow-doubledown" color={PaletteColors.NEON_RED} size={16} />
					{t("EXPECTED_RETURNS_ANALYSYS.HISTORICAL_VALUE_PERCENTILE.LEGEND.LOW")}
				</div>
				<div className="legend-container light more-space book">
					<Icon icon="driver-arrow-center" color={PaletteColors.AZURE} size={16} />
					{t("EXPECTED_RETURNS_ANALYSYS.HISTORICAL_VALUE_PERCENTILE.LEGEND.NEUTRAL")}
				</div>
				<div className="legend-container light more-space book">
					<Icon icon="driver-arrow-doubleup" color={PaletteColors.GREENY_BLUE} size={16} />
					{t("EXPECTED_RETURNS_ANALYSYS.HISTORICAL_VALUE_PERCENTILE.LEGEND.HIGH")}
				</div>
			</GraphLegend>
		</>
	);
};

export default ERAHistoricalValuePercentile;
