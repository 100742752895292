import { DateTime, Duration } from "luxon";

export function dateToStringWithoutTime(dateOrString: Date | string): string {
	return dateYYYYMMDD(dateOrString);
}

export function dateWithoutTime(dateOrString: Date | string): Date {
	return new Date(dateYYYYMMDD(dateOrString));
}

export function dateYYYYMMDD(dateOrString: Date | string): string {
	const date = typeof dateOrString === "string" ? new Date(dateOrString) : dateOrString;
	return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
		.getDate()
		.toString()
		.padStart(2, "0")}`;
}

export function dateYYYY_W(dateOrString: Date | DateTime | string): string {
	const date =
		typeof dateOrString === "string"
			? DateTime.fromISO(dateOrString)
			: dateOrString instanceof Date
			  ? DateTime.fromJSDate(dateOrString)
			  : dateOrString;
	return `${date.weekYear}-W${date.weekNumber.toString().padStart(2, "0")}`;
}

export function date_WeekYear(dateOrString: Date | DateTime | string): number {
	const date =
		typeof dateOrString === "string"
			? DateTime.fromISO(dateOrString)
			: dateOrString instanceof Date
			  ? DateTime.fromJSDate(dateOrString)
			  : dateOrString;
	return date.weekYear;
}

export function date_W(dateOrString: Date | DateTime | string): string {
	const date =
		typeof dateOrString === "string"
			? DateTime.fromISO(dateOrString)
			: dateOrString instanceof Date
			  ? DateTime.fromJSDate(dateOrString)
			  : dateOrString;
	return `W${date.weekNumber.toString().padStart(2, "0")}`;
}

export function dateYYYY_W_to_monday(yyyy_w: string): string {
	return dateYYYYMMDD(DateTime.fromISO(yyyy_w).toJSDate());
}

/**
 *
 * @param from e.g. 2020-W01
 * @param to e.g. 2021-W52
 * @returns an array containing all weeks in the range from (included) - to (excluded)
 */
export function weekRange(from: string, to: string): string[] {
	const output: string[] = []; // output will have 52 or 53 weeks depending on the year
	let cursor = DateTime.fromISO(from).plus(Duration.fromObject({ hours: 12 }));
	while (dateYYYY_W(cursor) !== to) {
		output.push(dateYYYY_W(cursor));
		cursor = cursor.plus(Duration.fromObject({ day: 7 }));
	}
	return output;
}
