import type { FileResponse } from "$root/api/api-gen";
// import Footer from "$root/components/EvolvedPrint/components/pdf/Footer";
import FooterPoweredBySphere from "$root/components/EvolvedPrint/components/pdf/FooterPoweredBySphere";
import Header from "$root/components/EvolvedPrint/components/pdf/Header";
import type { ComponentAndPropsPair } from "$root/components/EvolvedPrint/configuration";
import { Renderer } from "$root/components/EvolvedPrint/configuration";
import {
	firstPageHeaderHeight,
	otherPagesHeaderHeight,
	pageFooterHeight,
	paperPlaneId,
} from "$root/components/EvolvedPrint/configuration/shared";
import { mmToPx } from "$root/components/EvolvedPrint/configuration/utils";
import { CircularProgressBar } from "@mdotm/mdotui/components";
import { toClassName } from "@mdotm/mdotui/react-extensions";
import { useEffect, useRef, useState } from "react";

export default function Portrait(props: {
	title: string;
	subtitle: string;
	footer?: {
		logo?: FileResponse;
		description?: string;
	};
	componentAndPropsList: Array<Promise<Array<ComponentAndPropsPair<any, any>>>>;
	autoScroll?: boolean;
}): JSX.Element {
	const [isReady, setIsReady] = useState(false);
	const A4PortraitHeight_mm = 297;
	const A4PortraitWidth_mm = 210;
	const portraitContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		let intervalId: ReturnType<typeof setInterval> | undefined = undefined;
		if (portraitContainerRef.current !== null && props.autoScroll) {
			intervalId = setInterval(() => {
				const reportTemplateRenderId = document.getElementById(paperPlaneId);

				if (reportTemplateRenderId && intervalId) {
					clearInterval(intervalId);
				}
				portraitContainerRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
			}, 700);

			portraitContainerRef.current.addEventListener("wheel", () => {
				clearInterval(intervalId);
			});
		}
	}, [props.componentAndPropsList, props.autoScroll]);

	return (
		<div
			ref={portraitContainerRef}
			className={toClassName({
				"overflow-scroll": props.autoScroll,
			})}
		>
			{isReady ? (
				<div key="ready" data-role="start-printing" data-qualifier="pdfReport/promiseId" id={paperPlaneId} />
			) : (
				<CircularProgressBar
					key="loading"
					value="indeterminate"
					classList="fixed bottom-2 left-1/2 -translate-x-1/2"
					outerDiameter={36}
				/>
			)}
			<div data-size="A4Template">
				<Renderer
					pageHeader={(forward) => <Header {...forward} title={props.title} subtitle={props.subtitle} />}
					pageFooter={() => (
						<FooterPoweredBySphere
						// {...forward}
						// customLogo={props.footer?.logo}
						// customDescription={props.footer?.description}
						/>
					)}
					firstPageHeaderHeight={firstPageHeaderHeight}
					otherPagesHeaderHeight={otherPagesHeaderHeight}
					pageFooterHeight={pageFooterHeight}
					pageHeight={mmToPx(A4PortraitHeight_mm)}
					pageWidth={mmToPx(A4PortraitWidth_mm)}
					componentAndPropsList={props.componentAndPropsList}
					onFinish={() => setIsReady(true)}
				/>
			</div>
		</div>
	);
}
