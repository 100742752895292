import type { ValueOnMap } from "./WorldGrid";

export const rangesByValueOnMapType: Record<
	ValueOnMap,
	{
		min: number;
		max: number;
		sentimentDirection: "positiveMin" | "positiveMax";
		scenarioDescription: { min: number; max: number };
	}
> = {
	REGIME_PROBABILITIES: {
		min: -100,
		max: 100,
		sentimentDirection: "positiveMax",
		scenarioDescription: {
			min: -100,
			max: 100,
		},
	},
	VALUE_AT_RISK: {
		min: -100,
		max: 100,
		sentimentDirection: "positiveMax",
		scenarioDescription: {
			min: 0,
			max: 15,
		},
	},
	VOLATILITY: {
		min: -100,
		max: 100,
		sentimentDirection: "positiveMax",
		scenarioDescription: {
			min: 0,
			max: 30,
		},
	},
};
