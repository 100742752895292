import type { StylableProps } from "@mdotm/mdotui/components";
import { Icon, TextInput } from "@mdotm/mdotui/components";
import type { KeyboardEvent } from "react";
import { useCallback, useEffect, useState } from "react";
import { useDebounced, useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";

type DebouncedSearchInputProps = {
	query: string;
	onChange(debouncedQuery: string): void;
	timeout?: number;
	placeholder?: string;
	name?: string;
	maxLength?: number;
	onKeyDown?(e: KeyboardEvent): void;
} & StylableProps;

export function DebouncedSearchInput({
	query: propsQuery,
	onChange,
	timeout,
	...forward
}: DebouncedSearchInputProps): JSX.Element {
	const [query, _setQuery] = useState(propsQuery);

	const { invoke: dispatchOnChange, cancel } = useDebounced((q: string) => onChange(q), {
		debounceInterval: timeout ?? 200,
	});

	const cancelRef = useUnsafeUpdatedRef(cancel);
	useEffect(() => {
		cancelRef.current();
		_setQuery(propsQuery);
	}, [cancelRef, propsQuery]);

	const setQuery = useCallback(
		(q: string) => {
			_setQuery(q);
			dispatchOnChange(q);
		},
		[_setQuery, dispatchOnChange],
	);

	return <TextInput leftContent={<Icon icon="Search" />} value={query} onChangeText={setQuery} {...forward} />;
}
