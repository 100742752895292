import { createPersistentAtom } from "$root/third-party-integrations/zustand";
import type { Updater } from "$root/utils/functions";

export const PortfolioListViewMode = {
	mixed: "MIXED",
	chart: "CHART",
	table: "TABLE",
} as const;

export type PortfolioListViewMode = (typeof PortfolioListViewMode)[keyof typeof PortfolioListViewMode];

export type PortfolioStudioStoreProps = {
	isightPanel?: {
		open: boolean;
	};
	viewMode: PortfolioListViewMode;
};

export const portfolioStudioDefault: PortfolioStudioStoreProps = {
	isightPanel: { open: true },
	viewMode: "MIXED",
};

export const usePortfolioStudioStore = createPersistentAtom<PortfolioStudioStoreProps>(
	portfolioStudioDefault,
	"portfolio-studio",
);

export function usePortfolioStudioValue(): PortfolioStudioStoreProps {
	return usePortfolioStudioStore((x) => x.value);
}

export function usePortfolioStudioState(): {
	portfolioStudioStore: PortfolioStudioStoreProps;
	setPortfolioStudioStore: (newValue: PortfolioStudioStoreProps | Updater<PortfolioStudioStoreProps>) => void;
} {
	const { value: portfolioStudioStore, set: setPortfolioStudioStore } = usePortfolioStudioStore();
	return { portfolioStudioStore, setPortfolioStudioStore };
}
