import type {
	RichTicker,
	UserCompositionColumnPreference,
	UserEnhancementCompositionColumnPreference,
} from "$root/api/api-gen";
import { EntityEditorControllerApiFactory, type InvestmentListEntry, type ReviewTicker } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { CopyableText } from "$root/components/CopyableText";
import CustomLabelsEditor from "$root/components/CustomLabels";
import { InfoDelta } from "$root/components/InfoDelta";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { TagBadge } from "$root/components/tags/TagBadge";
import PortfolioExposureSummary from "$root/functional-areas/compare-portfolio/PortfolioExposureSummary";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import { PortfolioDetailsTabs } from "$root/pages/PortfolioDetails";
import type { UploadEntity } from "$root/pages/Portfolios/UploadPortfolioPage";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { CommonItemActions } from "$root/ui-lib/interactive-collections/common-item-actions";
import { builtInCaseInsensitiveSortFor } from "$root/utils/collections";
import type { TableColumn } from "@mdotm/mdotui/components";
import {
	ActionText,
	AutoTooltip,
	CircularProgressBar,
	Controller,
	Icon,
	NullableNumberInput,
	TextInput,
	TooltipContent,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, unpromisify } from "@mdotm/mdotui/utils";
import BigNumber from "bignumber.js";
import type { Map, Set } from "immutable";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import type { rowType } from ".";
import type { UseCompositionBuilderResult } from "../../universe/composition";
import type { ReadOnlyTagOptions } from "../edit-tags";
import { TagButton } from "../edit-tags";
import { isIdentifierCodeValid } from "../indentifier";
import { useDrivenState } from "@mdotm/mdotui/react-extensions";

type CommonEditorColumnsKey = "name" | "assetClass" | "microAssetClass" | "alias";

export type EditorTableMode = "edit" | "new";
export type EditorCompositionIntruments = ReviewTicker & {
	rowType: rowType;
	id: string;
	investment?: InvestmentListEntry;
};

function WeightInput(props: {
	instrument: EditorCompositionIntruments;
	compositionBuilder: UseCompositionBuilderResult;
	onCheckIsMinimumWeightValid(value: number | null): MaybePromise<void>;
	deleted: Set<string>;
}) {
	const { instrument, compositionBuilder, deleted, onCheckIsMinimumWeightValid } = props;
	const [weight, _setWeight] = useDrivenState(compositionBuilder.getWeight(instrument.id ?? "-")?.toNumber() ?? null);

	const latestWeightRef = useRef(weight);
	function setWeight(newValue: number | null) {
		latestWeightRef.current = newValue;
		_setWeight(newValue);
	}

	const [isLoading, setIsLoading] = useState(false);
	const isInstrumentDeleted = useMemo(() => deleted.has(instrument.id ?? "-"), [deleted, instrument.id]);
	const isMinWeightValid = useMemo(() => (weight ?? 0) >= 0.01, [weight]);
	return (
		<AutoTooltip
			overrideColor={themeCSSVars.palette_N300}
			disabled={isMinWeightValid || isInstrumentDeleted}
			position="left"
			trigger={({ innerRef }) => (
				<NullableNumberInput
					classList="grow"
					step={0.01}
					innerRef={innerRef}
					inputAppearance={{
						classList: {
							"text-right": true,
							[`!border-[color:${themeCSSVars.palette_N600}]`]: isMinWeightValid === false,
						},
					}}
					disabled={deleted.has(instrument.id ?? "-") || isLoading}
					size="x-small"
					key={instrument.id}
					value={weight}
					// onChangeNative={(e) => {
					// 	const newValueAsString = e.currentTarget.value.trim();
					// 	const toNumberThenToString = BigNumber(newValueAsString).toNumber();
					// 	setWeight(stringToNumberHelper(toNumberThenToString));
					// }}
					onChange={setWeight}
					onBlur={unpromisify(async () => {
						const newWeight = latestWeightRef.current;
						compositionBuilder.setWeight(instrument.id, newWeight === null ? null : BigNumber(newWeight));
						if (instrument.proxyOverwriteType === "PORTFOLIO_MIXED") {
							setIsLoading(true);
							await onCheckIsMinimumWeightValid(newWeight);
							setIsLoading(false);
						}
					})}
					rightContent={
						isLoading ? <CircularProgressBar value="indeterminate" classList="h-3 w-3" /> : <Icon icon="Percentile" />
					}
					name="weigths"
					onClick={(e) => e.stopPropagation()}
					data-qualifier="CompositionEditor/Table/Column(Weight)/Input"
				/>
			)}
		>
			Requested minimum weight of 0.01
		</AutoTooltip>
	);
}


export function useInstrumentEditorColumns(props: {
	compositionBuilder: UseCompositionBuilderResult;
	existingIdentifiers: Set<string | undefined>;
	onDelete(deleteId: Set<string>, instrument: EditorCompositionIntruments): void;
	entity: UploadEntity;
	existingTags: ReadOnlyTagOptions;
	uuid?: string;
	mode?: EditorTableMode;
	compareSelection?: Map<string, InvestmentListEntry>;
	onCompare?(selection: EditorCompositionIntruments[], action: "remove" | "add"): void;
	instrumentNumber: number;
	exceededWeight?: Map<string, RichTicker[]>;
	onExceededWeight?(id: string, removedTickers?: RichTicker[]): void;
	userColumnPreferences?: {
		columnPreference?: UserCompositionColumnPreference[];
		enhanceColumnPreference?: UserEnhancementCompositionColumnPreference[];
	};
}): TableColumn<EditorCompositionIntruments>[] {
	const {
		compositionBuilder,
		existingIdentifiers,
		existingTags,
		entity,
		onDelete,
		mode,
		uuid,
		onCompare,
		compareSelection,
		instrumentNumber,
		exceededWeight,
		onExceededWeight,
		userColumnPreferences,
	} = props;
	const { formatNumber } = useLocaleFormatters();
	const { t } = useTranslation();
	const user = useUserValue();

	const deleted = compositionBuilder.getDeleted();
	const identifiers = compositionBuilder.getIdentifiers();
	const totalWeight = compositionBuilder.getTotalWeight();

	const commonColumns = useMemo<Record<CommonEditorColumnsKey, TableColumn<EditorCompositionIntruments>>>(
		() => ({
			name: {
				header: "name",
				content: (row) => row.instrument ?? "-",
				sortFn: builtInCaseInsensitiveSortFor("instrument"),
				name: "instrument",
			},
			assetClass: {
				header: "Asset class",
				content: (row) => row.assetClass ?? "-",
				sortFn: builtInCaseInsensitiveSortFor("assetClass"),
				name: "assetClass",
			},
			microAssetClass: {
				header: "Micro asset class",
				content: (row) => row.microAssetClass ?? "-",
				sortFn: builtInCaseInsensitiveSortFor("microAssetClass"),
				name: "microAssetClass",
			},
			alias: {
				header: "Identifier",
				content: (row) => <CopyableText onClick={(e) => e.stopPropagation()} text={row.alias ?? "-"} />,
				sortFn: builtInCaseInsensitiveSortFor("microAssetClass"),
				name: "microAssetClass",
			},
		}),
		[],
	);

	const editorApi = useApiGen(EntityEditorControllerApiFactory);
	const isWeightValid = useCallback(
		async (value: number | null, instrument: EditorCompositionIntruments) => {
			const { id, investment } = instrument;
			try {
				if (investment?.uuid === undefined) {
					throw Error("missing uuid");
				}
				if (value === null || value < 0.01) {
					throw Error("weight too low");
				}
				const res = await axiosExtract(editorApi.verifyEditorPortfolio(investment?.uuid, value, entity));

				onExceededWeight?.(id, res.removedTickers);
			} catch (error) {
				console.log(error);
				throw error;
			}
		},
		[editorApi, entity, onExceededWeight],
	);

	const defaultColumns = useMemo<Array<TableColumn<EditorCompositionIntruments>>>(() => {
		const cellClassList = ({ id }: EditorCompositionIntruments) => ({
			"line-through opacity-50": deleted.has(id ?? "-"),
		});

		if (userColumnPreferences?.columnPreference && userColumnPreferences.columnPreference.length > 0) {
			return [
				...userColumnPreferences.columnPreference.map((preference) => ({
					hidden: !preference.enabled,
					...match(preference)
						.returnType<TableColumn<EditorCompositionIntruments>>()
						.with({ preferenceType: "ASSET_CLASS" }, () => ({
							...commonColumns.assetClass,
							cellClassList,
						}))
						.with({ preferenceType: "IDENTIFIER" }, () => ({
							header: "identifier",
							content: (instrument) => {
								if (instrument.rowType === "add") {
									return (
										<Controller
											value={compositionBuilder.getIdentifier(instrument.id)}
											onChange={(identifier) => compositionBuilder.updateIdentifier(instrument.id, identifier)}
										>
											{function RenderBody({ value, onCommit, onChange }) {
												const isIdentifierValid = useMemo(() => isIdentifierCodeValid(value), [value]);
												const isDuplicated = useMemo(() => {
													if (existingIdentifiers.has(value)) {
														return true;
													}
													return Boolean(
														identifiers.find((v, k) => {
															if (k === instrument.id || deleted.has(instrument.id)) {
																return false;
															}
															return v === value;
														}),
													);
												}, [value]);

												return (
													<AutoTooltip
														severity="error"
														disabled={(isIdentifierValid && isDuplicated === false) || value.length === 0}
														position="left"
														trigger={({ innerRef }) => (
															<TextInput
																size="x-small"
																value={value}
																onChangeText={onChange}
																onBlur={() => onCommit(value)}
																classList={{
																	"w-full": true,
																	[`[&>input]:border-[${themeCSSVars.Button_bg_danger}]`]:
																		value.length > 0 && (isIdentifierValid === false || isDuplicated),
																	[`[&>input]:border-[${themeCSSVars.palette_N600}]`]: value.length === 0,
																}}
																placeholder="Insert a valid identifier"
																disabled={deleted.has(instrument.id)}
																innerRef={innerRef}
																name="identifiers"
																onClick={(e) => e.stopPropagation()}
																data-qualifier="CompositionEditor/Table/Column(Identifier)/Input"
															/>
														)}
													>
														{isDuplicated
															? "identifier is duplicated"
															: isIdentifierValid === false
															  ? "insert a valid identifier"
															  : ""}
													</AutoTooltip>
												);
											}}
										</Controller>
									);
								}

								return <CopyableText onClick={(e) => e.stopPropagation()} text={instrument.alias ?? ""} />;
							},
							sortFn: builtInSortFnFor("alias"),
							name: "alias",
							cellClassList,
						}))
						.with({ preferenceType: "INSTRUMENT_NAME" }, () => ({
							header: "name",
							content: (row) => {
								if (row.proxyOverwriteType !== "PORTFOLIO_MIXED") {
									return row.instrument ?? "-";
								}

								const exposureComposition = row.investment?.macroAssetClassExposure?.map((x) => ({
									quality: x.firstQualityLevel,
									weight: x.weight,
								}));

								return (
									<div className="flex-1 flex justify-between overflow-hidden gap-2">
										<div className="flex flex-1 w-[calc(100%_-_162px)]">
											<AutoTooltip
												overrideColor={themeCSSVars.palette_N300}
												position="right"
												trigger={({ innerRef }) => (
													<div className="inline-flex items-center gap-1 w-full">
														<ActionText
															classList="font-[weight:500] truncate items-center"
															onClick={() =>
																window.open(
																	typedUrlForRoute("PortfolioDetails", {
																		portfolioUid: row.investment?.uuid ?? row.ticker ?? "",
																		tab: PortfolioDetailsTabs.COMPOSITION,
																	}),
																	"_blank",
																)
															}
															innerRef={innerRef}
														>
															{row.instrument ?? "-"}
														</ActionText>
														<svg
															width="12"
															height="12"
															viewBox="0 0 12 12"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
															className="shrink-0"
														>
															<path
																d="M8 1.5H10.5V4"
																stroke="currentColor"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M7 5L10.5 1.5"
																stroke="currentColor"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M8.5 7.4375V9.625C8.5 10.1084 8.10844 10.5 7.625 10.5H2.375C1.89156 10.5 1.5 10.1084 1.5 9.625V4.375C1.5 3.89156 1.89156 3.5 2.375 3.5H4.5625"
																stroke="currentColor"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													</div>
												)}
											>
												<TooltipContent>
													<div className="w-[280px]">
														<PortfolioExposureSummary
															compared={compareSelection?.has(row.id)}
															composition={exposureComposition ?? []}
															onCompare={(e) => {
																e.stopPropagation();
																onCompare?.([row], compareSelection?.has(row.id) ? "remove" : "add");
															}}
															title={row.instrument ?? "-"}
														/>
													</div>
												</TooltipContent>
											</AutoTooltip>
										</div>
										<p className="font-semibold">{row.investment?.nofInstruments ?? 0} ins.</p>
									</div>
								);
							},
							sortFn: builtInCaseInsensitiveSortFor("instrument"),
							name: "instrument",
							cellClassList: "relative",
							footerCellClassList: { "justify-end font-semibold": true, hidden: instrumentNumber === 0 },
						}))
						.with({ preferenceType: "MICRO_ASSET_CLASS" }, () => ({
							...commonColumns.microAssetClass,
							cellClassList,
							footerCellClassList: "!justify-end font-semibold",
						}))
						.with({ preferenceType: "TAG" }, () => ({
							name: "tag",
							header: "Tag",
							content: ({ tagLabel }) => {
								const currentTag = existingTags?.find((item) => item.value === tagLabel);
								if (currentTag === undefined) {
									return "";
								}

								return <TagBadge color={currentTag.color}>{currentTag.value}</TagBadge>;
							},
						}))
						.with({ preferenceType: "SCORE" }, () => ({
							header:
								mode === "edit" && uuid ? (
									<CustomLabelsEditor
										labelKey={`${uuid}_score1`}
										fallback={t("SCORE")}
										mode="view"
										isEditable={false}
									/>
								) : (
									t("SCORE")
								),
							cellClassList: "w-full",
							content: ({ score }) => (score ? formatNumber(score) : ""),
							relativeWidth: 1,
							footerCellClassList: "font-semibold",
							footer: () =>
								`Scored: ${
									compositionBuilder
										.getScores()
										.toArray()
										.filter(([_key, value]) => value !== null).length
								}`, // change score
							hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }) || !preference.enabled,
						}))
						.otherwise(() => ({ hidden: true }) as any),
				})),
				{
					header: "weight",
					align: "end",
					cellClassList: "tabular-nums",
					content: (instrument) => (
						<div className="flex flex-1 gap-2 justify-end">
							<WeightInput
								compositionBuilder={compositionBuilder}
								deleted={deleted}
								instrument={instrument}
								onCheckIsMinimumWeightValid={(v) => isWeightValid(v, instrument)}
							/>
							<CommonItemActions.DeleteRestore
								deleted={deleted}
								item={instrument.id ?? "-"}
								onDeletedChange={(x) => onDelete(x, instrument)}
							/>
						</div>
					),
					footerCellClassList: "font-semibold",
					minWidth: 130,
					footer: () =>
						compositionBuilder.getComposition().toArray().length > 0
							? `Total: ${formatNumber(totalWeight.toNumber())}%`
							: undefined,
				},
			];
		}

		return [
			{
				header: "name",
				content: (row) => {
					if (row.proxyOverwriteType !== "PORTFOLIO_MIXED") {
						return row.instrument ?? "-";
					}

					const exposureComposition = row.investment?.macroAssetClassExposure?.map((x) => ({
						quality: x.firstQualityLevel,
						weight: x.weight,
					}));

					return (
						<div className="flex-1 flex justify-between overflow-hidden gap-2">
							<div className="flex flex-1 w-[calc(100%_-_162px)]">
								<AutoTooltip
									overrideColor={themeCSSVars.palette_N300}
									position="right"
									trigger={({ innerRef }) => (
										<div className="inline-flex items-center gap-1 w-full">
											<ActionText
												classList="font-[weight:500] truncate items-center"
												onClick={() =>
													window.open(
														typedUrlForRoute("PortfolioDetails", {
															portfolioUid: row.investment?.uuid ?? row.ticker ?? "",
															tab: PortfolioDetailsTabs.COMPOSITION,
														}),
														"_blank",
													)
												}
												innerRef={innerRef}
											>
												{row.instrument ?? "-"}
											</ActionText>
											<svg
												width="12"
												height="12"
												viewBox="0 0 12 12"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
												className="shrink-0"
											>
												<path
													d="M8 1.5H10.5V4"
													stroke="currentColor"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M7 5L10.5 1.5"
													stroke="currentColor"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M8.5 7.4375V9.625C8.5 10.1084 8.10844 10.5 7.625 10.5H2.375C1.89156 10.5 1.5 10.1084 1.5 9.625V4.375C1.5 3.89156 1.89156 3.5 2.375 3.5H4.5625"
													stroke="currentColor"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</div>
									)}
								>
									<TooltipContent>
										<div className="w-[280px]">
											<PortfolioExposureSummary
												compared={compareSelection?.has(row.id)}
												composition={exposureComposition ?? []}
												onCompare={(e) => {
													e.stopPropagation();
													onCompare?.([row], compareSelection?.has(row.id) ? "remove" : "add");
												}}
												title={row.instrument ?? "-"}
											/>
										</div>
									</TooltipContent>
								</AutoTooltip>
							</div>
							<p className="font-semibold">{row.investment?.nofInstruments ?? 0} ins.</p>
						</div>
					);
				},
				sortFn: builtInCaseInsensitiveSortFor("instrument"),
				name: "instrument",
				cellClassList: "relative",
				footerCellClassList: { "justify-end font-semibold": true, hidden: instrumentNumber === 0 },
			},
			{
				header: "identifier",
				content: (instrument) => {
					if (instrument.rowType === "add") {
						return (
							<Controller
								value={compositionBuilder.getIdentifier(instrument.id)}
								onChange={(identifier) => compositionBuilder.updateIdentifier(instrument.id, identifier)}
							>
								{function RenderBody({ value, onCommit, onChange }) {
									const isIdentifierValid = useMemo(() => isIdentifierCodeValid(value), [value]);
									const isDuplicated = useMemo(() => {
										if (existingIdentifiers.has(value)) {
											return true;
										}
										return Boolean(
											identifiers.find((v, k) => {
												if (k === instrument.id || deleted.has(instrument.id)) {
													return false;
												}
												return v === value;
											}),
										);
									}, [value]);

									return (
										<AutoTooltip
											severity="error"
											disabled={(isIdentifierValid && isDuplicated === false) || value.length === 0}
											position="left"
											trigger={({ innerRef }) => (
												<TextInput
													size="x-small"
													value={value}
													onChangeText={onChange}
													onBlur={() => onCommit(value)}
													classList={{
														"w-full": true,
														[`[&>input]:border-[${themeCSSVars.Button_bg_danger}]`]:
															value.length > 0 && (isIdentifierValid === false || isDuplicated),
														[`[&>input]:border-[${themeCSSVars.palette_N600}]`]: value.length === 0,
													}}
													placeholder="Insert a valid identifier"
													disabled={deleted.has(instrument.id)}
													innerRef={innerRef}
													name="identifiers"
													onClick={(e) => e.stopPropagation()}
													data-qualifier="CompositionEditor/Table/Column(Identifier)/Input"
												/>
											)}
										>
											{isDuplicated
												? "identifier is duplicated"
												: isIdentifierValid === false
												  ? "insert a valid identifier"
												  : ""}
										</AutoTooltip>
									);
								}}
							</Controller>
						);
					}

					return <CopyableText onClick={(e) => e.stopPropagation()} text={instrument.alias ?? ""} />;
				},
				sortFn: builtInSortFnFor("alias"),
				name: "alias",
				cellClassList,
			},
			{ ...commonColumns.assetClass, cellClassList },
			{
				...commonColumns.microAssetClass,
				cellClassList,
				footerCellClassList: "!justify-end font-semibold",
				footer: compositionBuilder.getComposition().toArray().length > 0 ? "Total" : undefined,
			},
			{
				header: "weight",
				align: "end",
				cellClassList: "tabular-nums",
				content: (instrument) => (
					<div className="flex flex-1 gap-2 justify-end">
						<WeightInput
							compositionBuilder={compositionBuilder}
							deleted={deleted}
							instrument={instrument}
							onCheckIsMinimumWeightValid={(v) => isWeightValid(v, instrument)}
						/>
						<CommonItemActions.DeleteRestore
							deleted={deleted}
							item={instrument.id ?? "-"}
							onDeletedChange={(x) => onDelete(x, instrument)}
						/>
					</div>
				),
				footerCellClassList: "font-semibold",
				footer: () =>
					compositionBuilder.getComposition().toArray().length > 0
						? `${formatNumber(totalWeight.toNumber())}%`
						: undefined,
			},
		];
	}, [
		userColumnPreferences?.columnPreference,
		instrumentNumber,
		commonColumns.assetClass,
		commonColumns.microAssetClass,
		compositionBuilder,
		deleted,
		existingIdentifiers,
		identifiers,
		compareSelection,
		onCompare,
		existingTags,
		mode,
		uuid,
		t,
		user,
		formatNumber,
		isWeightValid,
		onDelete,
		totalWeight,
	]);

	const investmentEnhancementColumns = useMemo<Array<TableColumn<EditorCompositionIntruments>>>(() => {
		const cellClassList = ({ id }: EditorCompositionIntruments) => ({
			"line-through opacity-50": deleted.has(id ?? "-"),
		});

		if (userColumnPreferences?.enhanceColumnPreference && userColumnPreferences.enhanceColumnPreference.length > 0) {
			return [
				...userColumnPreferences.enhanceColumnPreference.map((preference) => ({
					hidden: !preference.enabled,
					...match(preference)
						.returnType<TableColumn<EditorCompositionIntruments>>()
						.with({ preferenceType: "ASSET_CLASS" }, () => ({ ...commonColumns.assetClass, cellClassList }))
						.with({ preferenceType: "MICRO_ASSET_CLASS" }, () => ({ ...commonColumns.microAssetClass, cellClassList }))
						.with({ preferenceType: "INSTRUMENT_NAME" }, () => ({
							header: "name",
							content: (row) => {
								if (row.proxyOverwriteType !== "PORTFOLIO_MIXED") {
									return row.instrument ?? "-";
								}

								const exposureComposition = row.investment?.macroAssetClassExposure?.map((x) => ({
									quality: x.firstQualityLevel,
									weight: x.weight,
								}));

								return (
									<>
										<AutoTooltip
											overrideColor={themeCSSVars.palette_N300}
											position="right"
											trigger={({ innerRef }) => (
												<span ref={innerRef} className="font-semibold truncate pr-14">
													{row.instrument ?? "-"}
												</span>
											)}
										>
											<TooltipContent>
												<div className="w-[280px]">
													<PortfolioExposureSummary
														compared={compareSelection?.has(row.id)}
														composition={exposureComposition ?? []}
														onCompare={(e) => {
															e.stopPropagation();
															onCompare?.([row], compareSelection?.has(row.id) ? "remove" : "add");
														}}
														title={row.instrument ?? "-"}
													/>
												</div>
											</TooltipContent>
										</AutoTooltip>
										<div className="absolute right-0 ">
											<p className="font-semibold">{row.investment?.nofInstruments ?? 0} ins.</p>
										</div>
									</>
								);
							},
							sortFn: builtInCaseInsensitiveSortFor("instrument"),
							name: "instrument",
							cellClassList: "relative",
							relativeWidth: 3.8,
							footerCellClassList: { "justify-end font-semibold": true, hidden: instrumentNumber === 0 },
						}))
						.with({ preferenceType: "CURRENT_WEIGHT" }, () => ({
							header: "Current Weight",
							align: "end",
							cellClassList: "tabular-nums",
							content: (row) => (row.previousWeight ? `${formatNumber(row.previousWeight)}%` : ""),
							sortFn: builtInSortFnFor("previousWeight"),
							name: "previousWeight",
						}))
						.with({ preferenceType: "ENHANCED_WEIGHT" }, () => ({
							header: "Enhanced Weight",
							align: "end",
							cellClassList: "tabular-nums",
							content: (row) => (row.weight ? `${formatNumber(row.weight)}%` : ""),
							sortFn: builtInSortFnFor("weight"),
							name: "weight",
						}))
						.with({ preferenceType: "DIFFERENCE" }, () => ({
							header: "Difference",
							align: "end",
							cellClassList: "tabular-nums",
							content: (instrument) => {
								const deltaWeight = Number((instrument.weight ?? 0) - (instrument.previousWeight ?? 0)).toFixed(2);
								return <InfoDelta diff={Number(deltaWeight) ?? 0} enh={instrument.weight ?? 0} />;
							},
							width: 110,
							footerCellClassList: "font-semibold",
							// footer: () => "Total",
							name: "difference",
							sortFn: (rowa, rowb) => {
								const deltaA = Math.round((rowa.weight ?? 0) - (rowa.previousWeight ?? 0)).toFixed(2);
								const deltaB = Math.round((rowb.weight ?? 0) - (rowb.previousWeight ?? 0)).toFixed(2);

								if (deltaA > deltaB) {
									return 1;
								}

								if (deltaA < deltaB) {
									return -1;
								}

								return 0;
							},
						}))
						.with({ preferenceType: "IDENTIFIER" }, () => ({
							header: "identifier",
							content: (row) => <CopyableText onClick={(e) => e.stopPropagation()} text={row.alias ?? "-"} />,
							sortFn: builtInSortFnFor("alias"),
							name: "alias",
							cellClassList,
						}))
						.with({ preferenceType: "TAG" }, () => ({
							name: "tag",
							header: "Tag",
							content: ({ tagLabel }) => {
								const currentTag = existingTags?.find((item) => item.value === tagLabel);
								if (currentTag === undefined) {
									return "";
								}

								return <TagBadge color={currentTag.color}>{currentTag.value}</TagBadge>;
							},
						}))
						.with({ preferenceType: "SCORE" }, () => ({
							header:
								mode === "edit" && uuid ? (
									<CustomLabelsEditor
										labelKey={`${uuid}_score1`}
										fallback={t("SCORE")}
										mode="view"
										isEditable={false}
									/>
								) : (
									t("SCORE")
								),
							cellClassList: " w-full",
							content: ({ score }) => (score ? formatNumber(score) : ""),
							relativeWidth: 1,
							footerCellClassList: "font-semibold",
							footer: () =>
								`Scored: ${
									compositionBuilder
										.getScores()
										.toArray()
										.filter(([_key, value]) => value !== null).length
								}`, // change score
							hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }) || !preference.enabled,
						}))
						.otherwise(() => ({ hidden: true }) as any),
				})),
				{
					header: "Custom weights",
					align: "end",
					content: (instrument) => (
						<div className="flex flex-1 gap-2">
							<WeightInput
								compositionBuilder={compositionBuilder}
								deleted={deleted}
								instrument={instrument}
								onCheckIsMinimumWeightValid={(v) => isWeightValid(v, instrument)}
							/>
							<CommonItemActions.DeleteRestore
								deleted={deleted}
								item={instrument.id ?? "-"}
								onDeletedChange={(x) => onDelete(x, instrument)}
							/>
						</div>
					),
					minWidth: 130,
					footerCellClassList: "font-semibold",
					footer: () => `Total: ${formatNumber(totalWeight.toNumber())}%`,
				},
			];
		}

		return [
			{
				header: "name",
				content: (row) => {
					if (row.proxyOverwriteType !== "PORTFOLIO_MIXED") {
						return row.instrument ?? "-";
					}

					const exposureComposition = row.investment?.macroAssetClassExposure?.map((x) => ({
						quality: x.firstQualityLevel,
						weight: x.weight,
					}));

					return (
						<>
							<AutoTooltip
								overrideColor={themeCSSVars.palette_N300}
								position="right"
								trigger={({ innerRef }) => (
									<span ref={innerRef} className="font-semibold truncate pr-14">
										{row.instrument ?? "-"}
									</span>
								)}
							>
								<TooltipContent>
									<div className="w-[280px]">
										<PortfolioExposureSummary
											compared={compareSelection?.has(row.id)}
											composition={exposureComposition ?? []}
											onCompare={(e) => {
												e.stopPropagation();
												onCompare?.([row], compareSelection?.has(row.id) ? "remove" : "add");
											}}
											title={row.instrument ?? "-"}
										/>
									</div>
								</TooltipContent>
							</AutoTooltip>
							<div className="absolute right-0 ">
								<p className="font-semibold">{row.investment?.nofInstruments ?? 0} ins.</p>
							</div>
						</>
					);
				},
				sortFn: builtInCaseInsensitiveSortFor("instrument"),
				name: "instrument",
				cellClassList: "relative",
				relativeWidth: 3.8,
				footerCellClassList: { "justify-end font-semibold": true, hidden: instrumentNumber === 0 },
			},
			{
				header: "identifier",
				content: (row) => <CopyableText onClick={(e) => e.stopPropagation()} text={row.alias ?? "-"} />,
				sortFn: builtInSortFnFor("alias"),
				name: "alias",
				cellClassList,
			},
			{ ...commonColumns.assetClass, cellClassList },
			{ ...commonColumns.microAssetClass, cellClassList },
			{
				header: "Weigth current",
				align: "end",
				cellClassList: "tabular-nums",
				content: (row) => (row.previousWeight ? `${formatNumber(row.previousWeight)}%` : ""),
				sortFn: builtInSortFnFor("previousWeight"),
				name: "previousWeight",
			},
			{
				header: "Optimised",
				align: "end",
				cellClassList: "tabular-nums",
				content: (row) => (row.weight ? `${formatNumber(row.weight)}%` : ""),
				sortFn: builtInSortFnFor("weight"),
				name: "weight",
			},
			{
				header: "Difference",
				align: "end",
				cellClassList: "tabular-nums",
				content: (instrument) => {
					const deltaWeight = Number((instrument.weight ?? 0) - (instrument.previousWeight ?? 0)).toFixed(2);
					return <InfoDelta diff={Number(deltaWeight) ?? 0} enh={instrument.weight ?? 0} />;
				},
				width: 110,
				footerCellClassList: "font-semibold",
				// footer: () => "Total",
				name: "difference",
				sortFn: (rowa, rowb) => {
					const deltaA = Math.round((rowa.weight ?? 0) - (rowa.previousWeight ?? 0)).toFixed(2);
					const deltaB = Math.round((rowb.weight ?? 0) - (rowb.previousWeight ?? 0)).toFixed(2);

					if (deltaA > deltaB) {
						return 1;
					}

					if (deltaA < deltaB) {
						return -1;
					}

					return 0;
				},
			},
			{
				header: "Custom weights",
				align: "end",
				content: (instrument) => (
					<div className="flex flex-1 gap-2">
						<WeightInput
							compositionBuilder={compositionBuilder}
							deleted={deleted}
							instrument={instrument}
							onCheckIsMinimumWeightValid={(v) => isWeightValid(v, instrument)}
						/>
					</div>
				),
				width: 100,
				footerCellClassList: "font-semibold",
				footer: () => `${formatNumber(totalWeight.toNumber())}%`,
			},
			{
				header: "",
				align: "end",
				content: (instrument) => (
					<CommonItemActions.DeleteRestore
						deleted={deleted}
						item={instrument.id ?? "-"}
						onDeletedChange={(x) => onDelete(x, instrument)}
					/>
				),
			},
		];
	}, [
		userColumnPreferences?.enhanceColumnPreference,
		instrumentNumber,
		commonColumns.assetClass,
		commonColumns.microAssetClass,
		deleted,
		compareSelection,
		onCompare,
		formatNumber,
		existingTags,
		mode,
		uuid,
		t,
		user,
		compositionBuilder,
		isWeightValid,
		onDelete,
		totalWeight,
	]);

	const universeColumns = useMemo<Array<TableColumn<EditorCompositionIntruments>>>(() => {
		const cellClassList = ({ id }: EditorCompositionIntruments) => ({
			"line-through opacity-50": deleted.has(id ?? "-"),
		});
		return [
			{
				...commonColumns.name,
				cellClassList,
				relativeWidth: 3.6,
				content: (row) => {
					if (row.proxyOverwriteType !== "PORTFOLIO_MIXED") {
						return row.instrument ?? "-";
					}

					return (
						<div className="flex-1 flex justify-between overflow-hidden gap-2">
							<div className="flex flex-1 w-[calc(100%_-_162px)]">
								<ActionText
									classList="inline-flex items-center gap-1 w-full"
									onClick={() =>
										window.open(
											typedUrlForRoute("PortfolioDetails", {
												portfolioUid: row.investment?.uuid ?? "",
												tab: PortfolioDetailsTabs.COMPOSITION,
											}),
											"_blank",
										)
									}
								>
									<span className="font-[weight:500] truncate items-center">{row.instrument ?? "-"}</span>
									<svg
										width="12"
										height="12"
										viewBox="0 0 12 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										className="shrink-0"
									>
										<path
											d="M8 1.5H10.5V4"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M7 5L10.5 1.5"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M8.5 7.4375V9.625C8.5 10.1084 8.10844 10.5 7.625 10.5H2.375C1.89156 10.5 1.5 10.1084 1.5 9.625V4.375C1.5 3.89156 1.89156 3.5 2.375 3.5H4.5625"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</ActionText>
							</div>
							<p className="font-semibold">{row.investment?.nofInstruments ?? 0} ins.</p>
						</div>
					);
				},
			},
			{
				header: "identifier",
				content: (instrument) => {
					if (instrument.rowType === "add") {
						return (
							<Controller
								value={compositionBuilder.getIdentifier(instrument.id)}
								onChange={(identifier) => compositionBuilder.updateIdentifier(instrument.id, identifier)}
							>
								{function RenderBody({ value, onCommit, onChange }) {
									const isIdentifierValid = useMemo(() => isIdentifierCodeValid(value), [value]);
									const isDuplicated = useMemo(() => {
										if (existingIdentifiers.has(value)) {
											return true;
										}
										return Boolean(
											identifiers.find((v, k) => {
												if (k === instrument.id || deleted.has(instrument.id)) {
													return false;
												}
												return v === value;
											}),
										);
									}, [value]);

									return (
										<AutoTooltip
											severity="error"
											disabled={(isIdentifierValid && isDuplicated === false) || value.length === 0}
											trigger={({ innerRef }) => (
												<TextInput
													size="x-small"
													value={value}
													onChangeText={onChange}
													onBlur={() => onCommit(value)}
													classList={{
														[`[&>input]:border-[${themeCSSVars.Button_bg_danger}]`]:
															value.length > 0 && (isIdentifierValid === false || isDuplicated),
														[`[&>input]:border-[${themeCSSVars.palette_N600}]`]: value.length === 0,
													}}
													placeholder="Insert a valid identifier"
													disabled={deleted.has(instrument.id)}
													innerRef={innerRef}
													name="identifiers"
													onClick={(e) => e.stopPropagation()}
													data-qualifier="CompositionEditor/Table/Column(Identifier)/Input"
												/>
											)}
										>
											{isDuplicated
												? "identifier is duplicated"
												: isIdentifierValid === false
												  ? "insert a valid identifier"
												  : ""}
										</AutoTooltip>
									);
								}}
							</Controller>
						);
					}

					return <CopyableText onClick={(e) => e.stopPropagation()} text={instrument.alias ?? "-"} />;
				},
				sortFn: builtInSortFnFor("alias"),
				name: "alias",
				cellClassList: (instrument) => ({
					...cellClassList(instrument),
					"flex-grow": true,
				}),
				relativeWidth: 1.5,
			},
			{ ...commonColumns.assetClass, cellClassList, relativeWidth: 1.9 },
			{
				...commonColumns.microAssetClass,
				cellClassList,
				relativeWidth: 1.5,
				footerCellClassList: "flex justify-end font-semibold",
				footer: () => `Number of instrument`,
			},
			{
				header: "Tags",
				content: ({ id }) => {
					const currentTag = compositionBuilder.getTags().get(id);
					return (
						// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
						<div onClick={(e) => e.stopPropagation()}>
							<TagButton
								options={existingTags}
								color={existingTags.find(({ value }) => value === currentTag)?.color}
								value={currentTag ?? null}
								onClick={(newTag, e) => {
									e?.stopPropagation();
									compositionBuilder.updateTag(id, newTag);
								}}
								disabled={deleted.has(id)}
								enableDebounce
							/>
						</div>
					);
				},
				relativeWidth: 1.5,
				footerCellClassList: "font-semibold",
				footer: () => `Tagged: ${compositionBuilder.getTags().size}`,
			},
			{
				header:
					mode === "edit" && uuid ? (
						<CustomLabelsEditor labelKey={`${uuid}_score1`} fallback={t("SCORE")} mode="view" />
					) : (
						t("SCORE")
					),
				cellClassList: " w-full",
				content: (row) => (
					<div className="[&>div]:w-full [&>div]:flex flex">
						<NullableNumberInput
							min={0}
							max={100}
							step={0.01}
							inputAppearance={{ classList: "text-right" }}
							disabled={deleted.has(row.id ?? "-")}
							size="x-small"
							value={compositionBuilder.getScore(row.id ?? "-")?.toNumber() ?? null}
							onChange={(newValue: number | null) =>
								compositionBuilder.setScore(row.id, newValue === null ? null : BigNumber(newValue))
							}
							name="score"
							onClick={(e) => e.stopPropagation()}
							data-qualifier="CompositionEditor/Table/Column(Score)/Input"
						/>
					</div>
				),
				relativeWidth: 1,
				footerCellClassList: "font-semibold",
				footer: () =>
					`Scored: ${
						compositionBuilder
							.getScores()
							.toArray()
							.filter(([_key, value]) => value !== null).length
					}`, // change score
				hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
			},
			{
				header: "",
				align: "end",
				content: (instrument) => (
					// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
					<span onClick={(e) => e.stopPropagation()}>
						<CommonItemActions.DeleteRestore
							deleted={deleted}
							item={instrument.id ?? "-"}
							onDeletedChange={(x) => onDelete(x, instrument)}
						/>
					</span>
				),
				relativeWidth: 0.3,
			},
		];
	}, [
		commonColumns.name,
		commonColumns.assetClass,
		commonColumns.microAssetClass,
		mode,
		uuid,
		t,
		user,
		deleted,
		compositionBuilder,
		existingIdentifiers,
		identifiers,
		existingTags,
		onDelete,
	]);

	return useMemo(() => {
		if (entity === "UNIVERSE") {
			return universeColumns;
		}

		if (entity === "INVESTMENT_ENHANCEMENT") {
			return investmentEnhancementColumns;
		}

		return defaultColumns;
	}, [defaultColumns, entity, investmentEnhancementColumns, universeColumns]);
}
