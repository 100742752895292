import BgLogin from "$root/assets/images/bg_login.jpg";
import { hasAccess } from "$root/components/AuthorizationGuard";
import type { ComponentPropsWithoutRef } from "react";
import { lazy, useMemo } from "react";
import { include } from "$root/utils/objects";
import { useHistory } from "react-router";
import { useUserValue, type IUser } from "$root/functional-areas/user";
import type { PlatformLayoutOptions } from "./layout-options";
import type { IconName } from "@mdotm/mdotui/components";
import type { MaybeFn } from "@mdotm/mdotui/utils";
import { maybeCall } from "@mdotm/mdotui/utils";
import type { Intersect, Replace } from "$root/utils/types";
import type { ServiceType } from "$root/api/api-gen";

export function extractPath(relativeUrl: string): string {
	return new URL(`http://www.example.com/${relativeUrl[0] === "/" ? relativeUrl.slice(1) : relativeUrl}`).pathname;
}
export function extractSearch(relativeUrl: string): string {
	return new URL(`http://www.example.com/${relativeUrl[0] === "/" ? relativeUrl.slice(1) : relativeUrl}`).search;
}
export function extractSearchParams(relativeUrl: string): URLSearchParams {
	return new URL(`http://www.example.com/${relativeUrl[0] === "/" ? relativeUrl.slice(1) : relativeUrl}`).searchParams;
}

export type RouteOptionsContext = { user: IUser };

export type GuardFn = (
	params: RouteOptionsContext,
) => { passed: true } | { passed: false; reason?: "requires-login" | "missing-permission" };

const alwaysPassGuard: GuardFn = () => ({ passed: true });

export type TopBarOptions = {
	icon: IconName;
	title: string;
	show: boolean;
};
export type SideBarOptions = {
	icon: IconName;
	title: string;
	show: boolean;
	subMenuItems: Array<{
		label: string;
		url: string;
		actions?: Array<{ icon: IconName; url: string }>;
	}> | null;
};

export type RouteSerializedProps = { query?: string; params?: Record<string, string> };

export type PropsSerde<T> = {
	serialize: (props: T) => RouteSerializedProps;
	deserialize: (url: string, baseUrl: string) => T;
};

const signInGuard: GuardFn = ({ user }) =>
	user.signedIn ? { passed: true } : { passed: false, reason: "requires-login" };

function requiresServiceGuard(requiredService: ServiceType): GuardFn {
	return ({ user }) =>
		hasAccess(user, { requiredService }) ? { passed: true } : { passed: false, reason: "missing-permission" };
}

function andGuards(...guards: GuardFn[]): GuardFn {
	return (params) => {
		for (let i = 0; i < guards.length; i++) {
			const guardResult = guards[i](params);
			if (!guardResult.passed) {
				return guardResult;
			}
		}
		return { passed: true };
	};
}

function orGuards(...guards: GuardFn[]): GuardFn {
	return (params) => {
		let lastGuardResult: ReturnType<GuardFn> | undefined;
		for (let i = 0; i < guards.length; i++) {
			lastGuardResult = guards[i](params);
			if (lastGuardResult.passed) {
				return lastGuardResult;
			}
		}
		return lastGuardResult ?? { passed: false };
	};
}

const defaultLayoutOptions: PlatformLayoutOptions = {
	topBar: true,
	sideBar: true,
	mode: "default",
};

export type RouteData<Name extends string, TProps extends Record<string, string>> = {
	name: Name;
	baseUrl: string;
	component: (props: TProps) => JSX.Element;
	propsSerde: PropsSerde<TProps>;
	guard: GuardFn;
	layoutOptions: MaybeFn<PlatformLayoutOptions, [RouteOptionsContext]>;
	topBarOptions: MaybeFn<TopBarOptions | null, [RouteOptionsContext]>;
	sideBarOptions: MaybeFn<SideBarOptions | null, [RouteOptionsContext]>;
};

function createRoute<Name extends string, TProps extends Record<string, string>>({
	name,
	baseUrl,
	component,
	propsSerde,
	guard,
	layoutOptions,
	sideBarOptions,
	topBarOptions,
}: Replace<
	RouteData<Name, TProps>,
	{
		component: () => Promise<{ default: (props: TProps) => JSX.Element }>;
		topBarOptions?: MaybeFn<Replace<TopBarOptions, { show?: boolean }> | null, [RouteOptionsContext]>;
		sideBarOptions?: MaybeFn<
			Replace<SideBarOptions, { show?: boolean; subMenuItems?: SideBarOptions["subMenuItems"] }> | null,
			[RouteOptionsContext]
		>;
		layoutOptions?: MaybeFn<Partial<PlatformLayoutOptions> | null, [RouteOptionsContext]>;
		guard?: GuardFn;
		propsSerde?: PropsSerde<TProps>;
	}
>): Record<Name, RouteData<Name, TProps>> {
	const actualGuard = guard ?? alwaysPassGuard;
	const routeData: RouteData<Name, TProps> = {
		name,
		baseUrl,
		component: lazy(component) as unknown as (props: TProps) => JSX.Element,
		propsSerde: propsSerde ?? noneSerde(),
		guard: actualGuard,
		topBarOptions: (params: RouteOptionsContext) => {
			const overrides = maybeCall(topBarOptions, params);
			return !overrides
				? null
				: {
						show: true,
						...overrides,
				  };
		},
		sideBarOptions: (params: RouteOptionsContext) => {
			const overrides = maybeCall(sideBarOptions, params);
			return !overrides
				? null
				: {
						subMenuItems: null,
						...overrides,
						show: actualGuard(params).passed && overrides.show !== false,
				  };
		},
		layoutOptions: (params: RouteOptionsContext) => {
			const overrides = maybeCall(layoutOptions, params);
			return !overrides
				? defaultLayoutOptions
				: {
						...defaultLayoutOptions,
						...overrides,
				  };
		},
	};
	return {
		[name as Name]: routeData,
	} as Record<Name, RouteData<Name, TProps>>;
}

function noneSerde<T extends Record<string, string>>(): PropsSerde<T> {
	return {
		serialize: () => ({}),
		deserialize: () => ({}) as unknown as T,
	};
}

function querySerde<K extends string, T extends [K, ...K[]]>(
	propKeys: T,
): PropsSerde<Partial<Record<T[number], string>>> {
	return {
		serialize: (props) => ({
			query: new URLSearchParams(
				Object.entries(include(props, propKeys)).filter(([_, value]) => value !== undefined) as [string, string][],
			).toString(),
		}),
		deserialize: (url) => {
			const query = Object.fromEntries(extractSearchParams(url).entries());
			if (query["from"] !== undefined) {
				const { from, ...others } = query;
				let customFrom = from;
				Object.entries(others).map(([key, v]) => {
					if (key === "atubm") {
						return;
					}
					customFrom += `&${key}=${v}`;
				});

				return { from: customFrom, ...others } as Record<T[number], string>;
			}

			return query as Record<T[number], string>;
		},
	};
}

function paramsSerde<K extends string, T extends [K, ...K[]]>(propKeys: T): PropsSerde<Record<T[number], string>> {
	return {
		serialize: (props) => {
			return {
				params: include(props, propKeys),
			};
		},
		deserialize: (url, baseUrl) => {
			const urlParts = extractPath(url).split("/");
			const baseUrlParts = extractPath(baseUrl).split("/");
			const props = {} as Partial<Record<keyof T, string>>;
			for (let i = 0; i < Math.min(urlParts.length, baseUrlParts.length); i++) {
				if (baseUrlParts[i].at(0) === ":") {
					props[baseUrlParts[i].slice(1) as keyof T] = urlParts[i];
				}
			}
			return props as Record<T[number], string>;
		},
	};
}

// TODO: add to pages with both parameters and query
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function multiSerde<T extends [PropsSerde<any>, ...PropsSerde<any>[]]>(
	serdes: T,
): {
	serialize(
		props: Intersect<{ [K in keyof T]: Parameters<T[K]["serialize"]>[0] }[number]>, // TODO: why doesn't keyof T work here?
	): ReturnType<PropsSerde<any>["serialize"]>;
	deserialize(url: string, baseUrl: string): Intersect<{ [K in keyof T]: ReturnType<T[K]["deserialize"]> }[number]>;
} {
	return {
		serialize: (props) => serdes.reduce((acc, serde) => ({ ...acc, ...serde.serialize(props as any) }), {}) as any,
		deserialize: (url, baseUrl) =>
			serdes.reduce((acc, serde) => ({ ...acc, ...serde.deserialize(url, baseUrl) }), {}) as any,
	};
} /*

type T1 = Parameters<PropsSerde<Record<"a", string>>["serialize"]>[0];
type T2 = Parameters<PartialPropsSerde<Record<"b", string>>["serialize"]>[0];
type T3 = T1 | T2;
type T4 = Intersect<T3>;
const a: T4 = {};

type Q1 = ReturnType<ReturnType<typeof paramsSerde<string, ["a"]>>["deserialize"]>;
type Q2 = ReturnType<ReturnType<typeof querySerde<string, ["b"]>>["deserialize"]>;
type Q3 = Q1 & Q2;

type Multi = typeof multiSerde<[PropsSerde<Record<"a", string>>, PartialPropsSerde<Record<"b", string>>]>;
const m1: Multi = () => ({
	serialize(props) {
		return {};
	},
	deserialize() {
		return { a: "hello" };
	},
});
const multi = multiSerde([paramsSerde(["a"]), querySerde(["b"])]);
multi.serialize({ a: "hello" }); */

export const routesMap = {
	/* Test: newRoute({
		baseUrl: "/test",
		component: () => import("../../pages/Test"),
		propsSerde: multiSerde([paramsSerde(["a"]), querySerde(["b"])]),
		layoutOptions: {
			topBar: false,
			sideBar: false,
			backgroundImage: BgLogin,
		},
	}), */
	...createRoute({
		name: "Login",
		baseUrl: "/login",
		component: () => import("../../pages/Login"),
		propsSerde: querySerde(["from", "atubm"]),
		layoutOptions: {
			topBar: false,
			sideBar: false,
			backgroundImage: BgLogin,
		},
	}),
	...createRoute({
		name: "Index",
		baseUrl: "/",
		component: () => import("../../pages/IndexRedirect"),
		guard: signInGuard,
	}),
	...createRoute({
		name: "Dashboard",
		baseUrl: "/dashboard",
		component: () => import("../../pages/Dashboard"),
		guard: andGuards(signInGuard, requiresServiceGuard("REPORTS")),
		sideBarOptions: {
			title: "Dashboard",
			icon: "dashboard",
		},
	}),
	...createRoute({
		name: "Outlook",
		baseUrl: "/outlook",
		component: () => import("../../pages/Outlook"),
		guard: andGuards(signInGuard, requiresServiceGuard("REPORTS")),
		sideBarOptions: () => ({
			icon: "Outlook",
			title: "Outlook",
			subMenuItems: [
				// TODO: types?
				{ label: "Equity", url: "/outlook?filter=EQ&area=MARKETS" },
				{ label: "Fixed Income", url: "/outlook?filter=FI&area=MARKETS" }, // MARKETS is a default option specified in Outlook line 38, to get rid of this should change the inside logic
				{ label: "Commodities", url: "/outlook?filter=CO&area=MARKETS" }, // MARKETS is a default option specified in Outlook line 38, to get rid of this should change the inside logic
			],
		}),
	}),
	...createRoute({
		name: "AssetClass",
		baseUrl: "/asset_class",
		propsSerde: querySerde(["filter", "area", "sector"]),
		component: () => import("../../pages/AssetClass/index"),
		guard: andGuards(signInGuard, requiresServiceGuard("REPORTS")),
		sideBarOptions: () => ({
			title: "Outlook Focus",
			icon: "assetfocus",
			subMenuItems: [
				// TODO: types?
				{ label: "Equity", url: "/asset_class?filter=EQ&area=EU&sector=ALL_SECTORS" },
				{ label: "Fixed Income", url: "/asset_class?filter=FI&area=EM&sector=CORP" },
				{ label: "Commodities", url: "/asset_class?filter=CO&area=AGRICULTURE&sector=ALL_SECTORS" },
			],
		}),
	}),
	...createRoute({
		name: "RegimeAnalysisTool",
		baseUrl: "/regime_analysis_tool",
		component: () => import("../../pages/RegimeAnalysisTool"),
		propsSerde: querySerde(["region"]),
		guard: signInGuard,
		sideBarOptions: ({ user }) => ({
			title: "Regime Analysis Tool",
			icon: "hmm",
			show: hasAccess(user, { requiredService: "SIGNALS" }),
			subMenuItems: [
				...(hasAccess(user, { requiredService: "GLOBAL_RISK_MAP" })
					? [
							{
								label: "GLOBAL",
								url: "/regime_analysis_tool?region=GLOBAL",
							},
					  ]
					: []),
				{
					label: "EU",
					url: "/regime_analysis_tool?region=EU",
				},
				{
					label: "USA",
					url: "/regime_analysis_tool?region=US",
				},
				{
					label: "JP",
					url: "/regime_analysis_tool?region=JP",
				},
			],
		}),
	}),
	...createRoute({
		name: "CustomReports",
		baseUrl: "/custom_reports",
		component: () => import("../../pages/CustomReports"),
		guard: signInGuard,
		sideBarOptions: ({ user }) => ({
			icon: "Positioning-indicator",
			title: "Custom Reports",
			show: hasAccess(user, { requiredService: "CUSTOM_REPORT_CB1" }),
		}),
	}),
	...createRoute({
		name: "PortfoliosStudio",
		baseUrl: "/portfolios",
		component: () => import("../../pages/PortfoliosStudio"),
		propsSerde: querySerde(["tab", "status", "zipId"]),
		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
		sideBarOptions: () => ({
			icon: "Portfolio",
			title: "Portfolio Studio",
			subMenuItems: [
				{
					label: "Portfolios",
					url: "/portfolios?tab=Portfolios",
					actions: [{ icon: "Outline1", url: "/portfolios/new" }],
				},
				{
					label: "References",
					url: "/portfolios?tab=References",
					actions: [{ icon: "Outline1", url: "/portfolios/upload/benchmark" }],
				},
				{
					label: "Universes",
					url: "/portfolios?tab=Universes",
					actions: [{ icon: "Outline1", url: "/portfolios/upload/universe" }],
				},
				{
					label: "Market Views",
					url: "/portfolios?tab=MarketViews",
				},
			],
		}),
	}),
	...createRoute({
		name: "Portfolios/UploadPortfolioPage",
		baseUrl: "/portfolios/upload/:uploadType", //TODO: rename path with manual creation
		component: () => import("../../pages/Portfolios/UploadPortfolioPage"),
		propsSerde: multiSerde([paramsSerde(["uploadType"]), querySerde(["selectBenchmarkTemplate"])]),

		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "Portfolios/UploadPortfolioPage/Portfolio",
		baseUrl: "/portfolios/upload/portfolio",
		component: () => import("../../pages/Portfolios/UploadPortfolioPage"),
		propsSerde: querySerde(["uuids"]),

		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		/* this creates a proposal */
		name: "Portfolios/EditPortfolio",
		baseUrl: "/portfolios/edit/:portfolioUid",
		component: () => import("../../pages/Portfolios/CreatePortfolio"),
		propsSerde: paramsSerde(["portfolioUid"]),

		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		/* this edits the settings without creating a proposal */
		name: "Portfolios/SettingsPortfolio",
		baseUrl: "/portfolios/settings/:portfolioUid",
		component: () => import("../../pages/Portfolios/CreatePortfolio"),
		propsSerde: multiSerde([paramsSerde(["portfolioUid"]), querySerde(["isSettings"])]),

		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "Portfolios/EditPortfolioMulti",
		baseUrl: "/portfolios/edit-multi/:proposalUuid",
		component: () => import("../../pages/Portfolios/CreatePortfolioMulti"),
		propsSerde: paramsSerde(["proposalUuid"]),

		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "Portfolios/SettingsPortfolioMulti",
		baseUrl: "/portfolios/settings-multi/:bulkUid",
		component: () => import("../../pages/Portfolios/SettingsPortfolioMulti"),
		propsSerde: paramsSerde(["bulkUid"]),

		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "Portfolios/CreatePortfolio",
		baseUrl: "/portfolios/new",
		component: () => import("../../pages/Portfolios/CreatePortfolio"),
		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "Portfolios/Draft",
		baseUrl: "/portfolios/draft/:portfolioUid", //TODO: unify with Portfolios/CreatePortfolio
		component: () => import("../../pages/Portfolios/CreatePortfolio"),
		propsSerde: paramsSerde(["portfolioUid"]),

		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "Portfolios/DraftManualCreation",
		baseUrl: "/portfolios/upload/draft/:uploadType/:portfolioUid",
		component: () => import("../../pages/Portfolios/UploadPortfolioPage"),
		propsSerde: paramsSerde(["uploadType", "portfolioUid"]),

		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "EditPortfolioCompositionPage",
		baseUrl: "/portfolio_edit_composition/:portfolioUid",
		propsSerde: multiSerde([paramsSerde(["portfolioUid"]), querySerde(["override"])]),
		component: () => import("../../pages/EditPortfolioCompositionPage"),
		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "PortfolioDetails",
		baseUrl: "/portfolio_details/:portfolioUid",
		component: () => import("../../pages/PortfolioDetails"),
		propsSerde: multiSerde([paramsSerde(["portfolioUid"]), querySerde(["proposal", "tab"])]),
		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "UniverseDetails",
		baseUrl: "/universe_details/:mode/:universeUuid",
		component: () => import("../../pages/UniverseDetails"),
		propsSerde: paramsSerde(["mode", "universeUuid"]),
		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "WorkspaceSettings",
		baseUrl: "/workspace_settings",
		component: () => import("../../pages/WorkspaceSettings"),
		guard: signInGuard,
	}),
	...createRoute({
		name: "MarketViewWorkSpace",
		baseUrl: "/workspace_market/:action/:uuid/:type",
		component: () => import("../../pages/MarketViewWorkSpace"),
		propsSerde: multiSerde([paramsSerde(["action", "uuid", "type"]), querySerde(["isCustom"])]),
		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "MarketViewWorkSpace/New",
		baseUrl: "/workspace_market/:type",
		component: () => import("../../pages/MarketViewWorkSpace"),
		propsSerde: multiSerde([paramsSerde(["type"]), querySerde(["isCustom"])]),
		guard: signInGuard,
	}),
	...createRoute({
		name: "ForgotPassword",
		baseUrl: "/forgot_password",
		component: () => import("../../pages/ForgotPassword"),
		layoutOptions: {
			topBar: false,
			sideBar: false,
			backgroundImage: BgLogin,
		},
	}),
	...createRoute({
		name: "PortfolioStudioSettings",
		baseUrl: "/portfolio_studio_settings",
		component: () => import("../../pages/PortfolioStudioSettings"),
		propsSerde: querySerde(["tab", "uuid"]),
		topBarOptions: {
			icon: "Settings",
			title: "Portfolio studio settings",
		},
		guard: signInGuard,
	}),
	...createRoute({
		name: "PortfolioStudioSettings/ReportEditor",
		baseUrl: "/portfolio_studio_settings/report-editor/:reportUid",
		component: () => import("../../pages/PortfolioStudioSettings/ReportEditor"),
		propsSerde: paramsSerde(["reportUid"]),
		guard: signInGuard,
	}),
	...createRoute({
		name: "Notification",
		baseUrl: "/notification_settings",
		component: () => import("../../pages/Notification"),

		guard: andGuards(signInGuard, orGuards(requiresServiceGuard("INVESTMENTS"), requiresServiceGuard("REPORTS"))),
		topBarOptions: {
			icon: "Notification-settings",
			title: "Notification Settings",
		},
	}),
	...createRoute({
		name: "Report",
		baseUrl: "/report/:templateId",
		component: () => import("../../pages/Report"),
		propsSerde: multiSerde([
			paramsSerde(["templateId"]),
			querySerde(["variant", "objectId", "status", "enhanced", "autoScroll"]),
		]),
		guard: signInGuard,
		layoutOptions: {
			mode: "print",
			sideBar: false,
			topBar: false,
		},
	}),
	...createRoute({
		name: "Report/Editor",
		baseUrl: "/report_editor/:customerId",
		component: () => import("../../pages/Report/ReportEditor"),
		propsSerde: paramsSerde(["customerId"]),
		guard: signInGuard,
		layoutOptions: {
			mode: "print",
			sideBar: false,
			topBar: false,
		},
	}),
	...createRoute({
		name: "Report/Demo",
		baseUrl: "/report/:templateId",
		component: () => import("../../pages/Report"),
		propsSerde: paramsSerde(["templateId"]),
		guard: signInGuard,
		layoutOptions: {
			mode: "print",
			sideBar: false,
			topBar: false,
		},
	}),
	...createRoute({
		name: "CustomBenchmark",
		baseUrl: "/custom_benchmarks/:benchmarkId",
		component: () => import("../../pages/CustomBenchmark"),
		propsSerde: paramsSerde(["benchmarkId"]),
		guard: signInGuard,
	}),
	/* ...createRoute({
		name: "CustomBenchmark/Creation",
		baseUrl: "/custom_benchmarks/create",
		component: () => import("../../pages/CustomBenchmark/Creation"),
		guard: signInGuard,
	}), */
	...createRoute({
		name: "CustomBenchmark/EditCustomBenchmark",
		baseUrl: "/custom_benchmarks/edit/:benchmarkId",
		component: () => import("../../pages/CustomBenchmark/EditCustomBenchmark"),
		propsSerde: paramsSerde(["benchmarkId"]),
		guard: signInGuard,
	}),
	...createRoute({
		name: "AdvanceSettings/UserSettingsPanel",
		baseUrl: "/advance_settings/settings_panel",
		component: () => import("../../pages/AdvanceSettings/UserSettingsPanel"),
		guard: signInGuard,
		sideBarOptions: ({ user }) => ({
			icon: "Optimize",
			show: hasAccess(user, { requiredRoles: ["ROOT"] }),
			title: "Advanced settings",
			subMenuItems: [
				{
					label: "Global Settings Panel",
					url: "/advance_settings/settings_panel",
				},
				{
					label: "ChatGPT Prompt",
					url: "/advance_settings/chatgpt_prompt",
				},
				{
					label: "Advanced editor",
					url: "/advance_settings/advanced_editor",
				},
				{
					label: "Info",
					url: "/advance_settings/platform_info",
				},
				{
					label: "Refactor",
					url: "/advance_settings/editor",
				},
			],
		}),
	}),
	...createRoute({
		name: "AdvanceSettings/AdvancedEditor",
		baseUrl: "/advance_settings/advanced_editor",
		component: () => import("../../pages/AdvanceSettings/AdvancedEditor"),
		guard: signInGuard,
	}),
	...createRoute({
		name: "AdvanceSettings/CommentaryPrompt",
		baseUrl: "/advance_settings/chatgpt_prompt",
		component: () => import("../../pages/AdvanceSettings/CommentaryPrompt"),
		guard: signInGuard,
	}),
	...createRoute({
		name: "AdvanceSettings/PlatformInfo",
		baseUrl: "/advance_settings/platform_info",
		component: () => import("../../pages/AdvanceSettings/PlatformInfo"),
		guard: signInGuard,
	}),
	...createRoute({
		name: "AdvanceSettings/Refactor",
		baseUrl: "/advance_settings/editor",
		component: () => import("../../pages/AdvanceSettings/Editor"),
		guard: signInGuard,
	}),
	...createRoute({
		name: "Maintenance",
		baseUrl: "/maintenance",
		component: () => import("../../pages/Maintenance"),
		layoutOptions: ({ user }) => ({
			mode: "print",
			sideBar: false,
			topBar: hasAccess(user, { requiredRoles: ["ROOT"] }),
		}),
	}),
	...createRoute({
		name: "Storyfolio/Details",
		baseUrl: "/storyfolio/details/:uuid",
		component: () => import("../../pages/Storyfolio/StoryfolioDetails"),
		propsSerde: paramsSerde(["uuid"]),
		guard: andGuards(signInGuard, requiresServiceGuard("COMMENTARY_BUILDER")),
	}),
	...createRoute({
		name: "Storyfolio/Creation",
		baseUrl: "/storyfolio/new",
		component: () => import("../../pages/Storyfolio/StoryfolioCreation"),
		guard: andGuards(signInGuard, requiresServiceGuard("COMMENTARY_BUILDER")),
	}),
	...createRoute({
		name: "Storyfolio/Studio",
		baseUrl: "/storyfolio",
		component: () => import("../../pages/Storyfolio"),
		guard: andGuards(signInGuard, requiresServiceGuard("COMMENTARY_BUILDER")),
		sideBarOptions: ({ user }) => ({
			title: "Storyfolio",
			icon: "Storyfolio",
			show: hasAccess(user, { requiredService: "COMMENTARY_BUILDER" }),
		}),
	}),
	...createRoute({
		name: "Page404",
		baseUrl: "*",
		component: () => import("../../pages/404"),
		layoutOptions: {
			mode: "print",
			sideBar: false,
			topBar: false,
		},
	}),
};

export function useCurrentRoutesMap(): {
	[K in keyof typeof routesMap]: Omit<(typeof routesMap)[K], "layoutOptions" | "topBarOptions" | "sideBarOptions"> & {
		layoutOptions: PlatformLayoutOptions;
		topBarOptions: TopBarOptions | null;
		sideBarOptions: SideBarOptions | null;
	};
} {
	const user = useUserValue();
	return useMemo(() => {
		const ctx = { user };
		return Object.fromEntries(
			Object.entries(routesMap).map(([key, route]) => [
				key,
				{
					...route,
					topBarOptions: maybeCall(route.topBarOptions, ctx),
					sideBarOptions: maybeCall(route.sideBarOptions, ctx),
					layoutOptions: maybeCall(route.layoutOptions, ctx),
				},
			]),
		) as any;
	}, [user]);
}

type RouteName = keyof typeof routesMap;
type PropsForRouteHelper<K extends RouteName> = ComponentPropsWithoutRef<Awaited<(typeof routesMap)[K]["component"]>>;
type PropsForRoute<K extends RouteName> = PropsForRouteHelper<K> extends Record<string, string>
	? PropsForRouteHelper<K>
	: null;

function typedNavigation<K extends RouteName>(baseFn: (url: string) => void, target: K, props: PropsForRoute<K>): void {
	baseFn(typedUrlForRoute(target, props));
}

export function typedUrlForRoute<K extends RouteName>(target: K, props: PropsForRoute<K>): string {
	const serializedProps = props ? routesMap[target].propsSerde.serialize(props as any) : {};
	return (
		Object.entries(serializedProps.params ?? {}).reduce(
			(url, [key, value]) => url.replace(`:${key}`, value),
			routesMap[target].baseUrl,
		) +
		`${serializedProps.query ? `${routesMap[target].baseUrl.includes("?") ? "&" : "?"}${serializedProps.query}` : ""}`
	);
}

export function typedPropsFromUrl<K extends RouteName>(target: K, url: string): PropsForRoute<K> {
	return routesMap[target].propsSerde.deserialize(url, routesMap[target].baseUrl) as PropsForRoute<K>;
}

export function useTypedNavigation(): {
	push<K extends RouteName>(target: K, props: PropsForRoute<K>): void;
	replace<K extends RouteName>(target: K, props: PropsForRoute<K>): void;
} {
	const { push, replace } = useHistory();
	return useMemo(
		() => ({
			push: (target, props) => typedNavigation(push, target, props),
			replace: (target, props) => typedNavigation(replace, target, props),
		}),
		[push, replace],
	);
}
