import type { InvestmentCommentaryResponse } from "$root/api/api-gen";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import type { MarkdownRendererProps } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { MarkdownRenderer } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import type { ReportTemplateItemMap } from "$root/pages/PortfolioStudioSettings/ReportEditor/version/report-v1";
import { ForEach } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import ReactDOMServer from "react-dom/server";
import { useReportTranslation } from "../translation";

export function getCommentaryProps(data: InvestmentCommentaryResponse): string[] {
	if (!data.commentary) {
		return [];
	}

	const description = `${data.commentary ?? ""}`;
	const marktdownToHtmlString = ReactDOMServer.renderToStaticMarkup(
		<MarkdownRenderer componentOverrides={markdownConfig}>{description}</MarkdownRenderer>,
	);

	const div = document.createElement("div");
	div.innerHTML = marktdownToHtmlString;
	const markdownContainer = div.children[0]!;
	const nodes: string[] = [];

	for (const x of markdownContainer.children) {
		nodes.push(x.outerHTML);
	}

	return nodes;
}

const Commentary = (
	props: PrintableProps<
		{
			config: ReportTemplateItemMap["commentary"];
			commentary: InvestmentCommentaryResponse;
		},
		string
	>,
): JSX.Element => {
	const { commentary, list, firstRender } = props;
	const { status } = commentary;
	const { t } = useReportTranslation();

	if (list.length === 0 || status !== "COMPLETED") {
		return <></>;
	}

	return (
		<Card title={firstRender ? t("REPORT_BUILDER.COMMENTARY.TITLE") : ""} classList="relative pb-6">
			<ForEach collection={list}>
				{({ item: text, index }) => (
					<>
						<div dangerouslySetInnerHTML={{ __html: text }} />

						{list.length - 1 === index && (
							<div className="absolute bottom-2">
								<p className="text-[#8c8ea8]">Content generated by Generative AI</p>
							</div>
						)}
					</>
				)}
			</ForEach>
		</Card>
	);
};

const markdownConfig: MarkdownRendererProps["componentOverrides"] = {
	h1: ({ node: _node, ...forward }) => (
		<h1 className="text-[14px] font-bold pb-2 pt-2" {...forward}>
			{forward.children}
		</h1>
	),
	h2: ({ node: _node, ...forward }) => (
		<h2 className="text-[12px] font-bold pb-2 pt-2" {...forward}>
			{forward.children}
		</h2>
	),
	h3: ({ node: _node, ...forward }) => (
		<h3 className="text-[10px] font-bold pb-2 pt-2" {...forward}>
			{forward.children}
		</h3>
	),
	ul: ({ node: _node, ...forward }) => <ul className="list-disc pl-4" {...forward} />,
	ol: ({ node: _node, ...forward }) => <ul className="list-decimal pl-4" {...forward} />,
	li: ({ node: _node, ...forward }) => <li {...forward} />,
	p: ({ node: _node, ...forward }) => <p className="text pb-2" {...forward} />,
	blockquote: ({ node: _node, ...forward }) => <blockquote className="bg-sky-100 px-2 py-1 rounded" {...forward} />,
	table: ({ node: _node, ...props }) => <table className="w-full border-collapse" {...props} />,
	thead: ({ node: _node, ...props }) => <thead {...props} />,
	tr: ({ node: _node, ...props }) => (
		<tr className={`border-b border-b-[color:${themeCSSVars.palette_N100}]`} {...props} />
	),
	td: ({ node: _node, ...props }) => <td className="text-left p-2 !text-[10px]" {...props} />,
	th: ({ node: _node, ...props }) => (
		<th className="text-left px-2 py-1 !font-bold !text-[10px] !uppercase text-[#667085]" {...props} />
	),
	tbody: ({ node: _node, ...props }) => <tbody {...props} />,
};

export default Commentary;
