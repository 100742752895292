import type {
	Currencies,
	ExposureContributionRequestExposureContributionTypeEnum,
	InvestmentBenchmark,
	InvestmentListEntry,
	InvestmentReference,
	InvestmentSummary,
	ReviewTicker,
} from "$root/api/api-gen";
import {
	BenchmarksControllerApiFactory,
	EditorSaveEditOrReviewRequestPortfolioSavingModeEnum,
	EntityEditorControllerApiFactory,
	InvestmentControllerV4ApiFactory,
	InvestmentReportsControllerApiFactory,
	InvestmentsExposureCompareControllerApiFactory,
	ReferenceUniversesControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { ExposureSankeyLikeChart } from "$root/components/ExposureSankeyLikeChart/ExposureSankeyLikeChart";
import { IconWalls } from "$root/components/IconWall";
import ReactQueryWrapper, { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { platformToast } from "$root/notification-system/toast";
import type { UploadEntity } from "$root/pages/Portfolios/UploadPortfolioPage";
import { UploadEnum } from "$root/pages/Portfolios/UploadPortfolioPage";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { customObjectValuesFn } from "$root/utils/experimental";
import { valueByPath } from "$root/utils/objects";
import { useQueryNoRefetch } from "$root/utils/react-query";
import type { MaybePromise } from "$root/utils/types";
import { zodResolver } from "@hookform/resolvers/zod";
import type { Option } from "@mdotm/mdotui/components";
import {
	AsyncButton,
	Banner,
	Button,
	CircularProgressBar,
	Dialog,
	DialogFooter,
	DropdownMenu,
	DropdownMenuActionButton,
	FormField,
	Icon,
	Radio,
	RadioGroup,
	Select,
	SubmitButton,
	Text,
} from "@mdotm/mdotui/components";
import { generateUniqueDOMId, toClassName, useTick } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { MaybeArray } from "@mdotm/mdotui/utils";
import { alwaysArray, groupBy, noop, unpromisify } from "@mdotm/mdotui/utils";
import equal from "fast-deep-equal";
import { Map, Set } from "immutable";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useForm, type Control, type FormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import type { CompareDataItem } from "../compare-portfolio/CompareOverlay";
import { CompareOverlay } from "../compare-portfolio/CompareOverlay";
import { EditTagButton } from "../instruments/edit-tags";
import InstrumentEditorTable, { If } from "../instruments/instrumentEditorTable";
import type { EditorCompositionIntruments } from "../instruments/instrumentEditorTable/instrumentEditorColumns";
import { useDebouncedNameUniquenessChecker } from "../named-entities/uniqueness";
import type { UseCompositionBuilderResult } from "../universe/composition";
import { useUserValue } from "../user";
import AddAssetClassButton from "./Actions/AddAssetClassButton";
import AddPortfolioButton from "./Actions/AddPortfolioButton";
import UploadInstrumentButton from "./Actions/UploadInstrumentButton";
import { exposureCompareOptions } from "./shared";
import { parallelize } from "$root/utils/promise";
import { spawnCopyTemplateDialog } from "./Actions/CopyTemplateDialog";
import { useHistory } from "react-router";

function payloadBuilder(
	data: Omit<EditorCompositionIntruments, "id" | "rowType"> & {
		id?: string;
		rowType?: "upload" | "add" | "select" | "cash";
	},
) {
	delete data.id;
	delete data.rowType;
	delete data.investment;
	return data;
}

export type UploadCompositionSectionProps = {
	uploadEntity: UploadEntity;
	submitForm?: {
		isOpen: boolean;
		onCancel?(): void;
		onSubmit?(
			uploadEntity: UploadEntity,
			payload: {
				composition: ReviewTicker[];
				formData: {
					baseCurrency?: Currencies;
					name: string;
					primaryBenchmark?: InvestmentBenchmark;
					portfolioSavingMode?: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum;
				};
			},
			canSubmit: boolean,
			uuid?: string,
		): MaybePromise<void>;
	};
	instrumentsLimit?: number;
	uuids?: string[];
	enitityUuid?: string;
	investmentSummary?: InvestmentSummary;
	hasPortfolioIncomposition?: boolean;
	selectBenchmarkTemplate?: boolean;
};

type SubmitFormProps = {
	control: Control<
		{
			baseCurrency: Currencies | undefined;
			name: string;
			primaryBenchmark: InvestmentBenchmark | undefined;
			investmentReference: InvestmentReference | undefined;
			portfolioSavingMode: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum | undefined;
		},
		any
	>;
	formState: FormState<{
		baseCurrency: Currencies | undefined;
		name: string;
		primaryBenchmark: InvestmentBenchmark | undefined;
		investmentReference: InvestmentReference | undefined;
		portfolioSavingMode: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum | undefined;
	}>;
	checkingNameUniqueness: boolean;
	uploadEntity: UploadEntity;
	enitityUuid?: string;
	hasPortfolioIncomposition?: boolean;
};

function SubmitForm({
	control,
	formState,
	checkingNameUniqueness,
	uploadEntity,
	enitityUuid,
	hasPortfolioIncomposition,
}: SubmitFormProps) {
	const editorApi = useApiGen(EntityEditorControllerApiFactory);

	const { t } = useTranslation();
	const formFiledMap: Record<UploadEntity, string> = {
		[UploadEnum.INVESTMENT]: "Portfolio name",
		[UploadEnum.INVESTMENT_DRAFT]: "Portfolio name",
		[UploadEnum.TARGET_INVESTMENT]: "Target name",
		[UploadEnum.BENCHMARK]: "Benchmark name",
		[UploadEnum.UNIVERSE]: "Universe name",
		[UploadEnum.INVESTMENT_ENHANCEMENT]: "",
	};

	const selectableQuery = useQueryNoRefetch({
		queryKey: ["currencies", "benchmarks"],
		enabled:
			uploadEntity === "INVESTMENT" || uploadEntity === "INVESTMENT_DRAFT" || uploadEntity === "TARGET_INVESTMENT",
		queryFn: async () => {
			const selectable = await axiosExtract(editorApi.getEditorNewSelectableMainInfo());

			const currencies: Option<Currencies>[] = (selectable.availableCurrencies ?? []).map((currency) => ({
				label: currency ?? "",
				value: currency,
			}));

			const benchmarks: Option<InvestmentBenchmark>[] = (selectable.availablePrimaryBenchmarks ?? []).map((el) => ({
				label: el.benchmarkName ?? "",
				value: { benchmarkIdentifier: el.benchmarkIdentifier, benchmarkType: el.benchmarkType },
				group: t(`INVESTMENT_REFERENCE_CATEGORIES.${el.benchmarkType!}`),
				disabled: !el.available,
			}));

			const references: Option<InvestmentReference>[] = (selectable.availableInvestmentReferences ?? []).map((el) => ({
				label: el.name ?? "-",
				value: { referenceIdentifier: el.identifier, referenceType: el.type } satisfies InvestmentReference,
				group: t(`INVESTMENT_REFERENCE_CATEGORIES.${el.type!}`),
				disabled: !el.available,
			}));

			return { currencies, benchmarks, references };
		},
	});

	return (
		<div className="grid gap-4 transition-all ease-elastic">
			<FormFields.Text
				control={control}
				formState={formState}
				name="name"
				label={formFiledMap[uploadEntity]}
				data-qualifier="CompositionEditor/SaveDialog/Name"
				placeholder="Name"
				disabled={enitityUuid !== undefined}
				rightContent={
					checkingNameUniqueness ? <CircularProgressBar classList="w-3" value="indeterminate" /> : undefined
				}
			/>

			{(uploadEntity === UploadEnum.INVESTMENT ||
				uploadEntity === UploadEnum.TARGET_INVESTMENT ||
				uploadEntity === UploadEnum.INVESTMENT_DRAFT) && (
				<ReactQueryWrapperBase
					query={selectableQuery}
					loadingFallback={<CircularProgressBar style={{ width: "1rem" }} value="indeterminate" />}
				>
					{({ currencies }) => (
						<FormFields.Select
							control={control}
							formState={formState}
							name="baseCurrency"
							label="Base currency"
							i18n={{ triggerPlaceholder: () => t("PORTFOLIOS.PORTFOLIO_CURRENCY_PLACEHOLDER") }}
							style={{ width: "100%" }}
							options={currencies}
							data-qualifier="CompositionEditor/SaveDialog/Currency"
							enableSearch
						/>
					)}
				</ReactQueryWrapperBase>
			)}
			{(uploadEntity === UploadEnum.INVESTMENT || uploadEntity === UploadEnum.INVESTMENT_DRAFT) && (
				<ReactQueryWrapperBase
					query={selectableQuery}
					loadingFallback={<CircularProgressBar style={{ width: "1rem" }} value="indeterminate" />}
				>
					{({ benchmarks }) => (
						<FormFields.Select
							enableSearch
							control={control}
							formState={formState}
							name="primaryBenchmark"
							label="Comparative benchmark"
							options={benchmarks}
							data-qualifier="CompositionEditor/SaveDialog/Benchmark"
							i18n={{
								triggerPlaceholder: () => t("PORTFOLIOS.PORTFOLIO_BENCHMARK_PLACEHOLDER"),
							}}
							style={{ width: "100%" }}
						/>
					)}
				</ReactQueryWrapperBase>
			)}

			{(uploadEntity === UploadEnum.INVESTMENT || uploadEntity === UploadEnum.INVESTMENT_DRAFT) && (
				<ReactQueryWrapperBase
					query={selectableQuery}
					loadingFallback={<CircularProgressBar style={{ width: "1rem" }} value="indeterminate" />}
				>
					{({ references }) => (
						<FormFields.Select
							enableSearch
							control={control}
							formState={formState}
							name="investmentReference"
							label="Reference"
							options={references}
							data-qualifier="CompositionEditor/SaveDialog/investmentReference"
							i18n={{
								triggerPlaceholder: () => t("PORTFOLIOS.PORTFOLIO_REFERENCE_PLACEHOLDER"),
							}}
							style={{ width: "100%" }}
							unselectOnMatch
						/>
					)}
				</ReactQueryWrapperBase>
			)}

			{uploadEntity !== "TARGET_INVESTMENT" &&
				uploadEntity !== "BENCHMARK" &&
				uploadEntity !== "UNIVERSE" &&
				hasPortfolioIncomposition && (
					<FormField
						label="Composition type"
						error={(valueByPath(formState.errors, "portfolioSavingMode") as { message?: string })?.message}
					>
						{(fieldProps) => (
							<FormController
								control={control}
								name="portfolioSavingMode"
								defaultValue={EditorSaveEditOrReviewRequestPortfolioSavingModeEnum.NestPortfolios}
								render={({ field: { ref: _ref, ...controllerProps } }) => (
									<RadioGroup
										{...controllerProps}
										{...fieldProps}
										onChange={controllerProps.onChange}
										data-qualifier="CompositionEditor/SaveDialog/CompositionMode"
									>
										<div className="flex flex-row flex-wrap gap-4">
											<Radio value={EditorSaveEditOrReviewRequestPortfolioSavingModeEnum.NestPortfolios}>Nested</Radio>

											<Radio value={EditorSaveEditOrReviewRequestPortfolioSavingModeEnum.MixInstruments}>
												Aggregate
											</Radio>
										</div>
									</RadioGroup>
								)}
							/>
						)}
					</FormField>
				)}
		</div>
	);
}

type EditorActionHeaderProps = {
	onResetInstruments(newInstruments: EditorCompositionIntruments[]): void;
	onAddInstruments(newInstruments: EditorCompositionIntruments[]): void;
	setShowExposureContribution?(visible: boolean): void;
	compositionBuilder: UseCompositionBuilderResult;
	uploadInstruments: EditorCompositionIntruments[];
	tagList?: Array<{ color: string; value: string }>;
	entityUuid?: string;
	uploadEntity: UploadEntity;
	tagCrud: {
		add: (tag: string) => void;
		remove: (tag: string) => void;
		reset(tagList: string[]): void;
	};
	willPortfolioCompositionBeShrinked: (id: string, ptfUuid?: string, weight?: number) => Promise<void>;
	selectBenchmarkTemplate?: boolean;
};

type UploadActionUnion = "add-row" | "edit-tag" | "copy-template" | "add-assetClass" | "add-portfolio" | "upload";

type UploadActions = {
	options?: Set<UploadActionUnion>;
	addInstrumentDropdownOptions?: Set<UploadActionUnion>;
};

const headerMapButtons: Record<UploadEntity, UploadActions> = {
	INVESTMENT: {
		addInstrumentDropdownOptions: Set(["add-assetClass", "add-portfolio", "upload"]),
	},
	INVESTMENT_DRAFT: {
		addInstrumentDropdownOptions: Set(["add-assetClass", "add-portfolio", "upload"]),
	},
	TARGET_INVESTMENT: { addInstrumentDropdownOptions: Set(["add-assetClass", "upload"]) },
	BENCHMARK: {
		addInstrumentDropdownOptions: Set(["add-assetClass", "upload"]),
	},
	UNIVERSE: {
		options: Set(["edit-tag"]),
		addInstrumentDropdownOptions: Set(["add-assetClass", "add-portfolio", "upload"]),
	},
	INVESTMENT_ENHANCEMENT: {},
};

const validateActionPermission = (values: MaybeArray<UploadActionUnion>, options?: Set<UploadActionUnion>) => {
	const actions = alwaysArray(values);
	return actions.every((action) => options?.has(action));
};

// TODO: refactor action to batch action approach
function UploadActionHeader({
	uploadEntity,
	entityUuid,
	compositionBuilder,
	uploadInstruments,
	tagList,
	onAddInstruments,
	onResetInstruments,
	setShowExposureContribution,
	willPortfolioCompositionBeShrinked,
	tagCrud,
	selectBenchmarkTemplate,
}: EditorActionHeaderProps) {
	const [bubbleSelectAssetClass, setBubbleSelectAssetClass] = useState<{ setShowDialog?(state: boolean): void }>();
	const [bubbleUploadExcel, setBubbleUploadExcel] = useState<{ setShowDialog?(state: boolean): void }>();
	const [bubbleSelectPortfolio, setBubbleSelectPortfolio] = useState<{ setShowDialog?(state: boolean): void }>();
	const addPortfolioCounterRef = useRef(0);
	const history = useHistory();

	const currentComposition = useMemo(() => {
		const deleted = compositionBuilder.getDeleted();
		return uploadInstruments
			.filter((instrument) => deleted.has(instrument.id) === false)
			.map((instrument) =>
				payloadBuilder({
					...instrument,
					weight: compositionBuilder.getWeight(instrument.id)?.toNumber(),
					alias: instrument.rowType === "add" ? compositionBuilder.getIdentifier(instrument.id) : instrument.alias,
				}),
			);
	}, [compositionBuilder, uploadInstruments]);

	const entityButtons = useMemo(() => headerMapButtons[uploadEntity], [uploadEntity]);

	useEffect(() => {
		if (selectBenchmarkTemplate) {
			spawnCopyTemplateDialog({
				onSubmit(benchmarks) {
					const mapInstruments = benchmarks.map(
						(b): EditorCompositionIntruments => ({
							...b,
							rowType: "select",
							weight: b.weight ?? 0,
							id: generateUniqueDOMId(),
						}),
					);
					onResetInstruments(mapInstruments);
					const newSearchParams = new URLSearchParams(history.location.search);
					newSearchParams.delete("selectBenchmarkTemplate");
					history.replace({ search: newSearchParams.toString() });
				},
			});
		}
	}, [onResetInstruments, selectBenchmarkTemplate, history]);

	return (
		<div className="flex gap-2">
			{validateActionPermission("edit-tag", entityButtons.options) && (
				<EditTagButton options={tagList ?? []} enableDebounce onAdd={tagCrud.add} onDelete={tagCrud.remove} />
			)}

			{validateActionPermission("upload", entityButtons.options) && (
				<UploadInstrumentButton
					data-qualifier="CompositionEditor/HeaderAction/Upload"
					uploadEntity={uploadEntity}
					currentComposition={currentComposition}
					onSave={(instruments) => {
						const mapInstruments = instruments.map(
							(i): EditorCompositionIntruments => ({
								...i,
								rowType: "upload",
								weight: i.weight ?? 0,
								id: generateUniqueDOMId(),
							}),
						);
						onResetInstruments(mapInstruments);
					}}
				/>
			)}

			{validateActionPermission("add-assetClass", entityButtons.options) && (
				<AddAssetClassButton
					filtersMode="multi"
					uploadEntity={uploadEntity}
					onConfirmSelectionAsync={onAddInstruments}
					selectedInstruments={uploadInstruments.filter(({ ticker }) => ticker).map(({ ticker }) => ticker!)}
					onAddCustomInstrument={({ identifier, weight }) => {
						onAddInstruments([{ rowType: "select", weight, id: generateUniqueDOMId(), alias: identifier }]);
						platformToast({
							children: `The instrument “${identifier}” has been added.`,
							icon: "Icon-full-ok",
							severity: "success",
						});
					}}
					instrumentsInComposition={uploadInstruments}
					data-qualifier="CompositionEditor/HeaderAction/AddAssetClass"
				/>
			)}

			{validateActionPermission("add-assetClass", entityButtons.addInstrumentDropdownOptions) && (
				<AddAssetClassButton
					filtersMode="multi"
					uploadEntity={uploadEntity}
					onConfirmSelectionAsync={onAddInstruments}
					onAddCustomInstrument={({ identifier, weight }) => {
						onAddInstruments([{ rowType: "select", weight, id: generateUniqueDOMId(), alias: identifier }]);
						platformToast({
							children: `The instrument “${identifier}” has been added.`,
							icon: "Icon-full-ok",
							severity: "success",
						});
					}}
					instrumentsInComposition={uploadInstruments}
					selectedInstruments={uploadInstruments.filter(({ ticker }) => ticker).map(({ ticker }) => ticker!)}
					renderCustomButton={function Bubble({ setShowDialog }) {
						useEffect(() => {
							setBubbleSelectAssetClass({ setShowDialog });
						}, [setShowDialog]);
						return <></>;
					}} //TODO: porcaruond rimouviii
					identifier={entityUuid}
				/>
			)}

			{validateActionPermission("upload", entityButtons.addInstrumentDropdownOptions) && (
				<UploadInstrumentButton
					uploadEntity={uploadEntity}
					currentComposition={currentComposition}
					renderCustomButton={function Bubble({ setShowDialog }) {
						useEffect(() => {
							setBubbleUploadExcel({ setShowDialog });
						}, [setShowDialog]);
						return <></>;
					}} //TODO: porcaruond rimouviii
					onSave={(instruments) => {
						const mapInstruments = instruments.map(
							(i): EditorCompositionIntruments => ({
								...i,
								rowType: "upload",
								weight: i.weight ?? 0,
								id: generateUniqueDOMId(),
							}),
						);
						onResetInstruments(mapInstruments);
					}}
				/>
			)}

			{validateActionPermission("add-portfolio", entityButtons.addInstrumentDropdownOptions) && (
				<AddPortfolioButton
					uuid={entityUuid}
					uploadEntity={uploadEntity}
					selectedPortfolios={uploadInstruments.filter(({ ticker }) => ticker).map(({ ticker }) => ticker!)}
					renderCustomButton={function Bubble({ setShowDialog, rows }) {
						const syncFlag = useRef(false);

						useEffect(() => {
							setBubbleSelectPortfolio({ setShowDialog });
						}, [setShowDialog]);

						useEffect(() => {
							if (rows.length > 0 && !syncFlag.current) {
								onResetInstruments(
									uploadInstruments.map((x) => ({
										...x,
										investment: rows.find(({ uuid }) => uuid === x.ticker),
									})),
								);
								syncFlag.current = true;
							}
						}, [rows]);

						return <></>;
					}} //TODO: porcaruond rimouviii
					onConfirm={(portfolios) => {
						const compositionToAdd = portfolios.map(
							(x): EditorCompositionIntruments => ({
								id: x.domId,
								rowType: "select",
								ticker: x.uuid,
								identifier: "Portfolio",
								instrument: x.name,
								proxyOverwriteType: "PORTFOLIO_MIXED",
								investment: x,
								weight: 10,
							}),
						);
						unpromisify(async () => {
							await parallelize(
								compositionToAdd.map((c) => () => willPortfolioCompositionBeShrinked(c.id, c.ticker!, c.weight!)),
							);
						})();
						onAddInstruments(compositionToAdd);
						if (addPortfolioCounterRef.current === 0 && uploadEntity !== "UNIVERSE") {
							setShowExposureContribution?.(true);
							addPortfolioCounterRef.current += 1;
						}
					}}
				/>
			)}

			{entityButtons.addInstrumentDropdownOptions && (
				<DropdownMenu
					position="bottom"
					trigger={(props) => (
						<Button
							palette="secondary"
							size="small"
							classList="flex gap-2"
							{...props}
							data-qualifier="CompositionEditor/HeaderAction/DropdownMenu"
						>
							<Icon icon="add-ptf" size={18} />
							Add
						</Button>
					)}
					align="endToEnd"
					actions={[
						({ onClose }) =>
							validateActionPermission("add-assetClass", entityButtons.addInstrumentDropdownOptions) && (
								<DropdownMenuActionButton
									onClick={() => {
										bubbleSelectAssetClass?.setShowDialog?.(true);
										onClose();
									}}
									data-qualifier="CompositionEditor/HeaderAction/DropdownMenu(AddInstrument)"
								>
									Add instruments
								</DropdownMenuActionButton>
							),
						({ onClose }) =>
							validateActionPermission("add-portfolio", entityButtons.addInstrumentDropdownOptions) && (
								<DropdownMenuActionButton
									onClick={() => {
										bubbleSelectPortfolio?.setShowDialog?.(true);
										onClose();
									}}
									data-qualifier="CompositionEditor/HeaderAction/DropdownMenu(AddPortfolio)"
								>
									Add portfolio
								</DropdownMenuActionButton>
							),
						({ onClose }) =>
							validateActionPermission("upload", entityButtons.addInstrumentDropdownOptions) && (
								<DropdownMenuActionButton
									onClick={() => {
										bubbleUploadExcel?.setShowDialog?.(true);
										onClose();
									}}
									data-qualifier="CompositionEditor/HeaderAction/DropdownMenu(Upload)"
								>
									Upload composition
								</DropdownMenuActionButton>
							),
					]}
				/>
			)}
		</div>
	);
}

const translation: Record<UploadEntity, string> = {
	INVESTMENT: "portfolio",
	INVESTMENT_DRAFT: "portfolio draft",
	INVESTMENT_ENHANCEMENT: "portfolio",
	BENCHMARK: "benchmark",
	TARGET_INVESTMENT: "target portfolio",
	UNIVERSE: "universe",
};

const errorMessageRequired: Record<UploadEntity, string> = {
	INVESTMENT: "Please provide a name for your portfolio",
	INVESTMENT_DRAFT: "Please provide a name for your portfolio",
	INVESTMENT_ENHANCEMENT: "Please provide a name for your portfolio",
	BENCHMARK: "Please provide a name for your benchmark",
	TARGET_INVESTMENT: "Please provide a name for your portfolio",
	UNIVERSE: "Please provide a name for your universe",
};

const uploadTitle: Record<UploadEntity, string> = {
	INVESTMENT: "Save portfolio",
	INVESTMENT_DRAFT: "Save portfolio draft",
	BENCHMARK: "Benchmark name",
	TARGET_INVESTMENT: "Target portfolio name",
	UNIVERSE: "Universe name",
	INVESTMENT_ENHANCEMENT: "",
};

const UploadCompositionSection = forwardRef<
	{ isDirty: boolean; hasPortfolioIncomposition: boolean },
	UploadCompositionSectionProps
>(function _UploadCompositionSection(
	{
		submitForm,
		uploadEntity,
		instrumentsLimit,
		uuids,
		enitityUuid,
		investmentSummary,
		hasPortfolioIncomposition,
		selectBenchmarkTemplate,
	},
	ref,
): JSX.Element {
	// const [hasComponentReachedTheLimit, setHasComponentReachedLimit] = useState(false);
	const [IsMinNumberInstrumentReached, setIsMinNumberInstrumentReached] = useState(false);
	const [isDraft, setIsDraft] = useState(false);
	const [submitCounter, setSubmitCounter] = useState(0);

	const [showExposureContribution, setShowExposureContribution] = useState(false);
	const [exposureCompareCategory, setExposureCompareCategory] =
		useState<ExposureContributionRequestExposureContributionTypeEnum>("MACRO_ASSET_CLASS");
	const [investmentEntry, setInvestmentEntry] = useState<Array<{ entityUuid: string; composedWeight: number }>>([]);
	const [compareSelection, setCompareSelection] = useState(Map<string, InvestmentListEntry>());

	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);
	const editorApi = useApiGen(EntityEditorControllerApiFactory);
	const benchmarksV4Api = useApiGen(BenchmarksControllerApiFactory);
	const referenceUniversesV4Api = useApiGen(ReferenceUniversesControllerApiFactory);
	const investmentsExposureCompareApi = useApiGen(InvestmentsExposureCompareControllerApiFactory);
	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);

	const user = useUserValue();
	const tick = useTick();
	const { checkingNameUniqueness, checkIfNameIsAvailable } = useDebouncedNameUniquenessChecker({
		isNameAvailableApi: (name, opts) => {
			switch (uploadEntity) {
				case "BENCHMARK":
					return axiosExtract(benchmarksV4Api.isBenchmarkNameAvailable(name, opts));
				case "UNIVERSE":
					return axiosExtract(referenceUniversesV4Api.isUniverseNameAvailable(name, opts));
				case "INVESTMENT":
				case "INVESTMENT_ENHANCEMENT":
				case "INVESTMENT_DRAFT":
				case "TARGET_INVESTMENT":
					return axiosExtract(investmentApi.isInvestmentNameAvailable(name, opts));
			}
		},
	});

	const minNumberOfInstrument = useMemo(
		() =>
			hasAccess(user, { requiredService: "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS" }) ||
			uploadEntity === "BENCHMARK" ||
			uploadEntity === "TARGET_INVESTMENT"
				? 1
				: 5,
		[user, uploadEntity],
	);

	const { control, formState, handleSubmit, reset } = useForm({
		defaultValues: {
			baseCurrency: undefined as Currencies | undefined,
			name: "",
			primaryBenchmark: undefined as undefined | InvestmentBenchmark,
			investmentReference: undefined as InvestmentReference | undefined,
			portfolioSavingMode: undefined as EditorSaveEditOrReviewRequestPortfolioSavingModeEnum | undefined,
		},
		resolver: zodResolver(
			uploadEntity === UploadEnum.INVESTMENT || uploadEntity === UploadEnum.INVESTMENT_DRAFT
				? isDraft
					? z.object({
							baseCurrency: z.string().optional(),
							name: z
								.string()
								.min(1, errorMessageRequired[uploadEntity])
								.refine(
									(name) => {
										if (!enitityUuid) {
											return checkIfNameIsAvailable(name);
										}
										return name ? true : checkIfNameIsAvailable(name);
									},
									{
										message: "Name not available",
									},
								),
							primaryBenchmark: z
								.object(
									{
										benchmarkIdentifier: z.string(),
										benchmarkType: z.string(),
									},
									{ invalid_type_error: "Please select a benchmark" },
								)
								.optional(),
							investmentReference: z
								.object({
									referenceType: z.any(),
									referenceIdentifier: z.string().nullable(),
								} satisfies Record<keyof InvestmentReference, unknown>)
								.optional()
								.nullable(),
							portfolioSavingMode: z.nativeEnum(EditorSaveEditOrReviewRequestPortfolioSavingModeEnum).optional(),
					  })
					: z.object({
							baseCurrency: z.string(),
							name: z
								.string()
								.min(1, errorMessageRequired[uploadEntity])
								.refine(
									(name) => {
										if (!enitityUuid) {
											return checkIfNameIsAvailable(name);
										}
										return name ? true : checkIfNameIsAvailable(name);
									},
									{
										message: "Name not available",
									},
								),
							primaryBenchmark: z
								.object(
									{
										benchmarkIdentifier: z.string(),
										benchmarkType: z.string(),
									},
									{ invalid_type_error: "Please select a benchmark" },
								)
								.required()
								.passthrough(),
							investmentReference: z
								.object({
									referenceType: z.any(),
									referenceIdentifier: z.string().nullable(),
								} satisfies Record<keyof InvestmentReference, unknown>)
								.optional()
								.nullable(),
							portfolioSavingMode: hasPortfolioIncomposition
								? z.nativeEnum(EditorSaveEditOrReviewRequestPortfolioSavingModeEnum)
								: z.nativeEnum(EditorSaveEditOrReviewRequestPortfolioSavingModeEnum).optional(),
					  })
				: uploadEntity === UploadEnum.TARGET_INVESTMENT
				  ? z.object({
							baseCurrency: z.string(),
							name: z
								.string()
								.min(1, errorMessageRequired[uploadEntity])
								.refine((name) => checkIfNameIsAvailable(name), {
									message: "Name not available",
								}),
				    })
				  : uploadEntity === UploadEnum.UNIVERSE
				    ? z.object({
								name: z
									.string()
									.min(1, errorMessageRequired[uploadEntity])
									.refine((name) => checkIfNameIsAvailable(name), {
										message: "Name not available",
									}),
								portfolioSavingMode: z
									.nativeEnum(EditorSaveEditOrReviewRequestPortfolioSavingModeEnum)
									.optional()
									.catch(undefined),
				      })
				    : z.object({
								name: z
									.string()
									.min(1, errorMessageRequired[uploadEntity])
									.refine((name) => checkIfNameIsAvailable(name), {
										message: "Name not available",
									}),
				      }),
		),
	});

	// TODO: React Hook Form Discurage this kind of resync, watch the doc
	useEffect(() => {
		if (investmentSummary) {
			reset(
				{
					baseCurrency: investmentSummary.baseCurrency,
					name: investmentSummary.name,
					primaryBenchmark: {
						benchmarkIdentifier: investmentSummary.primaryBenchmarkIdentifier,
						benchmarkType: investmentSummary.primaryBenchmarkType,
					},
					investmentReference: {
						referenceIdentifier: investmentSummary.referenceIdentifier,
						referenceType: investmentSummary.referenceType,
					},
				},
				{ keepDefaultValues: false, keepDirty: false },
			);
		}
	}, [investmentSummary, reset]);

	useQueryNoRefetch(["queryInvestmentSummary"], {
		enabled: (uploadEntity === "INVESTMENT" || uploadEntity === "INVESTMENT_DRAFT") && enitityUuid !== undefined,
		queryFn: () => axiosExtract(investmentReportApi.getInvestmentSummary(enitityUuid!)),
		onSuccess: (summary) => {
			reset(
				{
					baseCurrency: summary.baseCurrency,
					name: summary.name,
					primaryBenchmark: {
						benchmarkIdentifier: summary.primaryBenchmarkIdentifier,
						benchmarkType: summary.primaryBenchmarkType,
					},
					investmentReference: {
						referenceIdentifier: summary.referenceIdentifier,
						referenceType: summary.referenceType,
					},
				},
				{ keepDefaultValues: false, keepDirty: false },
			);
		},
	});

	const onSubmitAsync = useCallback(
		async (
			compositionBuilder: UseCompositionBuilderResult,
			uploadInstruments: EditorCompositionIntruments[],
			entity: UploadEntity,
			canSubmit: boolean,
			uuid?: string,
		) => {
			const deleted = compositionBuilder.getDeleted();
			const compositionToSubmit = uploadInstruments.filter(({ id }) => deleted.has(id) === false);

			if (!canSubmit) {
				await submitForm?.onSubmit?.(entity, { composition: [], formData: { name: "" } }, canSubmit);
				platformToast({
					children: `Unable to generate your ${translation[entity]} please review your composition`,
					severity: "warning",
					icon: "Portfolio",
				});
				if (compositionToSubmit.length < minNumberOfInstrument) {
					platformToast({
						children: `You must add at least ${minNumberOfInstrument} instruments to your composition before submitting. Please include more instruments to proceed.`,
						severity: "warning",
						icon: "Portfolio",
					});
				}
				return;
			}
			setIsDraft(false);
			await tick();
			await handleSubmit(async (formData) => {
				const composition = compositionToSubmit.map((instrument) =>
					payloadBuilder({
						...instrument,
						weight: compositionBuilder.getWeight(instrument.id)?.toNumber(),
						alias: instrument.rowType === "add" ? compositionBuilder.getIdentifier(instrument.id) : instrument.alias,
						score: compositionBuilder.getScore(instrument.id)?.toNumber(),
						tagLabel: compositionBuilder.getTag(instrument.id) || undefined,
					}),
				);
				await submitForm?.onSubmit?.(
					entity,
					{
						composition,
						formData: {
							...formData,
							portfolioSavingMode:
								hasPortfolioIncomposition && entity !== "UNIVERSE"
									? formData.portfolioSavingMode
									: hasPortfolioIncomposition
									  ? "NEST_PORTFOLIOS"
									  : "MIX_INSTRUMENTS",
						},
					},
					true,
					uuid,
				);
			}, console.log)();
		},
		[handleSubmit, hasPortfolioIncomposition, minNumberOfInstrument, submitForm, tick],
	);

	const onSaveDraft = useCallback(
		async (
			compositionBuilder: UseCompositionBuilderResult,
			uploadInstruments: EditorCompositionIntruments[],
			entity: UploadEntity,
			uuid?: string,
		) => {
			setIsDraft(true);
			await tick();
			await handleSubmit(async (formData) => {
				const deleted = compositionBuilder.getDeleted();
				const composition = uploadInstruments
					.filter(({ id }) => deleted.has(id) === false)
					.map((instrument) =>
						payloadBuilder({
							...instrument,
							weight: compositionBuilder.getWeight(instrument.id)?.toNumber(),
							alias: instrument.rowType === "add" ? compositionBuilder.getIdentifier(instrument.id) : instrument.alias,
							score: compositionBuilder.getScore(instrument.id)?.toNumber(),
							tagLabel: compositionBuilder.getTag(instrument.id) || undefined,
						}),
					);
				await submitForm?.onSubmit?.(entity, { composition, formData }, true, uuid);
				setSubmitCounter((c) => c + 1);
			}, noop)();
		},
		[handleSubmit, submitForm, tick],
	);

	const portfoliosWeights = useMemo(
		() => investmentEntry.filter((x) => x.entityUuid !== "INSTRUMENT_WEIGHTS"),
		[investmentEntry],
	);

	const havePortfolioCorrectWeight = portfoliosWeights.every((x) => x.composedWeight < 0 || x.composedWeight > 100);

	const { isFetching, data: exposureCompare } = useQueryNoRefetch(
		["queryExposureCompare", exposureCompareCategory, investmentEntry, showExposureContribution],
		{
			enabled:
				uploadEntity !== "UNIVERSE" &&
				investmentEntry.length > 0 &&
				showExposureContribution === true &&
				portfoliosWeights.some((x) => x.composedWeight > 0 && x.composedWeight <= 100),
			queryFn: () =>
				axiosExtract(
					investmentsExposureCompareApi.getExposureContribution({
						exposureContributionType: exposureCompareCategory,
						entries: portfoliosWeights.filter((x) => x.composedWeight > 0),
						instrumentsWeight: investmentEntry.find((x) => x.entityUuid === "INSTRUMENT_WEIGHTS")?.composedWeight ?? 0,
					}),
				),
		},
	);

	const sankeyData = useMemo(() => {
		if (!exposureCompare) {
			return [];
		}
		const { portfolioComposition } = exposureCompare;
		const groupedPortfolioByUuid = groupBy(portfolioComposition ?? [], (x) => x.entityUuid!);

		const investmentComposition = customObjectValuesFn(groupedPortfolioByUuid).flatMap((contributions) => {
			if (!contributions) {
				return [];
			}
			const entry = contributions[0];
			return [
				{
					label: entry.entityName!,
					name: entry.entityUuid!,
					weight: entry.composedWeight!,
					items: contributions.map((x) => ({
						quality: x.quality!,
						weight: x.weight ?? 0,
					})),
				},
			];
		});
		return investmentComposition;
	}, [exposureCompare]);

	return (
		<>
			<div
				className={toClassName({
					"flex min-h-[calc(100dvh_-_228px)] h-full": true,
					"space-x-2": showExposureContribution,
				})}
			>
				<div
					className={toClassName({
						"w-full": showExposureContribution === false,
						"w-4/6": showExposureContribution,
						"transition-[width]": true,
					})}
				>
					<div className="rounded bg-white p-4 flex flex-col">
						<If
							condition={
								IsMinNumberInstrumentReached === false &&
								minNumberOfInstrument > 0 &&
								!hasAccess(user, { requiredService: "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS" })
							}
						>
							<Banner severity="info" title="Minimum number of instrument" classList="mb-4">
								You need to add at least {minNumberOfInstrument} instruments or a portfolio
							</Banner>
						</If>
						<ReactQueryWrapper
							queryKey={["queryInitUploadComposition", uploadEntity, submitCounter]}
							queryFn={async () => {
								let response;
								let selectablePortfolios: InvestmentListEntry[] | undefined = [];
								if (enitityUuid) {
									response = await axiosExtract(editorApi.getEditorEditComposition(enitityUuid, "INVESTMENT_DRAFT"));
									selectablePortfolios = (
										await axiosExtract(editorApi.getEditorEditSelectablePortfolios(enitityUuid, "INVESTMENT_DRAFT"))
									).selectablePortfolios;
								} else {
									response = await axiosExtract(editorApi.getEditorNewComposition(uploadEntity));
									selectablePortfolios = (await axiosExtract(editorApi.getEditorNewSelectablePortfolios(uploadEntity)))
										.selectablePortfolios;
								}

								const mapPortfolios = Map(selectablePortfolios?.map((x) => [x.uuid, x]));
								const selectedPortfolio = (uuids ?? []).flatMap((uuid): Array<EditorCompositionIntruments> => {
									const entry = mapPortfolios.get(uuid);
									return entry && entry.canUseAsMixedPortfolio
										? [
												{
													rowType: "select",
													id: generateUniqueDOMId(),
													ticker: entry.uuid,
													investment: entry,
													identifier: "Portfolio",
													instrument: entry.name,
													proxyOverwriteType: "PORTFOLIO_MIXED",
													weight: 10,
												},
										  ]
										: [];
								});

								const composition = (response.composition ?? []).map(
									(instrument): EditorCompositionIntruments =>
										instrument.proxyOverwriteType === "PORTFOLIO_MIXED"
											? {
													...instrument,
													rowType: "select",
													id: generateUniqueDOMId(),
													investment: mapPortfolios.get(instrument.ticker),
													identifier: "Portfolio",
											  }
											: {
													...instrument,
													id: generateUniqueDOMId(),
													rowType: "select",
											  },
								);

								if (selectedPortfolio.length >= 1 && uploadEntity !== "UNIVERSE") {
									setShowExposureContribution(true);
								}
								return { ...response, composition, selectedPortfolio };
							}}
							errorFallback={function ErrorBlock() {
								return (
									<div className="bg-white rounded-md flex justify-center items-center h-[40dvh]">
										<p className="italic">Something went wrong</p>
									</div>
								);
							}}
						>
							{function MainBlock({ cashTicker, composition, selectedPortfolio }) {
								return (
									<InstrumentEditorTable
										mode="new"
										enableHardDelete
										moneyMarket={cashTicker}
										instruments={[...composition, ...selectedPortfolio]}
										entity={uploadEntity}
										compareSelection={compareSelection}
										limit={instrumentsLimit}
										onCompare={(selection, action) => {
											selection.forEach((x) => {
												const entry = compareSelection.get(x.id);
												const { investment } = x;
												if (entry === undefined && x.proxyOverwriteType === "PORTFOLIO_MIXED" && investment) {
													setCompareSelection((selectionMap) => selectionMap.set(x.id, investment));
												}

												if (entry && action === "remove") {
													setCompareSelection((selectionMap) => selectionMap.delete(x.id));
												}
											});
										}}
										actionHeader={(props) => (
											<div className="flex space-x-2">
												{(uploadEntity === "INVESTMENT" ||
													uploadEntity === "INVESTMENT_DRAFT" ||
													uploadEntity === "INVESTMENT_ENHANCEMENT") && (
													<Button
														unstyled
														classList="flex space-x-2 items-center"
														onClick={() => setShowExposureContribution((show) => !show)}
														disabled={havePortfolioCorrectWeight && showExposureContribution === false}
													>
														<Icon
															icon={showExposureContribution ? "ask-position-rightsmall" : "ask-positionleftsmall"}
															color={
																havePortfolioCorrectWeight && showExposureContribution === false
																	? themeCSSVars.palette_N400
																	: themeCSSVars.palette_P400
															}
															size={16}
														/>
														<Text
															as="span"
															type="Body/M/Bold"
															color={
																havePortfolioCorrectWeight && showExposureContribution === false
																	? themeCSSVars.palette_N400
																	: themeCSSVars.palette_P400
															}
														>
															{showExposureContribution ? "Hide exposure contribution" : "Show exposure contribution"}
														</Text>
													</Button>
												)}
												<UploadActionHeader
													{...props}
													uploadEntity={uploadEntity}
													entityUuid={enitityUuid}
													setShowExposureContribution={setShowExposureContribution}
													selectBenchmarkTemplate={selectBenchmarkTemplate}
												/>
											</div>
										)}
									>
										{function RenderModalBody({ compositionBuilder, uploadInstruments, instrumentNumber }) {
											// const dirty = useMemo(
											// 	() => !equal(composition, uploadInstruments) || compositionBuilder.isDirty,
											// 	[compositionBuilder.isDirty, uploadInstruments],
											// );

											const isTotalWeightValid = useMemo(
												() => uploadEntity === "UNIVERSE" || compositionBuilder.getTotalWeight().toNumber() === 100,
												[compositionBuilder, uploadInstruments], // composition builder reference inhert to memo dependecy on first action, added  uploadInstruments for memo re-trigger
											);

											const currentInstrumentsInList = useMemo(() => {
												const deleted = compositionBuilder.getDeleted();
												return uploadInstruments.filter(({ id }) => deleted.has(id) === false);
											}, [compositionBuilder, uploadInstruments]);

											const somePortfolioIncomposition = useMemo(
												() => currentInstrumentsInList.some((x) => x.proxyOverwriteType === "PORTFOLIO_MIXED"),
												[currentInstrumentsInList],
											);

											const cleanedComposition = useMemo(() => {
												const instrumentsNotDeleted = compositionBuilder.getComposition({ excludeDeleted: true });
												return {
													list: instrumentsNotDeleted,
													isValid:
														instrumentsNotDeleted.size > 0 &&
														instrumentsNotDeleted.size <= (instrumentsLimit ?? instrumentsNotDeleted.size),
												};
											}, [compositionBuilder, uploadInstruments]); // composition builder reference inhert to memo dependecy, added  uploadInstruments for memo re-trigger

											const minNumberInstrumentReached = useMemo(
												() => instrumentNumber >= minNumberOfInstrument,
												[instrumentNumber],
											);

											const brakeDownWeight = useMemo(() => {
												const deleted = compositionBuilder.getDeleted();
												const composition = uploadInstruments.reduce<{ [key: string]: number }>((acc, el) => {
													const isDeleted = deleted.get(el.id);
													if (isDeleted) {
														return acc;
													}

													if (el.proxyOverwriteType === "PORTFOLIO_MIXED" && el.ticker) {
														return {
															...acc,
															[el.ticker]: compositionBuilder.getWeight(el.id)?.toNumber() ?? 0,
														};
													}
													const instrumentsWeight = acc["INSTRUMENT_WEIGHTS"] ?? 0;
													return {
														...acc,
														INSTRUMENT_WEIGHTS:
															instrumentsWeight + (compositionBuilder.getWeight(el.id)?.toNumber() ?? 0),
													};
												}, {});

												return Object.entries(composition).map(([entityUuid, composedWeight]) => ({
													entityUuid,
													composedWeight,
												}));
											}, [compositionBuilder, uploadInstruments]);

											useEffect(() => {
												setInvestmentEntry((prev) => {
													if (equal(brakeDownWeight, prev)) {
														return prev;
													}

													return brakeDownWeight;
												});
											}, [brakeDownWeight]);

											useEffect(() => {
												setIsMinNumberInstrumentReached(minNumberInstrumentReached);
											}, [minNumberInstrumentReached]);

											const isCompositionBuilderValid = useMemo(
												() => isTotalWeightValid && cleanedComposition.isValid && minNumberInstrumentReached,
												[isTotalWeightValid, cleanedComposition.isValid, minNumberInstrumentReached],
											);

											useImperativeHandle(
												ref,
												() => {
													return {
														isDirty: compositionBuilder.isDirty,
														hasPortfolioIncomposition: somePortfolioIncomposition,
													};
												},
												[compositionBuilder.isDirty, somePortfolioIncomposition],
											);

											return (
												<>
													<Dialog
														header={uploadTitle[uploadEntity]}
														show={submitForm?.isOpen ?? false}
														onSubmitAsync={() =>
															onSubmitAsync(
																compositionBuilder,
																uploadInstruments,
																uploadEntity === "INVESTMENT_DRAFT" ? "INVESTMENT" : uploadEntity, // generate ptf
																isCompositionBuilderValid,
																enitityUuid,
															)
														}
														onClose={submitForm?.onCancel}
														footer={() => (
															<DialogFooter
																primaryAction={
																	<SubmitButton data-qualifier="CompositionEditor/Modal/Save">
																		{uploadEntity === "INVESTMENT_DRAFT" || uploadEntity === "INVESTMENT"
																			? "Generate"
																			: "Save"}
																	</SubmitButton>
																}
																secondaryAction={
																	uploadEntity === "INVESTMENT_DRAFT" || uploadEntity === "INVESTMENT" ? (
																		<AsyncButton
																			palette="secondary"
																			data-qualifier="CompositionEditor/Modal/SaveAsDraft"
																			onClickAsync={() =>
																				onSaveDraft(
																					compositionBuilder,
																					uploadInstruments,
																					"INVESTMENT_DRAFT", // save as draft
																					enitityUuid,
																				)
																			}
																		>
																			{uploadEntity === "INVESTMENT_DRAFT" ? "Save draft" : "Save as draft"}
																		</AsyncButton>
																	) : undefined
																}
																neutralAction={
																	<Button
																		palette="tertiary"
																		onClick={submitForm?.onCancel}
																		data-qualifier="CompositionEditor/Modal/Cancel"
																	>
																		Cancel
																	</Button>
																}
															/>
														)}
													>
														<SubmitForm
															control={control}
															formState={formState}
															checkingNameUniqueness={checkingNameUniqueness}
															uploadEntity={uploadEntity}
															enitityUuid={enitityUuid}
															hasPortfolioIncomposition={hasPortfolioIncomposition}
														/>
													</Dialog>
												</>
											);
										}}
									</InstrumentEditorTable>
								);
							}}
						</ReactQueryWrapper>
					</div>
				</div>
				<div
					className={toClassName({
						"w-0 scale-90": showExposureContribution === false,
						"w-2/6 scale-100": showExposureContribution,
						"min-h-[690px] bg-white rounded overflow-hidden transition-[width,transform] ": true,
					})}
				>
					<div className="h-full py-4 px-2 flex flex-col">
						<div className="mb-2">
							<Select
								value={exposureCompareCategory}
								onChange={setExposureCompareCategory}
								options={exposureCompareOptions}
							/>
						</div>
						{isFetching ? (
							<IconWalls.Loader />
						) : !exposureCompare ? (
							<IconWalls.DataNotAvailable />
						) : (
							<ExposureSankeyLikeChart classList="h-[600px]" aggregateBy="quality" data={sankeyData} />
						)}
					</div>
				</div>
			</div>
			<CompareOverlay
				show={compareSelection.size > 0}
				onClose={() => setCompareSelection(Map())}
				onRemove={(id) => setCompareSelection((selection) => selection.delete(id))}
				compareData={compareSelection.toArray().map(
					([id, investment]): CompareDataItem => ({
						id,
						composition:
							investment?.macroAssetClassExposure?.map((x) => ({
								quality: x.firstQualityLevel,
								weight: x.weight,
							})) ?? [],
						numberOfInstrument: investment.nofInstruments ?? 0,
						portfolioName: investment.name ?? "-",
						uuid: investment.uuid,
						note: investment.lastActionNote,
						action: investment.action,
					}),
				)}
			/>
		</>
	);
});

export default UploadCompositionSection;
