import type {
	InvestmentExposureEntry,
	InvestmentExposureResponse,
	InvestmentExposureResponseExposureTypeEnum,
} from "$root/api/api-gen";
import {
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentExportControllerApiFactory,
	InvestmentReportsControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import env from "$root/env";
import { useLocaleFormatters } from "$root/localization/hooks";
import {
	PortfolioQueryWidgetBase,
	WidgetStatus,
	portfolioWidgetMissingDataReason,
} from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { stableColorGenerator } from "$root/utils/chart/colorGenerator";
import Highcharts from "$root/utils/chart/highcharts-with-modules";
import { sumArrayLike } from "$root/utils/collections";
import { downloadContentDisposition } from "$root/utils/files";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useGridBlock, useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import {
	DropdownMenu,
	DropdownMenuActionButton,
	Icon,
	ScrollWrapper,
	Select,
	TableWithGroups,
} from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import HighchartsReact from "highcharts-react-official";
import type { CSSProperties, StyleHTMLAttributes } from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// type WidgetStatuses = Exclude<InvestmentStatuses, "RETRIEVING_DATA" | "REVIEW">;
// type WidgetActions = Exclude<InvestmentActions, "RETRIEVING_DATA_FOR_UPLOAD">;

export type ExposureChartData = Array<{
	label: string;
	groupName: string;
	weight: number;
	drillDown: Array<{
		label: string;
		weight: number;
		netLong: boolean;
	}>;
}>;

export const exposureCategoryInfo: Record<
	InvestmentExposureResponseExposureTypeEnum,
	{
		label: string;
		columnTitle: string;
		type: "proxy" | "hedge" | "tag";
	}
> = {
	MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: {
		label: "Macro Asset Class vs Micro Asset Class",
		columnTitle: "Macro Asset Class",
		type: "proxy",
	},
	MACRO_ASSET_CLASS_VS_GEOGRAPHY: {
		label: "Macro Asset Class vs Geography",
		columnTitle: "Macro Asset Class",
		type: "proxy",
	},
	MICRO_ASSET_CLASS_VS_GEOGRAPHY: {
		label: "Micro Asset Class vs Geography",
		columnTitle: "Micro Asset Class",
		type: "proxy",
	},
	GEOGRAPHY_VS_MACRO_ASSET_CLASS: {
		label: "Geography vs Macro Asset Class",
		columnTitle: "Geography",
		type: "proxy",
	},
	GEOGRAPHY_VS_MICRO_ASSET_CLASS: {
		label: "Geography vs Micro Asset Class",
		columnTitle: "Macro Geography",
		type: "proxy",
	},
	MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: {
		label: "Macro Asset Class vs Macro Geography",
		columnTitle: "Macro Asset Class",
		type: "proxy",
	},
	MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY: {
		label: "Macro Asset Class vs Micro Geography",
		columnTitle: "Macro Asset Class",
		type: "proxy",
	},
	MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: {
		label: "Micro Asset Class vs Macro Geography",
		columnTitle: "Micro Asset Class",
		type: "proxy",
	},
	MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY: {
		label: "Micro Asset Class vs Micro Geography",
		columnTitle: "Micro Asset Class",
		type: "proxy",
	},
	MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY: {
		label: "Macro Geography vs Micro Geography",
		columnTitle: "Macro Geography",
		type: "proxy",
	},
	CURRENCY: {
		label: "Currency",
		columnTitle: "Currency",
		type: "hedge",
	},
	TAG: {
		label: "Tags",
		columnTitle: "Tags",
		type: "tag",
	},
	MACRO_ASSET_CLASS: {
		label: "Macro Asset Class",
		columnTitle: "Macro Asset Class",
		type: "proxy",
	},
	MACRO_GEOGRAPHY: {
		label: "Macro Geography",
		columnTitle: "Macro Geography",
		type: "proxy",
	},
	MICRO_GEOGRAPHY: {
		label: "Micro Geography",
		columnTitle: "Micro Geography",
		type: "proxy",
	},
	MICRO_ASSET_CLASS: {
		label: "Micro Asset Class",
		columnTitle: "Micro Asset Class",
		type: "proxy",
	},
};

const exposureOptionsCategories = [
	"MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
	"MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	"MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY",
	"MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	"MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY",
	"MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY",
	"CURRENCY",
	"TAG",
] satisfies Array<InvestmentExposureResponseExposureTypeEnum>;

const exposureOptions = exposureOptionsCategories.map((category) => ({
	label: exposureCategoryInfo[category].label,
	value: category,
}));

export const ExposureChart = (props: {
	/** @default true */
	animated?: boolean;
	data: ExposureChartData;
	style?: StyleHTMLAttributes<HTMLDivElement>;
	customHeight?: CSSProperties["height"];
	hideLabel?: boolean;
}): JSX.Element => {
	const { formatNumber } = useLocaleFormatters();
	const options = useMemo<Highcharts.Options>(
		() => ({
			chart: {
				type: "pie",
				style: { fontFamily: "Gotham,sans-serif" },
				reflow: true,
			},
			title: { text: undefined },
			subtitle: { text: undefined },
			panning: false,
			credits: { enabled: false },
			legend: { enabled: false },
			plotOptions: {
				pie: {
					animation: props.animated
						? undefined
						: {
								duration: 0,
						  },
					shadow: false,
					size: "100%",
					dataLabels: {
						enabled: !props.hideLabel,
					},
				},
			},
			tooltip: {
				shared: true,
				useHTML: true,
				distance: 22,
				backgroundColor: "#FFFFFF",
				borderColor: undefined,
				borderRadius: 4,
				borderWidth: 0,
				style: {
					padding: "0",
					color: "#656d78",
					fontSize: "12px",
					boxShadow: "0 2px 10px 0 rgba(47, 53, 65, 0.15)",
				},
				formatter() {
					let tooltip_html =
						'<div class="DivTooltip" style="width: auto; min-width: 120px"><div class="Tooltip-title" style="color: #1D2433">' +
						this.key +
						((this.point as { netLong?: boolean }).netLong === false
							? ` - [
							<svg class="inline w-[8px] mb-1" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle opacity="0.3" r="4" cx="4" cy="4" fill="${stableColorGenerator(
									(this.point as { groupName?: string }).groupName!,
								)}"></circle>
							</svg><span class="truncate pl-1">Net Short</span>]`
							: "") +
						"</div>";
					tooltip_html += "<table>";
					tooltip_html +=
						'<tr><td style="padding: 5px 8px 0 0" style="color: #2F3541">' +
						"Current" +
						'</td><td style="text-align: right; font-weight: 500;padding: 5px 0 0 0; color: #2F3541"> ' +
						formatNumber(this.y) +
						"%" +
						"</td></tr>";
					tooltip_html += "</table></div>";
					return tooltip_html;
				},
			},
			series: [
				{
					name: "Primary",
					data: props.data.map((primary) => ({
						name: primary.label,
						value: primary.weight,
						y: primary.weight,
						color: stableColorGenerator(primary.groupName),
					})),
					dataSorting: {
						enabled: true,
						sortKey: "y",
					},
					size: "80%",
					type: "pie",
				},
				{
					name: "Secondary",
					data: props.data.flatMap((primary) =>
						primary.drillDown.map((drillDown) => ({
							name: drillDown!.label,
							value: primary.weight,
							y: drillDown!.weight,
							color: Highcharts.color(stableColorGenerator(primary.groupName)).brighten(0.15).get(),
							netLong: drillDown!.netLong,
							groupName: primary.groupName,
						})),
					),
					type: "pie",
					size: "80%",
					innerSize: "70%",
					id: "secondary",
					dataLabels: { enabled: false },
				},
			],
			responsive: {
				rules: [
					{
						condition: {
							maxWidth: 420,
						},
						chartOptions: {
							pane: {
								size: "100%",
							},
						},
					},
				],
			},
			exporting: { enabled: false },
		}),
		[formatNumber, props.animated, props.data, props.hideLabel],
	);

	return (
		<HighchartsReact
			containerProps={{ style: { height: props.customHeight ?? "320px", width: "100%", ...props.style } }}
			highcharts={Highcharts}
			constructorType="chart"
			options={options}
		/>
	);
};

export function aggregateExposureData(composition: InvestmentExposureEntry[]) {
	return composition.reduce(
		(acc, cur) => {
			if (!acc[cur.firstQualityLevel!]) {
				acc[cur.firstQualityLevel!] = {
					label: cur.firstQualityLevel!,
					weight: 0,
					drillDown: [],
					groupName: cur.firstQualityLevel!,
				};
			}
			acc[cur.firstQualityLevel!]!.weight += cur.weight!;

			acc[cur.firstQualityLevel!]!.drillDown.push({
				label: cur.secondQualityLevel!,
				weight: cur.weight!,
				netLong: cur.netLong ?? true,
			});
			return acc;
		},
		{} as Record<string, ExposureChartData[number]>,
	);
}

const ExposureEvolve = (ctx: ContextContent<typeof PortfolioContext>) => {
	const investmentEnhancementReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const investmentReportsApi = useApiGen(InvestmentReportsControllerApiFactory);
	const { portfolio, enhanced, selectedBenchmark, reportsExecution, reportExcutionCounter } = ctx;
	const { t } = useTranslation();

	const [exposureCategory, setExposureCategory] = useState<InvestmentExposureResponseExposureTypeEnum>(
		"MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
	);
	const uuid = portfolio?.uuid;
	const benchmarkId = selectedBenchmark ?? portfolio?.primaryBenchmarkIdentifier;

	useWidgetOptions(
		() => ({
			title: t("EXPOSURE.TITLE"),
			actionHeader: <InfoTooltip>{t("EXPOSURE.TOOLTIP")}</InfoTooltip>,
		}),
		[t],
	);

	const query = useQueryNoRefetch(
		["queryExposureLevelEvolve", uuid, enhanced, exposureCategory, portfolio?.status, reportExcutionCounter],
		{
			enabled: Boolean(portfolio),
			queryFn: async () => {
				if (!uuid || !benchmarkId) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(portfolio!, "ExposureEvolve"),
					};
				}

				const exposure = enhanced
					? await axiosExtract(
							investmentEnhancementReportApi.getTwoLevelsInvestmentExposure1(uuid, benchmarkId, exposureCategory),
					  )
					: await axiosExtract(
							investmentReportsApi.getTwoLevelsInvestmentExposure(uuid, benchmarkId, exposureCategory),
					  );

				if (!enhanced && (!exposure.benchmarkComposition || !exposure.investmentComposition)) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(portfolio, "ExposureEvolve"),
					};
				}

				if (enhanced && (!exposure.benchmarkComposition || !exposure.enhancementComposition)) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(portfolio, "ExposureEvolve"),
					};
				}

				return {
					data: exposure,
					widgetStatus: WidgetStatus.READY,
				};
			},
		},
	);

	return (
		<PortfolioQueryWidgetBase query={query}>
			{(exposure, { isError, isLoading, isFetching }) => (
				<ExposureEvolveInner
					ctx={ctx}
					exposure={exposure}
					onChangeCategory={setExposureCategory}
					category={exposureCategory}
					queryStatus={{ isError, isLoading, isFetching }}
				/>
			)}
		</PortfolioQueryWidgetBase>
	);
};

const ExposureEvolveInner = (props: {
	ctx: ContextContent<typeof PortfolioContext>;
	exposure: InvestmentExposureResponse;
	onChangeCategory(opt: InvestmentExposureResponseExposureTypeEnum): void;
	category: InvestmentExposureResponseExposureTypeEnum;
	queryStatus: { isError: boolean; isLoading: boolean; isFetching: boolean };
}) => {
	const { t } = useTranslation();
	const { ctx, exposure, onChangeCategory, category, queryStatus } = props;
	const { portfolio, enhanced } = ctx;

	const exportApi = useApiGen(InvestmentExportControllerApiFactory);

	const exportPortfolioExposureNew = async (uuid: string) => {
		const response = await exportApi.exportMultiGraphOfExposure(uuid, { responseType: "blob" });

		downloadContentDisposition(response);
		trackMixPanelEvent("Portfolio", {
			Type: "Export",
			Area: "Exposure",
			Mode: "XLS",
			ID: uuid,
		});
	};

	const exportPortfolioExposureNewRef = useUnsafeUpdatedRef(exportPortfolioExposureNew);

	const gridBlock = useGridBlock();
	useWidgetOptions(
		() => ({
			title: t("EXPOSURE.TITLE"),
			actionHeader: function Download() {
				return (
					<div style={{ display: "flex", flexDirection: "row" }} className="space-x-2">
						{env.featureFlags.widgets.grid.resizable && gridBlock && (
							<button type="button" onClick={() => gridBlock.setBlockWidth(gridBlock.blockWidth === 1 ? 2 : 1)}>
								<Icon size={20} color={themeCSSVars.MessageSeverity_success} icon="News-category-Contraints" />
							</button>
						)}
						{/* FIXME add noData Option*/}
						{!(queryStatus.isError || queryStatus.isLoading) && (
							<DropdownMenu
								trigger={({ innerRef, open, ...forward }) => (
									<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
										<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
									</button>
								)}
								actions={[
									({ onClose }) => (
										<DropdownMenuActionButton
											key="exposureNew"
											icon="xls"
											onClickAsync={async () => {
												await exportPortfolioExposureNewRef.current(portfolio!.uuid!);
												onClose();
											}}
										>
											{`${t("EXPOSURE.DOWNLOAD_TITLE")}`}
										</DropdownMenuActionButton>
									),
								]}
							/>
						)}
						<InfoTooltip>{t("EXPOSURE.TOOLTIP")}</InfoTooltip>
					</div>
				);
			},
		}),
		[t, gridBlock, queryStatus.isError, queryStatus.isLoading, exportPortfolioExposureNewRef, portfolio],
	);

	const chartData = useMemo(() => {
		if (!exposure) {
			return null;
		}
		const { enhancementComposition, investmentComposition } = exposure;
		if (enhanced && !enhancementComposition) {
			return null;
		}

		if (!enhanced && !investmentComposition) {
			return null;
		}

		const aggregatedExposure = aggregateExposureData(enhanced ? enhancementComposition! : investmentComposition!);

		return Object.values(aggregatedExposure);
	}, [exposure, enhanced]);

	const tableData = useMemo(
		() =>
			chartData &&
			chartData.map((row) => ({
				...row,
				drillDown: undefined,
				rows: row.drillDown.map((x) => ({ ...x, groupName: row.groupName })),
			})),
		[chartData],
	);

	const { formatNumber } = useLocaleFormatters();

	return (
		<div className="flex h-full w-full">
			<div className="h-full min-w-0 w-1/2">
				<Select value={category} onChange={onChangeCategory} options={exposureOptions} />
				<div>{chartData && <ExposureChart animated data={chartData} />}</div>
			</div>
			<div className="w-1/2 h-full flex flex-col">
				<ScrollWrapper classList="h-full relative z-0" startShadow={false}>
					<TableWithGroups
						groupedRows={tableData ?? []}
						headerRowClassList="sticky top-0 z-10 bg-white"
						groupRowKey={(x) => x.groupName}
						columns={[
							{
								header: exposureCategoryInfo[category].columnTitle,
								groupContent: (groupedRow) => (
									<div className="flex items-center min-w-0">
										<svg
											className="flex min-w-[8px]"
											width="6"
											height="15"
											viewBox="0 0 6 15"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<rect
												opacity="1"
												width="6"
												height="15"
												rx="4"
												fill={stableColorGenerator(groupedRow.groupName)}
											/>
										</svg>
										<strong className="truncate pl-2">{groupedRow.label}</strong>
									</div>
								),
								content: (row) => (
									<div className="flex items-center min-w-0 w-full">
										<svg
											className="flex min-w-[8px]"
											width="8"
											height="8"
											viewBox="0 0 8 8"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle opacity="0.3" r="4" cx="4" cy="4" fill={stableColorGenerator(row.groupName)} />
										</svg>
										<span className="truncate pl-2">
											{row.label}{" "}
											{!row.netLong && (
												<>
													- [&nbsp;
													<svg
														className="inline w-[8px] mb-1"
														width="8"
														height="8"
														viewBox="0 0 8 8"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<rect
															opacity="0.3"
															width="8"
															height="8"
															rx="4"
															fill={stableColorGenerator(row.groupName)}
														/>
													</svg>
													&nbsp;
													<span className="truncate">Net Short</span>&nbsp;]
												</>
											)}
										</span>
									</div>
								),
								sortFn: builtInSortFnFor("label"),
								name: "label",
							},
							{
								header: "Weight",
								groupCellClassList: "font-semibold tabular-nums",
								groupContent: (groupedRow) =>
									`${formatNumber(
										sumArrayLike(groupedRow.rows, (r) => r.weight),
										2,
									)}%`,
								cellClassList: "tabular-nums",
								content: (row) => `${formatNumber(row.weight, 2)}%`,
								name: "weight",
								sortFn(a, b) {
									return sumArrayLike(a.rows, (r) => r.weight) - sumArrayLike(b.rows, (r) => r.weight);
								},
								align: "end",
							},
						]}
					/>
				</ScrollWrapper>
			</div>
		</div>
	);
};

export default withContext(PortfolioContext)(ExposureEvolve);
