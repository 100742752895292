import type { InvestmentStatuses } from "$root/api/api-gen";
import {
	BenchmarksControllerApiFactory,
	CustomerControllerV3ApiFactory,
	IntegrationsControllerApiFactory,
	InvestmentControllerV4ApiFactory,
	MarketViewControllerApiFactory,
	PdfControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
	ReferenceUniversesControllerApiFactory,
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { IconWalls } from "$root/components/IconWall";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { useEventBus, useGroupEventBus } from "$root/event-bus";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import { useUserValue } from "$root/functional-areas/user";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { downloadFileResponse } from "$root/utils/files";
import { noRefetchDefaults, useQueryNoRefetch } from "$root/utils/react-query";
import type { IconName } from "@mdotm/mdotui/components";
import { Button, DefaultTabTitle, DropdownMenu, Icon, Tab, TabGroup } from "@mdotm/mdotui/components";
import { noop } from "@mdotm/mdotui/utils";
import { useQueries } from "@tanstack/react-query";
import { format } from "date-fns";
import { Set } from "immutable";
import type { ReactNode } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import MarketViewsList  from "./MarketViewList";
import PortfolioList from "./PortfolioList";
import ReferenceList from "./ReferenceList";
import UniverseList from "./UniverseList";
import { useDebounced } from "@mdotm/mdotui/react-extensions";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { spawnInvestmentImportDialog } from "$root/functional-areas/portfolio/Import";
import { spawnInvestmentReferenceImportDialog } from "$root/functional-areas/portfolio/ImportReference";
import { spawnUniverseImportDialog } from "$root/functional-areas/universe/Import";
import { spawnMarketViewImportDialog } from "$root/functional-areas/market-view/Import";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { spawnPortfolioCreationDialog } from "$root/functional-areas/portfolio-studio/dialogs/PortfolioCreationDialog";
import { spawnMarketViewCreationDialog } from "$root/functional-areas/portfolio-studio/dialogs/MarketViewCreationDialog";
import { spawnBenchmarkCreationDialog } from "$root/functional-areas/portfolio-studio/dialogs/BenchmarkCreationDialog";

export interface SortingFn {
	<T>(arg: { rowA: T; rowB: T }): 0 | 1 | -1;
}

export const statusIconMap: Record<InvestmentStatuses, { icon: IconName; size: number; title: string }> = {
	ERROR: {
		icon: "Close",
		title: "Error",
		size: 16,
	},
	READY: {
		icon: "Icon-Ptf-status-SMALL",
		title: "Ready",
		size: 14,
	},
	PROPOSAL_READY: {
		icon: "Icon-full-small",
		title: "Proposal Ready",
		size: 12,
	},
	CALCULATING: {
		icon: "calulating",
		title: "Calculating",
		size: 12,
	},
	ACCEPTED: {
		icon: "Icon-Ptf-status-SMALL",
		title: "Accepted",
		size: 14,
	},
	RETRIEVING_DATA: {
		icon: "calulating",
		size: 14,
		title: "Retrieving data",
	},
	REVIEW: {
		icon: "Edit",
		size: 14,
		title: "Review",
	},
	DRAFT: {
		icon: "Edit",
		size: 14,
		title: "Draft",
	},
};

export const sortingFn: SortingFn = (arg) => {
	const { rowA, rowB } = arg;
	if (rowA < rowB) {
		return 1;
	}
	if (rowA > rowB) {
		return -1;
	}
	return 0;
};

export function checkUndefined<T>(el: T | undefined, df: T): T {
	return el ? el : df;
}

export const PortfolioStudioTab = {
	Portfolios: "Portfolios",
	References: "References",
	Universes: "Universes",
	MarketViews: "MarketViews",
} as const;

export type PortfolioStudioTab = (typeof PortfolioStudioTab)[keyof typeof PortfolioStudioTab];

const tabTitle: Record<PortfolioStudioTab, string> = {
	MarketViews: "Market Views",
	Portfolios: "Portfolios",
	References: "References",
	Universes: "Universes",
};

const portfolioStudioDefaultTabOrdering = [
	PortfolioStudioTab.Portfolios,
	PortfolioStudioTab.References,
	PortfolioStudioTab.Universes,
	PortfolioStudioTab.MarketViews,
];

type PortfoliosStudioProps = {
	tab?: string;
	zipId?: string;
};

const PortfoliosStudio = ({ zipId }: PortfoliosStudioProps): JSX.Element => {
	const history = useHistory();
	const location = useLocation();
	const user = useUserValue();
	const { push } = useTypedNavigation();

	const currentTab = useMemo(() => {
		const queryParamTab = new URLSearchParams(location.search).get("tab");
		if (!queryParamTab) {
			return 0;
		}
		return portfolioStudioDefaultTabOrdering.findIndex((v) => v === queryParamTab);
	}, [location.search]);

	const setCurrentTab = useCallback(
		(newTab: number) => {
			if (currentTab === newTab) {
				return;
			}
			const newSearchParams = new URLSearchParams(location.search);
			newSearchParams.set(
				"tab",
				String(portfolioStudioDefaultTabOrdering.find((_t, i) => i === newTab) ?? portfolioStudioDefaultTabOrdering[0]),
			);
			history.replace({ pathname: location.pathname, search: newSearchParams.toString() });
		},
		[currentTab, history, location.pathname, location.search],
	);

	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);
	const referenceUniverseApi = useApiGen(ReferenceUniversesControllerApiFactory);
	const marketViewApi = useApiGen(MarketViewControllerApiFactory);
	const benchmarkApi = useApiGen(BenchmarksControllerApiFactory);
	const customerV3Api = useApiGen(CustomerControllerV3ApiFactory);
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const pdfControllerV3Api = useApiGen(PdfControllerApiFactory);
	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);

	useQueryNoRefetch(["queryGeneratedReport", zipId, user], {
		enabled: zipId !== undefined,
		queryFn: async () => {
			const zip = await axiosExtract(pdfControllerV3Api.downloadReportsById(zipId!));
			return zip;
		},
		onError: (error: { response: { status: number }; message: string }) => {
			if (error.response.status === 400) {
				platformToast({
					children: "Unable to find an existing report in your account",
					icon: "Portfolio",
					severity: "error",
				});
			} else {
				platformToast({
					children: "Something went wrong, please try later",
					icon: "Portfolio",
					severity: "error",
				});
			}

			const queryParams = new URLSearchParams(location.search);
			queryParams.delete("zipId");
			history.replace({ search: queryParams.toString() });
			reportPlatformError(error, "ERROR", "portfolio", "unable to download the generated zip");
			throw new Error(String(error));
		},
		onSuccess: (zip) => {
			downloadFileResponse(zip, {
				fileName: `${user.name ? `${user.name}_` : ""}Reports_${format(new Date(), "MMddyyyy")}.zip`,
			});
			//TODO i need to check the expiration date
			// if expiration date is exceeded have not notify user with platformToast
			const queryParams = new URLSearchParams(location.search);
			queryParams.delete("zipId");
			history.replace({ search: queryParams.toString() });
		},
	});
	const currentTabName = portfolioStudioDefaultTabOrdering[currentTab];
	const [visitedTabs, setVisitedTabs] = useState<Set<typeof currentTabName>>(() => Set());

	useEffect(() => {
		setVisitedTabs((v) => v.add(currentTabName));
	}, [currentTabName]);

	//splitted platform requests
	const portfoliosStudio = useQueries({
		queries: [
			{
				queryKey: ["investmentQueryStudio"],
				queryFn: () => axiosExtract(investmentApi.getInvestmentList()),
				enabled: visitedTabs.includes("Portfolios"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["referenceQueryStudio"],
				queryFn: () => axiosExtract(investmentApi.getInvestmentReferenceList()),
				enabled: visitedTabs.includes("References"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["universeQueryStudio"],
				queryFn: () => axiosExtract(referenceUniverseApi.getUserUniverses()),
				enabled: visitedTabs.includes("Universes"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["marketQueryStudio"],
				queryFn: () => axiosExtract(marketViewApi.getMarketViewList()),
				enabled: visitedTabs.includes("MarketViews"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["benchmarkQueryStudio"],
				queryFn: () => axiosExtract(benchmarkApi.getBenchmarkList()),
				enabled: visitedTabs.includes("References"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["marketStudioTypes"],
				queryFn: () => axiosExtract(marketViewApi.getMarketViewUserSettings()),
				// enabled: visitedTabs.includes("MarketViews"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["queryUserPortfolioColumnMetricsOrderingPreferences"],
				queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserPortfolioColumnMetricsOrderingPreferences()),
				enabled: visitedTabs.includes("Portfolios"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["queryUserReferenceColumnOrderingPreferences"],
				queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserReferenceColumnOrderingPreferences()),
				enabled: visitedTabs.includes("References"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["queryUserUniverseColumnMetricsOrderingPreferences"],
				queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserUniverseColumnMetricsOrderingPreferences()),
				enabled: visitedTabs.includes("Universes"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["queryUserMarketViewColumnMetricsOrderingPreferences"],
				queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserMarketViewColumnMetricsOrderingPreferences()),
				enabled: visitedTabs.includes("MarketViews"),
				...noRefetchDefaults,
			},
		],
	});

	const [
		portfolio,
		reference,
		universe,
		marketViews,
		benchmarks,
		marketViewSetting,
		userPortfolioColumnPreferences,
		userReferenceColumnPreferences,
		userUniverseColumnPreferences,
		userMarketViewColumnPreferences,
	] = portfoliosStudio;

	const dataStudio = useMemo(
		() =>
			({
				Portfolios: { list: portfolio, settings: userPortfolioColumnPreferences },
				References: {
					reference,
					benchmarks,
					settings: userReferenceColumnPreferences,
				},
				Universes: { list: universe, settings: userUniverseColumnPreferences },
				MarketViews: { list: marketViews, settings: userMarketViewColumnPreferences },
			}) satisfies Record<PortfolioStudioTab, unknown>,
		[
			benchmarks,
			marketViews,
			portfolio,
			reference,
			universe,
			userMarketViewColumnPreferences,
			userPortfolioColumnPreferences,
			userReferenceColumnPreferences,
			userUniverseColumnPreferences,
		],
	);

	const { data: userSyncInfo } = useQueryNoRefetch(["queryUserSyncInfo"], {
		enabled: user.automaticImport,
		queryFn: () => axiosExtract(customerV3Api.users1()),
	});

	const { invoke: debouncedRefetch } = useDebounced(
		() => {
			portfolio.refetch().catch(noop);
		},
		{ debounceInterval: 10000 },
	);

	useEventBus("investment-import-update", debouncedRefetch);
	useEventBus("investment-update", debouncedRefetch);
	useGroupEventBus("benchmark-update", () => benchmarks.refetch().catch(noop), { timeout: 10000 });
	useEventBus("shared-entity", (e) => {
		if (e.level === "INFO") {
			if (e.sharedEntityType === "INVESTMENT") {
				debouncedRefetch();
			}

			if (e.sharedEntityType === "UNIVERSE") {
				universe.refetch().catch(noop);
			}

			if (e.sharedEntityType === "BENCHMARK") {
				benchmarks.refetch().catch(noop);
			}

			if (e.sharedEntityType === "MARKET_VIEW") {
				marketViews.refetch().catch(noop);
			}
		}
		benchmarks.refetch().catch(noop);
	});

	const iconWall = useMemo((): ReactNode => {
		if (currentTabName === PortfolioStudioTab.References) {
			if (dataStudio[currentTabName].benchmarks.isLoading || dataStudio[currentTabName].reference.isLoading) {
				return <IconWalls.LoadingData />;
			}

			if (dataStudio[currentTabName].benchmarks.isError || dataStudio[currentTabName].reference.isError) {
				return <IconWalls.ErrorData />;
			}

			if (
				(dataStudio[currentTabName].benchmarks.data?.length ?? 0) +
					(dataStudio[currentTabName].reference.data?.length ?? 0) ===
				0
			) {
				return (
					<IconWalls.ReferenceListEmptyData
						onCreate={() => history.push("/portfolios/upload/benchmark")}
						onUpload={() => history.push(`/portfolios/upload/target`)}
						onImporFinished={({ imported }) => {
							if (imported.length > 0) {
								reference.refetch({ throwOnError: true }).catch(noop);
								benchmarks.refetch({ throwOnError: true }).catch(noop);
							}
						}}
					/>
				);
			}

			return null;
		}

		if (dataStudio[currentTabName].list.isLoading || dataStudio[currentTabName].settings.isLoading) {
			return <IconWalls.LoadingData />;
		}

		if (
			dataStudio[currentTabName].list.isError ||
			dataStudio[currentTabName].settings.isError ||
			portfoliosStudio === undefined
		) {
			return <IconWalls.ErrorData />;
		}

		if (currentTabName === PortfolioStudioTab.Universes) {
			if (dataStudio[currentTabName].list?.data?.length === 0) {
				return (
					<IconWalls.UniverseListEmptyData
						onUpload={() => history.push(`/portfolios/upload/universe`)}
						refetch={universe.refetch}
					/>
				);
			}
			return null;
		}

		if (currentTabName === PortfolioStudioTab.Portfolios) {
			if (dataStudio[currentTabName].list?.data?.length === 0) {
				return <IconWalls.PortfolioListEmptyData refetch={portfolio.refetch} />;
			}
			return null;
		}

		if (currentTabName === PortfolioStudioTab.MarketViews) {
			if (dataStudio[currentTabName].list?.data?.length === 0) {
				return (
					<IconWalls.MarketViewListEmptyData
						marketViewSetting={marketViewSetting.data}
						onImporFinished={({ imported }) => {
							if (imported.length > 0) {
								marketViews.refetch({ throwOnError: true }).catch(noop);
							}
						}}
					/>
				);
			}
		}
		return null;
	}, [
		currentTabName,
		dataStudio,
		portfoliosStudio,
		history,
		reference,
		benchmarks,
		universe.refetch,
		portfolio.refetch,
		marketViewSetting.data,
		marketViews,
	]);

	const tabs = useMemo(() => {
		const tabComponents = {
			[PortfolioStudioTab.Portfolios]: (
				<PortfolioList
					portfolios={portfolio?.data ?? []}
					columnsPreferences={userPortfolioColumnPreferences.data?.userPortfolioColumnPreferences}
					userSyncInfo={userSyncInfo}
					isVisible={currentTab === 0}
					refetch={{
						investments: portfolio.refetch,
						columnsPreferences: userPortfolioColumnPreferences.refetch,
					}}
				/>
			),
			[PortfolioStudioTab.References]: (
				<ReferenceList
					references={reference?.data ?? []}
					benchmarks={benchmarks?.data ?? []}
					columnsPreferences={userReferenceColumnPreferences.data?.userReferenceColumnPreferences}
					refetch={{
						benchmarks: benchmarks.refetch,
						references: reference.refetch,
						columnsPreferences: userReferenceColumnPreferences.refetch,
					}}
					isVisible={currentTab === 1}
				/>
			),
			[PortfolioStudioTab.Universes]: (
				<UniverseList
					universes={universe?.data ?? []}
					columnsPreferences={userUniverseColumnPreferences.data?.userUniverseColumnPreferences}
					refetch={{
						universe: universe.refetch,
						columnsPreferences: userUniverseColumnPreferences.refetch,
					}}
					isVisible={currentTab === 2}
				/>
			),
			[PortfolioStudioTab.MarketViews]: (
				<MarketViewsList
					markets={marketViews?.data ?? []}
					marketViewSetting={marketViewSetting.data}
					columnsPreferences={userMarketViewColumnPreferences.data?.userMarketViewColumnPreferences}
					refetch={{
						marketView: marketViews.refetch,
						columnsPreferences: userMarketViewColumnPreferences.refetch,
					}}
					isVisible={currentTab === 3}
				/>
			),
		};
		return portfolioStudioDefaultTabOrdering.map((tabName, idx) => (
			<Tab
				key={tabName + idx}
				title={(props) => (
					<DefaultTabTitle {...props} data-qualifier={`PortfolioStudio/TabItem(${tabName})`}>
						{tabTitle[tabName]}
					</DefaultTabTitle>
				)}
			>
				{iconWall ||
					(tabName === "Portfolios" ? (
						tabComponents[tabName]
					) : (
						<div className="rounded-lg bg-white px-4 py-6">{tabComponents[tabName]}</div>
					))}
			</Tab>
		));
	}, [
		benchmarks?.data,
		benchmarks.refetch,
		currentTab,
		iconWall,
		marketViewSetting.data,
		marketViews?.data,
		marketViews.refetch,
		portfolio?.data,
		portfolio.refetch,
		reference?.data,
		reference.refetch,
		universe?.data,
		universe.refetch,
		userMarketViewColumnPreferences.data?.userMarketViewColumnPreferences,
		userMarketViewColumnPreferences.refetch,
		userPortfolioColumnPreferences.data?.userPortfolioColumnPreferences,
		userPortfolioColumnPreferences.refetch,
		userReferenceColumnPreferences.data?.userReferenceColumnPreferences,
		userReferenceColumnPreferences.refetch,
		userSyncInfo,
		userUniverseColumnPreferences.data?.userUniverseColumnPreferences,
		userUniverseColumnPreferences.refetch,
	]);

	return (
		<div id="portfolioStudioToolBar">
			<PageHeader
				title="Portfolio studio"
				titleAction={
					<div className="flex items-center justify-between gap-2">
						{hasAccess(user, {
							requiredService: "IMPORT",
							requiredParam: ({ importFormats }) => importFormats !== undefined && importFormats.length > 0,
						}) ? (
							<DropdownMenu
								position="bottom"
								align="endToEnd"
								trigger={(props) => (
									<Button size="small" palette="primary" {...props} data-qualifier="PortfolioStudio/Import">
										<Icon icon="Dowload" size={16} classList="-rotate-90 mr-1" />
										Import
									</Button>
								)}
								actions={[
									{
										label: "Portfolio",
										onClickAsync: async () => {
											const importConverters = await axiosExtract(
												integrationsApi.retrieveInvestmentImportConverterType(),
											);
											const converters = importConverters.filter(
												(converter) => user.importFormats?.includes(converter),
											);

											spawnInvestmentImportDialog({
												converters,
												onImportFinished: ({ imported }) => {
													if (imported.length) {
														portfolio.refetch({ throwOnError: true }).catch(noop);
													}
												},
											});
										},
										"data-qualifier": "PortfolioStudio/Import/Portfolio",
									},
									hasAccess(user, {
										requiredService: "IMPORT",
										requiredParam: ({ importFormats }) => Boolean(importFormats?.includes("SPHERE_TEMPLATE_CONVERTER")),
									})
										? {
												label: "Reference",
												onClickAsync: async () => {
													const converters = await axiosExtract(integrationsApi.retrieveReferenceImportConverterType());
													spawnInvestmentReferenceImportDialog({
														converters,
														onImportFinished: ({ imported }) => {
															if (imported.length) {
																benchmarks.refetch({ throwOnError: true }).catch(noop);
																reference.refetch({ throwOnError: true }).catch(noop);
															}
														},
													});
												},
												"data-qualifier": "PortfolioStudio/Import/Reference",
										  }
										: null,
									{
										label: "Universe",
										onClickAsync: async () => {
											const importConverters = await axiosExtract(
												integrationsApi.retrieveUniverseImportConverterType(),
											);
											const converters = importConverters.filter((x) => user.importFormats?.includes(x));
											spawnUniverseImportDialog({
												converters,
												onImportFinished: ({ imported }) => {
													if (imported.length) {
														universe.refetch({ throwOnError: true }).catch(noop);
													}
												},
											});
										},
										"data-qualifier": "PortfolioStudio/Import/Universe",
									},
									hasAccess(user, {
										requiredService: "IMPORT",
										requiredParam: ({ importFormats }) => Boolean(importFormats?.includes("SPHERE_TEMPLATE_CONVERTER")),
									})
										? {
												label: "Market View",
												onClick: () =>
													spawnMarketViewImportDialog({
														converters: ["SPHERE_TEMPLATE_CONVERTER"] as const,
														onImportFinished: ({ imported }) => {
															if (imported.length) {
																marketViews.refetch({ throwOnError: true }).catch(noop);
															}
														},
													}),
												"data-qualifier": "PortfolioStudio/Import/MarketView",
										  }
										: null,
								]}
							/>
						) : (
							<></>
						)}

						<DropdownMenu
							position="bottom"
							align="endToEnd"
							trigger={(props) => (
								<Button size="small" palette="primary" {...props} data-qualifier="PortfolioStudio/New">
									<Icon icon="Outline1" size={16} />
									New
								</Button>
							)}
							actions={[
								{
									label: "Portfolio",
									onClick: () => spawnPortfolioCreationDialog({}),
									"data-qualifier": "PortfolioStudio/New/Portfolio",
								},
								{
									label: "Target portfolio",
									onClick: () => push("Portfolios/UploadPortfolioPage", { uploadType: "target" }),
									"data-qualifier": "PortfolioStudio/New/TargetPortfolio",
								},
								{
									label: "Benchmark portfolio",
									onClick: () => spawnBenchmarkCreationDialog({}),
									"data-qualifier": "PortfolioStudio/New/Benchmark",
								},
								{
									label: "Universe",
									onClick: () => push("Portfolios/UploadPortfolioPage", { uploadType: "universe" }),
									"data-qualifier": "PortfolioStudio/New/Universe",
								},
								{
									label: "Market View",
									onClick: () => spawnMarketViewCreationDialog({ marketViewSetting: marketViewSetting.data }),
									disabled: !marketViewSetting.data,
									"data-qualifier": "PortfolioStudio/New/MarketView",
								},
							]}
						/>

						<Link
							to={typedUrlForRoute("PortfolioStudioSettings", {
								tab: PortfolioStudioSettingTabEnum.InstrumentsCustomisation,
							})}
							style={{ textUnderlinePosition: "under" }}
							data-qualifier="PortfolioStudio/PortfolioStudioSettings"
						>
							<Icon icon="Settings" size={24} color={themeCSSVars.palette_N400} />
						</Link>
					</div>
				}
			/>

			<div className="relative z-0 overflow-hidden">
				<TabGroup palette="primary" tabIndex={currentTab} onTabChange={setCurrentTab} aria-label="ptf-studios tabs">
					{tabs}
				</TabGroup>
			</div>
		</div>
	);
};

export default PortfoliosStudio;
