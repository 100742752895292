import type { InvestmentListEntry, SyncInfoDto, UserPortfolioColumnPreference } from "$root/api/api-gen";
import { CustomLabels } from "$root/components/CustomLabels";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import type { PortfolioAlert } from "$root/functional-areas/portfolio/alerts";
import { mapAlert } from "$root/functional-areas/portfolio/alerts";
import { useLocaleFormatters } from "$root/localization/hooks";
import AlertTooltip from "$root/widgets-architecture/layout/WidgetsMapper/AlertTooltip";
import { TableV2, Text } from "@mdotm/mdotui/components";
import { ActionText, AutoTooltip, Icon, TooltipContent } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { addMinutes, format } from "date-fns";
import { t } from "i18next";
import { useMemo } from "react";
import { match } from "ts-pattern";
import { statusIconMap } from "..";
import { useUserValue } from "$root/functional-areas/user";
import { aclToUsersListEntries } from "$root/functional-areas/acl/checkers/all";
import UserShareIcon from "$root/functional-areas/acl/UserShareIcon";
import PortfolioExposureSummary from "$root/functional-areas/compare-portfolio/PortfolioExposureSummary";
import type { CompareDataItem } from "$root/functional-areas/compare-portfolio/CompareOverlay";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { sharingWithColumn } from "$root/functional-areas/acl/table-columns";
import { PortfolioDetailsTabs } from "$root/pages/PortfolioDetails";
import { builtInCaseInsensitiveSortFor } from "$root/utils/collections";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";

export function usePortfolioColumn(
	comparablePortfolios: CompareDataItem[],
	toggleComparePortfolio?: (portfolio: InvestmentListEntry, enhanced?: boolean) => void,
	columnsPreferences?: UserPortfolioColumnPreference[],
	isVisible?: boolean,
	userSyncInfo?: SyncInfoDto,
): TableV2.TableColumn<InvestmentListEntry>[] {
	const formatters = useLocaleFormatters();
	const { formatDate, formatNumber } = formatters;
	const user = useUserValue();

	const columns = useMemo(
		() =>
			columnsPreferences
				? columnsPreferences.map(
						(columnMeta) =>
							({
								header: columnMeta.preferencesType ? t(`TABLE.HEADERS.${columnMeta.preferencesType}`) : "-",
								hidden: columnMeta.enabled === false,
								content: () => null,
								...match(columnMeta)
									.with(
										{ preferencesType: "NAME" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row, props) => {
												const {
													uuid,
													name,
													richAcl,
													macroAssetClassExposure,
													status,
													macroAssetClassExposureEnhanced,
													lastActionNote,
													action,
												} = row;
												const exposureComposition = macroAssetClassExposure?.map((x) => ({
													quality: x.firstQualityLevel,
													weight: x.weight,
												}));

												const exposureEnhancedComposition = macroAssetClassExposureEnhanced?.map((x) => ({
													quality: x.firstQualityLevel,
													weight: x.weight,
												}));

												const isManualCreation = status === "DRAFT" && action === "UPLOAD";
												const isPipelineCreation = status === "DRAFT" && action === "CREATION";

												const selected = comparablePortfolios.find((x) => x.uuid === uuid && !x.enhanced) !== undefined;

												const selectedEnhanced =
													comparablePortfolios.find((x) => x.uuid === uuid && x.enhanced) !== undefined;

												return (
													<AutoTooltip
														severity="info"
														position="right"
														disabled={
															status === "ERROR" ||
															status === "CALCULATING" ||
															status === "REVIEW" ||
															status === "DRAFT" ||
															status === "RETRIEVING_DATA"
														}
														openDelay={700}
														trigger={({ innerRef }) => (
															<div
																style={props.style}
																className={overrideClassName(props.classList, "items-center flex px-2")}
															>
																<ActionText
																	classList="truncate mr-1"
																	color={themeCSSVars.palette_N900}
																	href={
																		isManualCreation
																			? typedUrlForRoute("Portfolios/DraftManualCreation", {
																					portfolioUid: uuid!,
																					uploadType: "portfolioDraft",
																			  })
																			: isPipelineCreation
																			  ? typedUrlForRoute("Portfolios/Draft", { portfolioUid: uuid! })
																			  : typedUrlForRoute("PortfolioDetails", { portfolioUid: uuid! })
																	}
																	title={name}
																	innerRef={innerRef}
																	data-qualifier="PortfolioStudio/PortfolioList/Table/Column(Name)"
																>
																	{name}
																</ActionText>
																<UserShareIcon
																	entity="INVESTMENT"
																	userId={user.id}
																	permission={richAcl?.currentUserPermissions}
																	color={themeCSSVars.palette_N300}
																	acl={richAcl?.acl}
																/>
															</div>
														)}
													>
														<TooltipContent>
															<div className="w-[280px] overflow-hidden space-y-2">
																<PortfolioExposureSummary
																	compared={selected}
																	subtitle="current"
																	composition={exposureComposition ?? []}
																	onCompare={(e) => {
																		e.stopPropagation();
																		toggleComparePortfolio?.(row);
																	}}
																	title={name ?? "-"}
																	note={lastActionNote}
																	action={action}
																/>
																{exposureEnhancedComposition && row.status === "PROPOSAL_READY" && (
																	<PortfolioExposureSummary
																		compared={selectedEnhanced}
																		subtitle="proposal"
																		subtitleClassList={`!text-[color:${themeCSSVars.palette_S400}]`}
																		composition={exposureEnhancedComposition}
																		onCompare={(e) => {
																			e.stopPropagation();
																			toggleComparePortfolio?.(row, true);
																		}}
																		action={action}
																	/>
																)}
															</div>
														</TooltipContent>
													</AutoTooltip>
												);
											},
											sortFn: builtInCaseInsensitiveSortFor("name"),
											name: "name",
											width: 320,
										}),
									)
									.with(
										{ preferencesType: "SHARING_VIEWS" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> =>
											sharingWithColumn({
												users: ({ richAcl }) => aclToUsersListEntries("portfolio", richAcl?.acl ?? []),
											}),
									)
									.with(
										{ preferencesType: "INCEPTION_DATE" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: ({ creationTime }) => {
												const sanitizedCreationTime = creationTime ? formatDate(new Date(creationTime)) : "-";
												return sanitizedCreationTime;
											},
											sortFn: ({ creationTime: a }, { creationTime: b }) => {
												const rowA = new Date(a ?? "").getTime();
												const rowB = new Date(b ?? "").getTime();
												return rowA > rowB ? 1 : rowA < rowB ? -1 : 0;
											},
											name: "inception",
											cellClassList: "tabular-nums",
											align: "end",
											minWidth: 128,
										}),
									)
									.with(
										{ preferencesType: "CURRENT_ACTION" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: ({ action }) => {
												const translatedAction = action ? t(`PORTFOLIO_ACTION_STATUS.${action}`) : "-";
												return translatedAction;
											},
											sortFn: builtInCaseInsensitiveSortFor("action"),
											name: "action",
											minWidth: 92,
										}),
									)
									.with(
										{ preferencesType: "STATUS" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: ({ status }, props) => {
												const sanitizedStatus = status ? statusIconMap[status] : "-";
												if (sanitizedStatus === "-") {
													return (
														<TableV2.TableDataCell
															{...props}
															data-qualifier="PortfolioStudio/PortfolioList/Table/Column(Status)"
														>
															{sanitizedStatus}
														</TableV2.TableDataCell>
													);
												}
												return (
													<div
														style={props.style}
														onClick={props.onClick}
														className={overrideClassName(props.classList, "flex items-center px-2")}
														title={sanitizedStatus.title}
														data-qualifier="PortfolioStudio/PortfolioList/Table/Column(Status)"
													>
														<Icon
															classList="mr-1"
															icon={sanitizedStatus.icon}
															color={themeCSSVars.palette_N800} // changed
															size={sanitizedStatus.size}
														/>
														<Text type="Body/M/Book" classList="line-clamp-2">
															{sanitizedStatus.title}
														</Text>
													</div>
												);
											},
											sortFn: builtInCaseInsensitiveSortFor("status"),
											name: "status",
											minWidth: 128,
										}),
									)
									.with(
										{ preferencesType: "LAST_STATUS_UPDATE" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: ({ modificationTime }) => {
												const sanitizedModificationTime = modificationTime
													? formatDate(new Date(modificationTime))
													: "-";
												return sanitizedModificationTime;
											},
											sortFn: ({ modificationTime: a }, { modificationTime: b }) => {
												const rowA = new Date(a ?? "").getTime();
												const rowB = new Date(b ?? "").getTime();
												return rowA > rowB ? 1 : rowA < rowB ? -1 : 0;
											},
											name: "lastUpdate",
											cellClassList: "tabular-nums",
											align: "end",
											minWidth: 92,
										}),
									)
									.with(
										{ preferencesType: "AVERAGE_SCORE" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row, props) => {
												return (
													<TableV2.TableDataCell {...props}>
														{row.score && (
															<>
																(<CustomLabels labelKey={row.scoreIdentifier ?? "-"} fallback="SCORE" />){" "}
																{row.score ? formatNumber(row.score) : ""}
															</>
														)}
													</TableV2.TableDataCell>
												);
											},
											sortFn: builtInSortFnFor("score"),
											name: "score",
											align: "end",
											cellClassList: "tabular-nums",
											minWidth: 92,
										}),
									)
									.with(
										{ preferencesType: "WARNINGS" },
										(): TableV2.TableColumn<InvestmentListEntry> => ({
											header: "W.",
											align: "end",
											content: ({ alerts }, props) => {
												if (!alerts || alerts.length === 0 || !isVisible) {
													return null;
												}
												return (
													<TableV2.TableDataCell {...props} onClick={(e) => e.stopPropagation()}>
														<AlertTooltip
															alerts={alerts
																.filter((a) => Boolean(a.type))
																.map((a) => mapAlert({ t, ...formatters }, a as PortfolioAlert))}
															summary
														/>
													</TableV2.TableDataCell>
												);
											},
											cellClassList: "grow",
											sortFn: builtInSortFnFor("alerts"),
											name: "alerts",
											minWidth: 64,
											maxWidth: 64,
										}),
									)
									.with(
										{ preferencesType: "AUTO_SYNC" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row, props) => {
												if (
													row.importedExternally &&
													user.automaticImport &&
													userSyncInfo &&
													userSyncInfo.lastExecutionTime &&
													userSyncInfo.frequency
												) {
													const lastAutoImportSync = new Date(userSyncInfo.lastExecutionTime);
													const nextAutoImportSync = addMinutes(lastAutoImportSync, userSyncInfo.frequency);
													return (
														<TableV2.TableDataCell
															style={props.style}
															classList={props.classList}
															onClick={props.onClick}
														>
															<AutoTooltip
																position="top"
																overrideColor={themeCSSVars.palette_N400}
																trigger={({ innerRef }) => (
																	<Icon
																		icon="Outline"
																		innerRef={innerRef}
																		color={themeCSSVars.palette_N400}
																		size={20}
																	/>
																)}
															>
																<TooltipContent>
																	LAST CHECK: {format(lastAutoImportSync, "KK:mm")} - NEXT CHECK:{" "}
																	{format(nextAutoImportSync, "KK:mm")}
																</TooltipContent>
															</AutoTooltip>
														</TableV2.TableDataCell>
													);
												}

												return null;
											},
											minWidth: 128,
											sortFn: builtInSortFnFor("importedExternally"),
											name: "importedExternally",
										}),
									)
									.with(
										{ preferencesType: "MARKET_VIEW_NAME" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row, props) => {
												if (!row.marketView) {
													return null;
												}

												const { marketViewType, scenarioName, scenarioIdentifier, forecastDates } = row.marketView;
												return (
													<TableV2.TableDataCell {...props}>
														<ActionText
															classList="line-clamp-2"
															href={
																scenarioIdentifier === "CUSTOM_PORTFOLIO_MARKET_VIEW"
																	? `${typedUrlForRoute("PortfolioDetails", {
																			// redirect to portfolio settings
																			portfolioUid: row?.uuid ?? "",
																	  })}?tab=${PortfolioDetailsTabs.PORTFOLIO_STUDIO_SETTINGS}#marketView`
																	: scenarioIdentifier && marketViewType
																	  ? typedUrlForRoute("MarketViewWorkSpace", {
																				uuid: scenarioIdentifier,
																				isCustom: String(row.marketView.custom ?? false),
																				action: "view",
																				type: String(marketViewType),
																	    })
																	  : ""
															}
															title={!scenarioName ? `Market view ${formatDate(forecastDates?.from)}` : scenarioName}
														>
															{!scenarioName ? `Market view ${formatDate(forecastDates?.from)}` : scenarioName}
														</ActionText>
													</TableV2.TableDataCell>
												);
											},
											minWidth: 192,
											name: "marketViewName",
											sortFn: builtInSortFnFor("marketView"),
										}),
									)
									.with(
										{ preferencesType: "CURRENCY_NAME" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row) => row.currencyName ?? "-",
											minWidth: 92,
											name: "investmentCurrency",
											sortFn: builtInCaseInsensitiveSortFor("currencyName"),
										}),
									)
									.with(
										{ preferencesType: "BENCHMARK_NAME" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row) => row.benchmarkName,
											minWidth: 192,
											name: "benchmarkName",
											sortFn: builtInCaseInsensitiveSortFor("benchmarkName"),
										}),
									)
									.with(
										{ preferencesType: "UNIVERSE_NAME" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row) => row.universeName,
											minWidth: 192,
											name: "universeName",
											sortFn: builtInCaseInsensitiveSortFor("universeName"),
										}),
									)
									.with(
										{ preferencesType: "COMMENTARY_TEMPLATE" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row) => row.commentaryTemplate ?? "",
											minWidth: 192,
											name: "commentaryTemplate",
											sortFn: builtInCaseInsensitiveSortFor("commentaryTemplate"),
										}),
									)
									.with(
										// benchmark eff ratio
										{ preferencesType: "BENCHMARK_EFFICIENCY_RATIO_1M" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row) => formatNumber(row.benchmarkEfficiencyRatio1M ?? 0),
											sortFn: builtInSortFnFor("benchmarkEfficiencyRatio1M"),
											name: "benchmark_efficiency_ratio_1M",
											cellClassList: "tabular-nums",
											align: "end",
											minWidth: 128,
										}),
									)
									.with(
										// benchmark max drawdown
										{ preferencesType: "BENCHMARK_MAX_DRAWDOWN_1M" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row) => `${formatNumber(row.benchmarkMaxDrawdown1M ?? 0)}%`,
											sortFn: builtInSortFnFor("benchmarkMaxDrawdown1M"),
											name: "benchmark_max_drawdown_1M",
											cellClassList: "tabular-nums",
											align: "end",
											minWidth: 92,
										}),
									)
									.with(
										// benchmark performance
										{ preferencesType: "BENCHMARK_PERFORMANCE_1M" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row) => `${formatNumber(row.benchmarkPerformance1M ?? 0)}%`,
											sortFn: builtInSortFnFor("benchmarkPerformance1M"),
											cellClassList: "tabular-nums",
											align: "end",
											name: "benchmark_performance_1M",
											minWidth: 128,
										}),
									)
									.with(
										// benchmark sortino
										{ preferencesType: "BENCHMARK_SORTINO_1M" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row) => `${formatNumber(row.benchmarkSortino1M ?? 0)}%`,
											sortFn: builtInSortFnFor("benchmarkSortino1M"),
											cellClassList: "tabular-nums",
											align: "end",
											name: "benchmark_sortino_1M",
											minWidth: 128,
										}),
									)
									.with(
										//benchmark volatility
										{ preferencesType: "BENCHMARK_VOLATILITY_1M" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row) => `${formatNumber(row.benchmarkVolatility1M ?? 0)}%`,
											sortFn: builtInSortFnFor("benchmarkVolatility1M"),
											cellClassList: "tabular-nums",
											align: "end",
											name: "benchmark_volatility_1M",
											minWidth: 92,
										}),
									)
									.with(
										//efficiency ratio volatility
										{ preferencesType: "EFFICIENCY_RATIO_1M" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row) => `${formatNumber(row.efficiencyRatio1M ?? 0)}`,
											sortFn: builtInSortFnFor("efficiencyRatio1M"),
											cellClassList: "tabular-nums",
											align: "end",
											name: "efficiency_ratio_1M",
											minWidth: 92,
										}),
									)
									.with(
										// max drawdown
										{ preferencesType: "MAX_DRAWDOWN_1M" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row) => `${formatNumber(row.maxDrawdown1M ?? 0)}%`,
											sortFn: builtInSortFnFor("maxDrawdown1M"),
											cellClassList: "tabular-nums",
											align: "end",
											name: "max_drawdown_1M",
											minWidth: 128,
										}),
									)
									.with(
										// performance
										{ preferencesType: "PERFORMANCE_1M" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row) => `${formatNumber(row.performance1M ?? 0)}%`,
											sortFn: builtInSortFnFor("performance1M"),
											cellClassList: "tabular-nums",
											align: "end",
											name: "perfomance_1M",
											minWidth: 128,
										}),
									)
									.with(
										// Sortino 1Y
										{ preferencesType: "SORTINO_1M" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row) => `${formatNumber(row.sortino1M ?? 0)}`,
											sortFn: builtInSortFnFor("sortino1M"),
											cellClassList: "tabular-nums",
											align: "end",
											name: "soritino_1M",
											minWidth: 64,
										}),
									)
									.with(
										// Volatility
										{ preferencesType: "VOLATILITY_1M" },
										(): Omit<TableV2.TableColumn<InvestmentListEntry>, "header"> => ({
											content: (row) => `${formatNumber(row.volatility1M ?? 0)}`,
											sortFn: builtInSortFnFor("volatility1M"),
											cellClassList: "tabular-nums",
											align: "end",
											name: "volatility_1M",
											minWidth: 92,
										}),
									)
									.otherwise(() => ({})),
							}) as unknown as TableV2.TableColumn<InvestmentListEntry>,
				  )
				: [],
		[
			columnsPreferences,
			comparablePortfolios,
			user.id,
			user.automaticImport,
			toggleComparePortfolio,
			formatDate,
			formatNumber,
			isVisible,
			formatters,
			userSyncInfo,
		],
	);

	return columns;
}
