import type { ReviewTicker } from "$root/api/api-gen";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { CustomLabels } from "$root/components/CustomLabels";
import { useUserValue } from "$root/functional-areas/user";
import { Card } from "../Card";
import { TableV2 } from "@mdotm/mdotui/components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { PrintableProps } from "../../configuration";
import type { CommonSetUpReportProps, UnionMapData } from "../../configuration/hooks/useExtractReports";
import { useLocaleFormatters } from "$root/localization/hooks";
import { TinyTableDataCell, TinyTableHeadCell } from "../table/tiny-table";

type CompositionProps = CommonSetUpReportProps<UnionMapData["portfolio-reference"]>;

const Composition = ({ data, list }: PrintableProps<{ data: CompositionProps }, ReviewTicker>): JSX.Element => {
	const { portfolio } = data;
	const user = useUserValue();
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();

	const columns = useMemo<Array<TableV2.TableColumn<ReviewTicker>>>(
		() => [
			{
				name: "instrument",
				minWidth: 248,
				header: (props) => <TinyTableHeadCell {...props}>{t("COMPOSITION.INSTRUMENT")}</TinyTableHeadCell>,
				content: ({ instrument }, props) => (
					<TinyTableDataCell fontSize={9} lineClamp={3} {...props}>
						{instrument ?? ""}
					</TinyTableDataCell>
				),
			},
			{
				name: "identifier",
				width: 104,
				header: (props) => <TinyTableHeadCell {...props}>{t("COMPOSITION.IDENTFIER")}</TinyTableHeadCell>,
				content: ({ identifier, proxyOverwriteType }, props) => (
					<TinyTableDataCell {...props}>
						{proxyOverwriteType === "PORTFOLIO_MIXED" ? "Portfolio" : identifier ? identifier : ""}
					</TinyTableDataCell>
				),
			},
			{
				name: "assetClass",
				width: 85,
				header: (props) => <TinyTableHeadCell {...props}>{t("COMPOSITION.ASSET_CLASS")}</TinyTableHeadCell>,
				content: ({ assetClass, proxyOverwriteType }, props) => (
					<TinyTableDataCell {...props}>
						{proxyOverwriteType === "PORTFOLIO_MIXED" ? "Portfolio" : assetClass ? assetClass : ""}
					</TinyTableDataCell>
				),
			},
			{
				name: "microAssetClass",
				width: 144,
				header: (props) => <TinyTableHeadCell {...props}>{t("COMPOSITION.MICRO_ASSET_CLASS")}</TinyTableHeadCell>,
				content: ({ microAssetClass, proxyOverwriteType }, props) => (
					<TinyTableDataCell fontSize={9} lineClamp={3} {...props}>
						{proxyOverwriteType === "PORTFOLIO_MIXED" ? "Portfolio" : microAssetClass ? microAssetClass : ""}
					</TinyTableDataCell>
				),
			},
			{
				name: "weight",
				width: 56,
				header: (props) => <TinyTableHeadCell {...props}>{t("COMPOSITION.WEIGHT")}</TinyTableHeadCell>,
				cellClassList: "tabular-nums",
				content: ({ weight }, props) => (
					<TinyTableDataCell {...props}>{`${formatNumber(weight ?? 0)}%`}</TinyTableDataCell>
				),
				align: "end",
			},
			{
				name: "score",
				width: 50,
				header: (props) => (
					<TinyTableHeadCell {...props}>
						{portfolio?.scoreIdentifier ? (
							<CustomLabels labelKey={portfolio?.scoreIdentifier} fallback={t("SCORE")} />
						) : (
							t("SCORE")
						)}
					</TinyTableHeadCell>
				),
				content: ({ score }, props) => (
					<TinyTableDataCell {...props}>{score ? formatNumber(score) : ""}</TinyTableDataCell>
				),
				relativeWidth: 1,
				hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
			},
		],
		[t, portfolio?.scoreIdentifier, user, formatNumber],
	);

	return (
		<Card title="Composition">
			<TableV2.BaseTable palette="uniform" columns={columns} rows={list} noDataText={t("COMPOSITION.NO_COMPOSITION")} />
		</Card>
	);
};

export default Composition;
