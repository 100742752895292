import type { InvestmentBenchmarkDTO, InvestmentBenchmarkDTOBenchmarkTypeEnum, ReviewTicker } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { objectTextSearchMatchFns } from "$root/utils/strings";
import type { DialogProps, OrderBy } from "@mdotm/mdotui/components";
import {
	Button,
	Controller,
	Dialog,
	DialogFooter,
	DialogHeader,
	sortRows,
	SubmitButton,
	TableV2,
	TextInput,
} from "@mdotm/mdotui/components";
import { useDebouncedSearch, useSearchable } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

type CopyTemplateDialogProps = {
	show: boolean;
	onClose?(): void;
	onSubmit?(payload: ReviewTicker[]): void;
	onAnimationStateChange?: DialogProps["onAnimationStateChange"];
};

const mappedBenchmarkType: Record<InvestmentBenchmarkDTOBenchmarkTypeEnum, string> = {
	CUSTOM_BENCHMARK: "Custom benchmark",
	REFERENCE_INVESTMENT: "Target portfolio",
	STANDARD_BENCHMARK: "Standard benchmark",
	CURRENT_PORTFOLIO: "Current portfolio",
};

function CopyTemplateDialog(props: CopyTemplateDialogProps) {
	const { t } = useTranslation();

	const { show, onAnimationStateChange, onClose, onSubmit } = props;
	const editorApi = useApiGen(EntityEditorControllerApiFactory);

	const query = useQueryNoRefetch(["queryBenchmarkIndicies"], {
		queryFn: async () => {
			const { availableCurrencies, availablePrimaryBenchmarks } = await axiosExtract(
				editorApi.getEditorNewSelectableMainInfo(),
			);

			return { availableCurrencies, availablePrimaryBenchmarks };
		},
	});

	const { availablePrimaryBenchmarks } = query.data ?? {};

	const { debouncedNormalizedQuery, query: search, setQuery: setSearch } = useDebouncedSearch("", 10);
	const { filtered } = useSearchable({
		collection: availablePrimaryBenchmarks ?? [],
		query: debouncedNormalizedQuery,
		matchFn: (row, q) => objectTextSearchMatchFns.keyword(row, q),
	});

	const {
		column: checkBoxColumn,
		rowClassList,
		toggle,
		multiSelectCtx,
	} = TableV2.useSelectableTableColumn({
		filteredRows: filtered,
		rows: availablePrimaryBenchmarks ?? [],
		selectBy: ({ benchmarkIdentifier }) => benchmarkIdentifier ?? "",
		mode: "radio",
	});

	const columns = useMemo<TableV2.TableColumn<InvestmentBenchmarkDTO>[]>(
		() => [
			checkBoxColumn,
			{
				header: "Name",
				content: (row) => row.benchmarkName,
				sortFn: builtInSortFnFor("benchmarkIdentifier"),
				name: "benchmarkIdentifier",
				relativeWidth: 0.8,
			},
			{
				header: "Type",
				content: (row) => (row.benchmarkType ? mappedBenchmarkType[row.benchmarkType] : "-"),
				sortFn: builtInSortFnFor("benchmarkType"),
				name: "benchmarkType",
				relativeWidth: 0.2,
			},
		],
		[checkBoxColumn],
	);

	const getBenchmarkCompositionById = useCallback(
		async (selectedBenchmark: InvestmentBenchmarkDTO | undefined) => {
			try {
				if (selectedBenchmark?.benchmarkIdentifier === undefined || selectedBenchmark === undefined) {
					throw new Error("unable to find an identifier");
				}
				const { composition } = await axiosExtract(
					editorApi.getBenchmarkInstruments(selectedBenchmark.benchmarkIdentifier),
				);

				return composition;
			} catch (error) {
				platformToast({
					children: "Unable to load the selected benchmark",
					severity: "error",
					icon: "Portfolio",
				});
				reportPlatformError(error, "ERROR", "benchmark", "try to load benchmark composition");
				throw error;
			}
		},
		[editorApi],
	);

	return (
		<Dialog
			size="xxlarge"
			show={show}
			onClose={onClose}
			header={<DialogHeader>Select benchmark template</DialogHeader>}
			onAnimationStateChange={onAnimationStateChange}
			onSubmitAsync={async () => {
				const benchmarkIdentifier = multiSelectCtx.data.selection.first();
				if (benchmarkIdentifier) {
					const selectedBenchmark = availablePrimaryBenchmarks?.find(
						(x) => x.benchmarkIdentifier === benchmarkIdentifier,
					);
					const composition = await getBenchmarkCompositionById(selectedBenchmark);
					onSubmit?.(composition ?? []);
				}
			}}
			footer={
				<DialogFooter
					primaryAction={<SubmitButton disabled={query.isFetching}>{t("BUTTON.CONFIRM")}</SubmitButton>}
					neutralAction={
						<Button palette="tertiary" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			}
		>
			<div className="mb-4">
				<TextInput placeholder="Search a benchmark" classList="max-w-[280px]" value={search} onChangeText={setSearch} />
			</div>
			<ReactQueryWrapperBase query={query}>
				{() => (
					<Controller value={defaultReportOrderBy}>
						{({ value: orderBy, onChange: onOrderByChange }) => (
							<TableV2.BaseTable
								rows={sortRows({ rows: filtered, columns, orderByArr: orderBy })}
								columns={columns}
								orderBy={orderBy}
								onOrderByChange={onOrderByChange}
								onRowClick={(x) => toggle(x.benchmarkIdentifier!)}
								palette="uniform"
								classList="h-[412px]"
								rowClassList={rowClassList}
							/>
						)}
					</Controller>
				)}
			</ReactQueryWrapperBase>
		</Dialog>
	);
}

const defaultReportOrderBy: Array<OrderBy<"name">> = [{ columnName: "name", direction: "asc" }];

type spawnCopyTemplateDialogPropsProps = Omit<CopyTemplateDialogProps, "show" | "onClose">;
export function spawnCopyTemplateDialog(props: spawnCopyTemplateDialogPropsProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<CopyTemplateDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmit={(payload) => {
					props.onSubmit?.(payload);
					resolve();
				}}
			/>
		)),
	);
}
