import type {
	AssetClass,
	ConstrainedTicker,
	InvestmentCreationConfigurationControllerV4Api,
	InvestmentDraftConfigurationControllerV4Api,
	InvestmentEnhancementConfigurationControllerV4Api,
	RichTicker,
	SelectableAllocationConstraintsResponse,
} from "$root/api/api-gen";
import {
	ConstraintRelation,
	ConstraintValidity,
	Currencies,
	InvestmentCreationConfigurationControllerV4ApiFactory,
	InvestmentDraftConfigurationControllerV4ApiFactory,
	InvestmentEnhancementConfigurationControllerV4ApiFactory,
	InvestmentsStaticConfigurationControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { CustomLabels } from "$root/components/CustomLabels";
import { DataDisplayOverlay } from "$root/components/DataDisplayOverlay";
import { IconWalls } from "$root/components/IconWall";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { InstrumentTagSelect } from "$root/components/tags/InstrumentTagSelect";
import { labelToTag } from "$root/components/tags/shared";
import { spawnInstrumentDialog } from "$root/functional-areas/instruments/spawn-instrument-dialog";
import { useUserValue } from "$root/functional-areas/user";
import { useLocaleFormatters } from "$root/localization/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { zExt } from "$root/third-party-integrations/zod";
import EllipsisText from "$root/ui-lib/ellipsisText";
import { ModalWithGroupFilters } from "$root/ui-lib/modalWithTables";
import { getTickerCompositeId } from "$root/ui-lib/modalWithTables/filterByGroup";
import RenderingGuard from "$root/ui-lib/renderingGuard";
import { unpromisify } from "@mdotm/mdotui/utils";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { noop } from "$root/utils/runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import type { Option, StylableProps } from "@mdotm/mdotui/components";
import {
	ActionText,
	AutoTooltip,
	Banner,
	Checkbox,
	Icon,
	NullableNumberInput,
	Radio,
	RadioGroup,
	ScrollWrapper,
	Select,
	Text,
	TooltipContent,
} from "@mdotm/mdotui/components";
import type { ClassList, NodeOrFn } from "@mdotm/mdotui/react-extensions";
import {
	ForEach,
	generateUniqueDOMId,
	renderNodeOrFn,
	toClassName,
	useTick,
	useUnsafeUpdatedRef,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { ReactNode } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { match } from "ts-pattern";
import { z } from "zod";
import type { EditPortfolioV4Props } from "../EditPortfolio";
import { StepBase } from "../StepBase";
import type { ConstraintAdder } from "../constraints/AddConstraintSection";
import { AddConstraintSection } from "../constraints/AddConstraintSection";
import { ConstraintListSection } from "../constraints/ConstraintListSection";
import type { ConstraintSpecificColumnsProps } from "../constraints/ConstraintSpecificColumns";
import { DefaultConstraintListItem } from "../constraints/DefaultConstraintListItem";
import { excludeRelationOption, relationOptions } from "../constraints/const";
import { useStepSync } from "../hooks";
import { makeStepToRequestMapper, responseToStepMapper } from "../requests";
import type { CustomConstrainedTicker, EditablePortfolioConstraint } from "../shared";
import {
	enumInstrumentWithouthScorePolicy,
	type EditPortfolioV4StepPayloadMap,
	type EditablePortfolioConstraintHelper,
	type InstrumentWithouthScorePolicy,
	type PortfolioConstraintTypePayloadMap,
	type StepPropsFor,
	useHandleSubmitToCustomSubmitHandlerInContext,
} from "../shared";
import { ModeVariants } from "$root/widgets-architecture/contexts/universe";
import { multiRef } from "$root/utils/react-extra";
import { Map, Set } from "immutable";
import { SolvedWithAIIcon } from "../ai-resources";
import type { MaybeArray } from "@mdotm/mdotui/utils";
import { alwaysArray, switchExpr } from "@mdotm/mdotui/utils";

function instrumentGroupToLabel(list: Array<RichTicker>) {
	if (list.length === 1) {
		return list[0].instrument;
	}

	if (list.length > 1) {
		return `Selected (${list.length} instruments)`;
	}

	return "Select instruments";
}

type ConstraintCategory =
	| "assetClassConstraints"
	| "instrumentConstraints"
	| "tagConstraints"
	| "instrumentMaxWeightConstraint"
	| "scoresConstraint"
	| "currencyConstraints"
	| "lockInstrumentGroup"
	| "forEachConstraint";

const titleClassName = `text-[color:${themeCSSVars.global_palette_neutral_700}] pb-2`;

const constraintTypeToCategory: Record<keyof PortfolioConstraintTypePayloadMap, ConstraintCategory> = {
	assetClassConstraints: "assetClassConstraints",
	instrumentConstraints: "instrumentConstraints",
	instrumentMaxWeightConstraint: "instrumentMaxWeightConstraint",
	tagConstraints: "tagConstraints",
	scoresConstraint: "scoresConstraint",
	currencyConstraints: "currencyConstraints",
	lockInstrumentGroup: "lockInstrumentGroup",
	forEachConstraint: "forEachConstraint",
};

type ConstraintTemplateOmittedKey = "id" | "readonly" | "type";

function constraintTemplatesHelper<
	T extends {
		[K in keyof PortfolioConstraintTypePayloadMap]: Omit<
			EditablePortfolioConstraintHelper<K>,
			ConstraintTemplateOmittedKey
		>;
	},
>(
	a: T,
): {
	[K in keyof T & keyof PortfolioConstraintTypePayloadMap]: () => EditablePortfolioConstraintHelper<K>;
} {
	return Object.fromEntries(
		Object.entries(a).map(([key, value]) => [
			key,
			() =>
				({
					...value,
					id: generateUniqueDOMId(),
					type: key,
					readonly: false,
					//target: false,
				}) satisfies Record<ConstraintTemplateOmittedKey, unknown>,
		]),
	) as unknown as {
		[K in keyof T & keyof PortfolioConstraintTypePayloadMap]: () => EditablePortfolioConstraintHelper<K>;
	};
}

const constraintTemplates = constraintTemplatesHelper({
	assetClassConstraints: {
		maxWeight: null,
		minWeight: null,
		assetClassesIdentifiers: [],
		relation: ConstraintRelation.Between,
		identifier: null,
		suggestedMaxWeight: null,
		suggestedMinWeight: null,
		validity: ConstraintValidity.Unchecked,
		target: false,
	},
	tagConstraints: {
		maxWeight: null,
		minWeight: null,
		tagLabel: "",
		identifier: null,
		tagIdentifiers: [],
		relation: ConstraintRelation.Between,
		suggestedMaxWeight: null,
		suggestedMinWeight: null,
		validity: ConstraintValidity.Unchecked,
		target: false,
	},
	instrumentConstraints: {
		identifier: null,
		tickerIdentifiers: [],
		maxWeight: null,
		minWeight: null,
		relation: ConstraintRelation.Between,
		suggestedMaxWeight: null,
		suggestedMinWeight: null,
		validity: ConstraintValidity.Unchecked,
		target: false,
	},
	currencyConstraints: {
		identifier: null,
		validity: ConstraintValidity.Unchecked,
		maxWeight: null,
		minWeight: null,
		relation: ConstraintRelation.Between,
		suggestedMaxWeight: null,
		suggestedMinWeight: null,
		target: false,
		currencies: [],
	},
	instrumentMaxWeightConstraint: {
		identifier: null,
		weight: null,
		suggestedMaxWeight: null,
		suggestedMinWeight: null,
		validity: ConstraintValidity.Unchecked,
		target: false,
	},
	scoresConstraint: {
		identifier: null,
		maxWeight: null,
		minWeight: null,
		suggestedMaxWeight: null,
		suggestedMinWeight: null,
		validity: ConstraintValidity.Unchecked,
		relation: ConstraintRelation.Between,
		instrumentWithouthScorePolicy: "EXCLUDE",
		target: false,
		scoreIdentifiers: null,
	},
	lockInstrumentGroup: {
		identifier: null,
		tickerIdentifiers: [],
		target: false,
		relation: ConstraintRelation.Equal, // must be always striced to equal
		validity: ConstraintValidity.Unchecked,
	},
	forEachConstraint: {
		identifier: null,
		tickerIdentifiers: [],
		maxWeight: null,
		minWeight: null,
		relation: ConstraintRelation.Between,
		suggestedMaxWeight: null,
		suggestedMinWeight: null,
		validity: ConstraintValidity.Unchecked,
		target: false,
	},
});

export function getPortfolioConstraintsStepData(
	createApi: Omit<InvestmentCreationConfigurationControllerV4Api, "basePath" | "axios">,
	enhanceApi: Omit<InvestmentEnhancementConfigurationControllerV4Api, "basePath" | "axios">,
	draftApi: Omit<InvestmentDraftConfigurationControllerV4Api, "basePath" | "axios">,
	area: EditPortfolioV4Props["area"],
): Promise<EditPortfolioV4StepPayloadMap["portfolioConstraints"]> {
	return (
		!area.portfolioUid
			? createApi.getCreationConfigurationAllocationConstraints()
			: area.name === "draft"
			  ? draftApi.getDraftConfigurationAllocationConstraints(area.portfolioUid)
			  : enhanceApi.getEnhancementConfigurationAllocationConstraints(area.portfolioUid)
	).then(({ data }) => responseToStepMapper.portfolioConstraints(data, area));
}

const validations = {
	assetClassConstraints: z.object({
		assetClassesIdentifiers: z.array(z.any()).nonempty({ message: "Select an Asset Class" }),
		identifier: zExt.maybe(z.string()),
		maxWeight: zExt.maybe(zExt.percentage()),
		minWeight: zExt.maybe(zExt.percentage()),
		relation: z.string(),
		target: z.boolean(),
		suggestedMaxWeight: z.any(),
		suggestedMinWeight: z.any(),
		validity: z.any(),
	} satisfies Record<keyof PortfolioConstraintTypePayloadMap["assetClassConstraints"], unknown>),
	instrumentConstraints: z.object({
		identifier: zExt.maybe(z.string()),
		maxWeight: zExt.maybe(zExt.percentage()),
		minWeight: zExt.maybe(zExt.percentage()),
		relation: z.string(),
		target: z.boolean(),
		tickerIdentifiers: z.array(z.any()).nonempty({ message: "Select an Instrument" }),
		suggestedMinWeight: z.any(),
		suggestedMaxWeight: z.any(),
		validity: z.any(),
	} satisfies Record<keyof PortfolioConstraintTypePayloadMap["instrumentConstraints"], unknown>),
	instrumentMaxWeightConstraint: z.object({
		identifier: zExt.maybe(z.string()),
		target: z.boolean(),
		weight: zExt.percentage(),
		validity: z.any(),
		suggestedMinWeight: z.any(),
		suggestedMaxWeight: z.any(),
	} satisfies Record<keyof PortfolioConstraintTypePayloadMap["instrumentMaxWeightConstraint"], unknown>),
	tagConstraints: z.object({
		identifier: zExt.maybe(z.string()),
		maxWeight: zExt.maybe(zExt.percentage()),
		minWeight: zExt.maybe(zExt.percentage()),
		relation: z.string(),
		target: z.boolean(),
		tagIdentifiers: z.array(z.any()).nonempty({ message: "Select a Tag" }),
		tagLabel: z.string(),
		suggestedMinWeight: z.any(),
		suggestedMaxWeight: z.any(),
		validity: z.any(),
	} satisfies Record<keyof PortfolioConstraintTypePayloadMap["tagConstraints"], unknown>),
	scoresConstraint: z.object({
		identifier: zExt.maybe(z.string()),
		maxWeight: zExt.maybe(zExt.percentage()),
		minWeight: zExt.maybe(zExt.percentage()),
		suggestedMinWeight: z.any(),
		suggestedMaxWeight: z.any(),
		instrumentWithouthScorePolicy: z.any(),
		relation: z.string(),
		target: z.boolean(),
		validity: z.any(),
		scoreIdentifiers: z.array(z.string()),
	} satisfies Record<keyof PortfolioConstraintTypePayloadMap["scoresConstraint"], unknown>),
	currencyConstraints: z.object({
		identifier: zExt.maybe(z.string()),
		target: z.boolean(),
		validity: z.any(),
		currencies: z
			.array(z.nativeEnum(Currencies), { required_error: "Currency is required" })
			.min(1, { message: "Currency is required" }),
		maxWeight: zExt.maybe(zExt.percentage()),
		minWeight: zExt.maybe(zExt.percentage()),
		suggestedMinWeight: z.any(),
		suggestedMaxWeight: z.any(),
		relation: z.string(),
	} satisfies Record<keyof PortfolioConstraintTypePayloadMap["currencyConstraints"], unknown>),
	lockInstrumentGroup: z.object({
		identifier: zExt.maybe(z.string()),
		tickerIdentifiers: z.array(z.any()).nonempty({ message: "Select an Instrument" }),
		target: z.boolean(),
		validity: z.any(),
		relation: z.string(),
	} satisfies Record<keyof PortfolioConstraintTypePayloadMap["lockInstrumentGroup"], unknown>),
	forEachConstraint: z.object({
		identifier: zExt.maybe(z.string()),
		maxWeight: zExt.maybe(zExt.percentage()),
		minWeight: zExt.maybe(zExt.percentage()),
		relation: z.nativeEnum(ConstraintRelation).nullable(),
		target: z.boolean(),
		tickerIdentifiers: z.array(z.any()).nonempty({ message: "Select an Instrument" }),
		validity: z.any(),
		suggestedMinWeight: z.any(),
		suggestedMaxWeight: z.any(),
	} satisfies Record<keyof PortfolioConstraintTypePayloadMap["forEachConstraint"], unknown>),
} satisfies Record<keyof PortfolioConstraintTypePayloadMap, unknown>;

export function Step03_PortfolioConstraints({
	context,
	stepMetadata,
	stepData,
	onStepDataChange,
	onStepError,
	toggleDirty,
	allStepsData,
	overrideCurrentStepIcon,
}: StepPropsFor<"portfolioConstraints">): JSX.Element {
	const { saveHandlers, area } = context;
	const edit = area.editable && area.edit;

	const [revertData, setRevertData] = useState<Map<string, (typeof stepData)["list"][number]>>(() => Map());
	const [solutionsWithAiApplied, setSolutionsWithAiApplied] = useState<Set<string>>(() => Set());
	const isSolveWithAiAppliedRef = useRef(false);

	const createInvestmentApi = useApiGen(InvestmentCreationConfigurationControllerV4ApiFactory);
	const draftInvestmentApi = useApiGen(InvestmentDraftConfigurationControllerV4ApiFactory);
	const enhanceInvestmentApi = useApiGen(InvestmentEnhancementConfigurationControllerV4ApiFactory);
	const staticController = useApiGen(InvestmentsStaticConfigurationControllerApiFactory);
	const user = useUserValue();
	const form = useForm({
		defaultValues: stepData,
		resolver: isSolveWithAiAppliedRef.current
			? undefined
			: zodResolver(
					z.object({
						list: z.array(
							z
								.discriminatedUnion("type", [
									z
										.object({
											type: z.literal("assetClassConstraints"),
										})
										.merge(validations.assetClassConstraints),
									z
										.object({
											type: z.literal("instrumentConstraints"),
										})
										.merge(validations.instrumentConstraints),
									z
										.object({
											type: z.literal("instrumentMaxWeightConstraint"),
										})
										.merge(validations.instrumentMaxWeightConstraint),
									z
										.object({
											type: z.literal("tagConstraints"),
										})
										.merge(validations.tagConstraints),
									z
										.object({
											type: z.literal("scoresConstraint"),
										})
										.merge(validations.scoresConstraint),
									z
										.object({
											type: z.literal("currencyConstraints"),
										})
										.merge(validations.currencyConstraints),
									z
										.object({
											type: z.literal("lockInstrumentGroup"),
										})
										.merge(validations.lockInstrumentGroup),
									z
										.object({
											type: z.literal("forEachConstraint"),
										})
										.merge(validations.forEachConstraint),
								])
								.refine(
									(item) => {
										switch (item.type) {
											case "assetClassConstraints":
											case "tagConstraints":
											case "instrumentConstraints":
											case "currencyConstraints":
											case "scoresConstraint":
											case "forEachConstraint":
												return !(
													(item.relation === "MIN" || item.relation === "BETWEEN" || item.relation === "EQUAL") &&
													(typeof item.minWeight !== "number" || item.minWeight > 100 || item.minWeight < 0)
												);
											case "instrumentMaxWeightConstraint":
											case "lockInstrumentGroup":
												return true;
										}
									},
									{
										message: "Select a value between 0 and 100",
										path: ["minWeight"],
									},
								)
								.refine(
									(item) => {
										switch (item.type) {
											case "assetClassConstraints":
											case "tagConstraints":
											case "instrumentConstraints":
											case "scoresConstraint":
											case "currencyConstraints":
											case "forEachConstraint":
												return !(
													(item.relation === "MAX" || item.relation === "BETWEEN") &&
													(typeof item.maxWeight !== "number" || item.maxWeight > 100 || item.maxWeight < 0)
												);
											case "instrumentMaxWeightConstraint":
											case "lockInstrumentGroup":
												return true;
										}
									},
									{
										message: "Select a value between 0 and 100",
										path: ["maxWeight"],
									},
								)
								.refine(
									(item) => {
										switch (item.type) {
											case "assetClassConstraints":
											case "tagConstraints":
											case "instrumentConstraints":
											case "scoresConstraint":
											case "currencyConstraints":
											case "forEachConstraint":
												return item.relation === "BETWEEN" &&
													typeof item.minWeight === "number" &&
													typeof item.maxWeight === "number"
													? item.minWeight <= item.maxWeight
													: true;
											case "instrumentMaxWeightConstraint":
											case "lockInstrumentGroup":
												return true;
										}
									},
									{
										message: "Max should be greater than Min",
										path: ["minWeight"],
									},
								)
								.refine(
									(item) => {
										let deleted = [];
										switch (item.type) {
											case "assetClassConstraints":
											case "tagConstraints":
											case "scoresConstraint":
											case "currencyConstraints":
											case "instrumentMaxWeightConstraint":
											case "instrumentConstraints":
												return true;
											case "forEachConstraint":
											case "lockInstrumentGroup":
												deleted = item.tickerIdentifiers.filter((x) => x.deleted);
												return deleted.length >= 1 && deleted.length === item.tickerIdentifiers.length
													? true
													: item.tickerIdentifiers.length > 0;
										}
									},
									{
										message: "Select an instrument",
										path: ["tickerIdentifiers"],
									},
								),
						),
					}),
			  ),
		mode: "onBlur",
	});

	const { control, handleSubmit, reset, getValues, watch, trigger, formState, clearErrors /* , setValue */ } = form;
	const { replace, append, fields } = useFieldArray({ control, name: "list", keyName: "_id" });
	const observableList = watch("list");
	const isCalculating = stepMetadata.processing;

	const applyAllAISolutions = () => {
		const invalidEntries = getValues().list.filter((x) => x.validity === ConstraintValidity.Invalid);
		const newRevertData = Map(invalidEntries.map((x) => [x.id, x]));
		setRevertData(newRevertData);
		replace(
			getValues().list.flatMap((x) => {
				if (newRevertData.has(x.id)) {
					const solutions = alwaysArray(applyAISolution(newRevertData.get(x.id)!));
					solutions.forEach((solution) => {
						setSolutionsWithAiApplied((prev) => prev.add(solution.id));
					});
					return solutions;
				}

				return [x];
			}),
		);

		if (invalidEntries.length > 0) {
			isSolveWithAiAppliedRef.current = true;
		} else {
			isSolveWithAiAppliedRef.current = false;
		}
	};

	const reapplyAllAISolutions = useCallback(() => {
		replace(
			stepData.list.flatMap((x) => {
				if (revertData.has(x.id)) {
					const solutions = alwaysArray(applyAISolution(revertData.get(x.id)!));
					solutions.forEach((solution) => {
						setSolutionsWithAiApplied((prev) => prev.add(solution.id));
					});
					return solutions;
				}
				return [x];
			}),
		);
		isSolveWithAiAppliedRef.current = true;
	}, [replace, revertData, stepData.list]);

	const tick = useTick();
	useEffect(() => {
		if (area.name !== "settings-current" && area.name !== "settings-enhanced") {
			noop(stepData);
			tick()
				.then(() => applyAllAISolutions())
				.catch(noop);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepData, area.name]);

	useStepSync({ reset, toggleDirty, isDirty: formState.isDirty, stepData });
	const { data: selectablePortfolioConstraints } = useQueryNoRefetch(
		["getStepAllocationConstrainsData", stepMetadata.processing],
		{
			enabled: !stepMetadata.processing,
			queryFn: () => {
				return match(context)
					.with({ area: { name: "enhance" } }, (x) =>
						axiosExtract(
							enhanceInvestmentApi.getEnhancementConfigurationSelectableAllocationConstraints(x.area.portfolioUid),
						),
					)
					.with({ area: { name: "settings-enhanced" } }, (x) =>
						axiosExtract(
							staticController.getStaticConfigurationSelectableAllocationConstraints(
								x.area.portfolioUid,
								allStepsData.investableUniverse.universeIdentifier,
							),
						),
					)
					.with({ area: { name: "settings-current" } }, (x) =>
						axiosExtract(
							staticController.getStaticConfigurationSelectableAllocationConstraints(
								x.area.portfolioUid,
								allStepsData.investableUniverse.universeIdentifier,
							),
						),
					)
					.with({ area: { name: "create" } }, () =>
						axiosExtract(createInvestmentApi.getCreationConfigurationSelectableAllocationConstraints()),
					)
					.with({ area: { name: "draft" } }, (x) =>
						axiosExtract(draftInvestmentApi.getDraftConfigurationSelectableAllocationConstraints(x.area.portfolioUid)),
					)
					.exhaustive();
			},
		},
	);

	const selectableAssetClasses = selectablePortfolioConstraints?.selectableAssetClasses?.filter(
		(selectable) =>
			!observableList.some((row) => {
				if (row.type === "assetClassConstraints") {
					return row.assetClassesIdentifiers.some(({ identifier }) => identifier === selectable.identifier);
				}
				return false;
			}),
	);

	const selectableInstruments = selectablePortfolioConstraints?.selectableInstruments?.filter(
		(selectable) =>
			!observableList.some((row) => {
				if (row.type === "instrumentConstraints") {
					return row.tickerIdentifiers.some(({ ticker }) => ticker === selectable.ticker);
				}
				return false;
			}),
	);

	const selectableScores = selectablePortfolioConstraints?.selectableScores?.filter(
		(selectable) =>
			!observableList.some((row) => {
				if (row.type === "scoresConstraint") {
					return row.scoreIdentifiers?.some((scoreIdentifier) => scoreIdentifier === selectable);
				}
				return false;
			}),
	);

	const selectableCurrencies = selectablePortfolioConstraints?.selectableCurrencies?.filter(
		(selectable) =>
			!observableList.some((row) => {
				if (row.type === "currencyConstraints") {
					return row.currencies.some((currency) => currency === selectable);
				}
				return false;
			}),
	);

	const selectableTags = selectablePortfolioConstraints?.selectableTags?.filter(
		(selectable) =>
			!observableList.some((row) => {
				if (row.type === "tagConstraints") {
					return row.tagIdentifiers.some((tag) => tag === selectable);
				}
				return false;
			}),
	);

	const constraintAdders = useMemo<Array<ConstraintAdder<keyof PortfolioConstraintTypePayloadMap>>>(
		() => [
			{
				maxOfType: selectablePortfolioConstraints?.selectableAssetClasses?.length ?? 0,
				label: "Asset allocation constraint",
				type: "assetClassConstraints",
				disabled: isCalculating || !(selectableAssetClasses && selectableAssetClasses?.length > 0),
				limitTooltip: isCalculating
					? "Sphere is calculating the constraint limits"
					: !allStepsData["investableUniverse"].universeIdentifier
					  ? "To add this constraint, please select a universe first."
					  : "You have already selected all Asset allocation categories",
				status: isCalculating ? "calculating" : undefined,
			},
			{
				maxOfType: selectablePortfolioConstraints?.selectableTags?.length ?? 0,
				label: "Tag constraint",
				type: "tagConstraints",
				disabled: isCalculating || !(selectableTags && selectableTags?.length > 0),
				limitTooltip: isCalculating ? (
					"Sphere is calculating the constraint limits"
				) : !allStepsData["investableUniverse"].universeIdentifier ? (
					"To add this constraint, please select a universe first."
				) : (
					<p>
						To add new tags, edit the universe{" "}
						<Link
							to={typedUrlForRoute("UniverseDetails", {
								universeUuid: allStepsData["investableUniverse"].universeIdentifier,
								mode: ModeVariants.viewer,
							})}
							className="font-semibold underline"
						>
							here
						</Link>
						.
					</p>
				),
				status: isCalculating ? "calculating" : undefined,
			},
			{
				maxOfType: 1,
				label: "Max weights on single instrument",
				type: "instrumentMaxWeightConstraint",
				limitTooltip: isCalculating
					? "Sphere is calculating the constraint limits"
					: !allStepsData["investableUniverse"].universeIdentifier
					  ? "To add this constraint, please select a universe first."
					  : "You have already added Max weights on single instrument",
				disabled: isCalculating || !allStepsData["investableUniverse"].universeIdentifier,
				status: isCalculating ? "calculating" : undefined,
			},
			{
				maxOfType: selectablePortfolioConstraints?.selectableScores?.length ?? 0,
				label: (
					<>
						Average{" "}
						<CustomLabels labelKey={selectablePortfolioConstraints?.selectableScores?.[0] ?? ""} fallback="score" />
					</>
				),
				type: "scoresConstraint",
				limitTooltip: isCalculating ? (
					"Sphere is calculating the constraint limits"
				) : !allStepsData["investableUniverse"].universeIdentifier ? (
					"To add this constraint, please select a universe first."
				) : (
					<p>
						To add new score, edit the universe{" "}
						<Link
							to={typedUrlForRoute("UniverseDetails", {
								universeUuid: allStepsData["investableUniverse"].universeIdentifier,
								mode: ModeVariants.viewer,
							})}
							className="font-semibold underline"
						>
							here
						</Link>
						.
					</p>
				),
				disabled: isCalculating || !(selectableScores && selectableScores?.length > 0),
				hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
				status: isCalculating ? "calculating" : undefined,
			},
			{
				maxOfType: selectablePortfolioConstraints?.selectableCurrencies?.length ?? 0,
				label: "Currency constraint",
				type: "currencyConstraints",
				limitTooltip: isCalculating
					? "Sphere is calculating the constraint limits"
					: !allStepsData["investableUniverse"].universeIdentifier
					  ? "To add this constraint, please select a universe first."
					  : "You have already selected all currencies",
				disabled: isCalculating || selectableCurrencies === undefined || selectableCurrencies.length === 0,
				status: isCalculating ? "calculating" : undefined,

				// hidden: true, // TODO(MDOT-10515): show (hide in bulk?)
			},
			{
				maxOfType: 1,
				label: "Lock instrument weight",
				type: "lockInstrumentGroup",
				disabled:
					isCalculating ||
					selectablePortfolioConstraints?.selectableLockableInstruments?.length === undefined ||
					selectablePortfolioConstraints?.selectableLockableInstruments?.length === 0,
				limitTooltip: isCalculating
					? "Sphere is calculating the constraint limits"
					: !allStepsData["investableUniverse"].universeIdentifier
					  ? "To add this constraint, please select a universe first."
					  : "You have already selected all lock instrument",
				hidden: context.area.name === "create",
				status: isCalculating ? "calculating" : undefined,
			},
			{
				label: "Single instrument constraint",
				type: "forEachConstraint",
				disabled:
					isCalculating ||
					selectablePortfolioConstraints?.selectableInstruments?.length === undefined ||
					selectablePortfolioConstraints?.selectableInstruments?.length === 0,
				limitTooltip: isCalculating
					? "Sphere is calculating the constraint limits"
					: !allStepsData["investableUniverse"].universeIdentifier
					  ? "To add this constraint, please select a universe first."
					  : "You have already selected all for each constraint",
				status: isCalculating ? "calculating" : undefined,
			},
			{
				label: "Group instrument constraint",
				type: "instrumentConstraints",
				disabled:
					isCalculating ||
					selectablePortfolioConstraints?.selectableInstruments === undefined ||
					selectablePortfolioConstraints?.selectableInstruments.length === 0,
				limitTooltip: isCalculating
					? "Sphere is calculating the constraint limits"
					: !allStepsData["investableUniverse"].universeIdentifier
					  ? "To add this constraint, please select a universe first."
					  : "All the universe instruments have already been selected",
				status: isCalculating ? "calculating" : undefined,
			},
		],
		[
			selectablePortfolioConstraints?.selectableAssetClasses?.length,
			selectablePortfolioConstraints?.selectableTags?.length,
			selectablePortfolioConstraints?.selectableInstruments,
			selectablePortfolioConstraints?.selectableScores,
			selectablePortfolioConstraints?.selectableCurrencies?.length,
			selectablePortfolioConstraints?.selectableLockableInstruments?.length,
			isCalculating,
			selectableAssetClasses,
			selectableTags,
			allStepsData,
			selectableScores,
			user,
			selectableCurrencies,
			context.area.name,
		],
	);

	const stepToRequestMapper = useMemo(() => makeStepToRequestMapper(area.portfolioUid), [area.portfolioUid]);

	const onSubmitAsync = useHandleSubmitToCustomSubmitHandlerInContext({
		context,
		stepName: "portfolioConstraints",
		handleSubmit,
		baseSubmitFn: async (values) => {
			try {
				onStepDataChange(await saveHandlers.portfolioConstraints(values), { skipMetadataUpdate: true, persist: true });
				trackMixPanelEvent("Portfolio-Draft", {
					Type: area.portfolioUid ? "enhance" : "creation",
					UID: area.portfolioUid,
					Action: "save",
					Step: "portfolio-constraints",
					...values,
				});
			} catch (err) {
				onStepError();
				console.error("save Portfolio Constraints failed", err);
				throw err;
			}
		},
	});

	return (
		<StepBase title="Portfolio constraints" optional={stepMetadata.optional} onSubmitAsync={() => onSubmitAsync()}>
			<DataDisplayOverlay
				dataProvider={() => ({
					formData: watch(),
					requestBody: stepToRequestMapper.portfolioConstraints(watch()),
				})}
				dataSource="Portfolio Constraints"
			/>
			<div className="mb-6" data-qualifier="portfolioWizard/portfolioConstraints/hint">
				{edit && "In this step, you will set portfolio constraints."} Constraints are hard or soft limits that Sphere
				must respect when building your portfolio. You can specify constraints for asset allocation, sector exposure, or
				any other requirements that you consider relevant.
			</div>
			<RenderingGuard type="condition" when={edit}>
				<AddConstraintSection
					constraintAdders={constraintAdders}
					constraintList={observableList}
					onAdd={(type) => append(constraintTemplates[type]())}
				/>
			</RenderingGuard>
			<div className="mb-6">
				<div className="font-bold text-[16px] mb-2">Selected constraints</div>
				{edit && (
					<div>
						Add preferred constraints and Sphere will validate them. You can also turn constraints into soft constraints
						or adjust their order by dragging them. The order determines their priority in finding an optimal solution
						for your portfolio.
					</div>
				)}
			</div>
			{!isCalculating &&
				area.name !== "settings-current" &&
				area.name !== "settings-enhanced" &&
				revertData.size > 0 && (
					<div className="mb-4">
						<Banner
							severity="error"
							overrides={{
								background: themeCSSVars.palette_graph_B50,
								iconColor: themeCSSVars.palette_graph_B500,
								icon: "Ask-ai",
							}}
							title="Feasibility Adjustments Applied"
						>
							<div>We&apos;ve adjusted your constraints for feasibility.</div>
							<div className="mt-1">
								{observableList.some((x) => x.validity === "UNCHECKED") && (
									<button type="button" className="underline font-bold" onClick={() => reapplyAllAISolutions()}>
										Reapply Adjustments
									</button>
								)}
							</div>
						</Banner>
					</div>
				)}
			{observableList.length === 0 ? (
				<div className="h-[50dvh]">
					<IconWalls.ConstraintNotSet constraintName="portfolio constraint" />
				</div>
			) : (
				<FormProvider {...form}>
					<ConstraintListSection
						constraints={observableList}
						onChange={(constraints) => {
							replace(constraints.map((constraint) => ({ ...constraint, validity: ConstraintValidity.Unchecked })));
							isSolveWithAiAppliedRef.current = false;
						}}
						reOrderable={edit}
					>
						{(props) => (
							<DefaultConstraintListItem
								{...props}
								key={fields[props.index]?._id ?? props.index} //TODO: change this workaround and migrate all with array approach
								actions={edit && !isCalculating}
								subItem={
									<PortfolioConstraintSubListItem
										item={props.item}
										index={props.index}
										control={control}
										editable={edit && !isCalculating}
										solvedWithAI={
											// revertData.has(props.item.id)
											solutionsWithAiApplied.has(props.item.id)
											// props.item.validity === ConstraintValidity.Invalid &&
										}
										revertData={revertData.get(props.item.id)}
										onConstraintChange={() => {
											replace(
												getValues().list.map((constraint) =>
													constraint.id === props.item.id
														? { ...constraint, validity: ConstraintValidity.Unchecked }
														: constraint,
												),
											);
											isSolveWithAiAppliedRef.current = false;
										}}
									/>
								}
							>
								<PortfolioConstraintListItem
									item={props.item}
									control={control}
									index={props.index}
									selectableData={{ ...selectablePortfolioConstraints }}
									updatedSelectabelData={{
										selectableInstruments,
										selectableAssetClasses,
										selectableTags,
										selectableCurrencies,
									}}
									editable={!isCalculating}
									trigger={unpromisify((fieldName) => trigger(fieldName).then(noop))}
									solvedWithAI={
										/* true ||  */
										// revertData.has(props.item.id)
										solutionsWithAiApplied.has(props.item.id)
										// props.item.validity === ConstraintValidity.Invalid
									}
									revertData={revertData.get(props.item.id)}
									onConstraintChange={() => {
										replace(
											getValues().list.map((constraint) =>
												constraint.id === props.item.id
													? { ...constraint, validity: ConstraintValidity.Unchecked }
													: constraint,
											),
										);
										isSolveWithAiAppliedRef.current = false;
									}}
								/>
							</DefaultConstraintListItem>
						)}
					</ConstraintListSection>
				</FormProvider>
			)}
		</StepBase>
	);
}

type UpdatedSelectabelData = {
	selectableInstruments: RichTicker[] | undefined;
	selectableAssetClasses: AssetClass[] | undefined;
	selectableTags: string[] | undefined;
	selectableCurrencies: Currencies[] | undefined;
};

function RowTooltip(props: {
	solvedWithAI: boolean;
	relation: ConstraintRelation;
	minWeight?: number;
	maxWeight?: number;
	constraint: "forEachConstraint" | "lockInstrumentGroup";
}) {
	const { solvedWithAI, relation, maxWeight, minWeight } = props;
	const betweeenText = "Current instrument weight between";
	const defaultText = "Current instrument weight";
	return (
		<AutoTooltip
			severity="warning"
			position="right"
			mode="hover"
			maxWidth={400}
			overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
			trigger={({ innerRef }) => (
				<Icon
					size={16}
					icon={solvedWithAI ? "Ask-ai" : "Icon-full-alert"}
					color={solvedWithAI ? themeCSSVars.palette_graph_B500 : themeCSSVars.palette_W500}
					innerRef={innerRef}
				/>
			)}
		>
			<TooltipContent>
				<div className="flex space-x-1 mb-2 items-center pr-4">
					<Icon
						icon={solvedWithAI ? "Ask-ai" : "Icon-full-alert"}
						color={solvedWithAI ? themeCSSVars.palette_graph_B500 : themeCSSVars.palette_W500}
						size={16}
					/>
					<Text as="span" type="Body/M/Bold">
						{solvedWithAI ? "Feasibility Adjustments Applied" : "Unfeasible constraints"}
					</Text>
				</div>
				<Text as="p" type="Body/M/Book" classList="mb-2">
					{solvedWithAI
						? `This instrument has been removed from the ${
								props.constraint === "lockInstrumentGroup" ? "lock constraint" : "single instrument constraint"
						  }.`
						: "Remove the constraint or change the constraints order."}
				</Text>
				{switchExpr(relation, {
					BETWEEN: () => (
						<Text as="p" type="Body/M/Book" classList="mb-2 italic">
							{betweeenText}: Min: <span className="not-italic">{minWeight ?? "-"}%</span> Max:{" "}
							<span className="not-italic">{maxWeight ?? "-"}%</span>
						</Text>
					),
					EQUAL: () => (
						<Text as="p" type="Body/M/Book" classList="mb-2 italic">
							{defaultText}: <span className="not-italic">{minWeight ?? "-"}%</span>
						</Text>
					),
					MAX: () => (
						<Text as="p" type="Body/M/Book" classList="mb-2 italic">
							{defaultText}: <span className="not-italic">{maxWeight ?? "-"}%</span>
						</Text>
					),
					MIN: () => (
						<Text as="p" type="Body/M/Book" classList="mb-2 italic">
							{defaultText}: <span className="not-italic">{minWeight ?? "-"}%</span>
						</Text>
					),
					EXCLUDE: () => <></>,
				})}
			</TooltipContent>
		</AutoTooltip>
	);
}

function ForEachInstrumentRow(
	props: ConstraintSpecificColumnsProps<
		EditablePortfolioConstraintHelper<"forEachConstraint">,
		EditablePortfolioConstraintHelper<keyof PortfolioConstraintTypePayloadMap>
	> & {
		editable: boolean;
		solvedWithAI: boolean;
		revertData?: EditablePortfolioConstraintHelper<"forEachConstraint">;
		onConstraintChange?(): void;
	},
): JSX.Element {
	const forEachInstrumentHandler = useFieldArray({
		control: props.control,
		name: `list.${props.index}.tickerIdentifiers`,
		keyName: "_id",
	});

	const listHandler = useFieldArray({ control: props.control, name: "list", keyName: "_id" });

	function separate(index: number, ticker: ConstrainedTicker) {
		listHandler.append({
			...constraintTemplates["instrumentConstraints"](),
			tickerIdentifiers: [ticker],
			relation: props.item.relation,
		} satisfies PortfolioConstraintTypePayloadMap["instrumentConstraints"]);
		forEachInstrumentHandler.remove(index);
	}

	return (
		<ScrollWrapper classList="max-h-[300px] mt-4" direction="vertical">
			<ForEach collection={props.item.tickerIdentifiers}>
				{({ item, index }) => (
					<div
						className={toClassName({
							[`py-3 flex justify-between`]: true,
							[`border-t`]: index === 0,
							[`border-b border-b-[${themeCSSVars.global_palette_neutral_200}]`]:
								index !== props.item.tickerIdentifiers.length - 1,
						})}
					>
						<div className="flex">
							<div className="w-[207px]" />
							<div className="flex space-x-2 items-end">
								<p
									className={toClassName({
										[`text-[color:${themeCSSVars.global_palette_neutral_700}]`]: true,
										"line-through	": item.deleted,
									})}
								>
									{item.instrument}
								</p>
								{item.feasible === false && item.deleted && (
									<RowTooltip
										solvedWithAI={props.solvedWithAI}
										relation={props.item.relation ?? "EXCLUDE"}
										minWeight={item.suggestedMinWeight}
										maxWeight={item.suggestedMaxWeight}
										constraint="forEachConstraint"
									/>
								)}
								{/* {item.feasible === false && !item.deleted && (
									<ActionText onClick={() => separate(index, item)}>Split</ActionText>
								)} */}
							</div>
						</div>
						{props.editable && !item.deleted && (
							<button
								type="button"
								onClick={() => {
									forEachInstrumentHandler.remove(index);
									props.onConstraintChange?.();
								}}
							>
								<Icon icon="Delete" color={themeCSSVars.Button_bg_primary} size={21} />
							</button>
						)}
						{props.editable && item.deleted && (
							<button
								type="button"
								onClick={() => {
									forEachInstrumentHandler.update(index, { ...item, deleted: false });
									props.onConstraintChange?.();
								}}
							>
								<Icon icon="restore" color={themeCSSVars.Button_bg_primary} size={21} />
							</button>
						)}
					</div>
				)}
			</ForEach>
		</ScrollWrapper>
	);
}

function LockInstrumentRow(
	props: ConstraintSpecificColumnsProps<
		EditablePortfolioConstraintHelper<"lockInstrumentGroup">,
		EditablePortfolioConstraintHelper<keyof PortfolioConstraintTypePayloadMap>
	> & {
		editable: boolean;
		solvedWithAI: boolean;
		revertData?: EditablePortfolioConstraintHelper<"lockInstrumentGroup">;
		onConstraintChange?(): void;
	},
): JSX.Element {
	const lockInstrumentHandler = useFieldArray({
		control: props.control,
		name: `list.${props.index}.tickerIdentifiers`,
		keyName: "_id",
	});

	const listHandler = useFieldArray({ control: props.control, name: "list", keyName: "_id" });

	function separate(index: number, ticker: ConstrainedTicker) {
		listHandler.append({
			...constraintTemplates["instrumentConstraints"](),
			tickerIdentifiers: [ticker],
			relation: ConstraintRelation.Equal,
			minWeight: ticker.weight ?? null,
		} satisfies PortfolioConstraintTypePayloadMap["instrumentConstraints"]);
		lockInstrumentHandler.remove(index);
	}

	return (
		<ScrollWrapper classList="max-h-[300px] mt-4" direction="vertical">
			<ForEach collection={props.item.tickerIdentifiers}>
				{({ item, index }) => (
					<div
						className={toClassName({
							[`py-3 flex justify-between`]: true,
							[`border-t`]: index === 0,
							[`border-b border-b-[${themeCSSVars.global_palette_neutral_200}]`]:
								index !== props.item.tickerIdentifiers.length - 1,
						})}
					>
						<div className="flex">
							<div className="w-[207px]" />
							<div className="flex space-x-2 items-end">
								<p
									className={toClassName({
										[`text-[color:${themeCSSVars.global_palette_neutral_700}]`]: true,
										"line-through	": item.deleted,
									})}
								>
									{item.instrument}
								</p>
								{item.feasible === false && item.deleted && (
									<RowTooltip
										solvedWithAI={props.solvedWithAI}
										relation="EQUAL"
										minWeight={item.suggestedMinWeight}
										maxWeight={item.suggestedMaxWeight}
										constraint="lockInstrumentGroup"
									/>
								)}
								{/* {item.feasible === false && !item.deleted && (
									<ActionText onClick={() => separate(index, item)}>Split</ActionText>
								)} */}
							</div>
						</div>
						{props.editable && !item.deleted && (
							<button
								type="button"
								onClick={() => {
									lockInstrumentHandler.remove(index);
									props.onConstraintChange?.();
								}}
							>
								<Icon icon="Delete" color={themeCSSVars.Button_bg_primary} size={21} />
							</button>
						)}
						{props.editable && item.deleted && (
							<button
								type="button"
								onClick={() => {
									lockInstrumentHandler.update(index, { ...item, deleted: false });
									props.onConstraintChange?.();
								}}
							>
								<Icon icon="restore" color={themeCSSVars.Button_bg_primary} size={21} />
							</button>
						)}
					</div>
				)}
			</ForEach>
		</ScrollWrapper>
	);
}

function PortfolioConstraintSubListItem({
	control,
	editable,
	index,
	item,
	solvedWithAI,
	revertData,
	onConstraintChange,
}: ConstraintSpecificColumnsProps<EditablePortfolioConstraintHelper<keyof PortfolioConstraintTypePayloadMap>> & {
	editable: boolean;
	solvedWithAI: boolean;
	revertData?: EditablePortfolioConstraintHelper<keyof PortfolioConstraintTypePayloadMap>;
	onConstraintChange?(): void;
}) {
	return match(item)
		.with({ type: "lockInstrumentGroup" }, (params) => (
			<LockInstrumentRow
				item={params}
				control={control}
				index={index}
				editable={editable}
				solvedWithAI={solvedWithAI}
				revertData={revertData as EditablePortfolioConstraintHelper<"lockInstrumentGroup">}
				onConstraintChange={onConstraintChange}
			/>
		))
		.with({ type: "forEachConstraint" }, (params) => (
			<ForEachInstrumentRow
				item={params}
				control={control}
				index={index}
				editable={editable}
				solvedWithAI={solvedWithAI}
				revertData={revertData as EditablePortfolioConstraintHelper<"forEachConstraint">}
				onConstraintChange={onConstraintChange}
			/>
		))
		.otherwise(() => <></>);
}

//#region Asset class Constraints
function AssetAllocationColumns({
	index,
	item,
	control,
	selectableAssetClasses,
	updatedSelectabelData,
	editable,
	trigger,
	solvedWithAI,
	onConstraintChange,
	revertData,
}: ConstraintSpecificColumnsProps<EditablePortfolioConstraintHelper<"assetClassConstraints">> & {
	selectableAssetClasses: SelectableAllocationConstraintsResponse["selectableAssetClasses"] | undefined;
	updatedSelectabelData: {
		selectableInstruments: RichTicker[] | undefined;
		selectableAssetClasses: AssetClass[] | undefined;
		selectableTags: string[] | undefined;
	};
	editable: boolean;
	trigger(fieldName: string): void;
	solvedWithAI: boolean;
	onConstraintChange(): void;
	revertData?: EditablePortfolioConstraintHelper<"assetClassConstraints">;
}) {
	const { formatNumber } = useLocaleFormatters();
	const { t } = useTranslation();
	const [showSelectIndexDialog, setShowSelectIndexDialog] = useState(false);
	const originalValues = revertData && {
		relation: revertData.relation,
		max: revertData.maxWeight ?? null,
		min: revertData.minWeight ?? null,
	};

	const selectableAssetClassesMemo = useMemo(() => {
		return selectableAssetClasses?.map((el) => ({
			granularity: el.granularity,
			assetClass: el.macroAssetClass,
			geography: el.macroGeography,
			microAssetClass: el.microAssetClass,
			id: getTickerCompositeId(el)(["identifier", "macroAssetClass"]),
			...el,
		}));
	}, [selectableAssetClasses]);

	const selectedValues = useMemo(
		() =>
			item.assetClassesIdentifiers.map((ac) => ({
				granularity: ac.granularity,
				assetClass: ac.macroAssetClass,
				geography: ac.macroGeography,
				microAssetClass: ac.microAssetClass,
				microGeography: ac.microGeography,
				id: getTickerCompositeId(ac)(["identifier", "macroAssetClass"]),
				...ac,
			})),
		[item.assetClassesIdentifiers],
	);

	const selectableIds = useMemo(() => {
		return selectableAssetClassesMemo
			?.filter(
				(el) =>
					updatedSelectabelData.selectableAssetClasses?.some(({ identifier }) => el.identifier === identifier) ||
					item.assetClassesIdentifiers.some(({ identifier }) => identifier === el.identifier),
			)
			.map(({ id }) => id);
	}, [item.assetClassesIdentifiers, selectableAssetClassesMemo, updatedSelectabelData.selectableAssetClasses]);

	const { setValue } = useFormContext();

	return (
		<>
			<FormController
				control={control}
				name={`list.${index}.assetClassesIdentifiers`}
				render={({ field: { ref: _ref, ...fieldProps } }) => (
					<ModalWithGroupFilters
						{...fieldProps}
						setShowModal={setShowSelectIndexDialog}
						showModal={showSelectIndexDialog}
						rows={selectableAssetClassesMemo?.filter((el) => selectableIds?.includes(el.id)) ?? []}
						columns={[
							{
								header: "",
								content: ({ name }) => name,
							},
						]}
						state={{
							composition: selectedValues,
						}}
						matchFn={(a, q) => {
							const stringifiedRow = JSON.stringify(a.name).toLowerCase();
							const word = q.toLowerCase().split(" ");
							return word.every((keys) => stringifiedRow.indexOf(keys) >= 0);
						}}
						onLoad={(composition) => {
							fieldProps.onChange(composition);
							fieldProps.onBlur();
							setShowSelectIndexDialog(false);
							onConstraintChange();
						}}
						singleCheck
						customSelectableRowIds={selectableIds}
						filtersMode="multi"
						rowClassList={`portfolioConstraintsTable/constraint(${item.type})/row`}
					/>
				)}
			/>
			<div className="max-w-[180px] min-[1400px]:max-w-[300px] overflow-hidden pr-4">
				<div className={titleClassName}>Asset class</div>
				<div>
					{item.readonly || !editable ? (
						<EllipsisText text={item.assetClassesIdentifiers?.[0]?.name} />
					) : (
						<FormController
							control={control}
							name={`list.${index}.assetClassesIdentifiers`}
							render={({ fieldState, field }) => {
								const error = Boolean(fieldState.error?.message);
								const invalid = isFieldOnError(error, item.validity, solvedWithAI);

								return (
									<AutoTooltip
										severity="error"
										position="left"
										mode="hover"
										disabled={!error}
										trigger={({ innerRef }) => (
											<ActionText
												data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/assetClassesIdentifiers`}
												innerRef={innerRef}
												onClick={() => setShowSelectIndexDialog(true)}
												classList={{
													"w-full": true,
													// [`!text-[color:${themeCSSVars.global_palette_text_icon_placeholder}]`]:
													// 	field.value.length === 0 && !invalid,
													[`!text-[color:${themeCSSVars.global_palette_text_icon_filled}]`]:
														field.value.length > 0 && !invalid,
													[`!text-[color:${themeCSSVars.global_palette_danger_600}]`]: invalid,
												}}
											>
												<EllipsisText text={item.assetClassesIdentifiers?.[0]?.name ?? "Select asset class"} />
											</ActionText>
										)}
									>
										{fieldState.error?.message}
									</AutoTooltip>
								);
							}}
						/>
					)}
				</div>
				{/* TODO: translate */}
			</div>
			<div className="flex flex-1 space-x-4 mr-4 justify-end">
				<div className="w-28">
					{item.readonly ? (
						<ReadonlyRelation label="RANGE" classList="w-28">
							{t(`RELATION.${item.relation}`)}
						</ReadonlyRelation>
					) : (
						<FormController
							control={control}
							name={`list.${index}.relation`}
							render={({ field: { ref: fieldRef, ...fieldProps } }) => (
								<ReadonlyRelation label="RANGE" classList="w-28">
									<Select
										data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/relation`}
										{...fieldProps}
										innerRef={fieldRef}
										options={relationOptions}
										size="x-small"
										onChange={(constraint) => {
											fieldProps.onChange(constraint);
											setValue(`list.${index}.maxWeight`, null);
											setValue(`list.${index}.minWeight`, null);
											onConstraintChange();
										}}
										classList="w-full"
									/>
								</ReadonlyRelation>
							)}
						/>
					)}
				</div>
				<div
					className={toClassName({
						"min-w-[196px] flex": true,
						"justify-end": item.relation !== "BETWEEN",
					})}
				>
					{match(item)
						.with({ relation: "BETWEEN" }, (constraint) => (
							<>
								{constraint.readonly ? (
									<>
										<ReadonlyRelation classList="w-20 ml-auto text-right" label="MIN" align="right">
											{formatNumber(constraint.minWeight, 2)}%
										</ReadonlyRelation>
										<ReadonlyRelation classList="w-20 ml-auto text-right" label="MAX" align="right">
											{formatNumber(constraint.maxWeight, 2)}%
										</ReadonlyRelation>
									</>
								) : (
									<>
										<FormController
											control={control}
											name={`list.${index}.minWeight`}
											render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
												const error = Boolean(fieldState.error?.message);
												const invalid = isFieldOnError(error, item.validity, solvedWithAI);

												return (
													<AutoTooltip
														severity="error"
														position="left"
														mode="hover"
														disabled={solvedWithAI ? undefined : !invalid}
														overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
														trigger={({ innerRef }) => (
															<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
																<NullableNumberInput
																	data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
																	innerRef={multiRef(ref, innerRef)}
																	{...fieldProps}
																	inputAppearance={styleInputAppearance(solvedWithAI)}
																	onBlur={() => {
																		onBlur();
																		onConstraintChange();
																		trigger(`list.${index}.maxWeight`);
																	}}
																	invalid={invalid}
																	size="x-small"
																	rightContent={<Icon icon="Percentile" />}
																	placeholder="0"
																/>
															</ReadonlyRelation>
														)}
													>
														{fieldState.error?.message ?? (
															<Suggestion
																relation="MIN"
																suggestedMaxWeight={constraint.suggestedMaxWeight}
																suggestedMinWeight={constraint.suggestedMinWeight}
																solvedWithAI={solvedWithAI}
																maxWeight={constraint.maxWeight}
																minWeight={constraint.minWeight}
																originalValues={originalValues}
															/>
														)}
													</AutoTooltip>
												);
											}}
										/>

										<FormController
											control={control}
											name={`list.${index}.maxWeight`}
											render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
												const error = Boolean(fieldState.error?.message);
												const invalid = isFieldOnError(error, item.validity, solvedWithAI);

												return (
													<AutoTooltip
														severity="error"
														position="left"
														mode="hover"
														disabled={solvedWithAI ? undefined : !invalid}
														overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
														trigger={({ innerRef }) => (
															<ReadonlyRelation classList="w-20 ml-auto" label="MAX" align="right">
																<NullableNumberInput
																	data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/maxWeight`}
																	innerRef={multiRef(ref, innerRef)}
																	{...fieldProps}
																	inputAppearance={styleInputAppearance(solvedWithAI)}
																	onBlur={() => {
																		onBlur();
																		onConstraintChange();
																		trigger(`list.${index}.minWeight`);
																	}}
																	invalid={invalid}
																	size="x-small"
																	rightContent={<Icon icon="Percentile" />}
																	placeholder="100"
																/>
															</ReadonlyRelation>
														)}
													>
														{fieldState.error?.message ?? (
															<Suggestion
																relation="MAX"
																suggestedMaxWeight={constraint.suggestedMaxWeight}
																suggestedMinWeight={constraint.suggestedMinWeight}
																solvedWithAI={solvedWithAI}
																maxWeight={constraint.maxWeight}
																minWeight={constraint.minWeight}
																originalValues={originalValues}
															/>
														)}
													</AutoTooltip>
												);
											}}
										/>
									</>
								)}
							</>
						))
						.with({ relation: "MAX" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="MAX" align="right">
									{constraint.maxWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.maxWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MAX" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/maxWeight`}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={invalid}
															inputAppearance={styleInputAppearance(solvedWithAI)}
															onBlur={() => {
																onBlur();
																onConstraintChange();
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="100"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation={constraint.relation}
														suggestedMaxWeight={constraint.suggestedMaxWeight}
														suggestedMinWeight={constraint.suggestedMinWeight}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.with({ relation: "MIN" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="MIN" align="right">
									{constraint.minWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.minWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={invalid}
															inputAppearance={styleInputAppearance(solvedWithAI)}
															onBlur={() => {
																onBlur();
																onConstraintChange();
																trigger(`list.${index}.maxWeight`);
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="0"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation={constraint.relation}
														suggestedMaxWeight={constraint.suggestedMaxWeight}
														suggestedMinWeight={constraint.suggestedMinWeight}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.with({ relation: "EQUAL" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="WEIGHT" align="right">
									{constraint.minWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.minWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={invalid}
															inputAppearance={styleInputAppearance(solvedWithAI)}
															onBlur={() => {
																onBlur();
																onConstraintChange();
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="0"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation={constraint.relation}
														suggestedMaxWeight={constraint.suggestedMaxWeight}
														suggestedMinWeight={constraint.suggestedMinWeight}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.otherwise(() => (
							<></>
						))}
				</div>
			</div>
		</>
	);
}
//#endregion

//#region Instrument Constraints
function InstrumentConstraintsColumns({
	index,
	item,
	control,
	selectableInstruments,
	// updatedSelectabelData,
	editable,
	trigger,
	solvedWithAI,
	onConstraintChange,
	revertData,
}: ConstraintSpecificColumnsProps<EditablePortfolioConstraintHelper<"instrumentConstraints">> & {
	selectableInstruments: SelectableAllocationConstraintsResponse["selectableInstruments"];
	updatedSelectabelData: UpdatedSelectabelData;
	editable: boolean;
	trigger(fieldName: string): void;
	solvedWithAI: boolean;
	onConstraintChange(): void;
	revertData?: EditablePortfolioConstraintHelper<"instrumentConstraints">;
}) {
	const { setValue } = useFormContext();
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();
	const originalValues = revertData && {
		relation: revertData.relation,
		max: revertData.maxWeight ?? null,
		min: revertData.minWeight ?? null,
	};
	return (
		<>
			<div className="max-w-[180px] min-[1820px]:max-w-[300px] overflow-hidden pr-4">
				<div className={titleClassName}>Instruments</div>
				{item.readonly || !editable ? (
					<EllipsisText text={instrumentGroupToLabel(item.tickerIdentifiers)} />
				) : (
					<FormController
						control={control}
						name={`list.${index}.tickerIdentifiers`}
						render={({ fieldState, field: { value: tickerIdentifiers, ref, onChange, onBlur } }) => {
							const error = Boolean(fieldState.error?.message);
							const invalid = isFieldOnError(error, item.validity, solvedWithAI);

							return (
								<AutoTooltip
									severity="error"
									position="left"
									mode="hover"
									disabled={!error}
									overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
									trigger={({ innerRef }) => (
										<div ref={innerRef} className="flex items-center gap-1">
											<ActionText
												onClick={() =>
													spawnInstrumentDialog({
														instruments: selectableInstruments ?? [],
														selected: tickerIdentifiers,
														onSubmit: (v) => {
															onChange(v);
															trigger(`list.${index}.tickerIdentifiers`);
															onConstraintChange();
														},
														rowClassList: `portfolioConstraintsTable/constraint(${item.type},${index})/row`,
													})
												}
												innerRef={ref}
												data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/tickerIdentifiers`}
												classList={{
													"truncate py-2 h-6 !leading-none": true,
													[`!text-[color:${themeCSSVars.palette_P400}]`]: tickerIdentifiers.length > 0 && !invalid,
													[`!text-[color:${themeCSSVars.global_palette_danger_600}]`]: invalid,
													[`!text-[color:${themeCSSVars.palette_graph_B500}]`]: solvedWithAI,
												}}
											>
												{instrumentGroupToLabel(tickerIdentifiers)}
											</ActionText>
										</div>
									)}
								>
									{fieldState.error?.message}
								</AutoTooltip>
							);
						}}
					/>
				)}
				{/* TODO: translate */}
			</div>
			<div className="flex flex-1 space-x-4 mr-4 justify-end">
				<div className="w-28">
					{item.readonly ? (
						<ReadonlyRelation label="RANGE" classList="w-28">
							{t(`RELATION.${item.relation}`)}
						</ReadonlyRelation>
					) : (
						<FormController
							control={control}
							name={`list.${index}.relation`}
							render={({ field: { ref: fieldRef, ...fieldProps } }) => (
								<ReadonlyRelation label="RANGE" classList="w-28">
									<Select
										data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/relation`}
										{...fieldProps}
										innerRef={fieldRef}
										options={relationOptions}
										size="x-small"
										onChange={(constraint) => {
											fieldProps.onChange(constraint);
											setValue(`list.${index}.maxWeight`, null);
											setValue(`list.${index}.minWeight`, null);
											onConstraintChange();
										}}
										classList="w-full"
									/>
								</ReadonlyRelation>
							)}
						/>
					)}
				</div>
				<div
					className={toClassName({
						"min-w-[196px] flex": true,
						"justify-end": item.relation !== "BETWEEN",
					})}
				>
					{match(item)
						.with({ relation: "BETWEEN" }, (constraint) => (
							<>
								{constraint.readonly ? (
									<>
										<ReadonlyRelation classList="w-20 ml-auto text-right" label="MIN" align="right">
											{formatNumber(constraint.minWeight, 2)}%
										</ReadonlyRelation>
										<ReadonlyRelation classList="w-20 ml-auto text-right" label="MAX" align="right">
											{formatNumber(constraint.maxWeight, 2)}%
										</ReadonlyRelation>
									</>
								) : (
									<>
										<FormController
											control={control}
											name={`list.${index}.minWeight`}
											render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
												const error = Boolean(fieldState.error?.message);
												const invalid = isFieldOnError(error, item.validity, solvedWithAI);

												return (
													<AutoTooltip
														severity="error"
														position="left"
														mode="hover"
														disabled={solvedWithAI ? undefined : !invalid}
														overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
														trigger={({ innerRef }) => (
															<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
																<NullableNumberInput
																	data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
																	innerRef={multiRef(ref, innerRef)}
																	{...fieldProps}
																	inputAppearance={styleInputAppearance(solvedWithAI)}
																	onBlur={() => {
																		onBlur();
																		onConstraintChange();
																		trigger(`list.${index}.maxWeight`);
																	}}
																	invalid={invalid}
																	size="x-small"
																	rightContent={<Icon icon="Percentile" />}
																	placeholder="0"
																/>
															</ReadonlyRelation>
														)}
													>
														{fieldState.error?.message ?? (
															<Suggestion
																relation="MIN"
																suggestedMaxWeight={constraint.suggestedMaxWeight}
																suggestedMinWeight={constraint.suggestedMinWeight}
																solvedWithAI={solvedWithAI}
																maxWeight={constraint.maxWeight}
																minWeight={constraint.minWeight}
																originalValues={originalValues}
															/>
														)}
													</AutoTooltip>
												);
											}}
										/>

										<FormController
											control={control}
											name={`list.${index}.maxWeight`}
											render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
												const error = Boolean(fieldState.error?.message);
												const invalid = isFieldOnError(error, item.validity, solvedWithAI);

												return (
													<AutoTooltip
														severity="error"
														position="left"
														mode="hover"
														disabled={solvedWithAI ? undefined : !invalid}
														overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
														trigger={({ innerRef }) => (
															<ReadonlyRelation classList="w-20 ml-auto" label="MAX" align="right">
																<NullableNumberInput
																	data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/maxWeight`}
																	innerRef={multiRef(ref, innerRef)}
																	{...fieldProps}
																	inputAppearance={styleInputAppearance(solvedWithAI)}
																	onBlur={() => {
																		onBlur();
																		onConstraintChange();
																		trigger(`list.${index}.minWeight`);
																	}}
																	invalid={invalid}
																	size="x-small"
																	rightContent={<Icon icon="Percentile" />}
																	placeholder="100"
																/>
															</ReadonlyRelation>
														)}
													>
														{fieldState.error?.message ?? (
															<Suggestion
																relation="MAX"
																suggestedMaxWeight={constraint.suggestedMaxWeight}
																suggestedMinWeight={constraint.suggestedMinWeight}
																solvedWithAI={solvedWithAI}
																maxWeight={constraint.maxWeight}
																minWeight={constraint.minWeight}
																originalValues={originalValues}
															/>
														)}
													</AutoTooltip>
												);
											}}
										/>
									</>
								)}
							</>
						))
						.with({ relation: "MAX" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="MAX" align="right">
									{constraint.maxWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.maxWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MAX" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/maxWeight`}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={invalid}
															inputAppearance={styleInputAppearance(solvedWithAI)}
															onBlur={() => {
																onBlur();
																onConstraintChange();
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="100"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation={constraint.relation}
														suggestedMaxWeight={constraint.suggestedMaxWeight}
														suggestedMinWeight={constraint.suggestedMinWeight}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.with({ relation: "MIN" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="MIN" align="right">
									{constraint.minWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.minWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={invalid}
															inputAppearance={styleInputAppearance(solvedWithAI)}
															onBlur={() => {
																onBlur();
																onConstraintChange();
																trigger(`list.${index}.maxWeight`);
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="0"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation={constraint.relation}
														suggestedMaxWeight={constraint.suggestedMaxWeight}
														suggestedMinWeight={constraint.suggestedMinWeight}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.with({ relation: "EQUAL" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="WEIGHT" align="right">
									{constraint.minWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.minWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={invalid}
															inputAppearance={styleInputAppearance(solvedWithAI)}
															onBlur={() => {
																onBlur();
																onConstraintChange();
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="0"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation={constraint.relation}
														suggestedMaxWeight={constraint.suggestedMaxWeight}
														suggestedMinWeight={constraint.suggestedMinWeight}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.otherwise(() => (
							<></>
						))}
				</div>
			</div>
		</>
	);
}
//#endregion

//#region Tag Constraints
function TagColumns({
	index,
	item,
	control,
	selectableTags,
	updatedSelectabelData,
	editable,
	trigger,
	solvedWithAI,
	onConstraintChange,
	revertData,
}: ConstraintSpecificColumnsProps<EditablePortfolioConstraintHelper<"tagConstraints">> & {
	selectableTags: SelectableAllocationConstraintsResponse["selectableTags"];
	updatedSelectabelData: UpdatedSelectabelData;
	editable: boolean;
	trigger(fieldName: string): void;
	solvedWithAI: boolean;
	onConstraintChange(): void;
	revertData?: EditablePortfolioConstraintHelper<"tagConstraints">;
}) {
	const { formatNumber } = useLocaleFormatters();
	const { t } = useTranslation();
	const { setValue } = useFormContext();
	const originalValues = revertData && {
		relation: revertData.relation,
		max: revertData.maxWeight ?? null,
		min: revertData.minWeight ?? null,
	};

	const setOfTags = useMemo(() => {
		const tags = new window.Set(selectableTags ?? []);
		if (item.validity === "INVALID" && item.tagIdentifiers?.[0]) {
			tags.add(item.tagIdentifiers[0]);
		}

		return Array.from(tags)
			.filter((label) => label)
			.map((label, _i, set) =>
				labelToTag(
					{ label, disabled: updatedSelectabelData.selectableTags?.includes(label) === false },
					set as string[],
				),
			);
	}, [item.tagIdentifiers, item.validity, selectableTags, updatedSelectabelData.selectableTags]);

	return (
		<>
			<div className="flex items-center justify-center">
				<div className="w-28">
					<div className={titleClassName}>Tag</div>
					{item.readonly || !editable ? (
						item.tagIdentifiers?.[0] ?? "-"
					) : (
						<FormController
							control={control}
							name={`list.${index}.tagIdentifiers`}
							render={({ field: { ref: _ref, ...fieldProps }, fieldState }) => {
								const error = Boolean(fieldState.error?.message);
								const invalid = isFieldOnError(error, item.validity, solvedWithAI);
								return (
									<AutoTooltip
										severity="error"
										position="left"
										mode="hover"
										disabled={!fieldState.error?.message}
										overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
										trigger={({ innerRef }) => (
											<div ref={innerRef}>
												<InstrumentTagSelect
													universeTags={setOfTags}
													mode="selector"
													onTagClick={(id) => {
														fieldProps.onChange([id]);
														onConstraintChange();
													}}
													onTagCancel={() => {
														fieldProps.onChange([]);
														onConstraintChange();
													}}
													selectedTagId={item.tagIdentifiers?.[0] ?? null}
													editTagButtonClassName={toClassName({ "!text-red-600 !border-[color:red]": invalid })}
													icon={{ name: "Edit", size: 16 }}
												/>
											</div>
										)}
									>
										{fieldState.error?.message}
									</AutoTooltip>
								);
							}}
						/>
					)}
				</div>
			</div>
			<div className="flex flex-1 space-x-4 mr-4 justify-end">
				<div className="w-28">
					{item.readonly ? (
						<ReadonlyRelation label="RANGE" classList="w-28">
							{t(`RELATION.${item.relation}`)}
						</ReadonlyRelation>
					) : (
						<FormController
							control={control}
							name={`list.${index}.relation`}
							render={({ field: { ref: fieldRef, ...fieldProps } }) => (
								<ReadonlyRelation label="RANGE" classList="w-28">
									<Select
										data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/relation`}
										{...fieldProps}
										innerRef={fieldRef}
										options={relationOptions}
										size="x-small"
										onChange={(constraint) => {
											fieldProps.onChange(constraint);
											setValue(`list.${index}.maxWeight`, null);
											setValue(`list.${index}.minWeight`, null);
											onConstraintChange();
										}}
										classList="w-full"
									/>
								</ReadonlyRelation>
							)}
						/>
					)}
				</div>
				<div
					className={toClassName({
						"min-w-[196px] flex": true,
						"justify-end": item.relation !== "BETWEEN",
					})}
				>
					{match(item)
						.with({ relation: "BETWEEN" }, (constraint) => (
							<>
								{constraint.readonly ? (
									<>
										<ReadonlyRelation classList="w-20 ml-auto text-right" label="MIN" align="right">
											{formatNumber(constraint.minWeight, 2)}%
										</ReadonlyRelation>
										<ReadonlyRelation classList="w-20 ml-auto text-right" label="MAX" align="right">
											{formatNumber(constraint.maxWeight, 2)}%
										</ReadonlyRelation>
									</>
								) : (
									<>
										<FormController
											control={control}
											name={`list.${index}.minWeight`}
											render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
												const error = Boolean(fieldState.error?.message);
												const invalid = isFieldOnError(error, item.validity, solvedWithAI);

												return (
													<AutoTooltip
														severity="error"
														position="left"
														mode="hover"
														disabled={solvedWithAI ? undefined : !invalid}
														overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
														trigger={({ innerRef }) => (
															<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
																<NullableNumberInput
																	data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
																	innerRef={multiRef(ref, innerRef)}
																	{...fieldProps}
																	inputAppearance={styleInputAppearance(solvedWithAI)}
																	onBlur={() => {
																		onBlur();
																		onConstraintChange();
																		trigger(`list.${index}.maxWeight`);
																	}}
																	invalid={invalid}
																	size="x-small"
																	rightContent={<Icon icon="Percentile" />}
																	placeholder="0"
																/>
															</ReadonlyRelation>
														)}
													>
														{fieldState.error?.message ?? (
															<Suggestion
																relation="MIN"
																suggestedMaxWeight={constraint.suggestedMaxWeight}
																suggestedMinWeight={constraint.suggestedMinWeight}
																solvedWithAI={solvedWithAI}
																maxWeight={constraint.maxWeight}
																minWeight={constraint.minWeight}
																originalValues={originalValues}
															/>
														)}
													</AutoTooltip>
												);
											}}
										/>

										<FormController
											control={control}
											name={`list.${index}.maxWeight`}
											render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
												const error = Boolean(fieldState.error?.message);
												const invalid = isFieldOnError(error, item.validity, solvedWithAI);

												return (
													<AutoTooltip
														severity="error"
														position="left"
														mode="hover"
														disabled={solvedWithAI ? undefined : !invalid}
														overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
														trigger={({ innerRef }) => (
															<ReadonlyRelation classList="w-20 ml-auto" label="MAX" align="right">
																<NullableNumberInput
																	data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/maxWeight`}
																	innerRef={multiRef(ref, innerRef)}
																	{...fieldProps}
																	inputAppearance={styleInputAppearance(solvedWithAI)}
																	onBlur={() => {
																		onBlur();
																		onConstraintChange();
																		trigger(`list.${index}.minWeight`);
																	}}
																	invalid={invalid}
																	size="x-small"
																	rightContent={<Icon icon="Percentile" />}
																	placeholder="100"
																/>
															</ReadonlyRelation>
														)}
													>
														{fieldState.error?.message ?? (
															<Suggestion
																relation="MAX"
																suggestedMaxWeight={constraint.suggestedMaxWeight}
																suggestedMinWeight={constraint.suggestedMinWeight}
																solvedWithAI={solvedWithAI}
																maxWeight={constraint.maxWeight}
																minWeight={constraint.minWeight}
																originalValues={originalValues}
															/>
														)}
													</AutoTooltip>
												);
											}}
										/>
									</>
								)}
							</>
						))
						.with({ relation: "MAX" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="MAX" align="right">
									{constraint.maxWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.maxWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MAX" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/maxWeight`}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={invalid}
															inputAppearance={styleInputAppearance(solvedWithAI)}
															onBlur={() => {
																onBlur();
																onConstraintChange();
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="100"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation={constraint.relation}
														suggestedMaxWeight={constraint.suggestedMaxWeight}
														suggestedMinWeight={constraint.suggestedMinWeight}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.with({ relation: "MIN" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="MIN" align="right">
									{constraint.minWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.minWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={invalid}
															inputAppearance={styleInputAppearance(solvedWithAI)}
															onBlur={() => {
																onBlur();
																onConstraintChange();
																trigger(`list.${index}.maxWeight`);
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="0"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation={constraint.relation}
														suggestedMaxWeight={constraint.suggestedMaxWeight}
														suggestedMinWeight={constraint.suggestedMinWeight}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.with({ relation: "EQUAL" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="WEIGHT" align="right">
									{constraint.minWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.minWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={invalid}
															inputAppearance={styleInputAppearance(solvedWithAI)}
															onBlur={() => {
																onBlur();
																onConstraintChange();
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="0"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation={constraint.relation}
														suggestedMaxWeight={constraint.suggestedMaxWeight}
														suggestedMinWeight={constraint.suggestedMinWeight}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.otherwise(() => (
							<></>
						))}
				</div>
			</div>
		</>
	);
}
//#endregion

//#region Currency Constraints
function CurrencyColumn({
	index,
	item,
	control,
	editable,
	updatedSelectabelData,
	selectableCurrencies,
	trigger,
	solvedWithAI,
	onConstraintChange,
	revertData,
}: ConstraintSpecificColumnsProps<EditablePortfolioConstraintHelper<"currencyConstraints">> & {
	editable: boolean;
	trigger(fieldName: string): void;
	selectableCurrencies: SelectableAllocationConstraintsResponse["selectableCurrencies"];
	updatedSelectabelData: UpdatedSelectabelData;
	solvedWithAI: boolean;
	revertData?: EditablePortfolioConstraintHelper<"currencyConstraints">;
	onConstraintChange(): void;
}) {
	const { formatNumber } = useLocaleFormatters();
	const { t } = useTranslation();
	const { setValue } = useFormContext();
	const originalValues = revertData && {
		relation: revertData.relation,
		max: revertData.maxWeight ?? null,
		min: revertData.minWeight ?? null,
	};
	const currencyOptions = useMemo<Array<Option<Currencies>>>(
		() =>
			selectableCurrencies?.map((currency) => ({
				label: currency,
				value: currency,
				disabled: updatedSelectabelData.selectableCurrencies?.includes(currency) === false,
			})) ?? [],
		[selectableCurrencies, updatedSelectabelData.selectableCurrencies],
	);

	return (
		<>
			<div className="max-w-[180px] min-[1400px]:max-w-[300px] overflow-hidden pr-4">
				<div
					className={toClassName({
						[titleClassName]: true,
					})}
				>
					Currency
				</div>
				<div className="w-32 text-left">
					{item.readonly || !editable ? (
						item.currencies?.[0] ?? "-"
					) : (
						<FormController
							control={control}
							name={`list.${index}.currencies`}
							render={({ field: { ref: fieldRef, ...fieldProps }, fieldState }) => {
								const error = Boolean(fieldState.error?.message);
								const invalid = isFieldOnError(error, item.validity, solvedWithAI);

								return (
									<AutoTooltip
										severity="error"
										position="left"
										mode="hover"
										disabled={!invalid}
										overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
										trigger={({ innerRef }) => (
											<div ref={innerRef} className="flex items-center w-full gap-2">
												<Select
													data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/currency`}
													// {...fieldProps}
													options={currencyOptions}
													innerRef={fieldRef}
													size="x-small"
													classList="flex-1"
													invalid={invalid}
													onChange={(currency) => {
														fieldProps.onChange(currency ? [currency] : []);
														onConstraintChange();
													}}
													value={fieldProps.value[0] ?? null}
												/>
											</div>
										)}
									>
										{fieldState.error?.message ?? (
											<TooltipContent classList="font-bold mb-2">Constraint not feasible</TooltipContent>
										)}
									</AutoTooltip>
								);
							}}
						/>
					)}
				</div>
			</div>
			<div className="flex flex-1 space-x-4 mr-4 justify-end">
				<div className="w-28">
					{item.readonly ? (
						<ReadonlyRelation label="RANGE" classList="w-28">
							{t(`RELATION.${item.relation}`)}
						</ReadonlyRelation>
					) : (
						<FormController
							control={control}
							name={`list.${index}.relation`}
							render={({ field: { ref: fieldRef, ...fieldProps } }) => (
								<ReadonlyRelation label="RANGE" classList="w-28">
									<Select
										data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/relation`}
										{...fieldProps}
										innerRef={fieldRef}
										options={relationOptions}
										size="x-small"
										onChange={(constraint) => {
											fieldProps.onChange(constraint);
											setValue(`list.${index}.maxWeight`, null);
											setValue(`list.${index}.minWeight`, null);
											onConstraintChange();
										}}
										classList="w-full"
									/>
								</ReadonlyRelation>
							)}
						/>
					)}
				</div>
				<div
					className={toClassName({
						"min-w-[196px] flex": true,
						"justify-end": item.relation !== "BETWEEN",
					})}
				>
					{match(item)
						.with({ relation: "BETWEEN" }, (constraint) => (
							<>
								{constraint.readonly ? (
									<>
										<ReadonlyRelation classList="w-20 ml-auto text-right" label="MIN" align="right">
											{formatNumber(constraint.minWeight, 2)}%
										</ReadonlyRelation>
										<ReadonlyRelation classList="w-20 ml-auto text-right" label="MAX" align="right">
											{formatNumber(constraint.maxWeight, 2)}%
										</ReadonlyRelation>
									</>
								) : (
									<>
										<FormController
											control={control}
											name={`list.${index}.minWeight`}
											render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
												const error = Boolean(fieldState.error?.message);
												const invalid = isFieldOnError(error, item.validity, solvedWithAI);

												return (
													<AutoTooltip
														severity="error"
														position="left"
														mode="hover"
														disabled={solvedWithAI ? undefined : !invalid}
														overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
														trigger={({ innerRef }) => (
															<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
																<NullableNumberInput
																	data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
																	innerRef={multiRef(ref, innerRef)}
																	{...fieldProps}
																	inputAppearance={styleInputAppearance(solvedWithAI)}
																	onBlur={() => {
																		onBlur();
																		onConstraintChange();
																		trigger(`list.${index}.maxWeight`);
																	}}
																	invalid={invalid}
																	size="x-small"
																	rightContent={<Icon icon="Percentile" />}
																	placeholder="0"
																/>
															</ReadonlyRelation>
														)}
													>
														{fieldState.error?.message ?? (
															<Suggestion
																relation="MIN"
																suggestedMaxWeight={constraint.suggestedMaxWeight}
																suggestedMinWeight={constraint.suggestedMinWeight}
																solvedWithAI={solvedWithAI}
																maxWeight={constraint.maxWeight}
																minWeight={constraint.minWeight}
																originalValues={originalValues}
															/>
														)}
													</AutoTooltip>
												);
											}}
										/>

										<FormController
											control={control}
											name={`list.${index}.maxWeight`}
											render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
												const error = Boolean(fieldState.error?.message);
												const invalid = isFieldOnError(error, item.validity, solvedWithAI);

												return (
													<AutoTooltip
														severity="error"
														position="left"
														mode="hover"
														disabled={solvedWithAI ? undefined : !invalid}
														overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
														trigger={({ innerRef }) => (
															<ReadonlyRelation classList="w-20 ml-auto" label="MAX" align="right">
																<NullableNumberInput
																	data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/maxWeight`}
																	innerRef={multiRef(ref, innerRef)}
																	{...fieldProps}
																	inputAppearance={styleInputAppearance(solvedWithAI)}
																	onBlur={() => {
																		onBlur();
																		onConstraintChange();
																		trigger(`list.${index}.minWeight`);
																	}}
																	invalid={invalid}
																	size="x-small"
																	rightContent={<Icon icon="Percentile" />}
																	placeholder="100"
																/>
															</ReadonlyRelation>
														)}
													>
														{fieldState.error?.message ?? (
															<Suggestion
																relation="MAX"
																suggestedMaxWeight={constraint.suggestedMaxWeight}
																suggestedMinWeight={constraint.suggestedMinWeight}
																solvedWithAI={solvedWithAI}
																maxWeight={constraint.maxWeight}
																minWeight={constraint.minWeight}
																originalValues={originalValues}
															/>
														)}
													</AutoTooltip>
												);
											}}
										/>
									</>
								)}
							</>
						))
						.with({ relation: "MAX" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="MAX" align="right">
									{constraint.maxWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.maxWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MAX" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/maxWeight`}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={invalid}
															inputAppearance={styleInputAppearance(solvedWithAI)}
															onBlur={() => {
																onBlur();
																onConstraintChange();
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="100"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation={constraint.relation}
														suggestedMaxWeight={constraint.suggestedMaxWeight}
														suggestedMinWeight={constraint.suggestedMinWeight}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.with({ relation: "MIN" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="MIN" align="right">
									{constraint.minWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.minWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={invalid}
															inputAppearance={styleInputAppearance(solvedWithAI)}
															onBlur={() => {
																onBlur();
																onConstraintChange();
																trigger(`list.${index}.maxWeight`);
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="0"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation={constraint.relation}
														suggestedMaxWeight={constraint.suggestedMaxWeight}
														suggestedMinWeight={constraint.suggestedMinWeight}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.with({ relation: "EQUAL" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="WEIGHT" align="right">
									{constraint.minWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.minWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={invalid}
															inputAppearance={styleInputAppearance(solvedWithAI)}
															onBlur={() => {
																onBlur();
																onConstraintChange();
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="0"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation={constraint.relation}
														suggestedMaxWeight={constraint.suggestedMaxWeight}
														suggestedMinWeight={constraint.suggestedMinWeight}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.otherwise(() => (
							<></>
						))}
				</div>
			</div>
		</>
	);
}
//#endregion

//#region Max weight on single instrument
function InstrumentMaxWeightColumn({
	index,
	item,
	control,
	editable,
	solvedWithAI,
	revertData,
	onConstraintChange,
}: ConstraintSpecificColumnsProps<EditablePortfolioConstraintHelper<"instrumentMaxWeightConstraint">> & {
	editable: boolean;
	trigger(fieldName: string): void;
	solvedWithAI: boolean;
	onConstraintChange(): void;
	revertData?: EditablePortfolioConstraintHelper<"instrumentMaxWeightConstraint">;
}) {
	const { formatNumber } = useLocaleFormatters();
	const originalValues = revertData && {
		relation: ConstraintRelation.Max,
		max: revertData.weight ?? null,
		min: null,
	};
	return (
		<div className="grow grid justify-end mr-4">
			<div
				className={toClassName({
					[titleClassName]: true,
					"text-right": true,
				})}
			>
				Weight
			</div>
			<div className="w-20 text-right">
				{item.readonly || !editable ? (
					`${formatNumber(item.weight, 2)}%`
				) : (
					<FormController
						control={control}
						name={`list.${index}.weight`}
						render={({ field: { ref: fieldRef, onBlur, ...fieldProps }, fieldState }) => {
							const error = Boolean(fieldState.error?.message);
							const invalid = isFieldOnError(error, item.validity, solvedWithAI);

							return (
								<AutoTooltip
									severity="error"
									position="left"
									mode="hover"
									disabled={solvedWithAI ? undefined : !invalid}
									overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
									trigger={({ innerRef }) => (
										<NullableNumberInput
											inputAppearance={{
												style: solvedWithAI ? { borderColor: themeCSSVars.palette_graph_B500 } : undefined,
											}}
											data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/weight`}
											{...fieldProps}
											onBlur={() => {
												onBlur();
												onConstraintChange();
											}}
											innerRef={multiRef(innerRef, fieldRef)}
											rightContent={<Icon icon="Percentile" />}
											size="x-small"
											placeholder="100"
											invalid={invalid}
										/>
									)}
								>
									{fieldState.error?.message ?? (
										<Suggestion
											relation="MAX"
											suggestedMinWeight={item.suggestedMinWeight}
											suggestedMaxWeight={item.suggestedMaxWeight}
											solvedWithAI={solvedWithAI}
											maxWeight={item.weight}
											minWeight={null}
											originalValues={originalValues}
										/>
									)}
								</AutoTooltip>
							);
						}}
					/>
				)}
			</div>
		</div>
	);
}
//#endregion

//#region Score Constraint
function ScoreColumn({
	index,
	item,
	control,
	editable,
	selectableScores,
	trigger,
	solvedWithAI,
	onConstraintChange,
	revertData,
}: ConstraintSpecificColumnsProps<EditablePortfolioConstraintHelper<"scoresConstraint">> & {
	editable: boolean;
	trigger(fieldName: string): void;
	selectableScores: SelectableAllocationConstraintsResponse["selectableScores"];
	solvedWithAI: boolean;
	onConstraintChange(): void;
	revertData?: EditablePortfolioConstraintHelper<"scoresConstraint">;
}) {
	const { formatNumber } = useLocaleFormatters();
	const { setValue } = useFormContext();
	const { t } = useTranslation();
	const originalValues = revertData && {
		relation: revertData.relation,
		max: revertData.maxWeight ?? null,
		min: revertData.minWeight ?? null,
	};

	const scoreInstrumentWithouthScorePolicy = useWatch({
		control,
		name: `list.${index}.instrumentWithouthScorePolicy`,
	});

	const scoreInstrumentWithouthScorePolicyMap: Record<InstrumentWithouthScorePolicy, string> = {
		EXCLUDE: "Exclude",
		INCLUDE_WITH_ZERO_AS_SCORE: "Include with 0 as score",
	};

	const customRelatedOptions = excludeRelationOption(["EXCLUDE"]);

	const setValueRef = useUnsafeUpdatedRef(setValue);
	const indexRef = useUnsafeUpdatedRef(index);
	useEffect(() => {
		if (selectableScores) {
			setValueRef.current(`list.${indexRef.current}.scoreIdentifiers`, selectableScores, {
				shouldDirty: true,
				shouldValidate: true,
			});
		}
	}, [selectableScores, setValueRef, indexRef]);

	return (
		<div className="flex grow gap-4 mr-4 justify-between">
			<div className="shrink-0">
				<div className={titleClassName}>Instrument without score</div>
				{item.readonly || !editable ? (
					scoreInstrumentWithouthScorePolicyMap[scoreInstrumentWithouthScorePolicy]
				) : (
					<FormController
						control={control}
						name={`list.${index}.instrumentWithouthScorePolicy`}
						render={({ field: { value, onChange } }) => (
							<div>
								<RadioGroup
									value={value}
									onChange={(v) => {
										onChange(v);
										onConstraintChange();
									}}
								>
									<div
										className="flex gap-2"
										data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/instrumentWithouthScorePolicy`}
									>
										<Radio
											data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/instrumentWithouthScorePolicy(exclude)`}
											value={enumInstrumentWithouthScorePolicy.Exclude}
										>
											Exclude
										</Radio>
										<Radio
											data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/instrumentWithouthScorePolicy(includeWithZero)`}
											value={enumInstrumentWithouthScorePolicy.IncludeWithZero}
										>
											Include with 0 as score
										</Radio>
									</div>
								</RadioGroup>
							</div>
						)}
					/>
				)}
			</div>
			<div className="shrink-0 flex space-x-4">
				<div className="w-28">
					{item.readonly ? (
						<ReadonlyRelation label="RANGE" classList="w-28">
							{t(`RELATION.${item.relation}`)}
						</ReadonlyRelation>
					) : (
						<FormController
							control={control}
							name={`list.${index}.relation`}
							render={({ field: { ref: fieldRef, ...fieldProps } }) => (
								<ReadonlyRelation label="RANGE" classList="w-28">
									<Select
										data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/relation`}
										{...fieldProps}
										innerRef={fieldRef}
										options={customRelatedOptions}
										size="x-small"
										onChange={(constraint) => {
											fieldProps.onChange(constraint);
											setValue(`list.${index}.maxWeight`, null);
											setValue(`list.${index}.minWeight`, null);
											onConstraintChange();
										}}
										classList="w-full"
									/>
								</ReadonlyRelation>
							)}
						/>
					)}
				</div>
				<div
					className={toClassName({
						"min-w-[196px] flex": true,
						"justify-end": item.relation !== "BETWEEN",
					})}
				>
					{match(item)
						.with({ relation: "BETWEEN" }, (constraint) => (
							<>
								{constraint.readonly ? (
									<>
										<ReadonlyRelation classList="w-20 ml-auto text-right" label="MIN" align="right">
											{formatNumber(constraint.minWeight, 2)}%
										</ReadonlyRelation>
										<ReadonlyRelation classList="w-20 ml-auto text-right" label="MAX" align="right">
											{formatNumber(constraint.maxWeight, 2)}%
										</ReadonlyRelation>
									</>
								) : (
									<>
										<FormController
											control={control}
											name={`list.${index}.minWeight`}
											render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
												const error = Boolean(fieldState.error?.message);
												const invalid = isFieldOnError(error, item.validity, solvedWithAI);

												return (
													<AutoTooltip
														severity="error"
														position="left"
														mode="hover"
														disabled={solvedWithAI ? undefined : !invalid}
														overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
														trigger={({ innerRef }) => (
															<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
																<NullableNumberInput
																	data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
																	innerRef={multiRef(ref, innerRef)}
																	{...fieldProps}
																	inputAppearance={styleInputAppearance(solvedWithAI)}
																	onBlur={() => {
																		onBlur();
																		onConstraintChange();
																		trigger(`list.${index}.maxWeight`);
																	}}
																	invalid={invalid}
																	size="x-small"
																	rightContent={<Icon icon="Percentile" />}
																	placeholder="0"
																/>
															</ReadonlyRelation>
														)}
													>
														{fieldState.error?.message ?? (
															<Suggestion
																relation="MIN"
																suggestedMaxWeight={constraint.suggestedMaxWeight}
																suggestedMinWeight={constraint.suggestedMinWeight}
																solvedWithAI={solvedWithAI}
																maxWeight={constraint.maxWeight}
																minWeight={constraint.minWeight}
																originalValues={originalValues}
															/>
														)}
													</AutoTooltip>
												);
											}}
										/>

										<FormController
											control={control}
											name={`list.${index}.maxWeight`}
											render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
												const error = Boolean(fieldState.error?.message);
												const invalid = isFieldOnError(error, item.validity, solvedWithAI);

												return (
													<AutoTooltip
														severity="error"
														position="left"
														mode="hover"
														disabled={solvedWithAI ? undefined : !invalid}
														overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
														trigger={({ innerRef }) => (
															<ReadonlyRelation classList="w-20 ml-auto" label="MAX" align="right">
																<NullableNumberInput
																	data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/maxWeight`}
																	innerRef={multiRef(ref, innerRef)}
																	{...fieldProps}
																	inputAppearance={styleInputAppearance(solvedWithAI)}
																	onBlur={() => {
																		onBlur();
																		onConstraintChange();
																		trigger(`list.${index}.minWeight`);
																	}}
																	invalid={invalid}
																	size="x-small"
																	rightContent={<Icon icon="Percentile" />}
																	placeholder="100"
																/>
															</ReadonlyRelation>
														)}
													>
														{fieldState.error?.message ?? (
															<Suggestion
																relation="MAX"
																suggestedMaxWeight={constraint.suggestedMaxWeight}
																suggestedMinWeight={constraint.suggestedMinWeight}
																solvedWithAI={solvedWithAI}
																maxWeight={constraint.maxWeight}
																minWeight={constraint.minWeight}
																originalValues={originalValues}
															/>
														)}
													</AutoTooltip>
												);
											}}
										/>
									</>
								)}
							</>
						))
						.with({ relation: "MAX" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="MAX" align="right">
									{constraint.maxWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.maxWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MAX" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/maxWeight`}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={invalid}
															inputAppearance={styleInputAppearance(solvedWithAI)}
															onBlur={() => {
																onBlur();
																onConstraintChange();
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="100"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation={constraint.relation}
														suggestedMaxWeight={constraint.suggestedMaxWeight}
														suggestedMinWeight={constraint.suggestedMinWeight}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.with({ relation: "MIN" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="MIN" align="right">
									{constraint.minWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.minWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={invalid}
															inputAppearance={styleInputAppearance(solvedWithAI)}
															onBlur={() => {
																onBlur();
																onConstraintChange();
																trigger(`list.${index}.maxWeight`);
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="0"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation={constraint.relation}
														suggestedMaxWeight={constraint.suggestedMaxWeight}
														suggestedMinWeight={constraint.suggestedMinWeight}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.with({ relation: "EQUAL" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="WEIGHT" align="right">
									{constraint.minWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.minWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="WEIGHT" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={invalid}
															inputAppearance={styleInputAppearance(solvedWithAI)}
															onBlur={() => {
																onBlur();
																onConstraintChange();
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="0"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation={constraint.relation}
														suggestedMaxWeight={constraint.suggestedMaxWeight}
														suggestedMinWeight={constraint.suggestedMinWeight}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.otherwise(() => (
							<></>
						))}
				</div>
			</div>
		</div>
	);
}
//#endregion

//#region Lock instruments Constraint
function LockInstrumentGroupColumn({
	control,
	index,
	item,
	selectableLockableInstruments,
	editable,
	solvedWithAI,
	onConstraintChange,
}: ConstraintSpecificColumnsProps<EditablePortfolioConstraintHelper<"lockInstrumentGroup">> & {
	selectableLockableInstruments: SelectableAllocationConstraintsResponse["selectableLockableInstruments"];
	editable: boolean;
	solvedWithAI: boolean;
	onConstraintChange(): void;
}) {
	const { t } = useTranslation();

	return (
		<div className="flex-1">
			<div className="max-w-[180px] min-[1820px]:max-w-[300px] overflow-hidden pr-4">
				<div className={titleClassName}>Instruments</div>
				{item.readonly || !editable ? (
					<EllipsisText text={instrumentGroupToLabel(item.tickerIdentifiers)} />
				) : (
					<FormController
						control={control}
						name={`list.${index}.tickerIdentifiers`}
						render={({ field: { onChange: onSubmit, ref: fieldRef, value: selected }, fieldState }) => {
							const error = Boolean(fieldState.error?.message);
							const invalid = isFieldOnError(error, item.validity, solvedWithAI);
							function removeUnfeasibleInstruments(instruments: Array<ConstrainedTicker>) {
								onSubmit(instruments.filter((x) => x.feasible));
								onConstraintChange();
							}
							const unfeasibleConstraint = selected.filter((x) => x.feasible === false);
							const activeSelection = selected.filter((x) => !x.deleted);

							return (
								<div className="flex space-x-2 items-end">
									<AutoTooltip
										severity="error"
										position="left"
										mode="hover"
										disabled={!error}
										overrideColor={!solvedWithAI || error ? undefined : themeCSSVars.palette_graph_B500}
										trigger={({ innerRef }) => (
											<ActionText
												onClick={() =>
													spawnInstrumentDialog({
														instruments: selectableLockableInstruments ?? [],
														rowClassList: `portfolioConstraintsTable/constraint(${item.type},${index})/row`,
														selected: activeSelection,
														onSubmit: (instruments) => {
															const deletedInstruments = selected.filter((x) => x.deleted);
															const joinedInstruments = [...deletedInstruments, ...instruments];
															const listWithUniqueInstruments = joinedInstruments.reduce((acc, instrument) => {
																if (!acc.has(instrument.ticker)) {
																	return acc.set(instrument.ticker, instrument);
																}

																return acc.set(instrument.ticker, { ...instrument, deleted: false });
															}, Map());
															onSubmit(Array.from(listWithUniqueInstruments.values()));
															onConstraintChange();
														},
													})
												}
												innerRef={multiRef(innerRef, fieldRef)}
												classList={{
													"truncate pt-[2px] pb-[4px] !leading-none": true,
													[`!text-[color:${themeCSSVars.palette_P400}]`]: selected.length > 0 && !invalid,
													[`!text-[color:${themeCSSVars.global_palette_danger_600}]`]: invalid,
													[`!text-[color:${themeCSSVars.palette_graph_B500}]`]: solvedWithAI && !error,
												}}
											>
												{instrumentGroupToLabel(activeSelection)}
											</ActionText>
										)}
									>
										{fieldState.error?.message}
									</AutoTooltip>
									{item.validity === ConstraintValidity.Invalid &&
										activeSelection.some((x) => x.feasible === false) &&
										!solvedWithAI && (
											<AutoTooltip
												severity="warning"
												position="right"
												mode="hover"
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<Icon
														icon={solvedWithAI ? "Ask-ai" : "Icon-full-alert"}
														color={solvedWithAI ? themeCSSVars.palette_graph_B500 : themeCSSVars.palette_W500}
														innerRef={innerRef}
														size={16}
													/>
												)}
											>
												<TooltipContent>
													<div className="flex space-x-1 mb-2">
														<Icon
															icon={solvedWithAI ? "Ask-ai" : "Icon-full-alert"}
															color={solvedWithAI ? themeCSSVars.palette_graph_B500 : themeCSSVars.palette_W500}
															size={16}
														/>
														<Text as="span" type="Body/M/Bold">
															Unfeasible constraints
														</Text>
													</div>
													{unfeasibleConstraint.length === 0 ? (
														<>
															<Text as="div" type="Body/M/Book" classList="mb-2">
																Remove the constraint or change the constraints order.
															</Text>
															<Text as="div" type="Body/M/Book" classList="mb-2 italic">
																Current instrument weight: {unfeasibleConstraint[0]?.weight}%
															</Text>
														</>
													) : (
														<>
															<Text as="div" type="Body/M/Book" classList="mb-2">
																One of the values in the group is not feasible.
															</Text>
															<ul className="list-disc pl-4 mb-4">
																<ForEach collection={selected.filter((x) => x.feasible === false)}>
																	{({ item: subItem }) => <li>{subItem.instrument}</li>}
																</ForEach>
															</ul>
															<Text as="p" type="Body/M/Book">
																You can solve it by removing the lock constraint on the highlighted instruments.{" "}
																<ActionText
																	classList="inline-block"
																	onClick={() => removeUnfeasibleInstruments(activeSelection)}
																>
																	{t("BUTTON.REMOVE")}
																</ActionText>
															</Text>{" "}
														</>
													)}
												</TooltipContent>
											</AutoTooltip>
										)}
								</div>
							);
						}}
					/>
				)}
			</div>
		</div>
	);
}
//#endregion

//#region For each instruments Constraint
function ForEachConstraintColumn({
	index,
	item,
	selectableInstruments,
	editable,
	control,
	solvedWithAI, // TODO
	onConstraintChange,
	trigger,
	revertData,
}: ConstraintSpecificColumnsProps<EditablePortfolioConstraintHelper<"forEachConstraint">> & {
	selectableInstruments: SelectableAllocationConstraintsResponse["selectableLockableInstruments"];
	editable: boolean;
	solvedWithAI: boolean;
	onConstraintChange(): void;
	trigger(fieldName: string): void;
	revertData?: EditablePortfolioConstraintHelper<"forEachConstraint">;
}) {
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();
	const { setValue } = useFormContext();

	const originalValues = revertData && {
		relation: revertData.relation,
		max: revertData.maxWeight ?? null,
		min: revertData.minWeight ?? null,
	};

	const showSuggestionTooltip =
		revertData?.tickerIdentifiers !== undefined && revertData.tickerIdentifiers.length === 1;

	return (
		<div className="flex-1 flex">
			<div className="max-w-[180px] min-[1820px]:max-w-[300px] overflow-hidden pr-4">
				<div className={titleClassName}>Instruments</div>
				{item.readonly || !editable ? (
					<EllipsisText text={instrumentGroupToLabel(item.tickerIdentifiers)} />
				) : (
					<FormController
						control={control}
						name={`list.${index}.tickerIdentifiers`}
						render={({ field: { onChange: onSubmit, ref: fieldRef, value: selected }, fieldState }) => {
							const error = Boolean(fieldState.error?.message);
							function removeUnfeasibleInstruments(instruments: Array<ConstrainedTicker>) {
								onSubmit(instruments.filter((x) => x.feasible));
								trigger(`list.${index}.tickerIdentifiers`);
								onConstraintChange();
							}

							const activeSelection = selected.filter((x) => !x.deleted);

							return (
								<div className="flex space-x-2 items-end">
									<AutoTooltip
										severity="error"
										position="left"
										mode="hover"
										disabled={!error}
										overrideColor={!solvedWithAI || error ? undefined : themeCSSVars.palette_graph_B500}
										trigger={({ innerRef }) => (
											<ActionText
												onClick={() =>
													spawnInstrumentDialog({
														instruments: selectableInstruments ?? [],
														rowClassList: `portfolioConstraintsTable/constraint(${item.type},${index})/row`,
														selected: activeSelection,
														onSubmit: (instruments) => {
															const deletedInstruments = selected.filter((x) => x.deleted);
															const joinedInstruments = [...deletedInstruments, ...instruments];
															const listWithUniqueInstruments = joinedInstruments.reduce((acc, instrument) => {
																if (!acc.has(instrument.ticker)) {
																	return acc.set(instrument.ticker, instrument);
																}

																return acc.set(instrument.ticker, { ...instrument, deleted: false });
															}, Map());
															onSubmit(Array.from(listWithUniqueInstruments.values()));
															onConstraintChange();
														},
													})
												}
												innerRef={multiRef(innerRef, fieldRef)}
												classList={{
													"truncate pt-[2px] pb-[4px] !leading-none": true,
													[`!text-[color:${themeCSSVars.palette_P400}]`]: activeSelection.length > 0 && !error,
													[`!text-[color:${themeCSSVars.global_palette_danger_600}]`]: error,
													[`!text-[color:${themeCSSVars.palette_graph_B500}]`]: solvedWithAI && !error,
												}}
											>
												{instrumentGroupToLabel(activeSelection)}
											</ActionText>
										)}
									>
										{fieldState.error?.message}
									</AutoTooltip>
									{item.validity === ConstraintValidity.Invalid &&
										activeSelection.some((x) => x.feasible === false) &&
										!solvedWithAI && (
											<AutoTooltip
												severity="warning"
												position="right"
												mode="hover"
												trigger={({ innerRef }) => (
													<Icon
														icon="Icon-full-alert"
														color={themeCSSVars.palette_W500}
														innerRef={innerRef}
														size={16}
													/>
												)}
											>
												<TooltipContent>
													<div className="flex space-x-1 mb-2">
														<Icon icon="Icon-full-alert" color={themeCSSVars.palette_W500} size={16} />
														<Text as="span" type="Body/M/Bold">
															Unfeasible constraints
														</Text>
													</div>
													<Text as="div" type="Body/M/Book" classList="mb-2">
														One of the values in the group is not feasible.
													</Text>
													<ul className="list-disc pl-4 mb-4">
														<ForEach collection={activeSelection}>
															{({ item: { instrument } }) => <li>{instrument}</li>}
														</ForEach>
													</ul>
													<Text as="p" type="Body/M/Book">
														You can solve it by removing the lock constraint on the highlighted instruments.{" "}
														<ActionText
															classList="inline-block"
															onClick={() => removeUnfeasibleInstruments(activeSelection)}
														>
															{t("BUTTON.REMOVE")}
														</ActionText>
													</Text>
												</TooltipContent>
											</AutoTooltip>
										)}
								</div>
							);
						}}
					/>
				)}
			</div>
			<div className="flex-1 flex space-x-4 mr-4 justify-end">
				<div className="w-28">
					{item.readonly ? (
						<ReadonlyRelation label="RANGE" classList="w-28">
							{t(`RELATION.${item.relation}`)}
						</ReadonlyRelation>
					) : (
						<FormController
							control={control}
							name={`list.${index}.relation`}
							render={({ field: { ref: fieldRef, ...fieldProps } }) => (
								<ReadonlyRelation label="RANGE" classList="w-28">
									<Select
										data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/relation`}
										{...fieldProps}
										innerRef={fieldRef}
										options={relationOptions}
										size="x-small"
										onChange={(constraint) => {
											fieldProps.onChange(constraint);
											setValue(`list.${index}.maxWeight`, null);
											setValue(`list.${index}.minWeight`, null);
											onConstraintChange();
										}}
										classList="w-full"
									/>
								</ReadonlyRelation>
							)}
						/>
					)}
				</div>
				<div
					className={toClassName({
						"min-w-[196px] flex": true,
						"justify-end": item.relation !== "BETWEEN",
					})}
				>
					{match(item)
						.with({ relation: "BETWEEN" }, (constraint) => (
							<>
								{constraint.readonly ? (
									<>
										<ReadonlyRelation classList="w-20 ml-auto text-right" label="MIN" align="right">
											{formatNumber(constraint.minWeight, 2)}%
										</ReadonlyRelation>
										<ReadonlyRelation classList="w-20 ml-auto text-right" label="MAX" align="right">
											{formatNumber(constraint.maxWeight, 2)}%
										</ReadonlyRelation>
									</>
								) : (
									<>
										<FormController
											control={control}
											name={`list.${index}.minWeight`}
											render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
												const error = Boolean(fieldState.error?.message);
												const invalid = isFieldOnError(error, item.validity, solvedWithAI);

												return (
													<AutoTooltip
														severity="error"
														position="left"
														mode="hover"
														disabled={solvedWithAI && showSuggestionTooltip ? undefined : !invalid}
														overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
														trigger={({ innerRef }) => (
															<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
																<NullableNumberInput
																	data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
																	inputAppearance={styleInputAppearance(solvedWithAI && showSuggestionTooltip)}
																	innerRef={multiRef(ref, innerRef)}
																	{...fieldProps}
																	invalid={error}
																	onBlur={() => {
																		onBlur();
																		onConstraintChange();
																		trigger(`list.${index}.maxWeight`);
																	}}
																	size="x-small"
																	rightContent={<Icon icon="Percentile" />}
																	placeholder="0"
																/>
															</ReadonlyRelation>
														)}
													>
														{fieldState.error?.message ?? (
															<Suggestion
																relation="MIN"
																suggestedMaxWeight={constraint.suggestedMaxWeight ?? null}
																suggestedMinWeight={constraint.suggestedMinWeight ?? null}
																solvedWithAI={solvedWithAI}
																maxWeight={constraint.maxWeight}
																minWeight={constraint.minWeight}
																originalValues={originalValues}
															/>
														)}
													</AutoTooltip>
												);
											}}
										/>

										<FormController
											control={control}
											name={`list.${index}.maxWeight`}
											render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
												const error = Boolean(fieldState.error?.message);
												const invalid = isFieldOnError(error, item.validity, solvedWithAI);

												return (
													<AutoTooltip
														severity="error"
														position="left"
														mode="hover"
														disabled={solvedWithAI && showSuggestionTooltip ? undefined : !invalid}
														overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
														trigger={({ innerRef }) => (
															<ReadonlyRelation classList="w-20 ml-auto" label="MAX" align="right">
																<NullableNumberInput
																	data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/maxWeight`}
																	inputAppearance={styleInputAppearance(solvedWithAI && showSuggestionTooltip)}
																	innerRef={multiRef(ref, innerRef)}
																	{...fieldProps}
																	invalid={error}
																	onBlur={() => {
																		onBlur();
																		onConstraintChange();
																		trigger(`list.${index}.minWeight`);
																	}}
																	size="x-small"
																	rightContent={<Icon icon="Percentile" />}
																	placeholder="100"
																/>
															</ReadonlyRelation>
														)}
													>
														{fieldState.error?.message ?? (
															<Suggestion
																relation="MAX"
																suggestedMaxWeight={constraint.suggestedMaxWeight ?? null}
																suggestedMinWeight={constraint.suggestedMinWeight ?? null}
																solvedWithAI={solvedWithAI}
																maxWeight={constraint.maxWeight}
																minWeight={constraint.minWeight}
																originalValues={originalValues}
															/>
														)}
													</AutoTooltip>
												);
											}}
										/>
									</>
								)}
							</>
						))
						.with({ relation: "MAX" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="MAX" align="right">
									{constraint.maxWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.maxWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI && showSuggestionTooltip ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MAX" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/maxWeight`}
															inputAppearance={styleInputAppearance(solvedWithAI && showSuggestionTooltip)}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={error}
															onBlur={() => {
																onBlur();
																onConstraintChange();
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="100"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation="MAX"
														suggestedMaxWeight={constraint.suggestedMaxWeight ?? null}
														suggestedMinWeight={constraint.suggestedMinWeight ?? null}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.with({ relation: "MIN" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="MIN" align="right">
									{constraint.minWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.minWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI && showSuggestionTooltip ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
															inputAppearance={styleInputAppearance(solvedWithAI && showSuggestionTooltip)}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={error}
															onBlur={() => {
																onBlur();
																onConstraintChange();
																trigger(`list.${index}.maxWeight`);
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="0"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation="MIN"
														suggestedMaxWeight={constraint.suggestedMaxWeight ?? null}
														suggestedMinWeight={constraint.suggestedMinWeight ?? null}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.with({ relation: "EQUAL" }, (constraint) =>
							constraint.readonly ? (
								<ReadonlyRelation classList="w-20 ml-auto text-right" label="WEIGHT" align="right">
									{constraint.minWeight}%
								</ReadonlyRelation>
							) : (
								<FormController
									control={control}
									name={`list.${index}.minWeight`}
									render={({ field: { ref, onBlur, ...fieldProps }, fieldState }) => {
										const error = Boolean(fieldState.error?.message);
										const invalid = isFieldOnError(error, item.validity, solvedWithAI);

										return (
											<AutoTooltip
												severity="error"
												position="left"
												mode="hover"
												disabled={solvedWithAI && showSuggestionTooltip ? undefined : !invalid}
												overrideColor={!solvedWithAI ? undefined : themeCSSVars.palette_graph_B500}
												trigger={({ innerRef }) => (
													<ReadonlyRelation classList="w-20 ml-auto" label="MIN" align="right">
														<NullableNumberInput
															data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/minWeight`}
															inputAppearance={styleInputAppearance(solvedWithAI && showSuggestionTooltip)}
															innerRef={multiRef(ref, innerRef)}
															{...fieldProps}
															invalid={error}
															onBlur={() => {
																onBlur();
																onConstraintChange();
															}}
															size="x-small"
															rightContent={<Icon icon="Percentile" />}
															placeholder="0"
														/>
													</ReadonlyRelation>
												)}
											>
												{fieldState.error?.message ?? (
													<Suggestion
														relation="EQUAL"
														suggestedMaxWeight={constraint.suggestedMaxWeight ?? null}
														suggestedMinWeight={constraint.suggestedMinWeight ?? null}
														solvedWithAI={solvedWithAI}
														maxWeight={constraint.maxWeight}
														minWeight={constraint.minWeight}
														originalValues={originalValues}
													/>
												)}
											</AutoTooltip>
										);
									}}
								/>
							),
						)
						.otherwise(() => (
							<></>
						))}
				</div>
			</div>
		</div>
	);
}
//#endregion

function PortfolioConstraintListItem({
	index,
	control,
	item,
	selectableData,
	updatedSelectabelData,
	editable,
	trigger,
	revertData,
	...forward
}: ConstraintSpecificColumnsProps<EditablePortfolioConstraintHelper<keyof PortfolioConstraintTypePayloadMap>> & {
	selectableData: SelectableAllocationConstraintsResponse | undefined;
	updatedSelectabelData: UpdatedSelectabelData;
	editable: boolean;
	trigger(fieldName: string): void;
	solvedWithAI: boolean;
	onConstraintChange(): void;
	revertData?: EditablePortfolioConstraintHelper<keyof PortfolioConstraintTypePayloadMap>;
}): JSX.Element {
	// TODO: need translation
	const constraintCategoryToLabel = useMemo<Record<ConstraintCategory, { label: string | ReactNode; tooltip: string }>>(
		() => ({
			assetClassConstraints: {
				label: "Asset allocation",
				tooltip:
					"Sets weights to different asset classes filtered by granularity, like a specific equity sector or geography",
			},
			instrumentConstraints: {
				label: "Group instrument constraint",
				tooltip: "Set weights to a single instrument or groups of specific instruments.",
			},
			instrumentMaxWeightConstraint: {
				label: "Max weights on single instrument",
				tooltip: "Set the maximum weights to be allocated to every single portfolio instrument",
			},
			tagConstraints: { label: "Tag", tooltip: "Sets weights to your already assigned universe tags." },
			currencyConstraints: { label: "Currency", tooltip: "Sets the currency for this portfolio." },
			scoresConstraint: {
				label: (
					<>
						Average <CustomLabels labelKey={selectableData?.selectableScores?.[0] ?? ""} fallback="score" />
					</>
				),
				tooltip: "Sets score to your instruments.",
			},
			lockInstrumentGroup: {
				label: "Lock instrument",
				tooltip: "This constraint will restrain the instrument in its current weight",
			},
			forEachConstraint: {
				label: "Single instrument constraint",
				tooltip: "set weights that will be applied to all the instruments in the selection",
			},
		}),
		[selectableData?.selectableScores],
	);

	return (
		<>
			<div className="flex mr-8 w-[140px]">
				<div className="flex-1">
					<div className={titleClassName}>Constraint</div>
					<div>
						<AutoTooltip
							position="left"
							overrideColor={themeCSSVars.global_palette_neutral_300}
							trigger={({ innerRef }) => (
								<span className="hover:font-medium hover:underline" ref={innerRef}>
									{constraintCategoryToLabel[constraintTypeToCategory[item.type]].label}
								</span>
							)}
						>
							<TooltipContent>
								<div className="font-bold mb-2">
									{constraintCategoryToLabel[constraintTypeToCategory[item.type]].label}
								</div>
								<div>{constraintCategoryToLabel[constraintTypeToCategory[item.type]].tooltip}</div>
							</TooltipContent>
						</AutoTooltip>
					</div>
				</div>
				<div>
					{forward.solvedWithAI && (
						<Icon classList="ml-auto mt-[26px]" icon="Ask-ai" size={16} color={themeCSSVars.palette_graph_B500} />
					)}
				</div>
			</div>
			{(function () {
				switch (item.type) {
					case "assetClassConstraints":
						return (
							<>
								<AssetAllocationColumns
									index={index}
									item={item}
									control={
										control as ConstraintSpecificColumnsProps<
											EditablePortfolioConstraintHelper<"assetClassConstraints">
										>["control"]
									}
									selectableAssetClasses={selectableData?.selectableAssetClasses}
									updatedSelectabelData={updatedSelectabelData}
									editable={editable}
									trigger={trigger}
									revertData={revertData as EditablePortfolioConstraintHelper<"assetClassConstraints"> | undefined}
									{...forward}
								/>
							</>
						);
					case "instrumentConstraints":
						return (
							<InstrumentConstraintsColumns
								index={index}
								item={item}
								control={
									control as ConstraintSpecificColumnsProps<
										EditablePortfolioConstraintHelper<"instrumentConstraints">
									>["control"]
								}
								selectableInstruments={selectableData?.selectableInstruments}
								updatedSelectabelData={updatedSelectabelData}
								editable={editable}
								trigger={trigger}
								revertData={revertData as EditablePortfolioConstraintHelper<"instrumentConstraints"> | undefined}
								{...forward}
							/>
						);
					case "tagConstraints":
						return (
							<>
								<TagColumns
									index={index}
									item={item}
									control={
										control as ConstraintSpecificColumnsProps<
											EditablePortfolioConstraintHelper<"tagConstraints">
										>["control"]
									}
									selectableTags={selectableData?.selectableTags}
									updatedSelectabelData={updatedSelectabelData}
									editable={editable}
									trigger={trigger}
									revertData={revertData as EditablePortfolioConstraintHelper<"tagConstraints"> | undefined}
									{...forward}
								/>
							</>
						);
					case "lockInstrumentGroup":
						return (
							<LockInstrumentGroupColumn
								index={index}
								item={item}
								control={
									control as ConstraintSpecificColumnsProps<
										EditablePortfolioConstraintHelper<"lockInstrumentGroup">
									>["control"]
								}
								selectableLockableInstruments={selectableData?.selectableLockableInstruments}
								editable={editable}
								{...forward}
							/>
						);
					case "forEachConstraint":
						return (
							<ForEachConstraintColumn
								index={index}
								item={item}
								control={
									control as ConstraintSpecificColumnsProps<
										EditablePortfolioConstraintHelper<"forEachConstraint">
									>["control"]
								}
								selectableInstruments={selectableData?.selectableInstruments}
								editable={editable}
								trigger={trigger}
								revertData={revertData as EditablePortfolioConstraintHelper<"forEachConstraint"> | undefined}
								{...forward}
							/>
						);
					case "instrumentMaxWeightConstraint":
						return (
							<InstrumentMaxWeightColumn
								index={index}
								item={item}
								control={
									control as ConstraintSpecificColumnsProps<
										EditablePortfolioConstraintHelper<"instrumentMaxWeightConstraint">
									>["control"]
								}
								editable={editable}
								trigger={trigger}
								revertData={
									revertData as EditablePortfolioConstraintHelper<"instrumentMaxWeightConstraint"> | undefined
								}
								{...forward}
							/>
						);
					case "scoresConstraint":
						return (
							<ScoreColumn
								index={index}
								item={item}
								control={
									control as ConstraintSpecificColumnsProps<
										EditablePortfolioConstraintHelper<"scoresConstraint">
									>["control"]
								}
								editable={editable}
								trigger={trigger}
								selectableScores={selectableData?.selectableScores}
								revertData={revertData as EditablePortfolioConstraintHelper<"scoresConstraint"> | undefined}
								{...forward}
							/>
						);
					case "currencyConstraints":
						return (
							<CurrencyColumn
								index={index}
								item={item}
								control={
									control as ConstraintSpecificColumnsProps<
										EditablePortfolioConstraintHelper<"currencyConstraints">
									>["control"]
								}
								editable={editable}
								trigger={trigger}
								selectableCurrencies={selectableData?.selectableCurrencies}
								updatedSelectabelData={updatedSelectabelData}
								revertData={revertData as EditablePortfolioConstraintHelper<"currencyConstraints"> | undefined}
								{...forward}
							/>
						);
				}
			})()}
			{item.type !== "lockInstrumentGroup" && (
				<div className="flex space-x-4 ml-auto">
					<div className="">
						<div className={titleClassName}>Soft constraint</div>
						<div className="flex justify-center">
							<FormController
								control={control}
								name={`list.${index}.target`}
								render={({ field: { ref: fieldRef, onChange, value, onBlur } }) => {
									// TODO: Actually needed for Research, this constraint is always Soft
									return (
										<Checkbox
											data-qualifier={`portfolioWizard/portfolioConstraints/constraint(${item.type},${index})/target`}
											disabled={item.readonly || !editable}
											checked={value}
											innerRef={fieldRef}
											onChange={(target) => {
												onChange(target);
												onBlur();
											}}
										/>
									);
								}}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

//#endregion
function Suggestion(props: {
	suggestedMaxWeight: number | null;
	suggestedMinWeight: number | null;
	maxWeight: number | null;
	minWeight: number | null;
	relation: ConstraintRelation;
	solvedWithAI: boolean;
	originalValues?: { relation: ConstraintRelation; min: number | null; max: number | null };
}) {
	const { formatNumber } = useLocaleFormatters();

	const constraintSubtitle = match(props)
		.with({ relation: "MIN" }, (s) => <p>Closest feasible solution {formatNumber(s.suggestedMinWeight, 2)}%</p>)
		.with({ relation: "MAX" }, (s) => <p>Closest feasible solution {formatNumber(s.suggestedMaxWeight, 2)}%</p>)
		.with({ relation: "BETWEEN" }, (s) => (
			<>
				<p>
					Closest feasible solution - <i>Min</i>: {formatNumber(s.suggestedMinWeight, 2)}%
				</p>
				<p>
					Closest feasible solution - <i>Max</i>: {formatNumber(s.suggestedMaxWeight, 2)}%
				</p>
			</>
		))
		.with({ relation: "EQUAL" }, (s) =>
			s.suggestedMinWeight !== null ? (
				<p>Closest feasible solution {formatNumber(s.suggestedMinWeight, 2)}%</p>
			) : s.suggestedMaxWeight !== null ? (
				<p>Closest feasible solution {formatNumber(s.suggestedMaxWeight, 2)}%</p>
			) : (
				<></>
			),
		)
		.with({ relation: "EXCLUDE" }, () => <></>)
		.exhaustive();

	if (props.solvedWithAI) {
		return (
			<TooltipContent>
				<div className="flex gap-2">
					<Icon icon="Ask-ai" color={themeCSSVars.palette_B500} />
					<Text as="span" type="Body/M/Bold">
						Feasibility Adjustments Applied
					</Text>
				</div>
				{props.originalValues && (
					<p>
						{`Original values - ${switchExpr(props.relation, {
							BETWEEN: () =>
								`Min: ${formatNumber(props.originalValues?.min)}%, Max: ${formatNumber(props.originalValues?.max)}%`,
							EQUAL: () => `Equal: ${formatNumber(props.originalValues?.min)}%`,
							EXCLUDE: () =>
								`Min: ${formatNumber(props.originalValues?.min)}%, Max: ${formatNumber(props.originalValues?.max)}%`,
							MAX: () => `Max: ${formatNumber(props.originalValues?.max)}%`,
							MIN: () => `Min: ${formatNumber(props.originalValues?.min)}%`,
						})}`}
					</p>
				)}
				{constraintSubtitle}
			</TooltipContent>
		);
	}

	return (
		<TooltipContent>
			<p className="font-bold mb-2">Constraint not feasible</p>
			{constraintSubtitle}
		</TooltipContent>
	);
}

function ReadonlyRelation(props: {
	label: "MAX" | "MIN" | "WEIGHT" | "RANGE";
	children: NodeOrFn;
	classList?: ClassList;
	align?: "right" | "left";
}) {
	const { t } = useTranslation();
	const { align = "left" } = props;
	return (
		<div className={toClassName(props.classList)}>
			<Text
				type="Body/M/Book"
				as="p"
				classList={{
					"pb-2": true,
					"text-right": align === "right",
					"text-left": align === "left",
				}}
			>
				{t(props.label)}
			</Text>
			{renderNodeOrFn(props.children)}
		</div>
	);
}

function styleInputAppearance(ai: boolean): StylableProps | undefined {
	return ai ? { style: { borderColor: themeCSSVars.palette_graph_B500 } } : undefined;
}

function isFieldOnError(error: boolean, validity: ConstraintValidity, solvedWithAI: boolean): boolean {
	if (solvedWithAI) {
		return error && validity === ConstraintValidity.Invalid;
	}

	return error || validity === ConstraintValidity.Invalid;
}

function applyAISolution<T extends EditablePortfolioConstraint>(el: T): MaybeArray<any> {
	switch (el.type) {
		case "assetClassConstraints":
		case "instrumentConstraints":
		case "tagConstraints":
		case "currencyConstraints":
			return {
				...el,
				minWeight: el.suggestedMinWeight,
				maxWeight: el.suggestedMaxWeight,
				validity: ConstraintValidity.Invalid,
			} satisfies T;
		case "instrumentMaxWeightConstraint":
			return {
				...el,
				weight: el.suggestedMaxWeight,
				validity: ConstraintValidity.Invalid,
			} satisfies T;
		case "scoresConstraint":
			return {
				...el,
				minWeight: el.suggestedMinWeight,
				maxWeight: el.suggestedMaxWeight,
				instrumentWithouthScoreConsideration: enumInstrumentWithouthScorePolicy.Exclude,
				validity: ConstraintValidity.Invalid,
			} satisfies T;
		case "forEachConstraint": {
			if (el.tickerIdentifiers.length === 1) {
				return [
					{
						...el,
						maxWeight: el.tickerIdentifiers[0]?.suggestedMaxWeight ?? el.maxWeight,
						minWeight: el.tickerIdentifiers[0]?.suggestedMinWeight ?? el.minWeight,
						suggestedMaxWeight: el.tickerIdentifiers[0]?.suggestedMaxWeight,
						suggestedMinWeight: el.tickerIdentifiers[0]?.suggestedMinWeight,
						validity: ConstraintValidity.Invalid,
					} satisfies EditablePortfolioConstraintHelper<"forEachConstraint">,
				];
			}

			const unfeasibleConstraints = el.tickerIdentifiers.filter((x) => !x.feasible);
			const splittedConstraint = unfeasibleConstraints.map((x) => ({
				id: generateUniqueDOMId(),
				type: el.type,
				identifier: null,
				tickerIdentifiers: [x],
				maxWeight: x.suggestedMaxWeight,
				minWeight: x.suggestedMinWeight,
				relation: el.relation,
				suggestedMaxWeight: x.suggestedMaxWeight,
				suggestedMinWeight: x.suggestedMinWeight,
				validity: ConstraintValidity.Invalid,
				target: false,
			}));
			return [
				{
					...el,
					tickerIdentifiers: el.tickerIdentifiers.map((x) => ({ ...x, deleted: !x.feasible })),
					validity: ConstraintValidity.Invalid,
				} satisfies T,
				...splittedConstraint,
			];
		}
		case "lockInstrumentGroup": {
			const unfeasibleConstraints = el.tickerIdentifiers.filter((x) => !x.feasible);
			const splittedConstraint = unfeasibleConstraints.map((x) => ({
				id: generateUniqueDOMId(),
				type: "forEachConstraint",
				identifier: null,
				tickerIdentifiers: [x],
				maxWeight: x.suggestedMaxWeight,
				minWeight: x.suggestedMinWeight,
				relation: el.relation,
				suggestedMaxWeight: x.suggestedMaxWeight,
				suggestedMinWeight: x.suggestedMinWeight,
				validity: ConstraintValidity.Invalid,
				target: false,
			}));

			return [
				{
					...el,
					tickerIdentifiers: el.tickerIdentifiers.map((x) => ({ ...x, deleted: !x.feasible })),
					validity: ConstraintValidity.Invalid,
				} satisfies T,
				...splittedConstraint,
			];
		}
	}
}
