import type {
	InvestmentConversionIdentifierItems,
	InvestmentIdentifierConversion,
	InvestmentIdentifierImport,
	InvestmentImportResponse,
	ReferenceConversionNamedItems,
	ReferenceImport,
	ReferenceImportConverterType,
	ReferenceNamedConversion,
	TemplateImportType,
} from "$root/api/api-gen";
import { IntegrationsControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import type { Converted } from "$root/components/Upload/BaseDropzoneBlock";
import BaseDropzoneBlock from "$root/components/Upload/BaseDropzoneBlock";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { downloadContentDisposition } from "$root/utils/files";
import { parallelize } from "$root/utils/promise";
import type { DialogProps, FormProps, Option, TableColumn } from "@mdotm/mdotui/components";
import {
	ActionText,
	Banner,
	Button,
	Checkbox,
	Collapsible,
	DefaultCollapsibleHeader,
	Dialog,
	DialogFooter,
	FormField,
	Select,
	SubmitButton,
	Table,
	Text,
} from "@mdotm/mdotui/components";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import {
	ForEach,
	adaptAnimatedNodeProvider,
	generateUniqueDOMId,
	spawn,
	useUnsafeUpdatedRef,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { unpromisify } from "@mdotm/mdotui/utils";
import { Set } from "immutable";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import XLSX from "xlsx";

type InvestmentReferenceImportConverterType = ReferenceImportConverterType;

const ACCEPTED_FILE = "*";
const concurrency = 32;
type DropzoneState = "empty" | "error" | "success" | "loading";
type Id<T> = T & { id: string };

function ConversionBlock({
	converter,
	status,
	converted,
	onSubmit,
	onChangeStatus,
}: {
	status: DropzoneState;
	onChangeStatus(status: DropzoneState): void;
	converter: InvestmentReferenceImportConverterType;
	onSubmit(conversions: Array<Converted<ReferenceConversionNamedItems>>): void;
	converted: Array<Converted<ReferenceConversionNamedItems>>;
}) {
	const { t } = useTranslation();
	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);

	const reset = useCallback(() => {
		onChangeStatus("empty");
		onSubmit([]);
	}, [onChangeStatus, onSubmit]);

	const onChange = useCallback(
		async (files: File[], type: InvestmentReferenceImportConverterType) => {
			try {
				reset();
				onChangeStatus("loading");
				const filesToConverter = files.map(
					(file) => () =>
						axiosExtract(integrationsApi.convertReferenceCheckFrom(type, file)).then(async (response) => ({
							form: response,
							items: await axiosExtract(integrationsApi.convertNamesToReferenceIdentifiers(response)),
						})),
				);

				const responseStack = await parallelize(filesToConverter, { concurrency });
				const assocFileWithConversion = files.map((file, i) => ({
					filename: file.name,
					conversion: responseStack[i].items,
				}));

				const succededResponses = assocFileWithConversion.filter(
					({ conversion }) => (conversion as ReferenceConversionNamedItems | undefined) !== undefined,
				);

				const failedResponses = assocFileWithConversion.filter(
					({ conversion }) => (conversion as ReferenceConversionNamedItems | undefined) === undefined,
				);

				if (succededResponses.length > 0) {
					onSubmit(assocFileWithConversion);
					onChangeStatus("success");
					return;
				}

				if (failedResponses.length > 0) {
					failedResponses.map((x) =>
						platformToast({
							children: `Failed to convert ${x.filename ?? "-"}`,
							severity: "error",
							icon: "Portfolio",
						}),
					);

					onChangeStatus("error");
				}
			} catch (error) {
				onChangeStatus("error");

				throw error;
			}
		},
		[integrationsApi, onChangeStatus, onSubmit, reset],
	);

	const failedConversion = useMemo(() => converted.filter(({ conversion }) => conversion === undefined), [converted]);

	return (
		<>
			<BaseDropzoneBlock
				converted={converted}
				disabled={status === "loading"}
				onDelete={reset}
				status={status}
				onChange={(x) => {
					if (x === null) {
						return reset();
					}

					unpromisify(() => onChange(x, converter))();
				}}
			/>
			{status === "loading" && (
				<div className="my-4">
					<Banner severity="info">{t("PORTFOLIOS.UPLOAD_DURATION_LIMIT")}</Banner>
				</div>
			)}
			{failedConversion.length > 0 && (
				<div className="mb-4">
					<Banner severity="warning" title="Failed to convert these files">
						{failedConversion.map(({ filename }) => filename).join(", ")}
					</Banner>
				</div>
			)}
		</>
	);
}

function OverrideTable({
	selected,
	onChange,
	rows,
}: {
	rows: Array<Id<ReferenceNamedConversion>>;
	onChange(value: Set<string>): void;
	// selected: MutableRefObject<Set<string>>;
	selected: Set<string>;
}) {
	const onChangeRef = useUnsafeUpdatedRef(onChange);
	const rowsId = useMemo(() => rows.map(({ id }) => id), [rows]);
	const columns = useMemo<TableColumn<Id<ReferenceNamedConversion>>[]>(
		() => [
			{
				header: "",
				content: (row) => row.referenceImport?.name ?? "-",
				relativeWidth: 6.3,
			},
			{
				header: () => (
					<div className="flex gap-2 min-w-0">
						<Checkbox
							checked={
								selected.size === 0
									? true
									: selected.size === rowsId.length
									  ? false
									  : selected.size > 0
									    ? "indeterminate"
									    : selected.size === 0
							}
							onChange={() => onChangeRef.current(Set())}
						/>
						<Text type="Body/S/Bold" classList="truncate">
							Overwrite
						</Text>
					</div>
				),
				content: ({ id }) => (
					<Checkbox checked={!selected.has(id)} onChange={() => onChangeRef.current(selected.remove(id))} />
				),
				relativeWidth: 1.85,
			},
			{
				header: () => (
					<div className="flex gap-2 min-w-0">
						<Checkbox
							checked={
								selected.size > 0 && selected.size < rowsId.length ? "indeterminate" : selected.size === rowsId.length
							}
							onChange={() => onChangeRef.current(Set(rowsId))}
						/>
						<Text type="Body/S/Bold" classList="truncate">
							Ignore
						</Text>
					</div>
				),
				content: ({ id }) => (
					<Checkbox
						checked={selected.has(id)}
						onChange={() => {
							onChangeRef.current(selected.add(id));
							console.log("ignore");
						}}
					/>
				),
				relativeWidth: 1.85,
			},
		],
		[onChangeRef, rowsId, selected],
	);

	return (
		<Table
			rows={rows}
			columns={columns}
			visibleRows={Math.min(rows.length, 5)}
			classList="!bg-transparent"
			headerRowClassList="!bg-transparent"
			rowClassList="!bg-transparent !border-[#A0A7B6]"
		/>
	);
}

const converterToTemplateType = {
	SPHERE_BENCHMARK: "SPHERE_REFERENCE_BENCHMARK_PTF_IMPORT",
	SPHERE_TARGET_PTF: "SPHERE_REFERENCE_TARGET_PTF_IMPORT",
} satisfies { [key in InvestmentReferenceImportConverterType]: TemplateImportType };

type InvestmentReferenceImportDialogProps = {
	converters: Array<InvestmentReferenceImportConverterType>;
	onImportFinished(params: {
		imported: Array<InvestmentImportResponse>;
		failed: Array<{ investment: InvestmentIdentifierImport; err: unknown }>;
	}): void;
	qualifier?: string;
	show: boolean;
	onClose(): void;
	onAnimationStateChange?: DialogProps["onAnimationStateChange"];
};

function InvestmentReferenceImportDialog({
	converters,
	onImportFinished,
	show,
	onClose,
	onAnimationStateChange,
}: InvestmentReferenceImportDialogProps): JSX.Element {
	const { t } = useTranslation();
	const options = useMemo(
		() =>
			converters.map(
				(converter): Option<InvestmentReferenceImportConverterType> => ({
					label: t(`IMPORT.${converter}`),
					value: converter,
				}),
			),
		[converters, t],
	);

	const [selectedConverter, setSelectedConverter] = useState(converters[0]);
	const [converted, setConverted] = useState<Array<Converted<ReferenceConversionNamedItems>>>([]);
	const [dropzoneStatus, setDropzoneStatus] = useState<DropzoneState>("empty");

	const [investmentToIgnore, _setInvestmentToIgnore] = useState(Set<string>());
	const latestInvestmentToIgnore = useRef(investmentToIgnore);
	function setInvestmentToIgnore(investments: Set<string>) {
		_setInvestmentToIgnore(investments);
		latestInvestmentToIgnore.current = investments;
	}
	const flatConversion = useMemo(
		() => converted.flatMap((x) => x.conversion.conversions ?? []).map((x) => ({ ...x, id: generateUniqueDOMId() })),
		[converted],
	);

	const existingInvestments = useMemo(() => {
		return flatConversion.filter(({ exist }) => exist);
	}, [flatConversion]);

	const conversionWithErrors = useMemo(
		() => converted.filter((x) => x.conversion.errors && x.conversion.errors.length > 0),
		[converted],
	);

	const cleanConvertion = useMemo(
		() =>
			conversionWithErrors.length === 0 &&
			// overridables.length === 0 &&
			// unconsistent.length === 0 &&
			converted.length > 0,
		[conversionWithErrors.length, converted.length],
	);

	const disabled = useMemo(() => {
		const common = conversionWithErrors.length > 0 || converted.length === 0;
		return common;
	}, [conversionWithErrors.length, converted.length]);

	const integrationsApiV2 = useApiGen(IntegrationsControllerApiFactory);

	const onSubmitAsync = useCallback<FormProps<void>["onSubmitAsync"]>(
		async ({ progressCb }) => {
			const fileToSubmit = flatConversion.flatMap(({ id, ...investments }): ReferenceImport[] => {
				if (investmentToIgnore.has(id) || !investments.referenceImport) {
					return [];
				}

				return [investments.referenceImport];
			});

			if (fileToSubmit.length === 0) {
				platformToast({
					children: "No reference imported",
					severity: "info",
					icon: "Dowload",
				});
				onImportFinished({ failed: [], imported: [] });
				return;
			}

			let progress = 0;
			const failed: { investment: ReferenceImport; err: unknown }[] = [];
			const imported: InvestmentImportResponse[] = [];
			const callStack = fileToSubmit.map(
				(investment) => () =>
					integrationsApiV2
						.importReference(investment)
						.then((resp) => imported.push(resp.data))
						.catch((err) => failed.push({ investment, err }))
						.finally(() => {
							progress++;
							progressCb(progress / callStack.length);
						}),
			);
			await parallelize(callStack, { concurrency });

			if (failed.length === 0) {
				platformToast({
					children: "Sphere has taken over your request",
					severity: "info",
					icon: "Dowload",
				});
			} else if (failed.length === callStack.length) {
				platformToast({
					children: "No portfolio imported",
					severity: "error",
					icon: "Dowload",
				});
			} else {
				platformToast({
					children: `Imported ${callStack.length - failed.length} out of ${callStack.length} portfolios`,
					severity: "warning",
					icon: "Dowload",
				});
			}

			onImportFinished({ imported, failed });
		},
		[flatConversion, integrationsApiV2, investmentToIgnore, onImportFinished],
	);

	const handleDownloadErrorReport = useCallback((report: Array<Converted<InvestmentConversionIdentifierItems>>) => {
		const xlsSheet = report.flatMap(
			({ conversion, filename }) =>
				conversion.errors?.map(({ errorMessage, investmentName }) => ({
					File: filename,
					Portfolio: investmentName,
					Message: errorMessage,
				})) ?? [],
		);

		const reportWS = XLSX.utils.json_to_sheet(xlsSheet);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, reportWS, "Report Errors");
		XLSX.writeFile(wb, "Errors file.xlsx");
	}, []);

	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);
	const handleDownloadTemplate = useCallback(
		(convertTo: InvestmentReferenceImportConverterType) =>
			integrationsApi
				.getUploadTemplate(converterToTemplateType[convertTo], {
					responseType: "blob",
				})
				.then(downloadContentDisposition)
				.catch((e) => {
					platformToast({
						children: "Oops, Something went wrong, please re-try",
						severity: "error",
						icon: "Portfolio",
					});
					reportPlatformError(e, "ERROR", "portfolio", "unable to download template");
					throw e;
				}),
		[integrationsApi],
	);

	return (
		<Dialog
			size="large"
			show={show}
			onClose={onClose}
			onSubmitAsync={onSubmitAsync}
			onAnimationStateChange={onAnimationStateChange}
			header="Import file"
			classList="max-h-[830px]"
			footer={
				<DialogFooter
					primaryAction={
						<SubmitButton palette="primary" disabled={disabled}>
							{t("BUTTON.SAVE")}
						</SubmitButton>
					}
					neutralAction={
						<Button palette="tertiary" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			}
		>
			<div className="mb-4">
				<FormField label="File format">
					{(forward) => (
						<Select
							{...forward}
							options={options}
							value={selectedConverter}
							onChange={(converter) => {
								setConverted([]);
								setDropzoneStatus("empty");
								if (converter) {
									setSelectedConverter(converter);
								}
							}}
							classList="w-[300px]"
						/>
					)}
				</FormField>
			</div>
			<ol className="list-decimal ml-4 mb-4">
				<li>
					Download the <ActionText onClickAsync={() => handleDownloadTemplate(selectedConverter)}>template</ActionText>
				</li>
				<li>Insert your portfolio composition</li>
				<li>Upload the edited template. Only files formatted like the template will be accepted.</li>
			</ol>
			<ConversionBlock
				converted={converted}
				onSubmit={setConverted}
				status={dropzoneStatus}
				onChangeStatus={setDropzoneStatus}
				converter={selectedConverter}
			/>

			{cleanConvertion && dropzoneStatus === "success" && (
				<div className="mb-4">
					<Banner severity="success" title="File uploaded correctly">
						Your portfolios has been converted by Sphere. Click &quot;
						<strong>Save</strong>&quot; to start analyzing and processing your instruments.
					</Banner>
				</div>
			)}

			{existingInvestments.length > 0 && (
				<div className="mb-4">
					<Banner severity="warning" title="Update existing portfolios">
						<p className="mb-2">Some reference already exists. Would you like to overwrite it?</p>
						<OverrideTable rows={existingInvestments} selected={investmentToIgnore} onChange={setInvestmentToIgnore} />
					</Banner>
				</div>
			)}

			{/* {unconsistent && unconsistent.length > 0 && (
				<div className="mb-4">
					<Banner severity="error" title="Some portfolios cannot be updated in this state.">
						Import{" "}
						<strong>
							{unconsistent
								.map((portfolios) => portfolios.investmentImport?.name)
								.join(", ")
								.toString()}{" "}
						</strong>
						had a ongoing process. You must edit your portfolios before proceeding with the update.
					</Banner>
				</div>
			)} */}

			{conversionWithErrors.length > 0 && (
				<Banner severity="error" title="Please review the template format and upload the file again" classList="mb-4">
					<p>The uploaded files have some formatting errors</p>
					{"\n"}
					<ActionText as="button" onClick={() => handleDownloadErrorReport(conversionWithErrors)}>
						Download errors file
					</ActionText>
				</Banner>
			)}
			<ForEach collection={conversionWithErrors}>
				{({ item }) => (
					<Collapsible
						header={(params) => (
							<DefaultCollapsibleHeader
								{...params}
								classList={{
									[`!bg-transparent h-[40px] flex items-center border-b border-[${themeCSSVars.palette_N200}]`]: true,
								}}
							>
								{item.filename}
							</DefaultCollapsibleHeader>
						)}
					>
						<ForEach collection={item.conversion.errors ?? []}>
							{({ item: e }) => (
								<div className="h-[40px] border-b border-[#eeeef1] transition-[background] data-[forceHover]:bg-[#EFF0F3] hover:bg-[#EFF0F3] flex space-x-2 items-center px-2">
									<div className="w-14">
										<p className="pr-1 truncate">{e.investmentName}</p>
									</div>
									<p className="pr-1 truncate">{e.errorMessage}</p>
								</div>
							)}
						</ForEach>
					</Collapsible>
				)}
			</ForEach>
		</Dialog>
	);
}

export type SpawnInvestmentReferenceImportDialogParams = Omit<InvestmentReferenceImportDialogProps, "onClose" | "show">;
export function spawnInvestmentReferenceImportDialog(
	params: SpawnInvestmentReferenceImportDialogParams,
): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show, onHidden }) => (
			<InvestmentReferenceImportDialog
				{...params}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onImportFinished={(...forward) => {
					params.onImportFinished(...forward);
					resolve();
				}}
			/>
		)),
	);
}
export default InvestmentReferenceImportDialog;
