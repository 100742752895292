import type {
	AlertDto,
	CompatibleBenchmarkEntry,
	InvestmentListEntry,
	InvestmentReferenceListEntry,
	InvestmentReportsBatchOrderingEnum,
	InvestmentsModel,
	InvestmentStatuses,
	InvestmentSummary,
} from "$root/api/api-gen";
import {
	IntegrationsControllerApiFactory,
	InvestmentActions,
	InvestmentBenchmarksControllerApiFactory,
	InvestmentControllerV4ApiFactory,
	InvestmentEnhancementControllerV4ApiFactory,
	InvestmentEnhancementExportControllerApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentExportControllerApiFactory,
	InvestmentExportConverterType,
	InvestmentReportsControllerApiFactory,
	InvestmentsReportTemplateControllerApiFactory,
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { useFunctionalAreas } from "$root/App/context";
import AuthorizationGuard, { hasAccess } from "$root/components/AuthorizationGuard";
import type { DefaultTemplateId } from "$root/components/EvolvedPrint/configuration/hooks/useExtractReports";
import { IconWalls, WallOverlay } from "$root/components/IconWall";
import { LabelRounded } from "$root/components/LabelRounded/Index";
import { PageDownloadAndMoreActionsMenu } from "$root/components/PageDownloadAndMoreActionsMenu";
import type { PageHeaderProps } from "$root/components/PageHeader";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import {
	EditPortfolioV4ContextProvider,
	EditPortfolioV4InContext,
} from "$root/components/Portfolio/CreatePortfolio/EditPortfolio";
import { useEventBus, useGroupEventBus } from "$root/event-bus";
import { spawnAccessDialog } from "$root/functional-areas/acl/AccessDialog";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import EntityStatus from "$root/functional-areas/acl/EntityStatus";
import { downloadPdf } from "$root/functional-areas/pdf";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import type { PortfolioAlert } from "$root/functional-areas/portfolio/alerts";
import {
	defaultPortfolioTemplates,
	DefaultReportTemplateName,
	isDefaultReportTemplate,
} from "$root/functional-areas/reports/default-templates";
import { useUserValue } from "$root/functional-areas/user";
import useCompositionDownload from "$root/hooks/useCompositionDownload";
import { useLocaleFormatters } from "$root/localization/hooks";
import { platformToast } from "$root/notification-system/toast";
import { PaletteColors } from "$root/styles/themePalette";
import { axiosExtract, type CustomAxiosError } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { dateToStringWithoutTime } from "$root/utils/dates";
import { customObjectEntriesFn, customObjectValuesFn } from "$root/utils/experimental";
import { downloadContentDisposition } from "$root/utils/files";
import type { Setter } from "$root/utils/functions";
import { objMatchFn } from "$root/utils/objects";
import { parallelize } from "$root/utils/promise";
import type { ContextContent } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { useSearchParams } from "$root/utils/react-router-extra";
import { noop } from "$root/utils/runtime";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import WidgetsMapper from "$root/widgets-architecture/layout/WidgetsMapper";
import {
	Button,
	CircularProgressBar,
	Dialog,
	DialogFooter,
	DropdownMenu,
	FormField,
	Icon,
	ProgressBar,
	Searchable,
	Select,
	SubmitButton,
	Tab,
	TabGroup,
	Table,
	TextInput,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import { useMultiSelect } from "@mdotm/mdotui/headless";
import { useDrivenState, useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, unpromisify } from "@mdotm/mdotui/utils";
import type { AxiosError } from "axios";
import * as dateFn from "date-fns";
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import "../Portfolios/Portfolios.scss";
import { PortfolioStudioTab } from "../PortfoliosStudio";
import type { ReportTemplateVariant } from "../PortfolioStudioSettings/ReportEditor/version/report-v1";
import Overview from "./Overview";
import { PortfolioCommentary } from "./PortfolioCommentary";
import { PortfolioComposition } from "./PortfolioComposition";
import type { PageCtx } from "$root/components/PageCtx";
import { usePortfolioEntityManagementActions } from "$root/functional-areas/portfolio/entity-management";
import { spawnPortfolioTemplateChooser } from "$root/functional-areas/portfolio-studio/dialogs/TemplateChooserDialog";

type PortfolioStatusVariants =
	| "isOptimize"
	| "isEnhance"
	| "isEnhancable"
	| "Error"
	| "enhanceRequested"
	| "isInit"
	| "isUpload";
export type TgridName = "PORTFOLIO_DETAILS" | "PORTFOLIO_ENHANCEMENT" | "PORTFOLIO_REFERENCE";
type PortfolioStatusProps = Record<PortfolioStatusVariants, boolean>;
export interface IextendedInvestmentsModel extends InvestmentSummary, PortfolioStatusProps {}
export interface IextendedInvestmentEnhancedDetails extends InvestmentSummary, PortfolioStatusProps {
	alerts?: AlertDto[];
}

type PortfolioConfiguration<T extends string> = {
	[StatusKey in InvestmentStatuses]?: { [ActionKey in InvestmentActions]?: T };
};

interface InvestmentsProps {
	investmentList: InvestmentListEntry[] | InvestmentReferenceListEntry[];
	investmentsHistory: InvestmentsModel[];
}

interface PortfolioMetricsProps {
	portfolio: IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails | undefined;
	isEnhanceSelected: boolean;
	setIsEnhanceSelected: Setter<boolean>;
	refetchPortfolioDetails: () => Promise<unknown>;
	entityManagementActions: ReturnType<typeof usePortfolioEntityManagementActions>;
	setSubtitlePortalTarget: PageCtx["setSubtitlePortalTarget"];
}

type PageProps = {
	portfolioUid: string;
};

const PAGE_NAME = "PORTFOLIO_DETAILS";
const ENH_PAGE_NAME = "PORTFOLIO_ENHANCEMENT";
const REFERENCE_PAGE_NAME = "PORTFOLIO_REFERENCE";

const PortfolioSubtitle: FC<PortfolioMetricsProps> = ({
	portfolio,
	isEnhanceSelected,
	setIsEnhanceSelected,
	refetchPortfolioDetails,
	entityManagementActions,
	setSubtitlePortalTarget,
}) => {
	const {
		status,
		action,
		actualAction,
		isEnhance,
		isEnhancable,
		isOptimize,
		isUpload,
		reference,
		modificationDate,
		uuid,
		syncDate,
		lastActionDate,
		importedExternally,
		richAcl,
		currentlyContainsNestedPortfolios,
		containsNestedPortfolios,
	} = portfolio ?? {};
	const { t } = useTranslation();
	const { formatDate } = useLocaleFormatters();
	const [isEnhancing, setIsEnhancing] = useState(false);
	const investmentEnhanceApi = useApiGen(InvestmentEnhancementControllerV4ApiFactory);

	useGroupEventBus(
		"investment-update",
		() => {
			refetchPortfolioDetails().catch(noop);
		},
		{
			filter: objMatchFn({ uuid }),
		},
	);

	useGroupEventBus(
		"shared-entity",
		() => {
			refetchPortfolioDetails().catch(noop);
		},
		{
			filter: objMatchFn({ sharedEntityUuid: uuid }),
		},
	);

	const { push } = useTypedNavigation();
	const handleEnhanceStatus = async (accept: boolean) => {
		try {
			setIsEnhancing(true);
			if (!uuid) {
				throw new Error("portfolio not found");
			}

			if (accept) {
				await investmentEnhanceApi.acceptEnhancement(uuid);
			} else {
				await investmentEnhanceApi.rejectEnhancement(uuid);
			}

			trackMixPanelEvent("Portfolio", {
				Type: accept ? "Accept" : "Reject",
				ID: uuid,
			});

			setIsEnhancing(false);
			setIsEnhanceSelected(false); // portfolio is unmounting set state no needed
		} catch (error) {
			reportPlatformError(
				error,
				"ERROR",
				"portfolio",
				`${action ? "accept" : "reject"} enhancement on investment "${uuid}"`,
			);
			setIsEnhancing(false);
			setIsEnhanceSelected(false);
		}
	};

	const user = useUserValue();

	const { downloadInvestmentConversion } = useCompositionDownload();
	const investmentsReportTemplateApi = useApiGen(InvestmentsReportTemplateControllerApiFactory);
	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);

	const downloadPtfPdf = useCallback(
		async (pdfRelativeUrl: string) => {
			await downloadPdf(
				pdfRelativeUrl,
				isEnhanceSelected
					? `Portfolio_Proposal_Report_${dateFn.format(new Date(), "MMddyyyy")}`
					: `Portfolio_Report_${dateFn.format(new Date(), "MMddyyyy")}`,
			);
		},
		[isEnhanceSelected],
	);
	const exportApi = useApiGen(InvestmentExportControllerApiFactory);
	const exportEnhancedApi = useApiGen(InvestmentEnhancementExportControllerApiFactory);

	const { data: templateList } = useQueryNoRefetch(["queryReportTemplateList"], {
		queryFn: async () => {
			const defautlts = [
				...defaultPortfolioTemplates.map((defaultTemplate) => ({
					...defaultTemplate,
					visible: true,
				})),
			];
			const list = await axiosExtract(investmentsReportTemplateApi.listInvestmentReportTemplates());
			return [
				...defautlts.filter((x) => !list.find((y) => x.templateName === y.templateName)),
				...list.filter((x) => x.visible),
			];
		},
	});

	if (!portfolio) {
		return <div className="h-16 bg-white rounded" />;
	}

	const canEditComposition =
		portfolio?.status !== "RETRIEVING_DATA" &&
		portfolio?.status !== "PROPOSAL_READY" &&
		portfolio?.status !== "ERROR" &&
		portfolio?.status !== "CALCULATING" &&
		aclByArea.portfolio.canEditComposition(user.id, [
			{ userId: user.id, permissions: portfolio?.richAcl?.currentUserPermissions },
		]);

	const canEditSettings =
		portfolio?.status !== "ERROR" &&
		portfolio?.status !== "CALCULATING" &&
		portfolio?.status !== "RETRIEVING_DATA" &&
		aclByArea.portfolio.canEditSettings(user.id, [
			{ userId: user.id, permissions: portfolio?.richAcl?.currentUserPermissions },
		]);

	return (
		<div className="flex justify-between py-2.5 min-h-[54px]">
			<div className="w-full flex items-center space-x-4 text-[#585D68] overflow-x-auto pr-2">
				<div className="flex items-center" data-qualifier="PortfolioDetails/PageHeader/Status">
					<span className="mr-2 uppercase">{t("STATUS")}</span>
					<LabelRounded type="status" content={{ label: status ?? "-", component: "" }} />
				</div>
				<div className="flex items-center space-x-2" data-qualifier="PortfolioDetails/PageHeader/Action">
					<p className="uppercase whitespace-nowrap">{t("PORTFOLIOS.PF_LAST_ACTION")} </p>
					<p className="font-bold uppercase whitespace-nowrap">
						{action ? t(`PORTFOLIO_ACTION_STATUS.${actualAction as InvestmentActions}`) : "-"}
					</p>
					{modificationDate && <div className="whitespace-nowrap">({formatDate(lastActionDate)})</div>}
				</div>
				{importedExternally && syncDate && user.automaticImport && (
					<div className="flex space-x-2 items-center">
						<Icon icon="Sync" color={themeCSSVars.palette_N400} size={20} />
						<div className="flex items-center">
							<span className="uppercase whitespace-nowrap">AUTO-SYNC </span>&nbsp;
							<span className="font-bold uppercase whitespace-nowrap">ACTIVE</span> &nbsp; -&nbsp;
							<span className="uppercase whitespace-nowrap">LAST IMPORT</span>&nbsp;
							<span className="font-bold uppercase whitespace-nowrap">
								{dateFn.format(new Date(syncDate), "MM/dd/yyyy KK:mm")}
							</span>
						</div>
					</div>
				)}
				<EntityStatus
					accessControl={richAcl}
					entity="INVESTMENT"
					entityId={portfolio?.uuid}
					entityName={portfolio?.name}
					refetch={refetchPortfolioDetails}
				/>
			</div>
			<div className="flex items-center space-x-2">
				<div className="empty:hidden" ref={setSubtitlePortalTarget} />
				{reference ? (
					<>
						{canEditComposition && (
							<Button
								size="small"
								palette="primary"
								onClick={() => push("EditPortfolioCompositionPage", { portfolioUid: portfolio!.uuid! })}
							>
								<Icon icon="Edit" size={16} />
								&nbsp; {t("BUTTON.EDIT")}
							</Button>
						)}
					</>
				) : status !== "REVIEW" ? (
					<>
						{isEnhance ? (
							!isEnhanceSelected ? (
								<Button size="small" palette="info" onClick={() => setIsEnhanceSelected(true)}>
									<Icon icon="Icon-full-small" />
									&nbsp;Open Proposal
								</Button>
							) : isOptimize || !isEnhance || !isEnhanceSelected || isUpload ? (
								<></>
							) : (
								<div className="space-x-2 flex items-center">
									<Button
										size="small"
										palette="infoOutline"
										disabled={isEnhancing}
										onClick={() => {
											setIsEnhanceSelected(false);
										}}
										style={{ borderColor: "#03a0dc" }}
									>
										Back to current {/* TODO: translate */}
									</Button>

									<AuthorizationGuard
										permissionChecker={aclByArea.portfolio.canCreateProposal}
										acl={richAcl?.acl ?? []}
									>
										<Button
											size="small"
											palette="info"
											disabled={isEnhancing}
											onClick={unpromisify(() => handleEnhanceStatus(true))}
										>
											<Icon icon="Outline" />
											&nbsp;Accept {/* TODO: translate */}
										</Button>
									</AuthorizationGuard>

									<AuthorizationGuard
										permissionChecker={aclByArea.portfolio.canCreateProposal}
										acl={richAcl?.acl ?? []}
									>
										<Button
											size="small"
											palette="info"
											onClick={() => push("EditPortfolioCompositionPage", { portfolioUid: uuid! })}
											disabled={isEnhancing}
										>
											<Icon icon="Edit" size={16} />
											&nbsp;Edit {/* TODO: translate */}
										</Button>
									</AuthorizationGuard>

									<AuthorizationGuard
										permissionChecker={aclByArea.portfolio.canCreateProposal}
										acl={richAcl?.acl ?? []}
									>
										<Button
											size="small"
											palette="info"
											disabled={isEnhancing}
											onClick={unpromisify(() => handleEnhanceStatus(false))}
										>
											<Icon icon="Close" />
											&nbsp;Reject {/* TODO: translate */}
										</Button>
									</AuthorizationGuard>
								</div>
							)
						) : (
							<AuthorizationGuard permissionChecker={aclByArea.portfolio.canCreateProposal} acl={richAcl?.acl ?? []}>
								<Button
									type="button"
									palette="primary"
									size="small"
									disabled={!isEnhancable || currentlyContainsNestedPortfolios || containsNestedPortfolios}
									onClick={() => push("Portfolios/EditPortfolio", { portfolioUid: uuid! })}
									classList={{ "!px-3": true }}
								>
									<Icon icon="Enhance" color={PaletteColors.WHITE} size={20} />
									&nbsp;Create Proposal
								</Button>
							</AuthorizationGuard>
						)}

						{(canEditSettings || canEditComposition) && !isEnhanceSelected && (
							<DropdownMenu
								trigger={(forward) => (
									<Button
										data-qualifier="PortfolioDetails/EditDropdownMenu/Button"
										size="small"
										palette="primary"
										{...forward}
									>
										<Icon icon="Edit" size={16} />
										&nbsp;
										{t("BUTTON.EDIT")}
									</Button>
								)}
								actions={[
									{
										disabled: !canEditSettings,
										icon: "Settings",
										label: "Edit portfolio settings",
										"data-qualifier": "PortfolioDetails/EditDropdownMenu/DropdownItem(settings)",
										onClick: () =>
											push("Portfolios/SettingsPortfolio", {
												portfolioUid: portfolio!.uuid!,
											}),
									},

									{
										disabled: !canEditComposition,
										icon: "composition",
										label: "Edit composition",
										"data-qualifier": "PortfolioDetails/EditDropdownMenu/DropdownItem(composition)",
										onClick: () =>
											push("EditPortfolioCompositionPage", {
												portfolioUid: portfolio!.uuid!,
											}),
									},
									{
										icon: "Content-Copy",
										disabled: !canEditComposition,
										label: "Copy composition from existing portfolio",
										onClick: () =>
											push("EditPortfolioCompositionPage", { portfolioUid: portfolio!.uuid!, override: "true" }),
									},
								]}
							/>
						)}
					</>
				) : (
					<></>
				)}

				<PageDownloadAndMoreActionsMenu
					area="portfolio"
					downloadActions={
						status && !(["REVIEW", "CALCULATING", "DRAFT"] satisfies InvestmentStatuses[]).some((x) => x === status)
							? [
									...(reference ? [] : templateList ? templateList : []).map((template) => ({
										group: { label: "", id: "DOWNLOAD" },
										icon: "pdf" as const,
										"data-qualifier": "PortfolioDetails/DropdownMenu/DropdownItem(portfolioTemplate)",
										onClickAsync: async () => {
											if (portfolio?.status === "PROPOSAL_READY") {
												spawnPortfolioTemplateChooser({
													investments: [{ uuid: uuid!, name: portfolio.name, status: portfolio.status }],
													onSubmitAsync: async (data) => {
														const pages = data.map((ptf) =>
															typedUrlForRoute("Report", {
																templateId: isDefaultReportTemplate(template) ? template.id : template.uuid!,
																objectId: ptf.uuid!,
																variant: (ptf.choice === "current"
																	? "current"
																	: "proposal") satisfies ReportTemplateVariant,
															}),
														);

														await parallelize(pages.map((url) => () => downloadPtfPdf(url)));
													},
												});
												return;
											}

											await downloadPtfPdf(
												typedUrlForRoute("Report", {
													templateId: isDefaultReportTemplate(template) ? template.id : template.uuid!,
													objectId: uuid!,
													variant: (isEnhanceSelected ? "proposal" : "current") satisfies ReportTemplateVariant,
												}),
											);
										},
										label: template.templateName ?? "",
									})),

									portfolio?.uuid
										? {
												group: { label: "", id: "DOWNLOAD" },
												icon: "xls" as const,
												onClickAsync: async () => {
													const response = isEnhanceSelected
														? await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "FULL_COMPOSITION", {
																responseType: "blob",
														  })
														: await exportApi.exportComposition(portfolio.uuid!, {
																responseType: "blob",
														  });

													trackMixPanelEvent("Portfolio", {
														Type: "Export",
														Area: "composition",
													});

													downloadContentDisposition(response);
												},
												label: reference ? "Target portfolio composition" : "Portfolio composition",
												"data-qualifier": "PortfolioDetails/DropdownMenu/DropdownItem(composition)",
										  }
										: null,

									portfolio.uuid && reference
										? {
												group: { label: "", id: "DOWNLOAD" },
												icon: "xls" as const,
												onClickAsync: async () => {
													const response = await integrationsApi.exportReference(portfolio!.uuid!, {
														responseType: "blob",
													});
													downloadContentDisposition(response);
												},
												label: "Reference template",
												"data-qualifier": "PortfolioDetails/Reference/DropdownMenu/DropdownItem(ReferenceTemplate)",
										  }
										: null,

									portfolio?.uuid && isEnhanceSelected
										? {
												group: { label: "", id: "DOWNLOAD" },
												icon: "xls" as const,
												onClickAsync: async () => {
													const response = await exportEnhancedApi.exportEnhancedComposition(
														portfolio!.uuid!,
														"TRADES_ONLY",
														{ responseType: "blob" },
													);

													downloadContentDisposition(response);
												},
												label: t("COMPOSITION.DOWNLOAD_TRADES_TITLE"),
												"data-qualifier": "PortfolioDetails/DropdownMenu/DropdownItem(trades)",
										  }
										: null,
									...(hasAccess(user, { requiredService: "EXPORT" }) && portfolio?.uuid && !reference
										? (user.exportFormats ?? []).map((format) => ({
												group: { label: "", id: "DOWNLOAD" },
												icon: "xls" as const,
												onClickAsync: async () => {
													if (format === InvestmentExportConverterType.EasimTemplateConverter) {
														const composition = await axiosExtract(
															integrationsApi.exportInvestment(portfolio.uuid!, isEnhanceSelected),
														);
														await axiosExtract(integrationsApi.convertTo(format, [composition], true));
														platformToast({
															children: "Sphere has taken over your request",
															severity: "info",
															icon: "Dowload",
														});
														return;
													}

													await downloadInvestmentConversion(
														format,
														portfolio.uuid!,
														isEnhanceSelected,
														// format === InvestmentExportConverterType.EasimTemplateConverter,
													);
													// if (format !== InvestmentExportConverterType.EasimTemplateConverter) {
													// } else {
													// platformToast({
													// 	children: "Sphere has taken over your request",
													// 	severity: "info",
													// 	icon: "Dowload",
													// });
													// }
													trackMixPanelEvent("Portfolio", {
														Type: "Export",
														Area: "composition",
														ID: portfolio!.uuid!,
													});
												},
												label: `${t(`EXPORT.${format}`)} template`,
												"data-qualifier": `PortfolioDetails/DropdownMenu/DropdownItem(portfolioTemplate,${format})`,
										  }))
										: []),

									hasAccess(user, {
										requiredService: "INVESTMENTS_REPORT_TEMPLATE_EDITOR",
									}) && !reference
										? {
												icon: "Settings" as const,
												onClickAsync: () => {
													push("PortfolioStudioSettings", {
														tab: PortfolioStudioSettingTabEnum.ReportCustomisation,
													});
												},
												label: "Report customisation",
												"data-qualifier": "PortfolioDetails/DropdownMenu/DropdownItem(reportCustomisation)",
										  }
										: null,
									hasAccess(user, { requiredRole: "ROOT" }) && !reference
										? {
												group: "ROOT FEATURES",
												icon: "Expand" as const,
												"data-qualifier": "PortfolioDetails/DropdownMenu/DropdownItem(PreviewReport)",
												onClick: () => {
													window.open(
														typedUrlForRoute("Report", {
															templateId: reference
																? ("portfolio-reference" satisfies DefaultTemplateId)
																: DefaultReportTemplateName.sphere,
															objectId: uuid!,
															variant: (isEnhanceSelected ? "proposal" : "current") satisfies ReportTemplateVariant,
														}),
														"_blank",
													);
												},
												disabled: status === "ERROR" || status === undefined,
												label: "Preview report",
										  }
										: null,
							  ]
							: []
					}
					moreActions={[
						...(isEnhanceSelected
							? []
							: [
									aclByArea.portfolio.canDelete(user.id, portfolio?.richAcl?.acl ?? []) && {
										icon: "Delete" as const,
										"data-qualifier": "PortfolioDetails/DropdownMenu/DropdownItem(Delete)",
										onClick: unpromisify(entityManagementActions.deleteAsync ?? noop),
										disabled: !entityManagementActions.deleteAsync,
										label: "Delete",
									},
									{
										icon: "Content-Copy" as const,
										"data-qualifier": "PortfolioDetails/DropdownMenu/DropdownItem(Duplicate)",
										onClick: unpromisify(entityManagementActions.duplicateAsync ?? noop),
										disabled: !entityManagementActions.duplicateAsync,
										label: "Duplicate",
									},
									entityManagementActions.renameAsync && {
										"data-qualifier": "PortfolioDetails/DropdownMenu/DropdownItem(Rename)",
										icon: "Edit" as const,
										onClick: unpromisify(entityManagementActions.renameAsync),
										label: "Rename",
									},
							  ]),
					]}
				/>
			</div>
		</div>
	);
};

type SelectBenchmarkModalProps = {
	comparativeBenchmarks: CompatibleBenchmarkEntry[];
	show: boolean;
	onClose(): void;
	onSubmitAsync(params: { benchmarks: CompatibleBenchmarkEntry[] }): Promise<void>;
};

function SelectBenchmarkModal({ comparativeBenchmarks, show, onSubmitAsync, onClose }: SelectBenchmarkModalProps) {
	const multiSelectCtx = useMultiSelect<string>();
	useEffect(() => {
		multiSelectCtx.actions.setSelection(
			comparativeBenchmarks.filter((x) => x.linked).map(({ identifier }) => identifier ?? ""),
		);
	}, [comparativeBenchmarks, multiSelectCtx.actions]);

	return (
		<Dialog
			header="Select benchmarks" // TODO: translate
			show={show}
			onClose={onClose}
			size="large"
			onSubmitAsync={() =>
				onSubmitAsync({
					benchmarks: comparativeBenchmarks.filter(({ identifier }) =>
						identifier ? multiSelectCtx.data.selection.toArray().includes(identifier) : false,
					),
				})
			}
			footer={
				<DialogFooter
					neutralAction={
						<Button palette="tertiary" onClick={onClose} data-qualifier="PortfolioDetails/ComparativeBenchmark/Cancel">
							Close
						</Button>
					}
					primaryAction={
						<SubmitButton data-qualifier="PortfolioDetails/ComparativeBenchmark/Confirm">Confirm</SubmitButton>
					}
				/>
			}
			classList="!w-[960px]"
		>
			<Searchable
				matchFn={(a, query) => (a.name ?? "").toLowerCase().includes(query.toLowerCase())}
				collection={comparativeBenchmarks ?? []}
			>
				{function Inner({ filtered, query, setQuery }) {
					const { column: checkboxColumn, rowClassList } = useSelectableTableColumn({
						rows: comparativeBenchmarks,
						multiSelectCtx,
						filteredRows: filtered,
						selectBy: (r) => r.identifier!,
						selectableRowIds: comparativeBenchmarks
							.filter(({ primary }) => primary === false)
							.map(({ identifier }) => identifier ?? ""),
					});
					return (
						<>
							<div className="pb-4">
								<TextInput
									placeholder="Search..."
									value={query}
									onChangeText={setQuery}
									innerRef={(e) => e?.setAttribute("data-qualifier", "PortfolioDetails/ComparativeBenchmark/Search")}
								/>
							</div>
							<Table
								rows={filtered}
								rowClassList={rowClassList}
								columns={[
									checkboxColumn,
									{
										//FIXME: if linked add push to custom benchmarks page
										header: "Name",
										content: ({ name }) => (
											<span
												data-qualifier={`PortfolioDetails/ComparativeBenchmark/Item(${name})`}
												className="truncante"
												title={name}
											>
												{name}
											</span>
										),
										relativeWidth: 65,
									}, // TODO: will be added when the benchmark area will be build
									// {
									// 	header: "Macro Exposure",
									// 	content: ({ exposureSummary }) => exposureSummary,
									// },
									{
										header: "Type",
										content: ({ primary }) => (primary ? "Portfolio benchmark" : "Benchmark"),
										relativeWidth: 25,
									},
								]}
								visibleRows={8}
								rowStyle={({ identifier }) => {
									const { Table_highlightedRowBackgroundColor } = themeCSSVars;
									const checked = multiSelectCtx.data.selection.get(identifier ?? "");
									if (checked) {
										return { backgroundColor: Table_highlightedRowBackgroundColor };
									}

									return {};
								}}
								noDataText={
									<div className="flex items-center justify-center h-20">
										<p>No Data</p>
									</div>
								}
							/>
						</>
					);
				}}
			</Searchable>
		</Dialog>
	);
}

const severityByStatus: Record<InvestmentStatuses, PageHeaderProps["severity"]> = {
	ACCEPTED: "success",
	READY: "success",
	PROPOSAL_READY: "info",
	ERROR: "error",
	CALCULATING: "calculating",
	REVIEW: undefined,
	RETRIEVING_DATA: "calculating",
	DRAFT: "info",
};

export type PortfolioDetailsTabs = (typeof PortfolioDetailsTabs)[keyof typeof PortfolioDetailsTabs];
export const PortfolioDetailsTabs = {
	OVERVIEW: "OVERVIEW",
	COMPOSITION: "COMPOSITION",
	COMMENTARY: "COMMENTARY",
	SCENARIO_ANALYSIS: "SCENARIO_ANALYSIS",
	PORTFOLIO_STUDIO_SETTINGS: "PORTFOLIO_STUDIO_SETTINGS",
};

const PortfolioDetails = (): JSX.Element => {
	const isEnhanceSelected = useSearchParams().proposal === "true";
	const history = useHistory();
	const setIsEnhanceSelected = useCallback(
		(newIsEnhanceSelected: boolean) => {
			const queryParams = new URLSearchParams(history.location.search);
			if (newIsEnhanceSelected === isEnhanceSelected) {
				return;
			}
			if (newIsEnhanceSelected) {
				queryParams.set("proposal", "true");
			} else {
				queryParams.delete("proposal");
			}
			history.replace({ search: queryParams.toString() });
		},
		[history, isEnhanceSelected],
	);

	const [isMarketScenarioEnhancableState, setIsMarketScenarioEnhancableState] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [reportsExecution, setReportsExecution] = useState<{ [key in InvestmentReportsBatchOrderingEnum]?: boolean }>(
		{},
	);
	const [reportExcutionCounter, setReportExcutionCounter] = useState(0);

	const [selectedComparativeBenchmark, setSelectedComparativeBenchmark] = useState<string | null>(null);

	const userToggledEnhancedRef = useRef(false);
	const user = useUserValue();
	const { t } = useTranslation();

	const { portfolioUid } = useParams<PageProps>();
	const investmentsApi = useApiGen(InvestmentControllerV4ApiFactory);
	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const investmentEnhancementReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);

	const { push } = useTypedNavigation();
	const pushRef = useUnsafeUpdatedRef(push);

	// check if portfolioUid exist
	useEffect(() => {
		if (!portfolioUid) {
			pushRef.current("PortfoliosStudio", { status: "notFound" });
		}
	}, [history, portfolioUid, pushRef]);

	useFunctionalAreas(
		() => ({
			areas: "portfolio",
			data: { uuid: portfolioUid },
		}),
		[portfolioUid],
	);

	const onChangePortfolioUid = useCallback(
		(uuid: string) => {
			setIsEnhanceSelected(false);
			push("PortfolioDetails", { portfolioUid: uuid! });
		},
		[push, setIsEnhanceSelected],
	);

	const createPortfolioStatus = (action?: InvestmentActions, status?: InvestmentStatuses) => {
		const STATUS = [
			"isOptimize",
			"isEnhance",
			"isEnhancable",
			"Error",
			"enhanceRequested",
			"isInit",
			"isUpload",
			"isReview",
		] satisfies Array<
			"isOptimize" | "isEnhance" | "isEnhancable" | "Error" | "enhanceRequested" | "isInit" | "isUpload" | "isReview"
		>;

		const configuration: PortfolioConfiguration<(typeof STATUS)[number]> = {
			CALCULATING: {
				ENHANCEMENT: "enhanceRequested",
				UPLOAD: "isInit",
				CREATION: "isInit",
				CLONE: "isInit",
			},
			PROPOSAL_READY: {
				OPTIMIZATION: "isOptimize",
				ENHANCEMENT: "isEnhance",
			},
			RETRIEVING_DATA: {
				UPLOAD: "isUpload",
				CREATION: "isUpload",
				ENHANCEMENT: "isUpload",
				OPTIMIZATION: "isUpload",
			},
			REVIEW: {
				UPLOAD: "isUpload",
				CREATION: "isUpload",
				ENHANCEMENT: "isUpload",
				OPTIMIZATION: "isUpload",
			},
			ERROR: customObjectValuesFn(InvestmentActions).reduce(
				(acc, investmentAction) => ({ ...acc, [investmentAction]: "Error" }),
				{},
			),
			READY: customObjectValuesFn(InvestmentActions).reduce(
				(acc, investmentAction) => ({ ...acc, [investmentAction]: "isEnhancable" }),
				{},
			),
			ACCEPTED: customObjectValuesFn(InvestmentActions).reduce(
				(acc, investmentAction) => ({ ...acc, [investmentAction]: "isEnhancable" }),
				{},
			),
		};

		const assocUndefinedStatus = (portfolioStatus: string) => ({ [portfolioStatus]: false });
		return !action || !status
			? STATUS.map(assocUndefinedStatus)
			: STATUS.map((portfolioStatus) => {
					const portfolioConf = configuration?.[status]?.[action] ?? "Error";
					return { [portfolioStatus]: portfolioConf === portfolioStatus };
			  });
	};

	// current portfolio data api call
	// TODO: leave just the getInvestmentSummary / getInvestmentEnhancementSummary response
	// and also the onSuccess since it update the url query param // and also the onError
	const {
		data: portfolio,
		isLoading: isLoadingInvestmentSummary,
		isError,
		refetch: refetchPortfolioDetails,
	} = useQueryNoRefetch<IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails, AxiosError<CustomAxiosError>>(
		["portoflioDetails", isEnhanceSelected, portfolioUid],
		{
			enabled: Boolean(portfolioUid),
			queryFn: async () => {
				let response;
				if (!isEnhanceSelected) {
					const { data } = await investmentReportApi.getInvestmentSummary(portfolioUid);
					response = data;
				} else {
					const { data } = await investmentEnhancementReportApi.getInvestmentEnhancementSummary(portfolioUid);
					response = data;
				}

				const { action, status } = response;
				const mappedPortfolioStatus = createPortfolioStatus(action, status);

				const portfolioStatus = mappedPortfolioStatus.reduce((acc, curr) => {
					const currentValue = Object.values(curr)[0];
					const currentKey = Object.keys(curr)[0] as string;
					return {
						...acc,
						[currentKey]: currentValue,
					};
				}, {}) as PortfolioStatusProps;

				return {
					...response,
					...portfolioStatus,
				};
			},
			onSuccess: (data) => {
				if (!isEnhanceSelected) {
					const { modificationDate } = data ?? {};
					const date = modificationDate ? dateToStringWithoutTime(new Date(modificationDate)) : "";
					const newSearchParams = new URLSearchParams(history.location.search);
					newSearchParams.set("date", String(date));
					history.replace({ search: newSearchParams.toString(), hash: history.location.hash });
					if (!userToggledEnhancedRef.current) {
						// const { status, action } = data ?? {};
						// setIsEnhanceSelected(enhanceToBeApproved); // TODO:
					}
				}
			},
			onError: (error) => {
				const { data } = error.response ?? {};
				if (data?.message === "Accesso negato" || data?.code === 404) {
					spawnAccessDialog({
						onClick: (onClose) => {
							push("PortfoliosStudio", {});
							onClose();
						},
					});
					return;
				}

				platformToast({
					children: t("SOMETHING_WENT_WRONG"),
					severity: "error",
					icon: "Portfolio",
				});
				push("PortfoliosStudio", { status: "error" });
			},
		},
	);

	// TODO: temporary comment
	useGroupEventBus(
		"investment-report-update",
		() => {
			refetchPortfolioDetails().catch(noop);
		},
		{
			filter: objMatchFn({ uuid: portfolioUid }),
		},
	);

	// TODO: temporary comment
	useEventBus("investment-report-update", (e) => {
		if (e.executedReports) {
			setReportsExecution((executions) => {
				const shallowCopy = { ...executions };
				customObjectEntriesFn(executions).forEach(([key, v]) => {
					if (shallowCopy[key]) {
						shallowCopy[key] = v;
					}
				});
				return shallowCopy;
			});
		}
	});

	// TODO: temporary comment
	useGroupEventBus(
		"investment-report-update",
		() => {
			setReportExcutionCounter((c) => c + 1);
		},
		{
			timeout: 10000,
		},
	);

	// user portfolio list api call
	// TODO: split reference and make it as base component
	// maybe remove investmen summary as api call
	// this use query should just fetch the list of reference or ptf list
	const {
		data: investments,
		refetch: refetchPortfolioInvesments,
		isLoading: areInvestmentsLoading,
	} = useQueryNoRefetch<InvestmentsProps, AxiosError<CustomAxiosError>>(["portfolioInvesments"], {
		enabled: Boolean(portfolioUid),
		queryFn: async () => {
			const { data: investment } = await investmentReportApi.getInvestmentSummary(portfolioUid);
			const { action, uuid, modificationDate, reference } = investment;
			if (reference) {
				const { data: investmentList } = await investmentsApi.getInvestmentReferenceList();
				return {
					investmentList,
					investmentsHistory: [{ modificationDate, action, _id: uuid }],
				};
			}

			const { data: investmentList } = await investmentsApi.getInvestmentList();

			return {
				investmentList,
				investmentsHistory: [{ modificationDate, action, _id: uuid }],
			};
		},
		onError: (err) => {
			console.error(err);
		},
	});

	// portfolio alert api call
	// TODO: remove unusued useQuery & Maybe(remove all alert system from widgets)
	const { data: portfolioAlert } = useQueryNoRefetch(["portfolioAlerts", isEnhanceSelected, portfolioUid], {
		//enabled: Boolean(portfolioUid && !isEnhance && portfolio),
		enabled: Boolean(portfolioUid),
		queryFn: async () => {
			// FIXME: Verify and Unify Data with Alerts
			let alerts;
			if (!isEnhanceSelected) {
				const { data } = await investmentReportApi.getInvestmentSummary(portfolioUid);
				alerts = (data.alerts ?? []).filter((a) => Boolean(a.type)) as PortfolioAlert[];
			} else {
				const { data } = await investmentEnhancementReportApi.getInvestmentEnhancementSummary(portfolioUid);
				alerts = (data.alerts ?? []).filter((a) => Boolean(a.type)) as PortfolioAlert[];
			}
			return alerts;
		},
		onError: (err) => {
			console.error(err);
		},
	});

	// api resp data splitting
	const { reference, isEnhancable } = portfolio ?? {};
	const isPortfolioEnhancable = isEnhancable;

	//  widgetGridName
	const composeGridName = useCallback((isPortfolioEnhance: boolean, isPortfolioReference: boolean) => {
		if (isPortfolioReference) {
			return REFERENCE_PAGE_NAME;
		}

		if (isPortfolioEnhance) {
			return ENH_PAGE_NAME;
		}

		return PAGE_NAME;
	}, []);

	const gridName = composeGridName(isEnhanceSelected, Boolean(reference));

	const requestEnhance = useCallback(() => {
		push("Portfolios/EditPortfolio", { portfolioUid });
	}, [push, portfolioUid]);

	// context
	// those callback context make widgets depending to ptf details
	const portfolioContextData = useMemo<ContextContent<typeof PortfolioContext>>(
		() => ({
			enhanced: isEnhanceSelected,
			portfolio: portfolio ?? null,
			selectedBenchmark: selectedComparativeBenchmark,
			alerts: portfolioAlert ?? [],
			isMarketScenarioEnhancable: isMarketScenarioEnhancableState,
			currentTab: 0,
			updateMarketScenario: setIsMarketScenarioEnhancableState,
			requestEnhance: isPortfolioEnhancable ? requestEnhance : undefined,
			requestOptimize: isPortfolioEnhancable ? () => setIsModalOpen(true) : undefined,
			onChangePortfolioUid,
			reportsExecution,
			reportExcutionCounter,
		}),
		[
			isEnhanceSelected,
			portfolio,
			selectedComparativeBenchmark,
			portfolioAlert,
			isMarketScenarioEnhancableState,
			isPortfolioEnhancable,
			requestEnhance,
			onChangePortfolioUid,
			reportsExecution,
			reportExcutionCounter,
		],
	);

	const entityManagementActions = usePortfolioEntityManagementActions(portfolio, {
		onRename() {
			refetchPortfolioDetails().catch(noop);
			refetchPortfolioInvesments().catch(noop);
		},
		onDuplicate: () => push("PortfoliosStudio", {}),
		onDelete: () => push("PortfoliosStudio", {}),
	});

	const investmentsPool = useMemo(() => {
		if (investments) {
			const generateLabels = ({
				name,
				uuid,
				status,
			}: {
				name?: string;
				uuid?: string;
				status?: InvestmentStatuses;
			}) => ({
				label: `${name}${status === "DRAFT" ? "(draft)" : ""}`,
				value: uuid!,
				disabled: status === "DRAFT",
			});
			return investments.investmentList.sort(builtInSortFnFor("creationTime")).reverse().map(generateLabels);
		}

		return [{ label: "No portfolio available", value: portfolioUid }];
	}, [investments, portfolioUid]);

	const { formatDate } = useLocaleFormatters();

	const proposalName = !portfolio ? "" : `Proposal ${formatDate(portfolio.modificationDate)}`; // TODO: translate

	const area = useMemo(() => {
		const areaName = isEnhanceSelected ? ("settings-enhanced" as const) : ("settings-current" as const);

		return {
			name: areaName,
			portfolioUid,
			editable: false /* readonly, edit settings will navigate to a dedicated page */,
			edit: false,
		};
	}, [isEnhanceSelected, portfolioUid]);

	const availableTabs = {
		OVERVIEW: {
			component: (active: boolean, ptf: IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails) => (
				<Tab title="Overview">
					{active && (
						<Overview
							isModalOpen={isModalOpen}
							gridName={gridName}
							portfolio={ptf}
							portfolioUid={portfolioUid}
							isEnhanceSelected={isEnhanceSelected}
							portfolioAlert={portfolioAlert ?? []}
							setIsModalOpen={setIsModalOpen}
							requestEnhance={requestEnhance}
							refetchPortfolio={refetchPortfolioDetails}
						/>
					)}
				</Tab>
			),
			hasAccess: true,
		},
		COMPOSITION: {
			component: (active: boolean, ptf: IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails) => (
				<Tab title="Composition">
					{active && (
						<PortfolioComposition
							enhanced={isEnhanceSelected}
							portfolio={ptf}
							reportExcutionCounter={reportExcutionCounter}
							alerts={portfolioAlert ?? []}
						/>
					)}
				</Tab>
			),
			hasAccess: true,
		},
		COMMENTARY: {
			component: (active: boolean, ptf: IextendedInvestmentsModel | IextendedInvestmentEnhancedDetails) => (
				<Tab title="Commentary">
					{active ? (
						<PortfolioCommentary
							enhanced={isEnhanceSelected}
							portfolio={ptf}
							reportExcutionCounter={reportExcutionCounter}
						/>
					) : (
						<></>
					)}
				</Tab>
			),
			hasAccess: hasAccess(user, { requiredService: "PORTFOLIO_STUDIO_COMMENTARY_TAB" }),
		},
		SCENARIO_ANALYSIS: {
			component: (active: boolean) => (
				<Tab title="Scenario Analysis">{active && <WidgetsMapper widgetName="ScenarioAnalysisEditable" />}</Tab>
			),
			hasAccess: true,
		},
		PORTFOLIO_STUDIO_SETTINGS: {
			component: (active: boolean) => <Tab title="Portfolio Settings">{active && <EditPortfolioV4InContext />}</Tab>,
			hasAccess: true,
		},
	} satisfies Record<
		PortfolioDetailsTabs,
		{
			component: (...args: any) => JSX.Element;
			hasAccess: boolean;
		}
	>;

	const tabs = customObjectEntriesFn(availableTabs).filter(([_, v]) => v.hasAccess);
	const tabSearchParams = useSearchParams().tab ?? tabs[0][0];
	const currentTab = Number(tabs.findIndex(([tabName]) => tabName === tabSearchParams));
	const setCurrentTab = useCallback(
		(tabIndex: number) => {
			if (currentTab === tabIndex) {
				return;
			}
			const newSearchParams = new URLSearchParams(history.location.search);
			if (tabs[tabIndex][0] !== "PORTFOLIO_STUDIO_SETTINGS") {
				newSearchParams.delete("edit");
			}
			newSearchParams.set("tab", String(tabs[tabIndex][0]));
			history.replace({ search: newSearchParams.toString(), hash: history.location.hash });
		},
		[currentTab, history, tabs],
	);

	return (
		<PortfolioContext.Provider value={portfolioContextData ?? {}}>
			<EditPortfolioV4ContextProvider area={area}>
				{() => {
					return (
						<>
							<PageHeader
								name="PortfolioDetails"
								severity={portfolio?.status ? severityByStatus[portfolio?.status as InvestmentStatuses] : undefined}
								// title={referenceInvestment ? "Portfolio Reference Details" : "Portfolio Details"} // TODO: translate
								title={
									areInvestmentsLoading || isLoadingInvestmentSummary
										? "..."
										: isEnhanceSelected
										  ? proposalName
										  : portfolio?.name ?? "Untitled"
								} // TODO: translate
								titleAction={
									portfolio?.status === "REVIEW" || portfolio?.reference || !portfolio?.uuid ? undefined : (
										<ComparativeBenchmark
											portfolioUuid={portfolio.uuid}
											value={selectedComparativeBenchmark}
											onChange={setSelectedComparativeBenchmark}
											canAddBenchmark={!["CALCULATING", "RETRIEVING_DATA", "REVIEW"].includes(portfolio?.status ?? "")}
										/>
									)
								}
								crumbs={[
									{
										children: "Portfolio studio",
										href: typedUrlForRoute("PortfoliosStudio", {}),
									},
									{
										children: reference ? "References" : "Portfolios", // TODO: translate
										href: reference
											? typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References })
											: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Portfolios }),
									},
									...(!isEnhanceSelected
										? [
												{
													children:
														areInvestmentsLoading || isLoadingInvestmentSummary ? (
															<CircularProgressBar value="indeterminate" style={{ width: 12, height: "auto" }} />
														) : (
															<Select
																classList="flex items-center w-40 truncate"
																strategy="fixed"
																value={portfolio?.uuid}
																onChange={(value) => {
																	push("PortfolioDetails", { portfolioUid: value! });
																}}
																enableSearch
																unstyled
																options={investmentsPool}
															/>
														),
												},
										  ]
										: [
												{
													children: portfolio?.name,
													onClick: () => setIsEnhanceSelected(false),
												},
										  ]),
									...(!isEnhanceSelected
										? []
										: [
												{
													children: !portfolio ? (
														<CircularProgressBar value="indeterminate" style={{ width: 12, height: "auto" }} />
													) : (
														proposalName
													),
												},
										  ]),
								]}
								subTitle={({ setSubtitlePortalTarget }) =>
									portfolio?.status !== "REVIEW" &&
									entityManagementActions && (
										<PortfolioSubtitle
											portfolio={portfolio}
											isEnhanceSelected={isEnhanceSelected}
											setIsEnhanceSelected={setIsEnhanceSelected}
											entityManagementActions={entityManagementActions}
											refetchPortfolioDetails={refetchPortfolioDetails}
											setSubtitlePortalTarget={setSubtitlePortalTarget}
										/>
									)
								}
							/>
							{isLoadingInvestmentSummary && (
								<div className="h-1">
									<ProgressBar value="indeterminate" />
								</div>
							)}
							<WallOverlay showOverlay={isError} classList="min-h-[40vh]" overlay={<IconWalls.ErrorData />}>
								{portfolio === undefined ? (
									<></>
								) : portfolio.reference || portfolio.isUpload ? (
									<Overview
										isModalOpen={isModalOpen}
										gridName={gridName}
										portfolio={portfolio}
										portfolioUid={portfolioUid}
										isEnhanceSelected={isEnhanceSelected}
										portfolioAlert={portfolioAlert ?? []}
										setIsModalOpen={setIsModalOpen}
										requestEnhance={requestEnhance}
										refetchPortfolio={refetchPortfolioDetails}
									/>
								) : (
									<TabGroup palette="primary" tabIndex={currentTab} onTabChange={setCurrentTab}>
										{tabs.map(([_, { component }], i) => component(currentTab === i, portfolio))}
									</TabGroup>
								)}
							</WallOverlay>
						</>
					);
				}}
			</EditPortfolioV4ContextProvider>
		</PortfolioContext.Provider>
	);
};

export default PortfolioDetails;

function ComparativeBenchmark(props: {
	portfolioUuid: string;
	value: string | null;
	onChange?(v: string): void;
	canAddBenchmark?: boolean;
}) {
	const investmentBenchmarksController = useApiGen(InvestmentBenchmarksControllerApiFactory);

	const [selectedComparativeBenchmark, setSelectedComparativeBenchmark] = useDrivenState(props.value, {
		onSet: props.onChange,
	});

	useGroupEventBus(
		"investment-update",
		() => {
			refetchComparativeBenchmark().catch(noop);
		},
		{
			filter: objMatchFn({ uuid: props.portfolioUuid }),
		},
	);

	useGroupEventBus(
		"shared-entity",
		() => {
			refetchComparativeBenchmark().catch(noop);
		},
		{
			filter: objMatchFn({ sharedEntityUuid: props.portfolioUuid }),
		},
	);

	// TODO: temporary comment
	useGroupEventBus(
		"investment-report-update",
		() => {
			refetchComparativeBenchmark().catch(noop);
		},
		{
			filter: objMatchFn({ uuid: props.portfolioUuid }),
		},
	);
	// associated benchmark to the portfolio api call
	const {
		data: comparativeBenchmarks,
		isLoading: isLoadingComparativeBenchmarks,
		refetch: refetchComparativeBenchmark,
	} = useQueryNoRefetch(["comparativeBenchmarks", props.portfolioUuid], {
		queryFn: () => axiosExtract(investmentBenchmarksController.getInvestmentBenchmarks(props.portfolioUuid)),
		onSuccess: (benchmarks) => {
			const hasPortfolioBenchmarksId = benchmarks.find((b) => b.primary === true);
			if (hasPortfolioBenchmarksId?.identifier) {
				setSelectedComparativeBenchmark(hasPortfolioBenchmarksId.identifier);
			}
		},
	});
	// benchmarks
	const comparativeBenchmarkOptions = useMemo(
		() =>
			comparativeBenchmarks
				?.filter(({ identifier }) => identifier !== undefined)
				.map((x) => ({ label: x.name ?? "", value: x.identifier ?? "", disabled: x.ready === false })),
		[comparativeBenchmarks],
	);

	const handleSaveVisibleComparativeBenchmarks = useCallback(
		async ({ benchmarks }: { benchmarks: CompatibleBenchmarkEntry[] }) => {
			try {
				await investmentBenchmarksController.setInvestmentBenchmarks({
					benchmarks,
					uuid: props.portfolioUuid,
				});
				window.location.reload();
			} catch (error) {
				reportPlatformError(error, "ERROR", "portfolio", `set benchmark on investment "${props.portfolioUuid}"`);
				if (typeof error === "string") {
					throw new Error(error);
				} else if (error instanceof Error) {
					throw new Error(error.message);
				}
			}
		},
		[investmentBenchmarksController, props.portfolioUuid],
	);

	const [showComparativeBenchmarkModal, setShowComparativeBenchmarkModal] = useState(false);

	// benchmarks that could be associated with the current investments
	const { data: compatibleBenchmarksIds, isLoading: isLoadingCompatibleBenchmarksIds } = useQueryNoRefetch(
		["compatibleBenchmarks", props.portfolioUuid],
		{
			enabled: Boolean(props.portfolioUuid),
			queryFn: () => axiosExtract(investmentBenchmarksController.getCompatibleBenchmarks(props.portfolioUuid)),
		},
	);

	return (
		<>
			<SelectBenchmarkModal
				comparativeBenchmarks={compatibleBenchmarksIds ?? []}
				onClose={() => setShowComparativeBenchmarkModal(false)}
				onSubmitAsync={handleSaveVisibleComparativeBenchmarks}
				show={showComparativeBenchmarkModal}
			/>
			<FormField label="Comparative Benchmark">
				{({ id }) => (
					<div className="flex items-center space-x-2">
						<Select
							style={{ width: 280 }}
							strategy="fixed"
							size="x-small"
							id={id}
							options={comparativeBenchmarkOptions ?? []}
							value={selectedComparativeBenchmark}
							disabled={isLoadingComparativeBenchmarks}
							onChange={setSelectedComparativeBenchmark}
							innerRef={(e) =>
								e?.setAttribute("data-qualifier", "PortfolioDetails/PageHeader/Action/SelectComparativeBenchark")
							}
						/>
						<Button
							size="x-small"
							palette="neutralOutline"
							disabled={
								isLoadingComparativeBenchmarks || isLoadingCompatibleBenchmarksIds || !(props.canAddBenchmark ?? true)
							}
							onClick={() => setShowComparativeBenchmarkModal(true)}
							data-qualifier="PortfolioDetails/PageHeader/Action/AddComparativeBenchmark"
						>
							<Icon icon="Outline1" />
						</Button>
					</div>
				)}
			</FormField>
		</>
	);
}
