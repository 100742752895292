import type { InvestmentAttributionResponse } from "$root/api/api-gen";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { ReportTemplateItemMap } from "$root/pages/PortfolioStudioSettings/ReportEditor/version/report-v1";
import WaterfallChart from "$root/ui-lib/charts/WaterfallChart";
import { useMemo } from "react";
import { Trans } from "react-i18next";
import { useReportTranslation } from "../translation";

const horizonOptions = [
	{
		label: "1m",
		value: "ONE_MONTH",
	},
	{
		label: "3m",
		value: "THREE_MONTHS",
	},
	{
		label: "6m",
		value: "SIX_MONTHS",
	},
	{
		label: "YTD",
		value: "YEAR_TO_DATE",
	},
	{
		label: "1y",
		value: "ONE_YEAR",
	},
	{
		label: "All",
		value: "FROM_INCEPTION",
	},
];

export function PerformanceAttribution(
	props: PrintableProps<
		{
			performanceAttribution: InvestmentAttributionResponse;
			horizon: "ONE_MONTH" | "THREE_MONTHS" | "SIX_MONTHS" | "ONE_YEAR" | "YEAR_TO_DATE" | "FROM_INCEPTION";
		},
		null
	>,
): JSX.Element {
	const { t, language } = useReportTranslation();
	const { horizon } = props;
	const processedData = useMemo(() => {
		const computedSeries = (props.performanceAttribution.current ?? []).map((el) => ({
			label: `<div class="h-[40px] flex flex-col text-end"><p><strong>${el.assetClass}</strong></p><p>${el.type}</p></div>`,
			tooltipTitle: `${el.assetClass} - ${el.type}`,
			value: el.value ?? 0,
		}));
		return computedSeries;
	}, [props.performanceAttribution]);

	const horizonTouple = horizonOptions.find((x) => x.value === horizon);

	if (processedData.length === 0) {
		return <></>;
	}

	return (
		<Card title={t("REPORT_BUILDER.PERFORMANCE_ATTRIBUTION.TITLE")}>
			<Trans
				i18nKey="REPORT_BUILDER.PERFORMANCE_ATTRIBUTION.DESCRIPTION"
				components={{ strong: <strong />, p: <p className="mb-2" />, p2: <p className="mb-4" /> }}
				className="mb-4 whitespace-pre-line"
				lang=""
				tOptions={{ lng: language }}
			/>
			<p className="font-semibold text-lg">{horizonTouple?.label ?? ""}</p>
			<WaterfallChart animated={false} dataSeries={processedData} height="360px" />
		</Card>
	);
}

export default PerformanceAttribution;
