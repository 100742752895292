import type { MaybeAsync } from "$root/utils/promise";
import type { ButtonPalette, FieldSize, Option } from "@mdotm/mdotui/components";
import { AsyncButton, Button, Icon, Select } from "@mdotm/mdotui/components";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { renderNodeOrFn, useDrivenState } from "@mdotm/mdotui/react-extensions";
import type { ForwardedRef } from "react";

export type ButtonWithSelectProps<T> = {
	disabled?: boolean;
	children: NodeOrFn;
	options?: Array<Option<T>>;
	value?: T;
	palette?: ButtonPalette;
	onClick: MaybeAsync<void, [T]>;
	size?: FieldSize;
	enableSearch?: boolean;
};

export function ButtonWithSelect<T>(props: ButtonWithSelectProps<T>): JSX.Element {
	const [value, setValue] = useDrivenState<T | null>(props.value ?? null);
	const noOptionAvailable = !props.options || !props.options.length;
	return (
		<div className="flex">
			<AsyncButton
				disabled={props.disabled || value === null}
				onClickAsync={() => value === null || props.onClick(value)}
				style={
					noOptionAvailable
						? {}
						: {
								borderTopRightRadius: 0,
								borderBottomRightRadius: 0,
						  }
				}
				palette={props.palette}
				size={props.size}
			>
				{renderNodeOrFn(props.children)}
			</AsyncButton>
			{noOptionAvailable ? null : (
				<Select
					trigger={(triggerProps) => (
						<Button
							innerRef={triggerProps.innerRef as ForwardedRef<HTMLButtonElement>}
							data-invalid={triggerProps.invalid}
							aria-expanded={triggerProps.open}
							aria-controls={triggerProps.listboxId}
							onClick={triggerProps.onClick}
							onKeyDown={triggerProps.onKeyDown}
							style={{
								borderTopLeftRadius: 0,
								borderBottomLeftRadius: 0,
								borderLeftWidth: 0,
							}}
							palette={props.palette}
							size={props.size}
						>
							<span
								className="transition-transform data-[open=true]:[transform:rotateX(180deg)] flex"
								data-open={triggerProps.open}
							>
								<Icon icon="Down" />
							</span>
						</Button>
					)}
					value={value}
					options={props.options!}
					onChange={(v) => setValue(v)}
					enableSearch={props.enableSearch}
				/>
			)}
		</div>
	);
}
