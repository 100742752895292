import type {
	CustomReportDataDTO,
	EditorCompositionResponse,
	EnhancementContributionResponse,
	ExposureContributionResponse,
	InvestmentAttributionResponse,
	InvestmentCompositionResponse,
	InvestmentContributionResponse,
	InvestmentExposureResponse,
	InvestmentExposureResponseExposureTypeEnum,
	InvestmentFactorsResponse,
	InvestmentPerformance,
	InvestmentStatuses,
	InvestmentSummary,
	MarketOverviewDTO,
	PortfolioExAnteMetricsResponse,
	PortfolioMetricsResponse,
	InvestmentCommentaryResponse,
} from "$root/api/api-gen";
import {
	CherryBankControllerV6ApiFactory,
	EntityEditorControllerApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentReportsControllerApiFactory,
	InvestmentsExposureCompareControllerApiFactory,
	MarketControllerV2ApiFactory,
	PortfolioStudioPreferencesApiFactory,
	UserControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import type { Languages } from "$root/localization/i18n";
import type { ReportTemplateVariant } from "$root/pages/PortfolioStudioSettings/ReportEditor/version/report-v1";
import { statusIconMap } from "$root/pages/PortfoliosStudio";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { handlePreviousMarketData } from "$root/utils/marketUtils";
import { parallelize } from "$root/utils/promise";
import { useQueryNoRefetch } from "$root/utils/react-query";
import type { CustomSectorModel } from "$root/widgets-architecture/widgets/OutlookBlock";
import type { TableParams } from "$root/widgets-architecture/widgets/OutlookBlock/OutlookBlockTable";
import type { Reducer } from "react";
import { useCallback, useReducer } from "react";
import { useTranslation } from "react-i18next";

export const DefaultTemplateId = {
	"portfolio-reference": "portfolio-reference",
	"mixed-portfolio": "mixed-portfolio",
	"custom-report": "custom-report",
	mocked: "mocked",
	storyfolio: "storyfolio",
} as const;

export type DefaultTemplateId = (typeof DefaultTemplateId)[keyof typeof DefaultTemplateId];

export type CommonSetUpReportProps<T> = T;

type ReportCustomizationData = {
	title: string;
	subtitle: string;
};

export type ReportProps = CustomReportDataDTO & ReportCustomizationData;

export type EnhanceDetailsReportData = {
	responseType: "enhancedDetails";
	portfolio: InvestmentSummary;
	commentary: InvestmentCommentaryResponse;
	editableComposition: EditorCompositionResponse;
	compositionVolatility: EnhancementContributionResponse;
	exAnteMetrics: PortfolioExAnteMetricsResponse;
	investmentFactors: InvestmentFactorsResponse;
	exposure: {
		macroVsGeo: InvestmentExposureResponse;
		macroVsMicro: InvestmentExposureResponse;
	};
	investmentComposition: InvestmentCompositionResponse;
	report: ReportProps;
};

export type PortfolioDetailsReportData = {
	responseType: "details";
	portfolio: InvestmentSummary;
	editableComposition: EditorCompositionResponse;
	compositionVolatility: InvestmentContributionResponse;
	contributionVolatility: InvestmentContributionResponse;
	performanceVolatility: InvestmentContributionResponse;
	performanceAttribution: InvestmentAttributionResponse;
	performanceMetrics: PortfolioMetricsResponse;
	investmentPerformance: InvestmentPerformance;
	exAnteMetrics: PortfolioExAnteMetricsResponse;
	investmentFactors: InvestmentFactorsResponse;
	investmentComposition: InvestmentCompositionResponse;
	exposure: {
		macroVsGeo: InvestmentExposureResponse;
		macroVsMicro: InvestmentExposureResponse;
	};
	commentary: InvestmentCommentaryResponse;
	report: ReportProps;
};

export type CustomReportData = {
	responseType: "customReport";
	Equity: TableParams[];
	EquityEu: TableParams[];
	EquityUsa: TableParams[];
	FixedIncome: TableParams[];
	Commodities: TableParams[];
	assetClassIndicators: MarketOverviewDTO;
	report: CustomReportDataDTO & ReportCustomizationData;
};

export type MixedPortfolioReportData = {
	portfolio: InvestmentSummary;
	sections: Array<{
		supportsChart: boolean;
		exposureCompare: ExposureContributionResponse;
		exposureCategory: InvestmentExposureResponseExposureTypeEnum;
	}>;
	report: ReportProps;
};

export type UnionMapData = {
	"portfolio-reference": PortfolioDetailsReportData;
	"custom-report": CustomReportData;
	"mixed-portfolio": MixedPortfolioReportData;
	mocked: { list: string[]; responseType: "mocked"; report: CustomReportDataDTO & ReportCustomizationData };
	storyfolio: {
		responseType: "storyfolio";
		report: ReportProps;
		commentary: InvestmentCommentaryResponse;
		portfolio: InvestmentSummary;
	};
};

export type CustomReportBodyData<Key extends keyof UnionMapData> = {
	template: Key;
	payload: UnionMapData[Key];
};

export type CustomReportDataUnion = {
	[K in keyof UnionMapData]: CustomReportBodyData<K>;
}[keyof UnionMapData];

type Action =
	| { status: "empty" }
	| { status: "loading" }
	| { status: "error"; message: unknown }
	| { status: "success"; data: CustomReportDataUnion };

type State = {
	data?: CustomReportDataUnion;
	isLoading?: boolean;
	isError?: boolean;
	errorMessage?: unknown;
};

function reducer(state: State, action: Action) {
	switch (action.status) {
		case "loading":
			return { ...state, isLoading: true };
		case "success":
			return { ...state, isLoading: false, data: action.data };
		case "error":
			return { ...state, isLoading: false, isError: true, errorMessage: action.message };
		case "empty":
			return state;
	}
}

const useExtractReportsData = (
	requestParams: (
		| {
				template: Exclude<DefaultTemplateId, "storyfolio">;
				variant?: ReportTemplateVariant;
				uuid?: string;
				historyEventUuid?: string;
		  }
		| {
				template: Extract<DefaultTemplateId, "storyfolio">;
				uuid?: string;
				enhanced?: boolean;
		  }
	) & { language: Languages },
): { state: State; refetch(): Promise<void> } => {
	const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, {
		data: undefined,
		errorMessage: "",
		isError: false,
		isLoading: false,
	});
	const { t } = useTranslation(undefined, { lng: requestParams.language });
	const reportInvestmentApi = useApiGen(InvestmentReportsControllerApiFactory);
	const enhanceInvestmentReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);

	const cherryBankApi = useApiGen(CherryBankControllerV6ApiFactory);
	const marketApi = useApiGen(MarketControllerV2ApiFactory);
	const editorApi = useApiGen(EntityEditorControllerApiFactory);
	const userApi = useApiGen(UserControllerApiFactory);
	const investmentsExposureCompareApi = useApiGen(InvestmentsExposureCompareControllerApiFactory);
	const portfolioStudioPreferences = useApiGen(PortfolioStudioPreferencesApiFactory);

	const composeTableRowData = useCallback(
		(data: Array<CustomSectorModel>) => {
			return data.reduce((acc, { currentValue, prevValue, type }, i) => {
				if (currentValue?.value === undefined) {
					return acc;
				}
				const { previous, tooltip: graphTooltip } = handlePreviousMarketData(
					"dashboard",
					(position) => t("CUSTOM_REPORT.OUTLOOK", { returnObjects: true })[position],
					{ value: currentValue.value, data: currentValue.data },
					{ value: prevValue?.value, data: prevValue?.data },
				);

				return [
					...acc,
					{
						assetClass: type,
						current: currentValue.value,
						prev: previous,
						tooltip: graphTooltip,
						index: i,
					},
				];
			}, [] as Array<TableParams>);
		},
		[t],
	);

	const ReportFactory = () => {
		async function GetPortfolioDetailsData(
			ptfTemplate: Extract<DefaultTemplateId, "portfolio-reference">,
			uid?: string,
		): Promise<CustomReportDataUnion> {
			if (!uid) {
				throw Error("portfolio is missing uuid");
			}

			const [portfolio, composition] = await Promise.all([
				axiosExtract(reportInvestmentApi.getInvestmentSummary(uid)),
				editorApi.getEditorEditComposition(uid, "INVESTMENT"),
			]);

			const benchmarkId = portfolio.primaryBenchmarkIdentifier;
			if (!benchmarkId) {
				throw Error(`portfolio(${uid}) is missing benchmarkId`);
			}

			const templateReport = await userApi.customReportData();
			const preferences = await axiosExtract(portfolioStudioPreferences.getPortfolioMetricsOrderingPreferences());

			const [
				compositionVolatility,
				contributionVolatility,
				performanceVolatility,
				performanceAttribution,
				performanceMetrics,
				investmentPerformance,
				exAnteMetrics,
				investmentFactors,
				exposure,
				investmentComposition,
				commentary,
			] = await Promise.all([
				reportInvestmentApi.getCompositionVolatilityContribution(uid, benchmarkId, "ONE_YEAR"),
				reportInvestmentApi.getRealizedVolatilityContribution(uid, benchmarkId, "ONE_YEAR"),
				reportInvestmentApi.getRealizedPerformanceContribution(uid, benchmarkId, "ONE_YEAR"),
				reportInvestmentApi.getRealizedPerformanceAttribution(uid, benchmarkId, "ONE_YEAR"),
				// reportInvestmentApi.getInvestmentRealizedMetrics(uid, benchmarkId),
				reportInvestmentApi.getPortfolioMetrics(uid, benchmarkId, preferences),
				reportInvestmentApi.getInvestmentPerformance(uid, benchmarkId),
				reportInvestmentApi.getPortfolioExAnteMetrics(uid, benchmarkId),
				reportInvestmentApi.getInvestmentFactors(uid, benchmarkId),
				parallelize({
					macroVsGeo: () =>
						axiosExtract(
							reportInvestmentApi.getTwoLevelsInvestmentExposure(uid, benchmarkId, "MACRO_ASSET_CLASS_VS_GEOGRAPHY"),
						),
					macroVsMicro: () =>
						axiosExtract(
							reportInvestmentApi.getTwoLevelsInvestmentExposure(
								uid,
								benchmarkId,
								"MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
							),
						),
				}),
				reportInvestmentApi.getInvestmentComposition(uid),
				reportInvestmentApi.getCommentaries(uid),
			]);

			return {
				template: ptfTemplate,
				payload: {
					responseType: "details",
					portfolio,
					editableComposition: composition.data,
					compositionVolatility: compositionVolatility.data,
					contributionVolatility: contributionVolatility.data,
					performanceVolatility: performanceVolatility.data,
					performanceAttribution: performanceAttribution.data,
					performanceMetrics: performanceMetrics.data,
					investmentPerformance: investmentPerformance.data,
					exAnteMetrics: exAnteMetrics.data,
					investmentFactors: investmentFactors.data,
					investmentComposition: investmentComposition.data,
					exposure,
					commentary: commentary.data,
					report: {
						title: ptfTemplate === "portfolio-reference" ? "PORTFOLIO REFERENCE" : "PORTFOLIO DETAILS",
						subtitle: `${portfolio.name?.toUpperCase()}`,
						...templateReport.data,
					},
				},
			};
		}

		async function GetCustomReports(): Promise<CustomReportDataUnion> {
			const assetClasses = await Promise.allSettled([
				cherryBankApi.dashboardControllerGetEquities("GLOBAL"),
				cherryBankApi.dashboardControllerGetEquities("EU"),
				cherryBankApi.dashboardControllerGetEquities("US"),
				cherryBankApi.dashboardControllerGetFii(),
				cherryBankApi.dashboardControllerGetSector(),
			]);

			const assetClassIndicators = await axiosExtract(marketApi.retrieveOverview());
			const templateReport = await userApi.customReportData();
			const [Equity, EquityEu, EquityUsa, FixedIncome, Commodities] = assetClasses.map((ac) => {
				if (ac.status === "rejected") {
					return [];
				}

				const mapRowData = ac.value.data.map(
					({ label, type, prevValue, currentValue }): CustomSectorModel => ({
						label: label ?? "-",
						type: type ?? "-",
						prevValue,
						currentValue,
					}),
				);
				return composeTableRowData(mapRowData);
			});

			return {
				template: "custom-report",
				payload: {
					responseType: "customReport",
					Equity,
					EquityEu,
					EquityUsa,
					FixedIncome,
					Commodities,
					assetClassIndicators,
					report: {
						title: "POSITIONING INDICATORS",
						subtitle: "CHERRYBANK",
						...templateReport.data,
					},
				},
			};
		}

		async function GetMixedPortfolio(uid: string, historyEventUuid: string): Promise<CustomReportDataUnion> {
			const portfolio = await axiosExtract(reportInvestmentApi.getInvestmentSummary(uid));

			const templateReport = await userApi.customReportData();

			/* chart + list */
			const macroExposureContribution = await axiosExtract(
				investmentsExposureCompareApi.getExposureContributionByHistoryInvestmentUuid(
					uid,
					historyEventUuid!,
					"MACRO_ASSET_CLASS",
				),
			);
			/* chart + list */
			const macroGeoExposureContribution = await axiosExtract(
				investmentsExposureCompareApi.getExposureContributionByHistoryInvestmentUuid(
					uid,
					historyEventUuid!,
					"MACRO_GEOGRAPHY",
				),
			);

			return {
				template: "mixed-portfolio",
				payload: {
					report: {
						title: "PORTFOLIO EXPOSURE COMPOSITION",
						subtitle: `${portfolio.name?.toUpperCase()} - ${
							portfolio.status ? statusIconMap[portfolio.status as InvestmentStatuses].title.toUpperCase() : ""
						}`,
						...templateReport.data,
					},
					portfolio,
					sections: [
						{
							supportsChart: true,
							exposureCategory: "MACRO_ASSET_CLASS",
							exposureCompare: macroExposureContribution,
						},
						{
							supportsChart: true,
							exposureCategory: "MACRO_GEOGRAPHY",
							exposureCompare: macroGeoExposureContribution,
						},
					],
				},
			};
		}

		async function GetMockedData(): Promise<CustomReportDataUnion> {
			const templateReport = await userApi.customReportData();
			return new Promise((resolve) => {
				setTimeout(
					() =>
						resolve({
							template: "mocked",
							payload: {
								responseType: "mocked",
								list: ["title 1", "title 2", "title 3"],
								report: { title: "TITLE", subtitle: "SUBITITLE", ...templateReport.data },
							},
						}),
					20,
				);
			});
		}

		async function getCommentaryData(uuid: string, enhanced?: boolean): Promise<CustomReportDataUnion> {
			return {
				template: "storyfolio",
				payload: {
					commentary: await axiosExtract(
						enhanced ? enhanceInvestmentReportApi.getCommentaries1(uuid) : reportInvestmentApi.getCommentaries(uuid),
					),
					portfolio: await axiosExtract(
						enhanced
							? enhanceInvestmentReportApi.getInvestmentEnhancementSummary(uuid)
							: reportInvestmentApi.getInvestmentSummary(uuid),
					),
					responseType: "storyfolio",
					report: { title: "COMMENTARY", subtitle: "" },
				},
			};
		}

		return { GetPortfolioDetailsData, GetCustomReports, GetMixedPortfolio, GetMockedData, getCommentaryData };
	};

	const { refetch } = useQueryNoRefetch(["GetReportData"], {
		queryFn: () => {
			dispatch({ status: "loading" });
			const factory = ReportFactory();
			switch (requestParams.template) {
				case "custom-report":
					return factory.GetCustomReports();
				case "portfolio-reference":
					return factory.GetPortfolioDetailsData("portfolio-reference", requestParams.uuid);
				case "mixed-portfolio":
					return factory.GetMixedPortfolio(requestParams.uuid!, requestParams.historyEventUuid!);
				case "mocked":
					return factory.GetMockedData();
				case "storyfolio":
					return factory.getCommentaryData(requestParams.uuid!, requestParams.enhanced);
				default:
					throw Error("unable to load template data");
			}
		},
		onError: (e) => dispatch({ status: "error", message: e }),
		onSuccess: (data) => dispatch({ status: "success", data }),
	});

	return {
		state,
		refetch: async () => {
			await refetch();
		},
	};
};

export default useExtractReportsData;
