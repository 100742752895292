import type { IconName, StylableProps } from "@mdotm/mdotui/components";
import { Transition, NestedTransition, TinyIconButton, ScrollWrapper, Text } from "@mdotm/mdotui/components";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import {
	overrideClassName,
	overrideClassList,
	WrapIfStrOrNumOrEmpty,
	renderNodeOrFn,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import Separator from "./Separator";
import { useMemo } from "react";

export type SideDrawerProps = {
	expand: boolean;
	onExpandChange(expand: boolean): void;
	disableTransition?: boolean;
	widths?: [number, number];
	title?: NodeOrFn<{ expand: boolean; onExpandChange(expand: boolean): void }>;
	actionHeader?: NodeOrFn<{ expand: boolean; onExpandChange(expand: boolean): void }>;
	children?: NodeOrFn<{ expand: boolean; onExpandChange(expand: boolean): void }>;
	footer?: NodeOrFn<{ expand: boolean; onExpandChange(expand: boolean): void }>;
	/** @default 'Left' */
	toggleIcon?: IconName;
	/** @default 28 */
	toggleOffset?: number;
} & StylableProps;

export function SideDrawer(props: SideDrawerProps): JSX.Element {
	const expandedWidth = props.widths?.[1] ?? 464;

	const childrenProps = useMemo(
		() => ({ expand: props.expand, onExpandChange: props.onExpandChange }),
		[props.expand, props.onExpandChange],
	);

	const renderedFooter = renderNodeOrFn(props.footer, childrenProps);

	const transitionsEnabled = !(props.disableTransition ?? false);

	return (
		<Transition
			in={props.expand}
			classList={{ "shrink-0": true, "transition-[width]": transitionsEnabled }}
			enterFromStyle={{ width: props.widths?.[0] ?? 24 }}
			enterToStyle={{ width: expandedWidth }}
		>
			{({ style, classList }) => (
				<div
					className={overrideClassName("relative z-0", overrideClassList(classList, props.classList))}
					style={{ ...style, ...props.style }}
				>
					<div
						className="absolute right-[16px] translate-x-full rounded-r-[4px] z-10 flex items-center justify-center"
						style={{
							top: props.toggleOffset,
							boxShadow: "10px 0px 16px -1px rgb(0 0 0 / 0.1)",
							backgroundColor: themeCSSVars.palette_N20,
							width: 36,
							height: 36,
						}}
						data-qualifier={`SideDrawer/Status(${props.expand ? "expanded" : "closed"})`}
					>
						<div
							className="absolute pointer-events-none inset-y-0 right-0 left-[14px] rounded-r-[4px]"
							style={{
								border: `2px solid ${themeCSSVars.palette_N100}`,
								borderLeft: "none",
							}}
						/>
						<NestedTransition
							classList={{ "transition-transform": transitionsEnabled }}
							enterToClassList="scale-x-100"
							enterFromClassList="-scale-x-100"
						>
							{({ classList: buttonClassList }) => (
								<TinyIconButton
									onClick={() => props.onExpandChange(!props.expand)}
									icon={props.toggleIcon ?? "Left"}
									size={24}
									applyNegativeMargins
									color={themeCSSVars.palette_N400}
									classList={buttonClassList}
									data-qualifier="SideDrawer/Toggle"
									style={{
										position: "relative",
									}}
								/>
							)}
						</NestedTransition>
					</div>
					<div
						className="absolute inset-0 z-0 overflow-hidden"
						style={{
							boxShadow: "10px 0px 16px -1px rgb(0 0 0 / 0.1)",
							backgroundColor: themeCSSVars.palette_N20,
							border: `2px solid ${themeCSSVars.palette_N100}`,
							borderTopRightRadius: 0,
							borderBottomRightRadius: 0,
						}}
					>
						<NestedTransition
							classList="transition-opacity"
							enterFromClassList="opacity-0"
							enterToClassList="opacity-100"
							exitedClassList="hidden"
						>
							{({ classList: mainContentClassList }) => (
								<div className={overrideClassName(mainContentClassList, "absolute inset-y-0 right-0")}>
									<div className="flex flex-col h-full py-[10px]" style={{ width: expandedWidth }}>
										<div className="flex items-center justify-between px-4">
											<div>
												<WrapIfStrOrNumOrEmpty
													wrapper={(content) => (
														<Text type="Body/XL/Bold" title={content?.toString()} classList="truncate">
															{content}
														</Text>
													)}
												>
													{renderNodeOrFn(props.title, childrenProps)}
												</WrapIfStrOrNumOrEmpty>
											</div>
											<div>{renderNodeOrFn(props.actionHeader, childrenProps)}</div>
										</div>
										<ScrollWrapper
											outerContainerAppearance={{
												classList: "grow",
											}}
											classList="px-4"
										>
											{renderNodeOrFn(props.children, childrenProps)}
										</ScrollWrapper>
										{renderedFooter && (
											<div className="px-4">
												<Separator />
												<WrapIfStrOrNumOrEmpty
													wrapper={(content) => (
														<Text as="div" type="Body/S/Book">
															{content}
														</Text>
													)}
												>
													{renderNodeOrFn(props.footer, childrenProps)}
												</WrapIfStrOrNumOrEmpty>
											</div>
										)}
									</div>
								</div>
							)}
						</NestedTransition>
					</div>
				</div>
			)}
		</Transition>
	);
}
