import { DefaultTagLabels } from "$root/api/api-gen";
import { useSearchableInstrumentTable } from "$root/functional-areas/instruments/hooks";
import EditScoreButton from "$root/functional-areas/upload/Actions/EditScroreButton";
import type { UploadEntity } from "$root/pages/Portfolios/UploadPortfolioPage";
import { commonBatchActions } from "$root/ui-lib/interactive-collections/common-batch-actions";
import colorGenerator from "$root/utils/chart/colorGenerator";
import type { BatchActionsProps } from "@mdotm/mdotui/components";
import {
	ActionText,
	BatchActions,
	Button,
	DropdownMenu,
	DropdownMenuActionButton,
	Icon,
	Table,
	TextInput,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import type { MultiSelectCtx } from "@mdotm/mdotui/headless";
import { useMultiSelect } from "@mdotm/mdotui/headless";
import { generateUniqueDOMId, toClassListRecord } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { Set } from "immutable";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TagButton } from "../instruments/edit-tags";
import type { CompositionEditorAreaProps } from "./EditorComposition";
import type { CompositionEntry } from "./tools/shared";
import { instrumentHelpersFactory, type DeleteMode } from "./tools/shared";
import type { UseEditorBuilderResult } from "./tools/useEditorBuilder";
import type { InstrumentEditorColumnsProps } from "./tools/useEditorColumns";
import { useInstrumentEditorColumns } from "./tools/useEditorColumns";
import { spawnInstrumentsDialog } from "./actions/spawn-instrument-dialog";
import BigNumber from "bignumber.js";
import { IconWalls } from "$root/components/IconWall";

type TagProps = { value: string; color: string; deletable: boolean };

function CompositionAdders({
	area,
	entity,
	rows,
	compositionBuilder,
}: {
	rows: CompositionEntry[];
	tags: Array<TagProps>;
	compositionBuilder: UseEditorBuilderResult;
	area: CompositionEditorAreaProps;
	entity: UploadEntity;
}) {
	const { t } = useTranslation();
	return (
		<DropdownMenu
			position="bottom"
			align="endToEnd"
			trigger={(props) => (
				<Button
					palette="secondary"
					size="small"
					classList="flex gap-2"
					{...props}
					data-qualifier="CompositionEditor/HeaderAction/DropdownMenu"
				>
					<Icon icon="add-ptf" size={18} />
					{t("BUTTON.ADD")}
				</Button>
			)}
			actions={[
				({ onClose }) => (
					<DropdownMenuActionButton
						onClick={() => {
							spawnInstrumentsDialog({
								area,
								entity,
								instruments: rows.flatMap((ac) => (ac.ticker ? [ac.ticker] : [])),
								onSubmit: (x) => console.log(x),
							});
							onClose();
						}}
						data-qualifier="CompositionEditor/HeaderAction/DropdownMenu(AddInstrument)"
					>
						Add instruments
					</DropdownMenuActionButton>
				),
				({ onClose }) => (
					<DropdownMenuActionButton
						onClick={() => {
							const id = generateUniqueDOMId();
							compositionBuilder.set(id, { rowType: "ADD", weight: BigNumber(0), alias: "", id });
							onClose();
						}}
						data-qualifier="CompositionEditor/HeaderAction/DropdownMenu(AddRow)"
					>
						Add row
					</DropdownMenuActionButton>
				),
			]}
		/>
	);
}

function BulkActions({
	multiSelectCtx,
	compositionBuilder,
	deleteMode,
	rows,
	entity,
	tags,
}: {
	multiSelectCtx: MultiSelectCtx<string>;
	compositionBuilder: UseEditorBuilderResult;
	deleteMode: DeleteMode;
	tags: Array<TagProps>;
	rows: CompositionEntry[];
	entity: UploadEntity;
}) {
	const { t } = useTranslation();
	const instrumentHelpers = instrumentHelpersFactory(compositionBuilder);
	const deleted = compositionBuilder.getDeleted();
	const BatchActionsMap: Record<DeleteMode, BatchActionsProps["actions"][number]> = useMemo(
		() => ({
			soft: commonBatchActions.deleteRestore({
				t,
				deleted,
				selection: multiSelectCtx.data.selection,
				onDeletedChange: (newDeleted) => instrumentHelpers.onDelete("soft", newDeleted.toArray()),
			}),
			hard: {
				label: "Delete all",
				disabled: multiSelectCtx.data.selection.size === 0,
				onClick: () => instrumentHelpers.onDelete("hard", multiSelectCtx.data.selection.toArray()),
			},
		}),
		[deleted, instrumentHelpers, multiSelectCtx.data.selection, t],
	);

	return (
		<div className="flex">
			<BatchActions
				selected={multiSelectCtx.data.selection.size}
				classList="my-2"
				total={rows.length}
				actions={[BatchActionsMap[deleteMode]]}
			/>
			{entity === "UNIVERSE" && (
				<>
					<TagButton
						options={tags}
						onClick={(opts) => instrumentHelpers.onBulkChangeTagLabel(multiSelectCtx.data.selection.toArray(), opts)}
						value={null}
						enableDebounce
						trigger={({ innerRef, handleFloatingStatus }) => (
							<ActionText
								as="span"
								onClick={multiSelectCtx.data.selection.size === 0 ? console.log : handleFloatingStatus}
								disabled={multiSelectCtx.data.selection.size === 0}
								innerRef={innerRef}
							>
								Edit Tags
							</ActionText>
						)}
					/>
					<ActionText
						as="button"
						onClick={() => instrumentHelpers.onBulkChangeTagLabel(multiSelectCtx.data.selection.toArray(), undefined)}
						disabled={multiSelectCtx.data.selection.size === 0}
					>
						Restore Tags
					</ActionText>
					<EditScoreButton
						disabled={multiSelectCtx.data.selection.size === 0}
						onApply={(score) => instrumentHelpers.onBulkChangeScore(multiSelectCtx.data.selection.toArray(), score)}
					/>
				</>
			)}
		</div>
	);
}

const CompositionTable = (props: {
	rows: CompositionEntry[];
	tags: Array<TagProps>;
	compositionBuilder: UseEditorBuilderResult;
	deleteMode: DeleteMode;
	area: CompositionEditorAreaProps;
	entity: UploadEntity;
	multiSelectCtx: MultiSelectCtx<string>;
}) => {
	const { compositionBuilder, entity, area, deleteMode, rows, tags, multiSelectCtx } = props;
	const deleted = compositionBuilder.getDeleted();

	const {
		column: checkBoxColumn,
		rowClassList,
		toggle,
	} = useSelectableTableColumn({
		rows,
		selectBy: ({ id }) => id ?? "",
		multiSelectCtx,
	});

	const instrumentHelpers = instrumentHelpersFactory(compositionBuilder);
	const instrumentColumnsParams = useCallback(
		(entity: UploadEntity): InstrumentEditorColumnsProps => {
			if (entity === "UNIVERSE") {
				return {
					area,
					compositionBuilder,
					entity,
					existingTags: tags,
					onChangeIdentifier: instrumentHelpers.onChangeIdentifier,
					onChangeScore: instrumentHelpers.onChangeScore,
					onChangeTag: instrumentHelpers.onChangeTagLabel,
					onDelete: (key) => instrumentHelpers.onDelete(deleteMode, key),
				};
			}

			if (entity === "INVESTMENT_ENHANCEMENT") {
				return {
					area,
					compositionBuilder,
					entity,
					onChangeWeight: instrumentHelpers.onChangeWeight,
					onDelete: (key) => instrumentHelpers.onDelete(deleteMode, key),
				};
			}

			return {
				area,
				compositionBuilder,
				entity,
				onDelete: (key) => instrumentHelpers.onDelete(deleteMode, key),
				onChangeWeight: instrumentHelpers.onChangeWeight,
				onChangeIdentifier: instrumentHelpers.onChangeIdentifier,
			};
		},
		[area, compositionBuilder, deleteMode, instrumentHelpers, tags],
	);

	const columns = useInstrumentEditorColumns(instrumentColumnsParams(entity));

	return (
		<Table
			palette="uniform"
			rows={props.rows}
			columns={[checkBoxColumn, ...columns]}
			visibleRows={Math.min(props.rows.length, 12)}
			rowClassList={(row, rowIndex) => ({
				...toClassListRecord(rowClassList(row, rowIndex)),
				CompositionEditorTableRow: true,
			})}
			enableVirtualScroll={props.rows.length > 300}
			noDataText={
				<div className="h-[60dvh] w-full">
					<IconWalls.EditorEmptyData entity={entity} />
				</div>
			}
			rowStyle={({ ticker }) => ({
				backgroundColor: deleted.has(ticker ?? "-") ? themeCSSVars.Table_highlightedRowBackgroundColor : undefined,
			})}
			onRowClick={({ id }) => toggle(id)}
		/>
	);
};

const defaultLabels = Object.values(DefaultTagLabels) as string[];
function CompositionEditor(props: {
	area: CompositionEditorAreaProps;
	entity: UploadEntity;
	compositionBuilder: UseEditorBuilderResult;
	deleteMode: DeleteMode;
}): JSX.Element {
	const { compositionBuilder, entity, ...forward } = props;
	const rows = useMemo(() => Array.from(compositionBuilder.getComposition().values()), [compositionBuilder]);
	const { query, filtered, setQuery } = useSearchableInstrumentTable(rows);
	const multiSelectCtx = useMultiSelect<string>();

	const tagLabels = useMemo(() => {
		if (entity !== "UNIVERSE") {
			return [];
		}

		const labelsIncomposition = rows.filter((x) => x.tagLabel).map((x) => x.tagLabel!);
		return Array.from(Set(defaultLabels.concat(labelsIncomposition))).map((tag, i) => ({
			value: tag as string,
			color: colorGenerator(i),
			deletable: defaultLabels.includes(tag) === false,
		}));
	}, [entity, rows]);

	return (
		<div className="bg-white rounded p-4">
			<div className="flex justify-between">
				<div className="max-w-[500px] flex-1">
					<TextInput
						leftContent={<Icon icon="Search" />}
						value={query}
						onChangeText={setQuery}
						placeholder="Filter by instrument name, ISIN, asset class or micro asset class"
					/>
				</div>
				<CompositionAdders
					rows={rows}
					compositionBuilder={compositionBuilder}
					tags={tagLabels}
					entity={entity}
					area={forward.area}
				/>
			</div>
			<BulkActions
				rows={filtered}
				entity={entity}
				tags={tagLabels}
				multiSelectCtx={multiSelectCtx}
				compositionBuilder={compositionBuilder}
				{...forward}
			/>
			<CompositionTable
				rows={filtered}
				entity={entity}
				tags={tagLabels}
				multiSelectCtx={multiSelectCtx}
				compositionBuilder={compositionBuilder}
				{...forward}
			/>
		</div>
	);
}

export default CompositionEditor;
