import type {
	EditorSaveEditOrReviewRequestPortfolioSavingModeEnum,
	InvestmentStatuses,
	ReferenceUniverseDetails,
	ReviewTicker,
} from "$root/api/api-gen";
import { EntityEditorControllerApiFactory, ReferenceUniversesControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { LabelRounded } from "$root/components/LabelRounded/Index";
import type { PageHeaderProps } from "$root/components/PageHeader";
import { PageHeader } from "$root/components/PageHeader";
import ReactQueryWrapper, { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import ReviewCompositionErrors from "$root/components/ReviewCompositionErrors";
import { useEventBus } from "$root/event-bus";
import EditCompositionSection from "$root/functional-areas/edit-composition/EditCompositionSection";
import { EditProxiedInstrumentSection } from "$root/functional-areas/proxies/EditProxiedInstrumentSection";
import { formatDate } from "$root/localization/formatters";
import type { CustomAxiosError } from "$root/third-party-integrations/axios";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { objMatchFn } from "$root/utils/objects";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { noop } from "$root/utils/runtime";
import { ellipsis } from "$root/utils/strings";
import { ModeVariants, UniverseContext } from "$root/widgets-architecture/contexts/universe";
import { Card } from "$root/widgets-architecture/layout/Card";
import {
	ActionText,
	AsyncButton,
	Banner,
	Button,
	CircularProgressBar,
	Icon,
	ProgressBar,
	Select,
	Table,
	Text,
} from "@mdotm/mdotui/components";

import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { spawnAccessDialog } from "$root/functional-areas/acl/AccessDialog";
import EntityStatus from "$root/functional-areas/acl/EntityStatus";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import { SmallUniverseSummary } from "$root/functional-areas/universe/SmallUniverseSummary";
import { useUserValue } from "$root/functional-areas/user";
import { platformToast } from "$root/notification-system/toast";
import WidgetsMapper from "$root/widgets-architecture/layout/WidgetsMapper";
import type { AxiosError } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { match } from "ts-pattern";
import "./Portfolios/Portfolios.scss";
import { PortfolioStudioTab } from "./PortfoliosStudio";
import { exportUniverse } from "$root/functional-areas/universe/export";
import { PageDownloadAndMoreActionsMenu } from "$root/components/PageDownloadAndMoreActionsMenu";
import { PageCtxConsumer } from "$root/components/PageCtx";
import { createPortal } from "react-dom";
import { PortfolioDetailsTabs } from "./PortfolioDetails";
import { useUniverseEntityManagementActions } from "$root/functional-areas/universe/entity-management";
import { nullary, unpromisify } from "@mdotm/mdotui/utils";

type PageSubtitleProps = {
	universe?: ReferenceUniverseDetails;
} & (
	| {
			mode: Extract<ModeVariants, "editor">;
			disabledOnSave?: boolean;
	  }
	| {
			mode: Extract<ModeVariants, "viewer">;
			onChangeAcl?: () => Promise<unknown>;
			onEdit: () => void;
			entityManagementActions: ReturnType<typeof useUniverseEntityManagementActions>;
	  }
	| {
			mode: Extract<ModeVariants, "review">;
			onChangeAcl?: () => Promise<unknown>;
			entityManagementActions: ReturnType<typeof useUniverseEntityManagementActions>;
	  }
);

const PageSubtitle = (props: PageSubtitleProps) => {
	const { t } = useTranslation();
	const { universe } = props;
	const { richAcl } = universe ?? {};
	const user = useUserValue();
	const canEditComposition = aclByArea.portfolio.canEditComposition(user.id, richAcl?.acl ?? []);

	if (props.mode === "editor" || props.mode === "review") {
		return <></>;
	}

	return (
		<div className="flex justify-between items-center py-2.5 text-[#585D68]">
			<div className="flex gap-4 items-center">
				<div className="flex items-center" data-qualifier="UniverseDetails/PageHeader/Status">
					<span className="mr-2 uppercase">{t("STATUS")}</span>
					<LabelRounded type="status" content={{ label: universe?.status ?? "-", component: "" }} />
				</div>
				<div>
					<span className="mr-2 uppercase">{t("INCEPTION_DATE")}:</span>
					{universe?.creationTime ? formatDate(universe.creationTime) : "..."}
				</div>
				<div>
					<span className="mr-2 uppercase">{t("PORTFOLIOS.PF_LAST_ACTION")}:</span>
					<span className="font-semibold text-[#616161]">{universe?.action}</span>
					&nbsp;
					{universe?.modificationTime ? <span>({formatDate(universe?.modificationTime)})</span> : "..."}
				</div>
				<EntityStatus
					accessControl={richAcl}
					entity="UNIVERSE"
					entityId={universe?.uuid}
					entityName={universe?.name}
					refetch={props.onChangeAcl}
				/>
			</div>
			<div className="flex gap-2 items-center">
				{match(props)
					.when(
						(x) => x.mode === "viewer" && universe?.status === "READY" && canEditComposition,
						(x) => (
							<Button palette="primary" size="small" onClick={x.onEdit}>
								<Icon icon="Edit" size={16} />
								&nbsp;
								{t("BUTTON.EDIT")}
							</Button>
						),
					)
					.otherwise(() => (
						<></>
					))}
				<PageDownloadAndMoreActionsMenu
					area="universe"
					downloadActions={
						universe?.status === "READY"
							? [
									{
										icon: "xls",
										disabled: !universe?.uuid,
										onClickAsync: async () => {
											if (!universe!.uuid) {
												throw new Error("unable to load a universe of undefined");
											}
											await exportUniverse.downloadUniverse(universe!.uuid);
										},
										label: "Universe template",
										"data-qualifier": "UniverseDetails/DropdownMenu/DropdownItem(UniverseTemplate)",
									},
									{
										icon: "xls",
										disabled: !universe?.uuid,
										onClickAsync: async () => {
											if (!universe!.uuid) {
												throw new Error("unable to load a universe of undefined");
											}
											await exportUniverse.downloadComposition(universe!.uuid);
										},
										label: "Instrument list",
										"data-qualifier": "UniverseDetails/DropdownMenu/DropdownItem(InstrumentList)",
									},
							  ]
							: undefined
					}
					moreActions={[
						aclByArea.universe.canDelete(user.id, universe?.richAcl?.acl ?? []) && {
							icon: "Delete",
							"data-qualifier": "UniverseDetails/DropdownMenu/DropdownItem(Delete)",
							disabled: !props.entityManagementActions.deleteAsync,
							onClick: unpromisify(props.entityManagementActions.deleteAsync ?? noop),
							label: "Delete",
						},
						{
							icon: "Content-Copy",
							"data-qualifier": "UniverseDetails/DropdownMenu/DropdownItem(Duplicate)",
							disabled: !props.entityManagementActions.duplicateAsync,
							onClick: unpromisify(props.entityManagementActions.duplicateAsync ?? noop),
							label: "Duplicate",
						},
						props.entityManagementActions.renameAsync && {
							icon: "Edit",
							"data-qualifier": "UniverseDetails/DropdownMenu/DropdownItem(Rename)",
							onClick: unpromisify(props.entityManagementActions.renameAsync ?? noop),
							label: "Rename",
						},
					]}
				/>
			</div>
		</div>
	);
};

type UniverseDetailsMapProps = {
	titleBlock: {
		title: string;
		showFilter: boolean;
	};
};

type PickedStatus = Extract<InvestmentStatuses, "CALCULATING" | "REVIEW" | "RETRIEVING_DATA" | "READY" | "ERROR">;
interface IpageUrl {
	universeUuid: string;
	mode?: ModeVariants;
}

const severityByStatus: Record<InvestmentStatuses, PageHeaderProps["severity"]> = {
	ACCEPTED: "success",
	READY: "success",
	PROPOSAL_READY: "info",
	ERROR: "error",
	CALCULATING: "calculating",
	REVIEW: undefined,
	RETRIEVING_DATA: "calculating",
	DRAFT: "info",
};

const UNIVERSE_DETAILS_MAP: Record<ModeVariants, UniverseDetailsMapProps> = {
	viewer: {
		titleBlock: {
			title: "Universe details",
			showFilter: true,
		},
	},
	editor: {
		titleBlock: {
			title: "Edit universe composition",
			showFilter: false,
		},
	},
	review: {
		titleBlock: {
			title: "Universe details",
			showFilter: true,
		},
	},
};

export const MODES: Record<PickedStatus, ModeVariants> = {
	READY: "viewer",
	CALCULATING: "viewer",
	ERROR: "viewer",
	RETRIEVING_DATA: "viewer",
	REVIEW: "review",
};

function UniverseDetails(): JSX.Element {
	const { universeUuid, mode = "viewer" } = useParams<IpageUrl>();

	const [isOpen, setIsOpen] = useState(false);
	const [editorComposition, setEditorComposition] = useState<{ disabled: boolean } | null>(null);
	const [pathToNotBlock, setPathToNotBlock] = useState(["/login"]);

	const history = useHistory();
	const user = useUserValue();
	const { t } = useTranslation();
	const { push } = useTypedNavigation();

	const referenceUniverseV4Api = useApiGen(ReferenceUniversesControllerApiFactory);
	const editorApi = useApiGen(EntityEditorControllerApiFactory);

	const {
		titleBlock: { showFilter },
	} = UNIVERSE_DETAILS_MAP[mode];

	useEffect(() => {
		if (!universeUuid) {
			history.push({ pathname: "/portfolios", search: `status=notFound&tab=${encodeURIComponent("Universes")}` });
		}
	}, [history, universeUuid]);

	const queryUniverseList = useQueryNoRefetch(["universeList"], {
		queryFn: () => axiosExtract(referenceUniverseV4Api.getUserUniverses()),
	});

	const {
		data: universe,
		isLoading,
		refetch,
	} = useQueryNoRefetch(["universeDetails", mode, universeUuid], {
		enabled: Boolean(universeUuid),
		queryFn: () => axiosExtract(referenceUniverseV4Api.getUniverse(universeUuid)),
		onSuccess: (universeResponse) => {
			const modeByStatus = universeResponse?.status
				? MODES[universeResponse?.status as keyof typeof MODES]
				: MODES.ERROR;
			if (mode !== modeByStatus && mode !== "editor") {
				push("UniverseDetails", { mode: modeByStatus, universeUuid });
			}
		},
		onError: (error: AxiosError<CustomAxiosError>) => {
			if (error.response?.data.code === 404 || error.response?.data.message === "Accesso negato") {
				spawnAccessDialog({
					onClick: (onClose) => {
						push("PortfoliosStudio", { tab: PortfolioStudioTab.Universes });
						onClose();
					},
				});
				return;
			}

			platformToast({
				children: t("SOMETHING_WENT_WRONG"),
				severity: "error",
				icon: "Portfolio",
			});
			push("PortfoliosStudio", { status: "notFound", tab: PortfolioStudioTab.Universes });
		},
	});

	useEventBus("investment-update", {
		filter: objMatchFn({ uuid: universeUuid }),
		listener: () => {
			refetch().catch(noop);
		},
	});

	useEventBus("shared-entity", {
		filter: objMatchFn({ sharedEntityUuid: universeUuid }),
		listener: () => {
			refetch().catch(noop);
		},
	});

	const { status, uuid, name } = universe ?? {};
	const canEditComposition = aclByArea.portfolio.canEditComposition(user.id, universe?.richAcl?.acl ?? []);

	const onChangeUniverseUuid = useCallback(
		(id: string) => {
			push("UniverseDetails", { mode: ModeVariants.viewer, universeUuid: id });
		},
		[push],
	);

	const entityManagementActions = useUniverseEntityManagementActions(universe, {
		onRename: unpromisify(nullary(refetch)),
		onDuplicate: () => push("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }),
		onDelete: () => push("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }),
	});

	const manageFinish = useCallback(
		(path: string) => {
			setPathToNotBlock([path]);
			setTimeout(() => history.push(path));
		},
		[history],
	);

	const onSave = useCallback(
		async (payload: {
			composition: ReviewTicker[];
			formData: {
				saveMode: "SAVE" | "SAVE_AS_NEW";
				name: string;
				portfolioSavingMode: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum | undefined;
			};
		}) => {
			try {
				if (payload.formData.saveMode === "SAVE_AS_NEW") {
					const { identifier } = await axiosExtract(
						editorApi.saveEditorNewEntity("UNIVERSE", {
							name: payload.formData.name,
							tickerComposition: payload.composition,
							portfolioSavingMode: payload.formData.portfolioSavingMode,
						}),
					);
					setIsOpen(false);
					manageFinish(`/universe_details/viewer/${identifier}`);
					return;
				}

				if (universeUuid === undefined) {
					return reportPlatformError(
						universeUuid,
						"ERROR",
						"universe",
						`unable to submit a valid universe of undefined`,
					);
				}

				const { identifier } = await axiosExtract(
					editorApi.saveEditorEditEntity("UNIVERSE", {
						identifier: universeUuid,
						tickerComposition: payload.composition,
						portfolioSavingMode: payload.formData.portfolioSavingMode,
					}),
				);

				setIsOpen(false);
				manageFinish(`/universe_details/viewer/${identifier}`);
			} catch (error) {
				reportPlatformError(
					error,
					"ERROR",
					"portfolio",
					`unable to save the edited universe composition (${universeUuid})`,
				);
				throw error;
			}
		},
		[editorApi, universeUuid, manageFinish],
	);

	return (
		<>
			<PageHeader
				name="UniverseDetails"
				severity={severityByStatus[status as InvestmentStatuses]} // TODO: is this the same enum from Portfolio?
				title={
					isLoading
						? "..."
						: mode === "editor"
						  ? t("PLACEHOLDER.TITLE.EDIT_UNIVERSE_COMPOSITION")
						  : name
						    ? name
						    : "Untitled"
				}
				titleAction={
					mode === "editor" && (
						<div className="flex items-center space-x-2">
							<Button size="small" onClick={() => history.goBack()} palette="tertiary">
								{t("BUTTON.CANCEL")}
							</Button>
							<Button
								size="small"
								onClick={() => setIsOpen(!isOpen)}
								palette="primary"
								disabled={editorComposition?.disabled ?? true}
							>
								{t("BUTTON.SAVE")}
							</Button>
						</div>
					)
				}
				crumbs={[
					{
						children: "Portfolio studio", // TODO: translate
						href: typedUrlForRoute("PortfoliosStudio", {}),
					},
					{
						children: "Universes", // TODO: translate
						href: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }),
					},
					...(mode === "editor"
						? [
								{
									children: ellipsis(name ?? "", 20),
									href: typedUrlForRoute("UniverseDetails", { mode: ModeVariants.viewer, universeUuid }),
								},
								{ children: "Edit" },
						  ]
						: [
								{
									children: !showFilter ? (
										ellipsis(name ?? "", 20)
									) : (
										<ReactQueryWrapperBase
											query={queryUniverseList}
											loadingFallback={
												<CircularProgressBar value="indeterminate" outerDiameter={12} innerDiameter={8} />
											}
										>
											{(universes) => (
												<Select
													unstyled
													classList="flex items-center w-40 truncate"
													strategy="fixed"
													enableSearch
													options={universes.map(({ name, uuid }) => ({ label: name!, value: uuid! })).reverse()}
													value={uuid}
													i18n={{ triggerPlaceholder: () => t("UNIVERSE.SELECT_PLACEHOLDER") }}
													onChange={onChangeUniverseUuid}
												/>
											)}
										</ReactQueryWrapperBase>
									),
								},
						  ]),
				]}
				subTitle={match(mode)
					.with("editor", (x) => <PageSubtitle mode={x} universe={universe} />)
					.with("review", (x) => (
						<PageSubtitle
							entityManagementActions={entityManagementActions}
							mode={x}
							universe={universe}
							onChangeAcl={refetch}
						/>
					))
					.with("viewer", (x) => (
						<PageSubtitle
							entityManagementActions={entityManagementActions}
							mode={x}
							universe={universe}
							onChangeAcl={refetch}
							onEdit={() => push("UniverseDetails", { universeUuid: universe?.uuid ?? "", mode: ModeVariants.editor })}
						/>
					))
					.exhaustive()}
			/>
			<UniverseContext.Provider value={{ mode, universe: universe ?? null }}>
				{mode !== "editor" && (
					<div className="mb-5">
						<SmallUniverseSummary universe={universe} />
					</div>
				)}
				{/* TODO: test review errors */}
				{mode === "review" && (
					<ReactQueryWrapper
						queryFn={() => axiosExtract(editorApi.getEditorReviewComposition(universe?.uuid ?? "", "UNIVERSE"))}
						queryKey={["universeUploadComposition", universe?.uuid, universe?.status]}
						enabled={Boolean(universe?.uuid)}
						loadingFallback={<ProgressBar value="indeterminate" />}
					>
						{(upload) => (
							<Card>
								<Text type="Body/XL/Bold" classList="mb-4">
									Instruments
								</Text>
								<ReviewCompositionErrors errors={upload?.uploadErrors} />
								<div className="mb-4">
									<Banner severity="warning" title="Review Before Saving">
										Some instruments require your attention. This may be due to missing information or because they have
										been delisted. Please review the list below and make any necessary updates before saving.
									</Banner>
								</div>
								<EditProxiedInstrumentSection
									uuid={universe?.uuid}
									disabled={canEditComposition === false}
									section="universe"
									composition={upload?.composition ?? []}
									onSaveAsync={async (tickerComposition) => {
										await editorApi.saveEditorReviewEntity("UNIVERSE", {
											identifier: universe?.uuid,
											tickerComposition,
										});
										await refetch();
									}}
									customActions={({ isSaveDisabled, onSaveAsync }) => (
										<PageCtxConsumer>
											{(ctx) =>
												ctx.titleActionPortalTarget &&
												createPortal(
													<div className="flex items-center gap-2">
														<Button
															onClick={() => push("PortfoliosStudio", { tab: PortfolioStudioTab.Universes })}
															size="small"
															palette="tertiary"
														>
															Cancel
														</Button>

														<AsyncButton
															palette="primary"
															size="small"
															onClickAsync={onSaveAsync}
															disabled={isSaveDisabled}
														>
															{t("BUTTON.SAVE")}
														</AsyncButton>
													</div>,
													ctx.titleActionPortalTarget,
												)
											}
										</PageCtxConsumer>
									)}
								/>
							</Card>
						)}
					</ReactQueryWrapper>
				)}

				{mode === "editor" && (
					<EditCompositionSection
						instrumentsLimit={1500}
						uploadEntity="UNIVERSE"
						uuid={universe?.uuid}
						ref={setEditorComposition}
						submitForm={{
							isOpen,
							onCancel: () => setIsOpen(!isOpen),
							onSubmit: onSave,
						}}
						pathToNotBlock={pathToNotBlock}
					/>
				)}

				{mode === "viewer" && (
					<>
						{status === "RETRIEVING_DATA" ? (
							<ReactQueryWrapper
								queryFn={() => axiosExtract(editorApi.getEditorReviewComposition(universe?.uuid ?? "", "UNIVERSE"))}
								queryKey={["retriveUniverseComposition", universe?.uuid, universe?.status]}
								enabled={Boolean(universe?.uuid)}
								loadingFallback={<ProgressBar value="indeterminate" />}
							>
								{({ composition }) => (
									<Card>
										<div className="mb-4">
											<Banner severity="info">{t("PORTFOLIO_UPLOAD_ALERTS.CALCULATING_UPLOAD")}</Banner>
										</div>
										<Table
											columns={[
												{
													header: "name",
													content: (row) => {
														if (row.proxyOverwriteType !== "PORTFOLIO_MIXED") {
															return row.instrument ?? "-";
														}

														return (
															<div className="flex flex-row items-center flex-nowrap w-full pr-4">
																<ActionText
																	classList="inline-flex items-center gap-1"
																	onClick={() =>
																		window.open(
																			typedUrlForRoute("PortfolioDetails", {
																				portfolioUid: row.ticker ?? "",
																				tab: PortfolioDetailsTabs.COMPOSITION,
																			}),
																			"_blank",
																		)
																	}
																>
																	<span className="font-[weight:500] truncate items-center">
																		{row.instrument ?? "-"}
																	</span>
																	<svg
																		width="12"
																		height="12"
																		viewBox="0 0 12 12"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																		className="shrink-0"
																	>
																		<path
																			d="M8 1.5H10.5V4"
																			stroke="currentColor"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7 5L10.5 1.5"
																			stroke="currentColor"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M8.5 7.4375V9.625C8.5 10.1084 8.10844 10.5 7.625 10.5H2.375C1.89156 10.5 1.5 10.1084 1.5 9.625V4.375C1.5 3.89156 1.89156 3.5 2.375 3.5H4.5625"
																			stroke="currentColor"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</ActionText>
															</div>
														);
													},
												},
												{ header: "Identifier", content: (row) => row.identifier ?? "-" },
												{ header: "Asset Class", content: (row) => row.assetClass ?? "-" },
												{ header: "Micro Asset Class", content: (row) => row.microAssetClass ?? "-" },
											]}
											rows={composition ?? []}
											visibleRows={Math.min(composition?.length ?? 0, 12)}
										/>
									</Card>
								)}
							</ReactQueryWrapper>
						) : (
							<WidgetsMapper style={{ minHeight: "120px" }} widgetName="ReadonlyUniverseComposition" />
						)}
					</>
				)}
			</UniverseContext.Provider>
		</>
	);
}

export default UniverseDetails;
