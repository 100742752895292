import type { CommentaryTemplateModel } from "$root/api/api-gen";
import { CommentaryTemplateControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { DebouncedSearchInput } from "$root/components/DebouncedSearchInput";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import { spawnDeleteDialog } from "$root/components/spawnable/entity-management/delete-dialog";
import { spawnDuplicateDialog } from "$root/components/spawnable/entity-management/duplicate-dialog";
import { spawnRenameDialog } from "$root/components/spawnable/entity-management/rename-dialog";
import { SentimentBadge } from "$root/functional-areas/market-view/analysis/SentimentBadge";
// import { CommentaryTemplateDto } from "$root/functional-areas/storyfolio/definition";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { actionsColumn } from "$root/ui-lib/interactive-collections/common-table-actions";
import { builtInCaseInsensitiveSortFor, builtInSortFnFor } from "$root/utils/collections";
import { parallelize, type Abortable } from "$root/utils/promise";
import { noop } from "$root/utils/runtime";
import { Card } from "$root/widgets-architecture/layout/Card";
import type { ActionOrActionWithGroup, OrderBy } from "@mdotm/mdotui/components";
import {
	ActionText,
	AsyncButton,
	Badge,
	BatchActions,
	Controller,
	DropdownMenuActionButton,
	Icon,
	TableV2,
	TinyIconButton,
	sortRows,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useMemo, useState } from "react";

function StoryfolioStudio(): JSX.Element {
	const commentaryTemplateApi = useApiGen(CommentaryTemplateControllerApiFactory);
	const { push } = useTypedNavigation();

	return (
		<ReactQueryWrapper
			queryKey={["getStoryfolioStudioList"]}
			queryFn={() => axiosExtract(commentaryTemplateApi.getTemplateList())}
		>
			{(rows, query) => (
				<StoryfolioStudioInner
					rows={rows}
					checkIfNameIsAvailable={(name) => axiosExtract(commentaryTemplateApi.isInvestmentNameAvailable1(name))}
					onRename={async (uuid, name, row) => {
						await commentaryTemplateApi.updateTemplate(uuid, { ...row, name });
						await query.refetch();
					}}
					onBulkDelete={async (uuids) => {
						let successCounter = 0;
						await parallelize(
							uuids.map((uuid) => () => commentaryTemplateApi.deleteTemplate(uuid).then(() => successCounter++)),
						);
						platformToast({
							children: `You have successfully deleted “${successCounter}” templates`,
							icon: "Ask-ai",
							severity: "success",
						});
						await query.refetch();
					}}
					onCreate={() => push("Storyfolio/Creation", {})}
					onDelete={async (uuid, row) => {
						await commentaryTemplateApi.deleteTemplate(uuid);
						platformToast({
							children: `You have successfully deleted “${row.name}” template`,
							icon: "Ask-ai",
							severity: "success",
						});
						await query.refetch();
					}}
					onDuplicate={async (name, row) => {
						await commentaryTemplateApi.createTemplate({
							...row,
							name,
							default: false,
						});
						await query.refetch();
					}}
					toggleVisibility={async (row) => {
						await commentaryTemplateApi.updateTemplate(row.uuid!, {
							...row,
							visible: !row.visible, //TODO: api need to be aligned
						});
						await query.refetch();
						/** TODO */
					}}
				/>
			)}
		</ReactQueryWrapper>
	);
}

const StoryfolioStudioInner = (props: {
	rows: Array<CommentaryTemplateModel>;
	onCreate: () => MaybePromise<void>;
	onDelete: (uuid: string, row: CommentaryTemplateModel) => MaybePromise<void>;
	onRename: (uuid: string, newName: string, row: CommentaryTemplateModel) => MaybePromise<void>;
	onDuplicate: (name: string, row: CommentaryTemplateModel) => MaybePromise<void>;
	checkIfNameIsAvailable: (name: string, opts?: Abortable) => MaybePromise<boolean>;
	toggleVisibility: (row: CommentaryTemplateModel) => MaybePromise<void>;
	onBulkDelete: (uuids: string[]) => Promise<void>;
}): JSX.Element => {
	const { toggleVisibility, onDelete, onRename, onDuplicate, checkIfNameIsAvailable } = props;

	const [query, setQuery] = useState("");

	const rows = useMemo(
		() =>
			props.rows.reduce<{ default: Array<CommentaryTemplateModel>; custom: Array<CommentaryTemplateModel> }>(
				(acc, el) => {
					if (el.default) {
						acc.default.push(el);
					} else {
						acc.custom.push(el);
					}
					return acc;
				},
				{ default: [], custom: [] },
			),
		[props.rows],
	);

	const filtered = useMemo(
		() => rows.custom.filter((item) => item.name?.toLowerCase().includes(query.toLowerCase()), []),
		[rows.custom, query],
	);

	const checkboxColumnData = TableV2.useSelectableTableColumn({
		rows: props.rows,
		selectBy: (r) => r.uuid ?? "",
		selectableRowIds: props.rows.flatMap((r) => (r.default ? [] : [r.uuid ?? ""])),
		filteredRows: filtered,
	});

	const someDefaultTemplateIsSelected = useMemo(
		() =>
			checkboxColumnData.multiSelectCtx.data.selection
				.toArray()
				.some((uuid) => props.rows.find((x) => (x.uuid === uuid ? x.default ?? false : false))),
		[checkboxColumnData.multiSelectCtx.data.selection, props.rows],
	);

	const columns = useMemo<Array<TableV2.TableColumn<CommentaryTemplateModel, string>>>(
		() => [
			checkboxColumnData.column,
			{
				name: "name",
				header: "Name",
				content: (row, cellProps) => (
					<TableV2.TableDataCell {...cellProps}>
						<div className="flex space-x-2 items-center">
							<ActionText
								href={typedUrlForRoute("Storyfolio/Details", {
									uuid: row.uuid!,
								})}
							>
								{row.name}
							</ActionText>
							{row.default && (
								<Badge size="x-small" backgroundColor={themeCSSVars.palette_N200} color={themeCSSVars.palette_N800}>
									Default
								</Badge>
							)}
						</div>
					</TableV2.TableDataCell>
				),
				width: 1008,
				sortFn: builtInCaseInsensitiveSortFor("name"),
			},
			{
				name: "visibility",
				sortFn: builtInSortFnFor("visible"),
				header: (headerProps) => (
					<TableV2.TableHeadCell {...headerProps}>
						<span className="line-clamp-2">Available on menu</span>
					</TableV2.TableHeadCell>
				),
				content: (row, contentProps) => (
					<TableV2.TableDataCell {...contentProps} onClick={(e) => e.stopPropagation()}>
						<TinyIconButton
							size={20}
							color={themeCSSVars.palette_N400}
							icon={row.visible ? "show" : "Hide"}
							onClickAsync={() => toggleVisibility(row)}
						/>
					</TableV2.TableDataCell>
				),
				width: 160,
			},
			actionsColumn({
				hideHeader: true,
				onSettingsClick: noop,
				dropdownActions: (row) =>
					row.default
						? [
								{
									children: ({ onClose }) => (
										<DropdownMenuActionButton
											onClick={() => {
												spawnDuplicateDialog({
													entityType: "template",
													onSubmitAsync: ({ param: name }) => onDuplicate(name, row),
													checkIfNameIsAvailable,
													originalName: row.name!,
													placeholder: "Template 123",
												}).catch(noop);
												onClose();
											}}
											icon="Content-Copy"
										>
											Duplicate
										</DropdownMenuActionButton>
									),
								},
						  ]
						: ([
								{
									children: ({ onClose }) => (
										<DropdownMenuActionButton
											onClick={() => {
												spawnDeleteDialog({
													entityType: "template",
													entityName: row.name ?? "",
													onDeleteAsync: () => onDelete(row.uuid!, row),
												}).catch(noop);
												onClose();
											}}
											icon="Delete"
										>
											Delete
										</DropdownMenuActionButton>
									),
								},
								{
									children: ({ onClose }) => (
										<DropdownMenuActionButton
											onClick={() => {
												spawnRenameDialog({
													entityType: "template",
													onSubmitAsync: ({ param: name }) => onRename(row.uuid!, name, row),
													checkIfNameIsAvailable,
													currentName: row.name!,
													placeholder: "Template 123",
												}).catch(noop);

												onClose();
											}}
											icon="Edit"
										>
											Rename
										</DropdownMenuActionButton>
									),
								},
								{
									children: ({ onClose }) => (
										<DropdownMenuActionButton
											onClick={() => {
												spawnDuplicateDialog({
													entityType: "template",
													onSubmitAsync: ({ param: name }) => onDuplicate(name, row),
													checkIfNameIsAvailable,
													originalName: row.name!,
													placeholder: "Template 123",
												}).catch(noop);
												onClose();
											}}
											icon="Content-Copy"
										>
											Duplicate
										</DropdownMenuActionButton>
									),
								},
						  ] satisfies Array<ActionOrActionWithGroup<string>>),
			}),
		],
		[checkboxColumnData.column, toggleVisibility, onDelete, checkIfNameIsAvailable, onRename, onDuplicate],
	);

	return (
		<>
			<PageHeader title="Storyfolio" />
			<Card>
				<div className="flex flex-row justify-between">
					<div className="max-w-md  grow">
						<DebouncedSearchInput query={query} onChange={setQuery} placeholder="Filter by name" />
					</div>
					<div>
						<AsyncButton onClickAsync={() => props.onCreate()} palette="secondary" size="small">
							<Icon icon="Outline1" size={16} />
							&nbsp;New template
						</AsyncButton>
					</div>
				</div>
				<BatchActions
					selected={checkboxColumnData.multiSelectCtx.data.selection.size}
					total={props.rows.length}
					actions={[
						{
							label: "Delete",
							icon: "Delete",
							...(someDefaultTemplateIsSelected
								? {
										onClick: noop,
										disabled: true,
										tooltip: {
											children: "Default templates cannot be deleted",
											overrideColor: themeCSSVars.palette_N300,
										},
								  }
								: {
										onClickAsync: () => props.onBulkDelete(checkboxColumnData.multiSelectCtx.data.selection.toArray()),
								  }),
						},
					]}
					classList="my-2"
				/>
				<Controller value={defaultReportOrderBy}>
					{({ value: orderBy, onChange: onOrderByChange }) => (
						<TableV2.BaseHScrollTable
							palette="uniform"
							style={{ maxHeight: "calc(100dvh - 295px)" }}
							columns={columns}
							rows={[...rows.default, ...sortRows({ rows: filtered, columns, orderByArr: orderBy })]}
							orderBy={orderBy}
							onOrderByChange={onOrderByChange}
							pinnedColumns={[
								{ name: "name", side: "left" },
								{ name: "settings-action", side: "right" },
							]}
							onRowClick={(row) => (row.default ? checkboxColumnData.toggle(row.uuid ?? "") : noop)}
							rowClassList={checkboxColumnData.rowClassList}
						/>
					)}
				</Controller>
			</Card>
		</>
	);
};

const defaultReportOrderBy: Array<OrderBy<"name">> = [{ columnName: "name", direction: "asc" }];
export default StoryfolioStudio;
